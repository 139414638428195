import React, { useState, useEffect } from "react";
import "bulma/css/bulma.css";
import { Typography, message, Table, Button } from "antd";
import { createClient } from "@supabase/supabase-js";
import { SUPABASE_FROSH_URL } from "../../general/Constants/api";

const { Title } = Typography;
const supabaseUrl = SUPABASE_FROSH_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_FROSH_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const columns = [
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "first_name",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "last_name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (data) => <a href={data.href}>{data.email}</a>,
  },
  {
    title: "Date Created",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Actions",
    key: "action",
    render: (record) => (
      <span>
        <Button type="link" onClick={() => deleteAdmin(record.id)}>
          Delete
        </Button>
      </span>
    ),
  },
];

export const deleteAdmin = async (id) => {
  try {
    const { data } = await supabase.from("admin").delete().eq("id", id);
    message
      .success("User sucessfully deleted" + data)
      .then((e) => window.location.reload());
  } catch (err) {
    message.error(err);
  }
};

function Admin() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getAdminUsers() {
      try {
        let { body } = await supabase.from("admin").select("*");
        createData(body);
      } catch (err) {
        message.error(err);
      }
    }
    getAdminUsers();
  }, []);

  const createData = (admin) => {
    let d = [];
    for (let i = 0; i < admin.length; i++) {
      let email_href = "mailto:" + admin[i].email;
      d.push({
        key: i,
        first_name: admin[i].first_name,
        last_name: admin[i].last_name,
        id: admin[i].id,
        date: admin[i].date_created,
        email: { href: email_href, email: admin[i].email },
      });
    }
    setData(d);
  };

  return (
    <div>
      <Title style={{ textAlign: "center" }} level={1}>
        Admin List{" "}
      </Title>
      <div className="columns">
        <div className="column">
          <div className="box">
            <Table columns={columns} dataSource={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Admin;
