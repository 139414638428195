import React from "react";
import { message, Tag, Divider } from "antd";
import Amplify, { API, graphqlOperation, Cache } from "aws-amplify";
import aws_exports from "../../../../aws-exports";
import { getCollegeApp, addToApplying } from "../../components/Schools/helpers";
import { removeFavsInCache } from "../../../cacheHelpers";
import * as queries from "../../../../graphql/queries";
import * as mutations from "../../../../graphql/mutations";

Amplify.configure(aws_exports);

export const favsSumCols = [
  {
    title: "College/University",
    dataIndex: "college",
    key: "college",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Area Type",
    dataIndex: "area",
    key: "area",
  },
  {
    title: "Population",
    dataIndex: "students",
    key: "students",
  },
  {
    title: "Action",
    key: "action",
    render: (map) => (
      <div className="columns">
        <div className="column">
          <div
            className="button is-text is-small"
            onClick={() => {
              map["action"][3](true);
              addToApplying(map["action"][1], map["action"][2]).then((data) =>
                map["action"][3](false)
              );
            }}
          >
            Add to Applying
          </div>
        </div>
        <div className="column is-1">
          <Divider type="vertical" />
        </div>
        <div className="column">
          <div
            className="button is-text is-small"
            onClick={() =>
              deleteFav(map["action"][0]).then((data) =>
                removeFavsInCache(map["action"][0]).then((favsData) =>
                  window.location.reload()
                )
              )
            }
          >
            Delete
          </div>
        </div>
      </div>
    ),
  },
];

export const applyingFinCols = [
  {
    title: "College/University",
    dataIndex: "college",
    key: "college",
  },
  {
    title: "Net Price (per year)",
    dataIndex: "net_price",
    key: "net_price",
  },
  {
    title: "Tuition In / Out State",
    dataIndex: "tuition",
    key: "tuition",
  },
];

export const applyingAdmCols = [
  {
    title: "College/University",
    dataIndex: "college",
    key: "college",
  },
  {
    title: "Admissions Rate",
    dataIndex: "rate",
    key: "rate",
  },
  {
    title: "SAT Range",
    dataIndex: "sat",
    key: "sat",
  },
  {
    title: "ACT Range",
    dataIndex: "act",
    key: "act",
  },
];

const getAppStatus = (status) => {
  if (status === "0") {
    return "Started Application";
  } else if (status === "1") {
    return "Applied";
  } else if (status === "2") {
    return "Accepted";
  } else if (status === "3") {
    return "Waitlisted";
  } else if (status === "4") {
    return "Rejected";
  }
};

export const applyingSumCols = [
  {
    title: "College/University",
    dataIndex: "college",
    key: "college",
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Tasks Progress",
    dataIndex: "tasks",
    key: "tasks",
  },
  {
    title: "Tags",
    key: "tags",
    dataIndex: "tags",
    render: (tags) => (
      <span>
        {tags.map((tag) => {
          let color = "geekblue";
          if (tag === "Safety") {
            color = "green";
          } else if (tag === "Match") {
            color = "orange";
          } else if (tag === "Reach") {
            color = "volcano";
          } else if (
            [
              "Started Application",
              "Applied",
              "Waitlisted",
              "Started Application",
            ].includes(getAppStatus(tag))
          ) {
            color = "purple";
          }
          return (
            <Tag
              color={tag ? color : "red"}
              key={tag}
              style={{ marginTop: "2%" }}
            >
              {tag
                ? ["0", "1", "2", "3", "4"].includes(tag)
                  ? getAppStatus(tag).toUpperCase()
                  : tag.toUpperCase()
                : "Uncategorized"}
            </Tag>
          );
        })}
      </span>
    ),
  },
];

export const deleteTasks = async (id) => {
  const task = { id: id };
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteAppTask, { input: task })
    );
    message.success("Task deleted");
  } catch (error) {
    message.error("There was an error deleting your task");
  }
};

export const getValidLink = (url) => {
  // var url = this.state.link
  if (/^ *$/.test(url) || isUrl(url) === false || !url) {
    // or it"s not a valid link
    message.error("Please add a valid link to your organizer");
    return false;
  } else {
    return true;
    //   window.open(url, "_blank")
  }
};

export const isUrl = (url) => {
  var strRegex =
    "^((https|http|ftp|rtsp|mms)?://)" +
    "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + //ftp的user@
    "(([0-9]{1,3}.){3}[0-9]{1,3}" + // IP形式的URL- 199.194.52.184
    "|" + // 允许IP和DOMAIN（域名）
    "([0-9a-z_!~*'()-]+.)*' // 域名- www." +
    "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]." + // 二级域名
    "[a-z]{2,6})" + // first level domain- .com or .museum
    "(:[0-9]{1,4})?" + // 端口- :80
    "((/?)|" + // a slash isn"t required if there is no file name
    "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$'";
  var re = new RegExp(strRegex);
  return re.test(url);
};

export const createTasks = async (task) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.createAppTask, { input: task })
    );
    message.success("Task created");
  } catch (error) {
    message.error("There was an error creating your task");
  }
};

export const updateTasks = async (task) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.updateAppTask, { input: task })
    );
    message.success("Task updated");
  } catch (error) {
    message.error("There was an error updating your task");
  }
};

export const deleteManager = async (id) => {
  try {
    const manager = { id: id };
    await API.graphql(
      graphqlOperation(mutations.deleteCollegeApp, { input: manager })
    );

    // Remove college from cache
    var currentList = Cache.getItem("applyingList");
    var updatedList = currentList.filter(function (x) {
      return x.id !== id;
    });
    Cache.setItem("applyingList", updatedList);
  } catch (error) {
    message.error("There was an error deleting your manager");
  }
};

export const updateManager = async (manager) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.updateCollegeApp, { input: manager })
    ).then((data) => {
      message.success("Manager updated!");

      // Save new school details
      const updatedSchool = data.data.updateCollegeApp;
      var currentList = Cache.getItem("applyingList");

      // and retrieve old details from the cache
      const indexOfOld = currentList.findIndex(
        (q) => q.id === updatedSchool.id
      );
      currentList[indexOfOld] = updatedSchool;

      // and set cache with the updated school list
      Cache.setItem("applyingList", currentList);
    });
  } catch (error) {
    message.error("There was an issue updating your manager");
  }
};

export const getPath = (name) => {
  name = name.replace("-", " ");
  var path = name.toLowerCase();
  path = "/college/" + path.replace(/ /g, "-");
  return path;
};

export const getFullSchool = async (id) => {
  const school = { id: id };
  try {
    const getSchool = await API.graphql(
      graphqlOperation(getCollegeApp, school)
    );
    return getSchool.data.getCollegeApp;
  } catch (error) {
    message.error(error.errors[0].message);
  }
};

export const listFavs = async () => {
  try {
    // Limit for favorites to add to a list is currently hard coded at 100
    const favsData = await API.graphql(
      graphqlOperation(queries.listFavs, { limit: 100 })
    );
    return favsData.data.listFavs.items;
  } catch (error) {
    message.error(error.errors[0].message);
  }
};

export const deleteFav = async (id) => {
  const fav = { id: id };
  try {
    await API.graphql(graphqlOperation(mutations.deleteFav, { input: fav }));
    message.success("Fav deleted");
  } catch (error) {
    message.error("There was an error deleting your Fav");
  }
};

export const listApplying = async () => {
  var limit = 5;

  try {
    // Check whether or not the user is a pro member
    const getUser = await API.graphql(graphqlOperation(queries.listUsers));
    var checkPro = getUser.data.listUsers.items;
    if (checkPro.length !== 0) {
      checkPro = checkPro[0];
      if (checkPro.subscription && checkPro.subscription.startsWith("sub")) {
        limit = 20;
      }
    }

    // NOTE: limit is disabled for now to debug issues happening in production
    // Depending on free or pro member, get schools based on found limit
    // const variables = { limit: limit };
    const applyingData = await API.graphql(
      graphqlOperation(queries.listCollegeApps)
    );

    var fullyApplying = [];

    // Get full information (mainly the nested tasks) stored for the college
    for (var i = 0; i < applyingData.data.listCollegeApps.items.length; i++) {
      var school = applyingData.data.listCollegeApps.items[i];
      await getFullSchool(school.id)
        .then((data) => fullyApplying.push(data))
        .catch((err) => message.error(err));
    }

    return fullyApplying;
  } catch (error) {
    message.error(error);
  }
  console.log(limit);
};
