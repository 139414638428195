import React, { Component } from "react";
// import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Typography, Icon, Avatar, message, Drawer, Modal } from "antd";
import groupChatImage from "../../../../assets/groupchat.svg";
import basketImage from "../../../../assets/basket.svg";
import { deleteGroup, updateGroup, removeMember } from "./helpers";

import * as mutations from "../../../../graphql/mutations";

import { Link } from "react-router-dom";

const { Title } = Typography;
const { confirm } = Modal;

const cardmaybeDashboardtop = {
  marginTop: "20px",
  // marginRight: '20px',
  borderRadius: "10px 10px 10px 10px",
  height: "100%",
};

const colPadding = {
  marginTop: "2%",
};

Amplify.configure(aws_exports);

class ListGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      visible: false,
      newMember: "",
      invite: false,
      isFlipping: [],
      dvisible: false,
      groupName: "",
      currentGroup: null,
      selectedInviteGroup: null,
      updateGroup: "",
      //   isFlipped: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
    this.showLeaveConfirm = this.showLeaveConfirm.bind(this);
  }

  async componentDidMount() {}

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  getAvatar = (items) => {
    const save = [].concat(items).map((item, i) => (
      <div>
        <Avatar style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}>
          {item.substring(0, 1).toUpperCase()}{" "}
        </Avatar>{" "}
        &nbsp;{" "}
      </div>
    ));
    return save;
  };

  getPath = (name, page) => {
    name = name.replace("-", " ");
    var path = name.toLowerCase();
    path = "/" + page + "/" + path.replace(/ /g, "-");
    return path;
  };

  addMember(id) {
    var toggle = !this.state.invite;
    this.setState({ invite: toggle, newMember: "", selectedInviteGroup: id });
  }

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this group?",
      content:
        "Deleting a group is irreversible. You and all other members will lose access to all conversations, direct messages, and basket items.",
      okText: "Yes, please delete",
      okType: "danger",
      cancelText: "No, just kidding",
      onOk() {
        deleteGroup(id);
      },
      onCancel() {
        message.info("Cool! The group will not be deleted.");
      },
    });
  };

  showLeaveConfirm = (id, members) => {
    const _this = this;
    confirm({
      title: "Are you sure you want to leave this group?",
      content:
        "You will lose access to all conversations, direct messages, and basket items. You'll need to be added back by a member of the group to rejoin.",
      okText: "Yes, please remove me",
      okType: "danger",
      cancelText: "No, just kidding",
      onOk() {
        _this.callRemove(id, members);
        // deleteGroup(id)
      },
      onCancel() {
        message.info("Cool! You're still a member of the group.");
      },
    });
  };

  callRemove = (id, members) => {
    let currMembers = members;
    currMembers = currMembers.filter((user) => user !== this.props.user);
    removeMember(id, currMembers);
  };

  async sendInvite(item) {
    if (
      /^ *$/.test(this.state.newMember) ||
      !this.validateEmail(this.state.newMember)
    ) {
      message.error("Please enter a valid email.");
    } else if (item.members.includes(this.state.newMember)) {
      message.error("This member is already in the Group");
    } else {
      var getMembers = item.members;
      getMembers.push(this.state.newMember);

      const members = { id: item.id, members: getMembers };
      await API.graphql(
        graphqlOperation(mutations.updateGroup, { input: members })
      ).catch((err) => console.log(err));

      this.setState({ invite: false, newMember: "" });
      message.success("New member added.");

      // NOTE: Make sure that if a user doesn't exist then you send an email
    }
  }

  showDrawer = (item) => {
    this.setState({
      currentGroup: item,
      dvisible: true,
    });
  };

  onClose = () => {
    this.setState({
      currentGroup: null,
      dvisible: false,
    });
  };

  toggleUpdate = () => {
    const update = !this.state.updateGroup;
    this.setState({
      updateGroup: update,
    });
  };

  handleUpdate = (e) => {
    e.preventDefault();
    updateGroup(this.state.currentGroup.id, this.state.groupName);
  };

  render() {
    const groups = []
      .concat(
        this.props.isPro ? this.props.groups : this.props.groups.slice(0, 1)
      )
      .map((item, i) => (
        <div className="columns">
          <div className="column">
            <div className="card" style={cardmaybeDashboardtop}>
              <div className="card-content">
                {/* {item.owner === this.props.userID ?  */}
                <div className="columns is-pulled-left">
                  <div className="column">
                    <Icon
                      type="info-circle"
                      style={{ scale: "1.5" }}
                      onClick={this.showDrawer.bind(this, item)}
                    />
                    <Drawer
                      title={
                        this.state.currentGroup
                          ? this.state.currentGroup.name
                          : null
                      }
                      placement="right"
                      closable={false}
                      onClose={this.onClose}
                      visible={this.state.dvisible}
                    >
                      {this.state.currentGroup ? (
                        <div>
                          {/* <p>Current Members</p>
                                                    <p>Some contents...</p> */}

                          {this.state.updateGroup ? (
                            <div className="columns">
                              <div className="column">
                                <label>Group Name</label>
                                <input
                                  className="input"
                                  type="groupName"
                                  name="groupName"
                                  value={this.state.groupName}
                                  onChange={this.handleChange.bind(this)}
                                  placeholder={this.state.currentGroup.name}
                                />
                                <br />
                                <br />
                                <div
                                  className="button is-success is-fullwidth"
                                  onClick={this.handleUpdate}
                                >
                                  Save Update
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="columns">
                            <div className="column">
                              <div
                                className="button is-warning is-fullwidth"
                                onClick={this.toggleUpdate}
                              >
                                {this.state.updateGroup
                                  ? "Cancel"
                                  : "Update Group"}
                              </div>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <div
                                className="button is-warning is-fullwidth"
                                onClick={this.showLeaveConfirm.bind(
                                  this,
                                  this.state.currentGroup.id,
                                  this.state.currentGroup.members
                                )}
                              >
                                Leave Group
                              </div>
                              {/* <div className="button is-warning is-fullwidth" onClick={this.showDeleteConfirm.bind(this, this.state.currentGroup.id)}>  ? "Cancel" : "Update Group"}</div> */}
                            </div>
                          </div>
                          <hr />
                          {this.state.currentGroup.owner ===
                          this.props.userID ? (
                            <div className="columns">
                              <div className="column">
                                <div
                                  onClick={this.showDeleteConfirm.bind(
                                    this,
                                    this.state.currentGroup.id
                                  )}
                                  className="button is-danger is-fullwidth"
                                >
                                  Delete Group
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </Drawer>
                  </div>
                </div>
                {/* : null } */}
                <div
                  className="columns is-centered is-vcentered"
                  style={colPadding}
                >
                  <div className="column is-one-third">
                    <div className="columns is-centered">
                      <Title style={{ fontWeight: 900 }} level={3}>
                        {item.name}
                      </Title>
                    </div>
                    <div className="columns is-centered" style={colPadding}>
                      {item.members.length < 10 ? (
                        this.getAvatar(item.members)
                      ) : (
                        <div>
                          {this.getAvatar(item.members.slice(0, 9))} +{" "}
                          {item.members.length - 10} more members
                        </div>
                      )}
                    </div>
                    <div className="columns is-centered" style={colPadding}>
                      <div className="column">
                        {/* MOVE TO FLIP SIDE OF CARD */}
                        <div
                          className="button is-rounded is-small"
                          onClick={this.addMember.bind(this, item.id)}
                        >
                          {this.state.invite &&
                          item.id === this.state.selectedInviteGroup
                            ? "Cancel"
                            : "Invite some friends"}
                        </div>
                      </div>
                      {this.state.invite &&
                      item.id === this.state.selectedInviteGroup ? (
                        <div className="column">
                          <div className="columns">
                            <div className="column">
                              <input
                                className="input"
                                type="text"
                                name="newMember"
                                value={this.state.newMember}
                                onChange={this.handleChange.bind(this)}
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <div
                                className="button is-link is-fullwidth"
                                onClick={this.sendInvite.bind(this, item)}
                              >
                                Send Invite
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="column is-one-third">
                    <Link
                      to={{
                        pathname: this.getPath(item.name, "picnic"),
                        state: {
                          group: item,
                          name: item.name,
                          userID: this.props.userID,
                          user: this.props.user,
                          // members: item.members
                        },
                      }}
                    >
                      <div className="card" style={{ height: "100%" }}>
                        <div
                          className="card-image has-text-centered"
                          style={{ paddingTop: "2%" }}
                        >
                          <figure className="is-inline-block">
                            <img src={groupChatImage} alt="Logo" />
                          </figure>
                        </div>
                        <div className="card-content">
                          <div className="columns">
                            <div className="column">
                              <p style={{ fontSize: 20, fontWeight: 600 }}>
                                {item.name}'s Picnic Chat
                              </p>
                              {/* <hr/> */}
                            </div>
                          </div>
                          <div className="columns">
                            <div
                              className="column"
                              style={{ marginLeft: "5%", marginRight: "5%" }}
                            >
                              <p className="help">
                                Chat with your friends about your college
                                choices, task updates, and whatever else.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="column is-one-third">
                    <Link
                      to={{
                        pathname: this.getPath(item.name, "basket"),
                        state: {
                          group: item,
                          name: item.name,
                          userID: this.props.userID,
                          user: this.props.user,
                        },
                      }}
                    >
                      <div className="card" style={{ height: "100%" }}>
                        <div
                          className="card-image has-text-centered"
                          style={{ paddingTop: "2%" }}
                        >
                          <figure className="is-inline-block">
                            <img src={basketImage} alt="Logo" />
                          </figure>
                        </div>
                        <div className="card-content">
                          <div className="columns">
                            <div className="column">
                              <p style={{ fontSize: 20, fontWeight: 600 }}>
                                {item.name}'s Basket
                              </p>
                              {/* <hr/> */}
                            </div>
                          </div>
                          <div className="columns">
                            <div
                              className="column"
                              style={{ marginLeft: "5%", marginRight: "5%" }}
                            >
                              <p className="help">
                                Add group suggestions for tasks to do, colleges
                                to explore, and scholarships to apply to.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    return <div>{groups}</div>;
  }
}
export default ListGroups;
