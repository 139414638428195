import { message } from "antd";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../../aws-exports";
import * as mutations from "../../../../graphql/mutations";

Amplify.configure(aws_exports);

export const deleteScholarship = async (id) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteScholarship, { input: { id: id } })
    );
    message.success("Scholarship successfully deleted.");
    window.location.reload();
  } catch (err) {
    message.error("There was an error deleting your scholarship");
  }
};

export const updateScholarship = async (scholarship) => {
  try {
    await API.graphql(
      graphqlOperation(mutations.updateScholarship, { input: scholarship })
    )
      .then((data) => message.success("Scholarship successfully updated."))
      .then((data) => window.location.reload());
  } catch (err) {
    message.error("There was an error updating your scholarship");
  }
};

export const addScholarship = async (scholarship) => {
  console.log(scholarship);
  try {
    await API.graphql(
      graphqlOperation(mutations.createScholarship, { input: scholarship })
    )
      .then((data) => message.success("Scholarship added."))
      .then((data) => window.location.reload());
  } catch (err) {
    message.error("There was an error creating a new scholarship");
  }
};
