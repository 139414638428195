import React from "react";
import "bulma/css/bulma.css";
import { Typography, Alert, Statistic } from "antd";

const { Title } = Typography;

function Mentor() {
  return (
    <div>
      <Title style={{ textAlign: "center" }} level={1}>
        Mentor Dashboard{" "}
      </Title>
      <div className="columns is-centered">
        <div className="column is-two-thirds">
          <div className="box">
            <div className="columns">
              <div className="column">
                <Statistic title="Active Students" value={20} />
              </div>
              <div className="column">
                <Statistic title="Active Groups" value={2} />
              </div>
              <div className="column">
                <Statistic title="Unanswered Questions" value={12} />
              </div>
              <div className="column">
                <Statistic title="Sessions This Week" value={0} />
              </div>
            </div>
            <div className="columns is-vcentered">
              <div className="column">
                <Title level={4}>Notifications</Title>
                <Alert
                  message="Nice! No new notifications."
                  type="success"
                  showIcon
                />
              </div>
              <div className="column">
                <Title level={4}>Upcoming Sessions</Title>
                <Alert
                  message="All good! No upcoming sessions for this week."
                  type="success"
                  showIcon
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Mentor;
