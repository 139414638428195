import React, { useState } from "react";
import "bulma/css/bulma.css";
import { Button, Input } from "antd";
import ReactQuill from "react-quill";

function CreatePost(props) {
  const [author, setAuthor] = useState("");
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  return (
    <div>
      <div className="columns">
        <div className="column">
          <Input
            value={title}
            placeholder="Title"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="column">
          <Input
            value={author}
            placeholder="Author"
            onChange={(e) => setAuthor(e.target.value)}
          />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <ReactQuill
            theme="snow"
            type="deeper"
            name="deeper"
            value={content}
            onChange={(val) => setContent(val)}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Button
            block
            onClick={() => props.createPost(content, title, author)}
          >
            Publish
          </Button>
        </div>
        <div className="column">
          <Button block onClick={() => props.setCurrentView(0)}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
export default CreatePost;
