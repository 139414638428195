import { message } from "antd";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://jdcymsopfpnaklhdcigk.supabase.co";
const supabaseKey = process.env.REACT_APP_SUPABASE_FROSH_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

export function abbrState(input, to) {
  var states = [
    ["Arizona", "AZ"],
    ["Alabama", "AL"],
    ["Alaska", "AK"],
    ["Arkansas", "AR"],
    ["California", "CA"],
    ["Colorado", "CO"],
    ["Connecticut", "CT"],
    ["Delaware", "DE"],
    ["Florida", "FL"],
    ["Georgia", "GA"],
    ["Hawaii", "HI"],
    ["Idaho", "ID"],
    ["Illinois", "IL"],
    ["Indiana", "IN"],
    ["Iowa", "IA"],
    ["Kansas", "KS"],
    ["Kentucky", "KY"],
    ["Louisiana", "LA"],
    ["Maine", "ME"],
    ["Maryland", "MD"],
    ["Massachusetts", "MA"],
    ["Michigan", "MI"],
    ["Minnesota", "MN"],
    ["Mississippi", "MS"],
    ["Missouri", "MO"],
    ["Montana", "MT"],
    ["Nebraska", "NE"],
    ["Nevada", "NV"],
    ["New Hampshire", "NH"],
    ["New Jersey", "NJ"],
    ["New Mexico", "NM"],
    ["New York", "NY"],
    ["North Carolina", "NC"],
    ["North Dakota", "ND"],
    ["Ohio", "OH"],
    ["Oklahoma", "OK"],
    ["Oregon", "OR"],
    ["Pennsylvania", "PA"],
    ["Rhode Island", "RI"],
    ["South Carolina", "SC"],
    ["South Dakota", "SD"],
    ["Tennessee", "TN"],
    ["Texas", "TX"],
    ["Utah", "UT"],
    ["Vermont", "VT"],
    ["Virginia", "VA"],
    ["Washington", "WA"],
    ["DC", "DC"],
    ["West Virginia", "WV"],
    ["Wisconsin", "WI"],
    ["Wyoming", "WY"],
  ];

  if (to === "abbr") {
    input = input.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    for (let i = 0; i < states.length; i++) {
      if (states[i][0] === input) {
        return states[i][1];
      }
    }
  } else if (to === "name") {
    input = input.toUpperCase();
    for (let i = 0; i < states.length; i++) {
      if (states[i][1] === input) {
        return states[i][0];
      }
    }
  }
}

export function numberWithCommas(x) {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "n/a";
}

export var getLocale = function (num) {
  var locale = "Unknown";
  if (num === 11) {
    locale = "Large City";
  } else if (num === 12) {
    locale = "Midsize City";
  } else if (num === 13) {
    locale = "Small City";
  } else if (num === 21) {
    locale = "Large Suburb";
  } else if (num === 22) {
    locale = "Midsize Suburb";
  } else if (num === 23) {
    locale = "Small Suburb";
  } else if (num === 31) {
    locale = "Fringe Town (10 miles from closest city)";
  } else if (num === 32) {
    locale = "Distant Town (10-35 miles from closest city)";
  } else if (num === 33) {
    locale = "Remote Town (35+ miles from closest city)";
  } else if (num === 41 || num === 42 || num === 43) {
    locale = "Rural";
  }
  return locale;
};

export const getCollegeData = async (id, fields) => {
  try {
    const { body } = await supabase
      .from("collegedata2020")
      .select(fields)
      .match({ UNITID: id });

    return body;
  } catch (err) {
    message.error(err.message);
  }
};
