import React from "react";
import "./components.css";
import { Icon, Menu, Badge, message, Tag } from "antd";
import { Auth } from "aws-amplify";
import { API, graphqlOperation, Cache } from "aws-amplify";
import ThemeContext from "../components/ThemeContext";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";

import * as queries from "../graphql/queries";

const { SubMenu } = Menu;

class ProtectedNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      notifications: [],
      user: null,
      userID: null,
      isPro: false,
    };
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    // get current user
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) =>
        this.setState({
          user: data.attributes.email,
          userID: data.attributes.sub,
        })
      )
      .catch((err) => message.error(err));

    await API.graphql(graphqlOperation(queries.listNotificationss))
      .then((notifs) =>
        this.setState({ notifications: notifs.data.listNotificationss.items })
      )
      .catch((err) => console.log(err));
  }

  async signOut() {
    try {
      // push to dashboard first
      Cache.clear();
      await Auth.signOut();
    } catch (error) {
      message.error("Error signing out");
    }
  }

  handleClick = () => {
    this.signOut();
  };

  render() {
    const currentPage = window.location.pathname;
    const getCurrentPage = currentPage.substring(1, currentPage.length);

    return (
      <div>
        <ThemeContext.Consumer>
          {(value) => (
            <Menu selectedKeys={getCurrentPage} mode="horizontal">
              <Menu.Item style={{ float: "left" }} key="dashboard">
                <Link to="/dashboard"></Link>
                <Icon type="home" />
                Dashboard
              </Menu.Item>

              <SubMenu
                style={{ float: "left" }}
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="bulb" />
                    Learn
                  </span>
                }
              >
                <Menu.Item key="playbooks">
                  <Link to="/playbooks">
                    <Icon type="book" />
                    Playbooks
                  </Link>
                </Menu.Item>
                <Menu.Item key="mentoring">
                  {/* mentoring page (/mentoring) disabled until ready */}
                  <Icon type="rocket" />
                  Mentoring&nbsp;<Tag>Coming Soon</Tag>
                </Menu.Item>
                <Menu.Item key="community">
                  {/* community page (/community) disabled until ready */}
                  <Icon type="coffee" />
                  Community&nbsp;<Tag>Coming Soon</Tag>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="stuff"
                style={{ float: "left" }}
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="smile" />
                    My Stuff
                  </span>
                }
              >
                {/* <Menu.Item key="groups">
                  <Icon type="team" />
                  Groups <Link to="/groups"></Link>
                </Menu.Item> */}

                <Menu.Item key="list">
                  <Icon type="ordered-list" />
                  Colleges <Link to="/list">College List</Link>
                </Menu.Item>

                <Menu.Item key="roadmap">
                  <Icon type="rise" />
                  Roadmap <Link to="/roadmap">Roadmap</Link>
                </Menu.Item>

                <Menu.Item key="calendar">
                  <Icon type="calendar" />
                  Deadlines <Link to="/calendar">Deadlines</Link>
                </Menu.Item>

                <Menu.Item key="scholarship">
                  <Icon type="dollar" />
                  Scholarships{" "}
                  <Link to="/scholarships">Scholarship Organizer</Link>
                </Menu.Item>

                <Menu.Item key="notes">
                  <Icon type="file-text" />
                  Notes
                  <Link to="/notes">Notes</Link>
                </Menu.Item>

                <Menu.Item key="profile">
                  <Icon type="folder-open" />
                  Locker
                  <Link to="/profile"></Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                style={{ float: "left" }}
                title={
                  <span className="submenu-title-wrapper">
                    <Icon type="setting" />
                    Tools
                  </span>
                }
              >
                <Menu.Item key="schools">
                  <Icon type="search" />
                  College Search<Link to="/schools"></Link>
                </Menu.Item>
                {/* <Menu.Item key="collegeMatch">
                  <Icon type="experiment" />
                  College Match<Link to="/match-making"></Link>
                </Menu.Item> */}
                {/* <Menu.Item key="majors" >
                  <Icon type="highlight" />
                    College Majors<Link to="/majors"></Link>
                  </Menu.Item> */}
              </SubMenu>

              <SubMenu
                style={{ float: "right" }}
                key="settings"
                title={
                  <span className="submenu-title-wrapper">
                    {/* <Link to="/settings"> */}
                    <Badge count={this.state.notifications.length} dot>
                      <Icon type="user" />
                      {this.state.user}
                    </Badge>
                    {/* </Link> */}
                  </span>
                }
              >
                <Menu.Item key="settings">
                  <Link to="/settings/notifications">Account & Settings</Link>
                </Menu.Item>

                <Menu.Item onClick={this.handleClick} key="signout">
                  Sign Out
                </Menu.Item>
              </SubMenu>

              {/* {value === false ? (
                <Button href="/settings">Upgrade to Student Pro</Button>
              ) : null} */}
            </Menu>
          )}
        </ThemeContext.Consumer>
      </div>
    );
  }
}
export default ProtectedNav;
