import React, { Component } from "react";
import { Route } from "react-router-dom";
import "../../../App.css";
import "bulma/css/bulma.css";
import Amplify from "aws-amplify";
import aws_exports from "../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Typography, Icon, Card, PageHeader, Progress } from "antd";
import { Layout } from "antd";
import { Tabs, Button } from "antd";
import {
  abbrState,
  numberWithCommas,
  getLocale,
} from "../components/Schools/collegeDataHelpers";
import { addToApplying, addToFavs } from "../components/Schools/helpers";

const { TabPane } = Tabs;

const { Content } = Layout;
const { Title } = Typography;

Amplify.configure(aws_exports);

class College extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      page: "",
      school: null,
    };
    this.getCollegeData = this.getCollegeData.bind(this);
  }

  async getCollegeData(id) {
    const fields =
      "id,school.name,latest.student.size,latest.admissions.sat_scores.average.overall,latest.admissions.admission_rate.overall,school.state,school.school_url,school";
    const fetchString = "id=" + id + "&";
    var results = null;
    const fetchURL =
      "https://api.data.gov/ed/collegescorecard/v1/schools.json?" +
      fetchString +
      "&fields=" +
      fields +
      "&api_key=ygpuCKKdi0cuKgzhlU2Zf6qOG22dCqlFVcjT8YaW";
    try {
      const response = await fetch(fetchURL);
      const jsonData = await response.json();

      this.setState({ school: jsonData.results });
      results = jsonData.results;
    } catch (err) {
      console.error(err.message);
    }
    return results;
  }

  async componentDidMount() {
    const path = window.location.pathname;
    this.setState({ page: path });
  }

  render() {
    return (
      <div>
        <div className="columns">
          <div className="column">
            <Layout>
              <Content>
                <Layout style={{ padding: "2% 0", background: "#fff" }}>
                  <Content style={{ marginLeft: "2%", marginRight: "2%" }}>
                    <div>
                      <Route
                        path={this.state.path}
                        render={(props) => (
                          <CollegeView
                            getCollegeData={this.getCollegeData}
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </Content>
                </Layout>
              </Content>
            </Layout>
          </div>
        </div>
      </div>
    );
  }
}
export default College;

class CollegeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      page: "",
      school: null,
      name: "",
    };
  }

  async componentDidMount() {
    const fields =
      "id,school.name,latest.student.size,latest.admissions,latest.academics,latest.cost,latest.student,school";
    const fetchString = "id=" + this.props.location.state.id + "&";
    const fetchURL =
      "https://api.data.gov/ed/collegescorecard/v1/schools.json?" +
      fetchString +
      "&fields=" +
      fields +
      "&api_key=ygpuCKKdi0cuKgzhlU2Zf6qOG22dCqlFVcjT8YaW";
    try {
      const response = await fetch(fetchURL);
      const jsonData = await response.json();

      this.setState({
        school: jsonData.results[0],
        name: jsonData.results["school.name"],
      });
    } catch (err) {
      console.error(err.message);
    }
  }

  render() {
    return (
      <div>
        {this.state.school ? (
          <div>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              onBack={() => window.history.back()}
              title="Back"
              subTitle={this.state.school["school.name"]}
              extra={[
                <Button
                  onClick={addToApplying.bind(
                    this,
                    this.state.school["id"],
                    this.state.school["school.name"]
                  )}
                >
                  <Icon type="plus-circle" theme="twoTone" />
                  Add to my List
                </Button>,
                <Button
                  onClick={addToFavs.bind(
                    this,
                    this.state.school["id"],
                    this.state.school["school.name"]
                  )}
                >
                  {" "}
                  <Icon type="heart" theme="twoTone" twoToneColor="#eb2f96" />
                  Favorite
                </Button>,
                <a
                  href={"https://" + this.state.school["school.school_url"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button>
                    <Icon type="global" />
                    Website
                  </Button>
                </a>,
              ]}
            />
            <Title style={{ fontWeight: 900, margin: "2%" }} level={2}>
              {this.state.school["school.name"]}
            </Title>
            <Tabs size={"large"} tabPosition={"left"}>
              <TabPane tab="Overview" key="1">
                <div className="columns">
                  <div className="column">
                    <Title
                      style={{ textAlign: "left", fontWeight: 600 }}
                      level={4}
                    >
                      Overview{" "}
                    </Title>
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-one-third">
                    <Card title="Student Population" style={{ height: "100%" }}>
                      <div className="columns is-gapless">
                        <div className="column">
                          <Icon
                            style={{ scale: "2.5", margin: "2%" }}
                            type="team"
                          />
                        </div>
                        <div className="column">
                          <Title style={{ fontWeight: 900 }} level={4}>
                            {numberWithCommas(
                              this.state.school["latest.student.size"]
                            )}{" "}
                          </Title>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="column is-one-third">
                    <Card title="Location">
                      <div className="columns is-gapless">
                        <div className="column">
                          <Icon
                            style={{ scale: "2.5", margin: "2%" }}
                            type="car"
                          />
                        </div>
                        <div className="column">
                          {this.state.school["school.city"]},&nbsp;
                          {abbrState(this.state.school["school.state"], "name")}
                          &nbsp;
                          <br />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="column is-one-third">
                    <Card title="Area">
                      <div className="columns is-gapless">
                        <div className="column">
                          <Icon
                            style={{ scale: "2.5", margin: "2%" }}
                            type="home"
                          />
                        </div>
                        <div className="column">
                          {getLocale(this.state.school["school.locale"])}
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-one-third">
                    <Card title="Level of Study">
                      <div className="columns is-gapless">
                        <div className="column">
                          {this.state.school[
                            "school.degrees_awarded.highest"
                          ] === 0
                            ? "Non-Degree Granting"
                            : this.state.school[
                                "school.degrees_awarded.highest"
                              ] === 1
                            ? "Certificate"
                            : this.state.school[
                                "school.degrees_awarded.highest"
                              ] === 2
                            ? "Associates Degree"
                            : this.state.school[
                                "school.degrees_awarded.highest"
                              ] === 3
                            ? "Undergrad"
                            : this.state.school[
                                "school.degrees_awarded.highest"
                              ] === 4
                            ? "Undergrad/Grad"
                            : null}
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="column is-one-third">
                    <Card title="School Type">
                      <div className="columns is-gapless">
                        <div className="column">
                          {this.state.school["school.carnegie_size_setting"] >=
                            1 &&
                          this.state.school["school.carnegie_size_setting"] <= 5
                            ? "2 Year"
                            : this.state.school[
                                "school.carnegie_size_setting"
                              ] >= 6 &&
                              this.state.school[
                                "school.carnegie_size_setting"
                              ] <= 17
                            ? "4 Year"
                            : null}{" "}
                          &nbsp;
                          {this.state.school["school.ownership"] === 1
                            ? "Public"
                            : this.state.school["school.ownership"] === 2
                            ? "Private Non-Profit"
                            : this.state.school["school.ownership"] === 3
                            ? "Private For-Profit"
                            : null}
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="column is-one-third">
                    <Card title="Acceptance Rate">
                      <div className="columns">
                        <div className="column">
                          <h3>
                            {Math.ceil(
                              this.state.school[
                                "latest.admissions.admission_rate.overall"
                              ] * 100
                            )}
                            %
                          </h3>
                        </div>
                        <div className="column">
                          <progress
                            className="progress is-link"
                            value={Math.ceil(
                              this.state.school[
                                "latest.admissions.admission_rate.overall"
                              ] * 100
                            )}
                            max="100"
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Admissions" key="2">
                <Title style={{ textAlign: "left", fontWeight: 600 }} level={4}>
                  Admissions{" "}
                </Title>
                <div className="columns is-centered is-vcentered">
                  <div className="column">
                    <Card title="SAT">
                      <div className="columns">
                        <div className="column">
                          <p className="help">
                            <strong>RANGE</strong>
                          </p>
                          <h3 style={{ fontWeight: 900 }}>
                            {this.state.school[
                              "latest.admissions.sat_scores.25th_percentile.critical_reading"
                            ] +
                              this.state.school[
                                "latest.admissions.sat_scores.25th_percentile.math"
                              ]}
                            -
                            {this.state.school[
                              "latest.admissions.sat_scores.75th_percentile.math"
                            ] +
                              this.state.school[
                                "latest.admissions.sat_scores.75th_percentile.critical_reading"
                              ]}
                          </h3>
                        </div>
                        <div className="column">
                          <p className="help">
                            <strong>AVG</strong>
                          </p>
                          <h3 style={{ fontWeight: 900 }}>
                            {
                              this.state.school[
                                "latest.admissions.sat_scores.average.overall"
                              ]
                            }
                          </h3>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">
                          <p>
                            Admissions data shows that{" "}
                            {this.state.school["school.name"]} regularly accepts
                            students with SAT's of{" "}
                            {this.state.school[
                              "latest.admissions.sat_scores.25th_percentile.critical_reading"
                            ] +
                              this.state.school[
                                "latest.admissions.sat_scores.25th_percentile.math"
                              ]}{" "}
                            and above. Remember that other factors like grades
                            and extracurriculars will be taken into
                            consideration when reviewing your application.
                          </p>
                        </div>
                      </div>

                      <hr />
                      <div className="columns">
                        <div className="column">
                          <div className="columns">
                            <div className="column">
                              <h6>SAT Reading 25th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.sat_scores.25th_percentile.critical_reading"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <h6>SAT Math 25th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.sat_scores.25th_percentile.math"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <h6>SAT Writing 25th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.sat_scores.25th_percentile.writing"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="column">
                          <div className="columns">
                            <div className="column">
                              <h6>SAT Reading 75th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.sat_scores.75th_percentile.critical_reading"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <h6>SAT Math 75th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.sat_scores.75th_percentile.math"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <h6>SAT Writing 75th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.sat_scores.75th_percentile.writing"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="column">
                    <Card title="ACT">
                      <div className="columns">
                        <div className="column">
                          <p className="help">
                            <strong>RANGE</strong>
                          </p>
                          <h3 style={{ fontWeight: 900 }}>
                            {
                              this.state.school[
                                "latest.admissions.act_scores.25th_percentile.cumulative"
                              ]
                            }
                            -
                            {
                              this.state.school[
                                "latest.admissions.act_scores.75th_percentile.cumulative"
                              ]
                            }
                          </h3>
                        </div>
                        <div className="column">
                          <p className="help">
                            <strong>AVG</strong>
                          </p>
                          <h3 style={{ fontWeight: 900 }}>
                            {
                              this.state.school[
                                "latest.admissions.act_scores.midpoint.cumulative"
                              ]
                            }
                          </h3>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">
                          <p>
                            Admissions data shows that{" "}
                            {this.state.school["school.name"]} regularly accepts
                            students with ACT's of{" "}
                            {
                              this.state.school[
                                "latest.admissions.act_scores.25th_percentile.cumulative"
                              ]
                            }{" "}
                            and above. Remember that other factors like grades
                            and extracurriculars will be taken into
                            consideration when reviewing your application.
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="columns">
                        <div className="column">
                          <div className="columns">
                            <div className="column">
                              <h6>ACT Reading 25th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.act_scores.25th_percentile.english"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <h6>ACT Math 25th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.act_scores.25th_percentile.math"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <h6>ACT Writing 25th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.act_scores.25th_percentile.writing"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div className="column">
                          <div className="columns">
                            <div className="column">
                              <h6>ACT Reading 75th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.act_scores.75th_percentile.english"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <h6>ACT Math 75th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.act_scores.75th_percentile.math"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <h6>ACT Writing 75th</h6>
                            </div>
                            <div className="column">
                              <h6>
                                <strong>
                                  {
                                    this.state.school[
                                      "latest.admissions.act_scores.75th_percentile.writing"
                                    ]
                                  }
                                </strong>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Price" key="3">
                <Title style={{ textAlign: "left", fontWeight: 600 }} level={4}>
                  Price{" "}
                </Title>
                <div className="columns is-centered is-vcentered">
                  <div className="column">
                    <Card title="Sticker Price">
                      <div className="columns">
                        <div className="column">
                          <h3 style={{ fontWeight: 900 }}>
                            $
                            {numberWithCommas(
                              this.state.school[
                                "latest.cost.attendance.academic_year"
                              ]
                            )}
                          </h3>
                          <p>
                            This is the annual on paper cost to attend{" "}
                            {this.state.school["school.name"]}. This yearly cost
                            includes tuition, room and board and books and
                            supplies.
                          </p>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">
                          <div className="columns">
                            <div className="column">
                              <p>TUITION OUT OF STATE</p>
                            </div>
                            <div className="column">
                              <strong>
                                $
                                {numberWithCommas(
                                  this.state.school[
                                    "latest.cost.tuition.out_of_state"
                                  ]
                                )}
                              </strong>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="columns">
                            <div className="column">
                              <p>TUITION IN STATE</p>
                            </div>
                            <div className="column">
                              <strong>
                                $
                                {numberWithCommas(
                                  this.state.school[
                                    "latest.cost.tuition.in_state"
                                  ]
                                )}
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="column">
                    <Card title="Net Price">
                      <h3 style={{ fontWeight: 900 }}>
                        <p className="help">
                          AVERAGE NET PRICE FOR {new Date().getFullYear() - 3}/
                          {new Date().getFullYear() - 2} ACADEMIC YEAR
                        </p>
                        $
                        {numberWithCommas(
                          this.state.school["latest.cost.avg_net_price.overall"]
                        )}
                      </h3>
                      <p>
                        This is how much (on average) students at{" "}
                        {this.state.school["school.name"]} end up paying per
                        year after scholarships and grants are considered. This
                        price will be different depending on your family's
                        income and financial need.
                      </p>
                    </Card>
                  </div>
                </div>

                <div className="columns">
                  <div className="column">
                    <Card title="Net Price By Family Income">
                      <div className="columns">
                        <div className="column">
                          <p className="help">
                            <strong>FAMILY INCOME</strong>
                          </p>
                        </div>
                        <div className="column">
                          <p className="help">
                            <strong>NET PRICE PAID PER YEAR</strong>
                          </p>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column">
                          <p>$0 to $30,000</p>
                        </div>
                        <div className="column">
                          <p>
                            <strong>
                              $
                              {numberWithCommas(
                                this.state.school[
                                  "latest.cost.net_price.consumer.by_income_level.0-30000"
                                ]
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column">
                          <p>$30,001 to $48,000</p>
                        </div>
                        <div className="column">
                          <p>
                            <strong>
                              $
                              {numberWithCommas(
                                this.state.school[
                                  "latest.cost.net_price.consumer.by_income_level.30001-48000"
                                ]
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column">
                          <p>$48,001 to $75,000</p>
                        </div>
                        <div className="column">
                          <p>
                            <strong>
                              $
                              {numberWithCommas(
                                this.state.school[
                                  "latest.cost.net_price.consumer.by_income_level.48001-75000"
                                ]
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column">
                          <p>$75,001 to $110,000</p>
                        </div>
                        <div className="column">
                          <p>
                            <strong>
                              $
                              {numberWithCommas(
                                this.state.school[
                                  "latest.cost.net_price.consumer.by_income_level.750001-111000"
                                ]
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column">
                          <p>$110,000+</p>
                        </div>
                        <div className="column">
                          <p>
                            <strong>
                              $
                              {numberWithCommas(
                                this.state.school[
                                  "latest.cost.net_price.consumer.by_income_level.110001-plus"
                                ]
                              )}
                            </strong>
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Students" key="4">
                <Title style={{ textAlign: "left", fontWeight: 600 }} level={4}>
                  Students{" "}
                </Title>
                <div className="columns is-centered is-vcentered">
                  <div className="column">
                    <div className="columns">
                      <div className="column">
                        <Card title="Race">
                          <div className="columns">
                            <div className="column">
                              <strong>White</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.race_ethnicity.white"
                                  ] * 100
                                )}
                              />
                            </div>
                            <div className="column">
                              <strong>Black</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.race_ethnicity.black"
                                  ] * 100
                                )}
                              />
                            </div>
                            <div className="column">
                              <strong>Hispanic</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.race_ethnicity.hispanic"
                                  ] * 100
                                )}
                              />
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>Asian</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.race_ethnicity.asian"
                                  ] * 100
                                )}
                              />
                            </div>
                            <div className="column">
                              <strong>American Indian / Alaskan</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.race_ethnicity.aian"
                                  ] * 100
                                )}
                              />
                            </div>
                            <div className="column">
                              <strong>Hawaiian / Pacific Islander</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.race_ethnicity.asian_pacific_islander"
                                  ] * 100
                                )}
                              />
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <strong>Two or more races</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.race_ethnicity.two_or_more"
                                  ] * 100
                                )}
                              />
                            </div>
                            <div className="column">
                              <strong>Race Unknown</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.race_ethnicity.unknown"
                                  ] * 100
                                )}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column">
                        <Card>
                          <p className="help">
                            <strong>RETENTION RATE</strong>
                          </p>
                          <h3 style={{ fontWeight: 900 }}>
                            {Math.round(
                              this.state.school[
                                "latest.student.retention_rate.overall.full_time"
                              ] * 100
                            )}
                            %
                          </h3>
                          <br />
                          <p>
                            This is the number of first year students who
                            returned for school the next year.
                          </p>
                        </Card>
                      </div>
                    </div>
                  </div>

                  <div className="column">
                    <div className="columns">
                      <div className="column">
                        <Card title="Gender">
                          <div className="columns">
                            <div className="column">
                              <strong>Women</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.women"
                                  ] * 100
                                )}
                              />
                            </div>
                            <div className="column">
                              <strong>Men</strong>
                              <Progress
                                percent={Math.round(
                                  this.state.school[
                                    "latest.student.demographics.men"
                                  ] * 100
                                )}
                              />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column">
                        <Card>
                          <p className="help">
                            <strong>AVERAGE FAMILY INCOME</strong>
                          </p>
                          <h3 style={{ fontWeight: 900 }}>
                            $
                            {numberWithCommas(
                              this.state.school[
                                "latest.student.demographics.avg_family_income"
                              ]
                            )}
                          </h3>
                        </Card>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column">
                        <Card>
                          <p className="help">
                            <strong>FIRST GENERATION</strong>
                          </p>
                          <h3 style={{ fontWeight: 900 }}>
                            {this.state.school[
                              "latest.student.demographics.first_generation"
                            ] ? (
                              <div>
                                {Math.round(
                                  this.state.school[
                                    "latest.student.demographics.first_generation"
                                  ] * 100
                                )}
                                %
                              </div>
                            ) : (
                              "N/A"
                            )}
                          </h3>
                          <br />
                          <p>
                            These are students who are the first person in their
                            family to attend a four-year college/university to
                            attain a bachelor's degree.
                          </p>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
            <p style={{ marginTop: "5%" }} className="help">
              Data found via{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://collegescorecard.ed.gov/"
              >
                U.S Department of Education College Scorecard
              </a>
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}
