export const questions = [
  {
    id: 0,
    question: "Do you plan on taking the SAT II Subject Tests?",
    choices: ["Yes", "No"],
    type: ["11", "12"],
  },
  {
    id: 1,
    question: "Have you started looking into colleges?",
    choices: ["Yes", "No"],
    type: ["12", "10", "11"],
  },
  {
    id: 2,
    question:
      "Have you already met with your guidance counselor to plan courses for next year?",
    choices: ["Yes", "No"],
    type: ["10", "11"],
  },
  {
    id: 3,
    question: "Do you plan to take the SAT or ACT?",
    choices: ["Yes", "No"],
    type: ["12", "11"],
  },
  {
    id: 4,
    question:
      "Have you started drafting a resume listing your accomplishments, activities, etc.?",
    choices: ["Yes", "No"],
    type: ["11", "12"],
  },
  {
    id: 5,
    question:
      "Are you involved in extracurricular activities (clubs, athletics, volunteering, other leadership activities)?",
    choices: ["Yes", "No"],
    type: ["10", "11", "12"],
  },
  {
    id: 6,
    question: "Have you started preparing for the SAT and/or ACT?",
    choices: ["Yes", "No"],
    type: ["11", "12"],
  },
  {
    id: 7,
    question:
      "Have you asked your school counselor if you qualify for a SAT and/or ACT fee waiver?",
    choices: ["Yes", "No"],
    type: ["11", "12"],
  },
  {
    id: 8,
    question: "Are you in any AP classes?",
    choices: ["Yes", "No"],
    type: ["11", "12"],
  },
  {
    id: 9,
    question: "Do you plan on taking the PSAT?",
    choices: ["Yes", "No"],
    type: ["10", "11"],
  },
];
