export const createNote = `mutation createNote($note: String!){
    createNote(input:{
      note: $note
    }){
      __typename
      id
      note
    }
  }`;

export const readNote = `query ListNotes(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      note
    }
    nextToken
  }
}`;

export const updateNote = `mutation updateNote($id: ID!,$note: String){
    updateNote(input:{
      id: $id
      note: $note
    }){
      __typename
      id
      note
    }
  }`;

export const deleteNote = `mutation deleteNote($id: ID!){
    deleteNote(input:{
      id: $id
    }){
      __typename
      id
      note
    }
  }`;
