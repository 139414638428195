import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../../../graphql/mutations";
import { message } from "antd";

export const deleteSite = async (id) => {
  const channelId = { id: id };
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteChannel, { input: channelId })
    ).then((data) => window.location.reload());
  } catch (err) {
    console.log(err);
  }
};

export const updateSite = async (id, name, header) => {
  const site = { id: id, name: name, header: header };
  try {
    await API.graphql(
      graphqlOperation(mutations.updateChannel, { input: site })
    ).then((data) => window.location.reload());
  } catch (err) {
    console.log(err);
  }
};

export const removeMember = async (id, members) => {
  const groupId = { id: id, members: members };
  try {
    await API.graphql(
      graphqlOperation(mutations.updateGroup, { input: groupId })
    ).then((data) => window.location.reload());
  } catch (err) {
    console.log(err);
  }
};

export const deleteGroup = async (id) => {
  const groupId = { id: id };
  try {
    await API.graphql(
      graphqlOperation(mutations.deleteGroup, { input: groupId })
    ).then((data) => window.location.reload());
  } catch (err) {
    console.log(err);
  }
};

export const updateGroup = async (id, name) => {
  if (/^ *$/.test(name)) {
    message.error("Please enter a valid Group name.");
  } else {
    const group = { id: id, name: name };
    try {
      await API.graphql(
        graphqlOperation(mutations.updateGroup, { input: group })
      ).then((data) => window.location.reload());
    } catch (err) {
      console.log(err);
    }
  }
};

export const checkChannelName = function (name, num) {
  if (/^ *$/.test(name)) {
    message.error("Please enter a valid Site name.");
    return false;
  } else if (name.length > 80) {
    message.error("Please enter a Site with less than 80 chars.");
    return false;
  } else if (num === 10) {
    message.error("Sorry, Picnic Chats can only have up to 10 Sites. ", 5);
    return false;
  } else {
    return true;
  }
};
