/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBlogPost = /* GraphQL */ `
  mutation CreateBlogPost(
    $input: CreateBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    createBlogPost(input: $input, condition: $condition) {
      id
      title
      content
      date
      author
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBlogPost = /* GraphQL */ `
  mutation UpdateBlogPost(
    $input: UpdateBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    updateBlogPost(input: $input, condition: $condition) {
      id
      title
      content
      date
      author
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBlogPost = /* GraphQL */ `
  mutation DeleteBlogPost(
    $input: DeleteBlogPostInput!
    $condition: ModelBlogPostConditionInput
  ) {
    deleteBlogPost(input: $input, condition: $condition) {
      id
      title
      content
      date
      author
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      content
      post {
        id
        title
        content
        date
        author
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      content
      post {
        id
        title
        content
        date
        author
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      content
      post {
        id
        title
        content
        date
        author
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPerformance = /* GraphQL */ `
  mutation CreatePerformance(
    $input: CreatePerformanceInput!
    $condition: ModelPerformanceConditionInput
  ) {
    createPerformance(input: $input, condition: $condition) {
      id
      weighted
      unweighted
      rank
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePerformance = /* GraphQL */ `
  mutation UpdatePerformance(
    $input: UpdatePerformanceInput!
    $condition: ModelPerformanceConditionInput
  ) {
    updatePerformance(input: $input, condition: $condition) {
      id
      weighted
      unweighted
      rank
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePerformance = /* GraphQL */ `
  mutation DeletePerformance(
    $input: DeletePerformanceInput!
    $condition: ModelPerformanceConditionInput
  ) {
    deletePerformance(input: $input, condition: $condition) {
      id
      weighted
      unweighted
      rank
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNewsletter = /* GraphQL */ `
  mutation CreateNewsletter(
    $input: CreateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    createNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateNewsletter = /* GraphQL */ `
  mutation UpdateNewsletter(
    $input: UpdateNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    updateNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewsletter = /* GraphQL */ `
  mutation DeleteNewsletter(
    $input: DeleteNewsletterInput!
    $condition: ModelNewsletterConditionInput
  ) {
    deleteNewsletter(input: $input, condition: $condition) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      note
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      note
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      note
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      id
      message
      category
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      id
      message
      category
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      id
      message
      category
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      task
      due
      complete
      status
      year
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      task
      due
      complete
      status
      year
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      task
      due
      complete
      status
      year
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      year
      awsId
      stripeCustomerId
      product
      subscription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      year
      awsId
      stripeCustomerId
      product
      subscription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      year
      awsId
      stripeCustomerId
      product
      subscription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOnboarding = /* GraphQL */ `
  mutation CreateOnboarding(
    $input: CreateOnboardingInput!
    $condition: ModelOnboardingConditionInput
  ) {
    createOnboarding(input: $input, condition: $condition) {
      id
      playbooks
      tasks
      favs
      applying
      locker
      onboarded
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOnboarding = /* GraphQL */ `
  mutation UpdateOnboarding(
    $input: UpdateOnboardingInput!
    $condition: ModelOnboardingConditionInput
  ) {
    updateOnboarding(input: $input, condition: $condition) {
      id
      playbooks
      tasks
      favs
      applying
      locker
      onboarded
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOnboarding = /* GraphQL */ `
  mutation DeleteOnboarding(
    $input: DeleteOnboardingInput!
    $condition: ModelOnboardingConditionInput
  ) {
    deleteOnboarding(input: $input, condition: $condition) {
      id
      playbooks
      tasks
      favs
      applying
      locker
      onboarded
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTestScore = /* GraphQL */ `
  mutation CreateTestScore(
    $input: CreateTestScoreInput!
    $condition: ModelTestScoreConditionInput
  ) {
    createTestScore(input: $input, condition: $condition) {
      id
      test
      score
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTestScore = /* GraphQL */ `
  mutation UpdateTestScore(
    $input: UpdateTestScoreInput!
    $condition: ModelTestScoreConditionInput
  ) {
    updateTestScore(input: $input, condition: $condition) {
      id
      test
      score
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTestScore = /* GraphQL */ `
  mutation DeleteTestScore(
    $input: DeleteTestScoreInput!
    $condition: ModelTestScoreConditionInput
  ) {
    deleteTestScore(input: $input, condition: $condition) {
      id
      test
      score
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createScholarship = /* GraphQL */ `
  mutation CreateScholarship(
    $input: CreateScholarshipInput!
    $condition: ModelScholarshipConditionInput
  ) {
    createScholarship(input: $input, condition: $condition) {
      id
      name
      refSent
      tranSent
      appSent
      recieved
      org
      deadline
      source
      contact
      amount
      criteria
      documents
      essay
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateScholarship = /* GraphQL */ `
  mutation UpdateScholarship(
    $input: UpdateScholarshipInput!
    $condition: ModelScholarshipConditionInput
  ) {
    updateScholarship(input: $input, condition: $condition) {
      id
      name
      refSent
      tranSent
      appSent
      recieved
      org
      deadline
      source
      contact
      amount
      criteria
      documents
      essay
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteScholarship = /* GraphQL */ `
  mutation DeleteScholarship(
    $input: DeleteScholarshipInput!
    $condition: ModelScholarshipConditionInput
  ) {
    deleteScholarship(input: $input, condition: $condition) {
      id
      name
      refSent
      tranSent
      appSent
      recieved
      org
      deadline
      source
      contact
      amount
      criteria
      documents
      essay
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCollegeApp = /* GraphQL */ `
  mutation CreateCollegeApp(
    $input: CreateCollegeAppInput!
    $condition: ModelCollegeAppConditionInput
  ) {
    createCollegeApp(input: $input, condition: $condition) {
      id
      schoolId
      name
      intended
      term
      status
      fee
      due
      category
      link
      note
      tasks {
        items {
          id
          task
          complete
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      location
      academics
      size
      distance
      extras
      social
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCollegeApp = /* GraphQL */ `
  mutation UpdateCollegeApp(
    $input: UpdateCollegeAppInput!
    $condition: ModelCollegeAppConditionInput
  ) {
    updateCollegeApp(input: $input, condition: $condition) {
      id
      schoolId
      name
      intended
      term
      status
      fee
      due
      category
      link
      note
      tasks {
        items {
          id
          task
          complete
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      location
      academics
      size
      distance
      extras
      social
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCollegeApp = /* GraphQL */ `
  mutation DeleteCollegeApp(
    $input: DeleteCollegeAppInput!
    $condition: ModelCollegeAppConditionInput
  ) {
    deleteCollegeApp(input: $input, condition: $condition) {
      id
      schoolId
      name
      intended
      term
      status
      fee
      due
      category
      link
      note
      tasks {
        items {
          id
          task
          complete
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      location
      academics
      size
      distance
      extras
      social
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAppTask = /* GraphQL */ `
  mutation CreateAppTask(
    $input: CreateAppTaskInput!
    $condition: ModelAppTaskConditionInput
  ) {
    createAppTask(input: $input, condition: $condition) {
      id
      app {
        id
        schoolId
        name
        intended
        term
        status
        fee
        due
        category
        link
        note
        tasks {
          nextToken
        }
        location
        academics
        size
        distance
        extras
        social
        createdAt
        updatedAt
        owner
      }
      task
      complete
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAppTask = /* GraphQL */ `
  mutation UpdateAppTask(
    $input: UpdateAppTaskInput!
    $condition: ModelAppTaskConditionInput
  ) {
    updateAppTask(input: $input, condition: $condition) {
      id
      app {
        id
        schoolId
        name
        intended
        term
        status
        fee
        due
        category
        link
        note
        tasks {
          nextToken
        }
        location
        academics
        size
        distance
        extras
        social
        createdAt
        updatedAt
        owner
      }
      task
      complete
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAppTask = /* GraphQL */ `
  mutation DeleteAppTask(
    $input: DeleteAppTaskInput!
    $condition: ModelAppTaskConditionInput
  ) {
    deleteAppTask(input: $input, condition: $condition) {
      id
      app {
        id
        schoolId
        name
        intended
        term
        status
        fee
        due
        category
        link
        note
        tasks {
          nextToken
        }
        location
        academics
        size
        distance
        extras
        social
        createdAt
        updatedAt
        owner
      }
      task
      complete
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFav = /* GraphQL */ `
  mutation CreateFav(
    $input: CreateFavInput!
    $condition: ModelFavConditionInput
  ) {
    createFav(input: $input, condition: $condition) {
      id
      schoolid
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFav = /* GraphQL */ `
  mutation UpdateFav(
    $input: UpdateFavInput!
    $condition: ModelFavConditionInput
  ) {
    updateFav(input: $input, condition: $condition) {
      id
      schoolid
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFav = /* GraphQL */ `
  mutation DeleteFav(
    $input: DeleteFavInput!
    $condition: ModelFavConditionInput
  ) {
    deleteFav(input: $input, condition: $condition) {
      id
      schoolid
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCalendar = /* GraphQL */ `
  mutation CreateCalendar(
    $input: CreateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    createCalendar(input: $input, condition: $condition) {
      id
      event
      date
      type
      year
      month
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCalendar = /* GraphQL */ `
  mutation UpdateCalendar(
    $input: UpdateCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    updateCalendar(input: $input, condition: $condition) {
      id
      event
      date
      type
      year
      month
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCalendar = /* GraphQL */ `
  mutation DeleteCalendar(
    $input: DeleteCalendarInput!
    $condition: ModelCalendarConditionInput
  ) {
    deleteCalendar(input: $input, condition: $condition) {
      id
      event
      date
      type
      year
      month
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPlaybook1 = /* GraphQL */ `
  mutation CreatePlaybook1(
    $input: CreatePlaybook1Input!
    $condition: ModelPlaybook1ConditionInput
  ) {
    createPlaybook1(input: $input, condition: $condition) {
      id
      steps
      shortgoals
      longgoals
      how
      moregoals
      deeper
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePlaybook1 = /* GraphQL */ `
  mutation UpdatePlaybook1(
    $input: UpdatePlaybook1Input!
    $condition: ModelPlaybook1ConditionInput
  ) {
    updatePlaybook1(input: $input, condition: $condition) {
      id
      steps
      shortgoals
      longgoals
      how
      moregoals
      deeper
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePlaybook1 = /* GraphQL */ `
  mutation DeletePlaybook1(
    $input: DeletePlaybook1Input!
    $condition: ModelPlaybook1ConditionInput
  ) {
    deletePlaybook1(input: $input, condition: $condition) {
      id
      steps
      shortgoals
      longgoals
      how
      moregoals
      deeper
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPlaybook2 = /* GraphQL */ `
  mutation CreatePlaybook2(
    $input: CreatePlaybook2Input!
    $condition: ModelPlaybook2ConditionInput
  ) {
    createPlaybook2(input: $input, condition: $condition) {
      id
      steps
      rankings
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePlaybook2 = /* GraphQL */ `
  mutation UpdatePlaybook2(
    $input: UpdatePlaybook2Input!
    $condition: ModelPlaybook2ConditionInput
  ) {
    updatePlaybook2(input: $input, condition: $condition) {
      id
      steps
      rankings
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePlaybook2 = /* GraphQL */ `
  mutation DeletePlaybook2(
    $input: DeletePlaybook2Input!
    $condition: ModelPlaybook2ConditionInput
  ) {
    deletePlaybook2(input: $input, condition: $condition) {
      id
      steps
      rankings
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPlaybook3 = /* GraphQL */ `
  mutation CreatePlaybook3(
    $input: CreatePlaybook3Input!
    $condition: ModelPlaybook3ConditionInput
  ) {
    createPlaybook3(input: $input, condition: $condition) {
      id
      steps
      qualities
      best
      experience
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePlaybook3 = /* GraphQL */ `
  mutation UpdatePlaybook3(
    $input: UpdatePlaybook3Input!
    $condition: ModelPlaybook3ConditionInput
  ) {
    updatePlaybook3(input: $input, condition: $condition) {
      id
      steps
      qualities
      best
      experience
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePlaybook3 = /* GraphQL */ `
  mutation DeletePlaybook3(
    $input: DeletePlaybook3Input!
    $condition: ModelPlaybook3ConditionInput
  ) {
    deletePlaybook3(input: $input, condition: $condition) {
      id
      steps
      qualities
      best
      experience
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPlaybook4 = /* GraphQL */ `
  mutation CreatePlaybook4(
    $input: CreatePlaybook4Input!
    $condition: ModelPlaybook4ConditionInput
  ) {
    createPlaybook4(input: $input, condition: $condition) {
      id
      steps
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updatePlaybook4 = /* GraphQL */ `
  mutation UpdatePlaybook4(
    $input: UpdatePlaybook4Input!
    $condition: ModelPlaybook4ConditionInput
  ) {
    updatePlaybook4(input: $input, condition: $condition) {
      id
      steps
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deletePlaybook4 = /* GraphQL */ `
  mutation DeletePlaybook4(
    $input: DeletePlaybook4Input!
    $condition: ModelPlaybook4ConditionInput
  ) {
    deletePlaybook4(input: $input, condition: $condition) {
      id
      steps
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createClass = /* GraphQL */ `
  mutation CreateClass(
    $input: CreateClassInput!
    $condition: ModelClassConditionInput
  ) {
    createClass(input: $input, condition: $condition) {
      id
      name
      teacher
      grade
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateClass = /* GraphQL */ `
  mutation UpdateClass(
    $input: UpdateClassInput!
    $condition: ModelClassConditionInput
  ) {
    updateClass(input: $input, condition: $condition) {
      id
      name
      teacher
      grade
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteClass = /* GraphQL */ `
  mutation DeleteClass(
    $input: DeleteClassInput!
    $condition: ModelClassConditionInput
  ) {
    deleteClass(input: $input, condition: $condition) {
      id
      name
      teacher
      grade
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRec = /* GraphQL */ `
  mutation CreateRec(
    $input: CreateRecInput!
    $condition: ModelRecConditionInput
  ) {
    createRec(input: $input, condition: $condition) {
      id
      teacher
      email
      req
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRec = /* GraphQL */ `
  mutation UpdateRec(
    $input: UpdateRecInput!
    $condition: ModelRecConditionInput
  ) {
    updateRec(input: $input, condition: $condition) {
      id
      teacher
      email
      req
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRec = /* GraphQL */ `
  mutation DeleteRec(
    $input: DeleteRecInput!
    $condition: ModelRecConditionInput
  ) {
    deleteRec(input: $input, condition: $condition) {
      id
      teacher
      email
      req
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createExtra = /* GraphQL */ `
  mutation CreateExtra(
    $input: CreateExtraInput!
    $condition: ModelExtraConditionInput
  ) {
    createExtra(input: $input, condition: $condition) {
      id
      extra
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateExtra = /* GraphQL */ `
  mutation UpdateExtra(
    $input: UpdateExtraInput!
    $condition: ModelExtraConditionInput
  ) {
    updateExtra(input: $input, condition: $condition) {
      id
      extra
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteExtra = /* GraphQL */ `
  mutation DeleteExtra(
    $input: DeleteExtraInput!
    $condition: ModelExtraConditionInput
  ) {
    deleteExtra(input: $input, condition: $condition) {
      id
      extra
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createExp = /* GraphQL */ `
  mutation CreateExp(
    $input: CreateExpInput!
    $condition: ModelExpConditionInput
  ) {
    createExp(input: $input, condition: $condition) {
      id
      exp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateExp = /* GraphQL */ `
  mutation UpdateExp(
    $input: UpdateExpInput!
    $condition: ModelExpConditionInput
  ) {
    updateExp(input: $input, condition: $condition) {
      id
      exp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteExp = /* GraphQL */ `
  mutation DeleteExp(
    $input: DeleteExpInput!
    $condition: ModelExpConditionInput
  ) {
    deleteExp(input: $input, condition: $condition) {
      id
      exp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGTask = /* GraphQL */ `
  mutation CreateGTask(
    $input: CreateGTaskInput!
    $condition: ModelGTaskConditionInput
  ) {
    createGTask(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      task
      suggestedby
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateGTask = /* GraphQL */ `
  mutation UpdateGTask(
    $input: UpdateGTaskInput!
    $condition: ModelGTaskConditionInput
  ) {
    updateGTask(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      task
      suggestedby
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteGTask = /* GraphQL */ `
  mutation DeleteGTask(
    $input: DeleteGTaskInput!
    $condition: ModelGTaskConditionInput
  ) {
    deleteGTask(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      task
      suggestedby
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createGCollege = /* GraphQL */ `
  mutation CreateGCollege(
    $input: CreateGCollegeInput!
    $condition: ModelGCollegeConditionInput
  ) {
    createGCollege(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      college
      suggestedby
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateGCollege = /* GraphQL */ `
  mutation UpdateGCollege(
    $input: UpdateGCollegeInput!
    $condition: ModelGCollegeConditionInput
  ) {
    updateGCollege(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      college
      suggestedby
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteGCollege = /* GraphQL */ `
  mutation DeleteGCollege(
    $input: DeleteGCollegeInput!
    $condition: ModelGCollegeConditionInput
  ) {
    deleteGCollege(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      college
      suggestedby
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createGScholarship = /* GraphQL */ `
  mutation CreateGScholarship(
    $input: CreateGScholarshipInput!
    $condition: ModelGScholarshipConditionInput
  ) {
    createGScholarship(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      suggestedby
      deadline
      link
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateGScholarship = /* GraphQL */ `
  mutation UpdateGScholarship(
    $input: UpdateGScholarshipInput!
    $condition: ModelGScholarshipConditionInput
  ) {
    updateGScholarship(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      suggestedby
      deadline
      link
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteGScholarship = /* GraphQL */ `
  mutation DeleteGScholarship(
    $input: DeleteGScholarshipInput!
    $condition: ModelGScholarshipConditionInput
  ) {
    deleteGScholarship(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      suggestedby
      deadline
      link
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      header
      messages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      header
      messages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      header
      messages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCMessage = /* GraphQL */ `
  mutation CreateCMessage(
    $input: CreateCMessageInput!
    $condition: ModelCMessageConditionInput
  ) {
    createCMessage(input: $input, condition: $condition) {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      channel {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        header
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateCMessage = /* GraphQL */ `
  mutation UpdateCMessage(
    $input: UpdateCMessageInput!
    $condition: ModelCMessageConditionInput
  ) {
    updateCMessage(input: $input, condition: $condition) {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      channel {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        header
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteCMessage = /* GraphQL */ `
  mutation DeleteCMessage(
    $input: DeleteCMessageInput!
    $condition: ModelCMessageConditionInput
  ) {
    deleteCMessage(input: $input, condition: $condition) {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      channel {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        header
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      name
      owner
      members
      requests
      channels {
        items {
          id
          name
          header
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          task
          suggestedby
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      scholarships {
        items {
          id
          name
          suggestedby
          deadline
          link
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      colleges {
        items {
          id
          college
          suggestedby
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          name
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      name
      owner
      members
      requests
      channels {
        items {
          id
          name
          header
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          task
          suggestedby
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      scholarships {
        items {
          id
          name
          suggestedby
          deadline
          link
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      colleges {
        items {
          id
          college
          suggestedby
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          name
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      name
      owner
      members
      requests
      channels {
        items {
          id
          name
          header
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          task
          suggestedby
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      scholarships {
        items {
          id
          name
          suggestedby
          deadline
          link
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      colleges {
        items {
          id
          college
          suggestedby
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          name
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      channelmessages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      year
      more
      email
      createdAt
      updatedAt
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      channelmessages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      year
      more
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      channelmessages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      year
      more
      email
      createdAt
      updatedAt
    }
  }
`;
export const createConvo = /* GraphQL */ `
  mutation CreateConvo(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConvo(input: $input, condition: $condition) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const createPrivateMessage = /* GraphQL */ `
  mutation CreatePrivateMessage(
    $input: CreatePrivateMessageInput!
    $condition: ModelPrivateMessageConditionInput
  ) {
    createPrivateMessage(input: $input, condition: $condition) {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      content
      createdAt
      updatedAt
    }
  }
`;
export const updatePrivateMessage = /* GraphQL */ `
  mutation UpdatePrivateMessage(
    $input: UpdatePrivateMessageInput!
    $condition: ModelPrivateMessageConditionInput
  ) {
    updatePrivateMessage(input: $input, condition: $condition) {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      content
      createdAt
      updatedAt
    }
  }
`;
export const deletePrivateMessage = /* GraphQL */ `
  mutation DeletePrivateMessage(
    $input: DeletePrivateMessageInput!
    $condition: ModelPrivateMessageConditionInput
  ) {
    deletePrivateMessage(input: $input, condition: $condition) {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      content
      createdAt
      updatedAt
    }
  }
`;
export const createConvoLink = /* GraphQL */ `
  mutation CreateConvoLink(
    $input: CreateConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    createConvoLink(input: $input, condition: $condition) {
      id
      member {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      convoLinkMember
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const updateConvoLink = /* GraphQL */ `
  mutation UpdateConvoLink(
    $input: UpdateConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    updateConvoLink(input: $input, condition: $condition) {
      id
      member {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      convoLinkMember
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const deleteConvoLink = /* GraphQL */ `
  mutation DeleteConvoLink(
    $input: DeleteConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    deleteConvoLink(input: $input, condition: $condition) {
      id
      member {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      convoLinkMember
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
