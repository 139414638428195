import React, { Component } from "react";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify from "aws-amplify";
import aws_exports from "../../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Popover, Drawer } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import quizQuestions from "../Quiz/questions/findAid";
import Quiz from "../Quiz/quiz";
import Result from "../Quiz/result";

Amplify.configure(aws_exports);

class PlaybookOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      current: 0,
      visible: false,
      shortgoal1: "",
      shortgoal2: "",
      shortgoal3: "",
      longgoal1: "",
      longgoal2: "",
      longgoal3: "",
      how: "",
      moregoal1: "",
      moregoal2: "",
      moregoal3: "",
      moregoal4: "",
      moregoal5: "",
      moregoal6: "",
      email: "f",
      text: "",
      counter: 0,
      questionId: 1,
      question: "",
      answerOptions: [],
      answer: "",
      answersCount: {},
      wrongAnswers: [],
      result: "",
    };
    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const shuffledAnswerOptions = quizQuestions.map((question) =>
      this.shuffleArray(question.answers)
    );

    this.setState({
      question: quizQuestions[0].question,
      answerOptions: shuffledAnswerOptions[0],
    });
  }

  shuffleArray = (array) => {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  handleAnswerSelected = (event) => {
    this.setUserAnswer(event.currentTarget.value);
    var wrong = {};
    // Check for wrong answers
    if (this.state.questionId <= quizQuestions.length) {
      const q = quizQuestions[this.state.questionId - 1];
      if (q.correct !== event.currentTarget.value) {
        wrong["question"] = q;
        wrong["user_answer"] = event.currentTarget.value;

        var fullOption = quizQuestions.find((obj) => {
          return obj.id === this.state.questionId;
        });

        fullOption = fullOption["answers"].find((obj) => {
          return obj.type === event.currentTarget.value;
        });

        wrong["option"] = fullOption["content"];
      }
    }

    this.setState(
      {
        wrongAnswers: [...this.state.wrongAnswers, wrong],
      },
      () => {
        this.nextSteps();
      }
    );
  };

  nextSteps = () => {
    if (this.state.questionId < quizQuestions.length) {
      setTimeout(() => this.setNextQuestion(), 300);
    } else {
      setTimeout(() => this.setResults(this.getResults()), 300);
    }
  };

  setUserAnswer = (answer) => {
    this.setState((state) => ({
      answersCount: {
        ...state.answersCount,
        [answer]: (state.answersCount[answer] || 0) + 1,
      },
      answer: answer,
    }));
  };

  setNextQuestion = () => {
    const counter = this.state.counter + 1;
    const questionId = this.state.questionId + 1;

    this.setState({
      counter: counter,
      questionId: questionId,
      question: quizQuestions[counter].question,
      answerOptions: quizQuestions[counter].answers,
      answer: "",
    });
  };

  getResults = () => {
    const answersCount = this.state.answersCount;
    const answersCountKeys = Object.keys(answersCount);
    const answersCountValues = answersCountKeys.map((key) => answersCount[key]);
    const maxAnswerCount = Math.max.apply(null, answersCountValues);

    return answersCountKeys.filter(
      (key) => answersCount[key] === maxAnswerCount
    );
  };

  setResults = (result) => {
    if (result.length === 1) {
      this.setState({ result: result[0] });
    } else {
      this.setState({ result: "Undetermined" });
    }
  };

  renderQuiz = () => {
    return (
      <Quiz
        answer={this.state.answer}
        answerOptions={this.state.answerOptions}
        questionId={this.state.questionId}
        question={this.state.question}
        questionTotal={quizQuestions.length}
        onAnswerSelected={this.handleAnswerSelected}
      />
    );
  };

  renderResult = () => {
    return (
      <Result
        quizResult={this.state.result}
        wrongAnswers={this.state.wrongAnswers}
        questions={quizQuestions}
      />
    );
  };

  handleChange(evt) {
    // check it out: we get the evt.target.name (which will be either "email" or "password")
    // and use it to target the key on our `state` object with the same name, using bracket syntax
    this.setState({ [evt.target.name]: evt.target.value });
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  setUserAnswer(answer) {
    this.setState((state) => ({
      answersCount: {
        ...state.answersCount,
        [answer]: (state.answersCount[answer] || 0) + 1,
      },
      answer: answer,
    }));
  }

  getMoreAboutGoals = (title) => {
    return (
      <div style={{ textAlign: "left" }}>
        <div className="columns">
          <div className="column is-two-fifths">
            <h5 className="title is-5">{title}</h5>
          </div>
        </div>
        <p>
          Before we dive into finding the right school, let's explore why you
          want to go to college in the first place.
        </p>

        <p> Start by thinking about a few of these questions:</p>

        <div className="content">
          <div className="columns">
            <div className="column is-half">
              <ul style={{ listStyle: "none" }}>
                <li>
                  <p>
                    {" "}
                    What are the classes you perform the best in? Is it an easy
                    A for you?
                  </p>
                  <input
                    className="input"
                    type="text"
                    name="moregoal1"
                    defaultValue={this.props.moregoal1}
                    onChange={this.props.handleChange}
                    placeholder="eg. English. I have don't have to study hard to get an A"
                  />
                </li>
                <li>
                  <p>
                    {" "}
                    What are the classes you perform the worst in? Why is this
                    class hard for you?
                  </p>
                  <input
                    className="input"
                    type="text"
                    name="moregoal2"
                    defaultValue={this.props.moregoal2}
                    onChange={this.props.handleChange}
                    placeholder="eg. Math. It's the class I struggle with the most"
                  />
                </li>
                <li>
                  <p> What are the classes do you like the most? Why?</p>
                  <input
                    className="input"
                    type="text"
                    name="moregoal3"
                    defaultValue={this.props.moregoal3}
                    onChange={this.props.handleChange}
                    placeholder="eg. Spanish and Latin. I like learning new languages"
                  />
                </li>
              </ul>
            </div>

            <div className="column is-half">
              <ul style={{ listStyle: "none" }}>
                <li>
                  <p> What do you like to do outside of school?</p>
                  <input
                    className="input"
                    type="text"
                    name="moregoal4"
                    defaultValue={this.props.moregoal4}
                    onChange={this.props.handleChange}
                    placeholder="eg. I like running"
                  />
                </li>
                <li>
                  <p>
                    {" "}
                    What's something you're good at that nobody else knows
                    about?
                  </p>
                  <input
                    className="input"
                    type="text"
                    name="moregoal5"
                    defaultValue={this.props.moregoal5}
                    onChange={this.props.handleChange}
                    placeholder="eg. I can speak multiple languages really well"
                  />
                </li>
                <li>
                  <p>
                    {" "}
                    Do you play any sports or are you involved in any clubs or
                    extracurriculars?
                  </p>
                  <input
                    className="input"
                    type="text"
                    name="moregoal6"
                    defaultValue={this.props.moregoal6}
                    onChange={this.props.handleChange}
                    placeholder="eg. I play club basketball"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <p>
              <strong>Think a Little Deeper</strong>
            </p>
            <p>
              Here's some space to think some more about yourself, what you
              like, and how you operate. Don't feel self-conscious about your
              answers. Answering and reflecting on these questions will help
              guide you in your search for the right college.
              <div className="button is-text" onClick={this.showDrawer}>
                Some more questions to ask yourself
              </div>
            </p>
            <Drawer
              title="Reflection Questions"
              placement="right"
              closable={true}
              onClose={this.onClose}
              visible={this.state.visible}
            >
              <ul>
                <li>What do you like the most about high school?</li>
                <br />
                <li>
                  If you could do high school over, what would you change?
                </li>
                <br />
                <li>What's most important to you right now?</li>
                <br />
                <li>What does success mean to you?</li>
                <br />
                <li>
                  What has happened in your life that made you who you are
                  today?
                </li>
              </ul>
            </Drawer>
            {/* <ReactQuill theme="snow"/> */}
            <ReactQuill
              theme="snow"
              type="deeper"
              name="deeper"
              value={this.props.deeper}
              onChange={this.props.handleEditorChange}
            />
          </div>
        </div>

        <p>
          By thinking about the questions in this section and the previous one,
          the goal is for you to think about why you want to go to college.
          Which goals do you think you need a college degree to complete? What
          do you want to accomplish in your life? We want you to think big.
          Sky's the limit when it comes to college.{" "}
        </p>
      </div>
    );
  };

  getLetsTalk = (title) => {
    return (
      <div style={{ textAlign: "left" }}>
        <div className="columns is-centered is-vcentered">
          <div className="column">
            <h5 className="title is-5">{title}</h5>
            <p>
              Why do people go to college? Why do you want to go to college?
              There are so many different reasons and there's no right answer.
              Whatever the reason is for you, it's a valid one.
            </p>
            <p>
              People go to college for many reasons. One of the biggest reasons
              to go to college is because of the opportunities that can be
              provided to you once you graduate. Many people say that college is
              an investment in your future, and they're right! People who get a
              4-year degree are likely to make way more than high school
              graduates. Research shows that people with a 4-year degree earn,
              on average, $50,000 while people with only a high school degree
              earn about $30,0000.
            </p>
            <p>
              Besides earning more in the future, there are a bunch of other
              reasons to go to college/get a higher education:
              <div className="content">
                <ul>
                  <li>
                    <strong>Learn a valuable skill</strong>
                    <p>
                      If you decide to go to a community college or a technical
                      program, you can earn an associate degree in two years or
                      a certificate in a specific field of interest (like
                      engineering, art, nursing). This is a great option if you
                      know exactly what you want to do in the future.
                    </p>
                  </li>
                  <li>
                    <strong>Making lasting connections</strong>
                    <p>
                      These days, getting a job, a lot of times, relies on who
                      you know. The people you meet in college — through clubs,
                      social groups like fraternities and sororities, and more
                      casual gatherings — could give you a leg up in the job
                      market for when you graduate.
                    </p>
                  </li>
                  <li>
                    <strong>Become more financially fit</strong>
                    <p>
                      As we talked about earlier, earning a degree means earning
                      more in the long run. On top of earning more, according to
                      research, if you have a bachelor’s degree or higher,
                      you’re more likely to have a retirement plan and to earn
                      more income from investments in stocks, savings accounts
                      and real estate.
                    </p>
                  </li>
                  <li>
                    <strong>More career opportunities</strong>
                    <p>
                      Going to college gives you so many more opportunities and
                      opens many doors for you. Most jobs today require some
                      level of a college education.
                    </p>
                  </li>
                  <li>
                    <strong>New experiences</strong>
                    <p>
                      Even if you don't end up using your college major when you
                      graduate, going to college is still a great investment in
                      yourself. By going to college, you'll gain broad skills,
                      like critical thinking and problem solving which are
                      oftentimes more important than your major. Also, outside
                      the classroom, you'd get the chance to meet new people and
                      see new places.
                    </p>
                  </li>
                </ul>
              </div>
              Getting some level of education after high school — whether at a
              traditional four-year campus or community college pays off more
              than you might know.
            </p>
          </div>
          <div className="column is-one-third">
            <div className="box">
              <div className="content">
                <strong>
                  Here’s how a college degree can help over a lifetime:
                </strong>
                <br />
                <br />
                Someone with a high school diploma can expect to earn{" "}
                <strong>$1.3 million</strong>.
                <br />
                <br />A worker with some college but no degree earns{" "}
                <strong>$1.5 million</strong>.
                <br />
                <br />
                An Associate’s degree-holder earns <strong>$1.7 million</strong>
                .
                <br />
                <br />A worker with a Bachelor’s degree will earn{" "}
                <strong>$2.3 million</strong>.
                <br />
                <br />
                <strong>
                  {" "}
                  Graduate degrees provide for even higher earnings:
                </strong>
                <br />
                <br />A Master’s degree-holder earns{" "}
                <strong>$2.7 million</strong>.
                <br />
                <br />A Doctoral degree-holder earns{" "}
                <strong>$3.3 million</strong>.
                <br />
                <br />A Professional degree-holder earns{" "}
                <strong>$3.6 million</strong>.
                <br />
                <br />
                <a
                  href="https://www.commonapp.org/plan/why-college-matters"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="help">cite: Common App</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <h5>What are your goals?</h5>
        <p>
          Let's start with thinking about your values, skills and interests.
          What do you think about when you think of life after high school?
        </p>
        <div className="columns">
          <div className="column">
            <img
              style={{ marginRight: "2%" }}
              alt=""
              src={require("../../../../../assets/gifs/school.gif")}
            />
            <p className="help">Giphy.</p>
          </div>
        </div>
        <p>
          Are you thinking about the military, work, trade, or college? Lots of
          things to think about early on and there's no right answer. The answer
          is whatever is best for you. But if you're here, you might be thinking
          of college as an option.
        </p>
        <p>Let's dive into some of your goals</p>
        <p>
          <strong>Short and Long Term Goals</strong>
        </p>
        <div className="content">
          <div className="columns">
            <div className="column is-half">
              <p>
                {" "}
                List some of your <strong>long term</strong> goals
              </p>
              <ol type="1">
                <li>
                  <input
                    className="input"
                    name="longgoal1"
                    defaultValue={this.props.longgoal1}
                    onChange={this.props.handleChange}
                    placeholder="graduate from college"
                  />
                </li>
                <li>
                  <input
                    className="input"
                    name="longgoal2"
                    defaultValue={this.props.longgoal2}
                    onChange={this.props.handleChange}
                    placeholder="do volunteer work"
                  />
                </li>
                <li>
                  <input
                    className="input"
                    name="longgoal3"
                    defaultValue={this.props.longgoal3}
                    onChange={this.props.handleChange}
                    placeholder="become a software engineer"
                  />
                </li>
              </ol>
            </div>

            <div className="column is-half">
              <p>
                {" "}
                List some of your <strong>short term</strong> goals
              </p>
              <ol type="1">
                <li>
                  <input
                    className="input"
                    type="text"
                    name="shortgoal1"
                    defaultValue={this.props.shortgoal1}
                    onChange={this.props.handleChange}
                    placeholder="join a club sport"
                  />
                </li>
                <li>
                  <input
                    className="input"
                    type="text"
                    name="shortgoal2"
                    defaultValue={this.props.shortgoal2}
                    onChange={this.props.handleChange}
                    placeholder="get As in at least 3 classes"
                  />
                </li>
                <li>
                  <input
                    className="input"
                    type="text"
                    name="shortgoal3"
                    defaultValue={this.props.shortgoal3}
                    onChange={this.props.handleChange}
                    placeholder="get to school on time"
                  />
                </li>
              </ol>
              <p className="help">
                {" "}
                These short term goals should help you reach your long term
                goals
              </p>
            </div>
          </div>
        </div>
        <p>
          <strong>How will college help you reach these goals?</strong>
        </p>{" "}
        Think about this for a little bit and then jot down some answers.
        <div className="content">
          <textarea
            className="textarea"
            name="how"
            defaultValue={this.props.how}
            onChange={this.props.handleChange}
            placeholder="e.g. College would help me explore different areas of engineering that I find interesting."
          ></textarea>
        </div>
        <p>
          The goal here is to think about your academic and personal goals for
          the future and what you can do to reach them and seeing how college
          fits into that equation.
        </p>
        <p>
          Knowing what your short and long term goals are will help you figure
          out what path to college is right for you. Of course, your goals may
          change over time so make sure to come back here every so often to see
          whether or not your long and short term goals still align with what
          you want to do.
        </p>
        <i>
          Don't worry. We'll send you reminders to check back here every couple
          of months to see if your goals have changed.
        </i>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.stepNum === 0 ? (
          <Doing title={this.props.title} />
        ) : this.props.stepNum === 1 ? (
          this.getLetsTalk(this.props.title)
        ) : this.props.stepNum === 2 ? (
          this.getMoreAboutGoals(this.props.title)
        ) : // <Goals title={this.props.title}
        //                                  showDrawer={this.showDrawer}
        //                                  visible={this.state.visible}
        //                                  onClose={this.onClose}
        // />
        // this.props.stepNum === 3 ? <Alternatives title={this.props.title} /> :
        this.props.stepNum === 3 ? (
          <BeforeMyths title={this.props.title} />
        ) : this.props.stepNum === 4 ? (
          <AidQuiz
            title={this.props.title}
            renderQuiz={this.renderQuiz}
            renderResult={this.renderResult}
            result={this.state.result}
          />
        ) : this.props.stepNum === 5 ? (
          <Resources title={this.props.title} />
        ) : null}

        {/* <Popover content="sfsdf" title="fasfa">
                                                    <p><strong>Hover me</strong></p>
                                                </Popover>
              playbook 1 */}
      </div>
    );
  }
}

export default PlaybookOne;

function Doing(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <img
            style={{ marginRight: "2%" }}
            alt=""
            src={require("../../../../../assets/gifs/cat.gif")}
          />
          <p className="help">Giphy.</p>
        </div>
        <div className="column is-two-thirds">
          <p>
            Unfortunately, there's no secret formula for getting into college.
            But even though it's almost impossible to predict whether or not
            you'll get in, colleges have put some emphasis on certain components
            they look at to determine whether or not to admit you to their
            school. They also rank these components in order from most to least
            important (but still important):{" "}
            <strong>
              grades, standardized test scores, extracurriculars, community
              service, and summer activities
            </strong>
            . One note though, if you're being recruited for a sport or a
            specific scholarship, this order does not apply to you. Otherwise,
            here's the breakdown of these key components. We'll talk about
            standardized tests in Playbook 3.
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <h5>Grades</h5>
          <p>
            {" "}
            Your grades are the most important piece of your application. The
            two main components of your grades that colleges look at are your
            GPA (of course) and the types of classes you took. You should be
            taking your core classes: math, history, english, etc but colleges
            would also like to see that you are challenging yourself. They'll be
            more impressed with you getting an A in calculus than an A in
            physical education.
          </p>
          <p>
            Work with your high school guidance counselor to take challenging
            courses that you can handle. Don't set yourself up for failure if
            you can't handle the workload.
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h5>Extracurriculars and Community Service</h5>
          <p>
            Colleges want to see that you plan to hit the ground running when
            you get to campus. This means that they want their students to get
            involved on campus. But don't go signing up for every
            club/extracurricular your school has to offer. Colleges like to see
            that you can commit to a couple, excel at them, and gain leadership
            experience. Having a couple of activities on your application where
            you have contributed for some time and have quality experience will
            carry more weight than listing a bunch of clubs that you showed up
            for once or twice.
          </p>
          <p>
            You can start exploring different extracurriculars your school or
            community offers in your freshman and sophomore year. This is the
            time where you want to join as many things as possible to figure out
            what you're interested in. By your junior year, you want to fully
            commit to a couple. If you don't find something of interest, feel
            free to start your own thing! But whatever you do, make sure you're
            doing it consistently.
          </p>
          <p>
            Community service experience is very similar. You want to pick a
            cause that you believe in and stick to it for at least a year.
            Again, this shows colleges that you are willing to step outside your
            comfort zone and give back to the community. Remember, you're not
            doing community service to pad your resume. You're participating in
            community service to grow as a person.
          </p>
        </div>
      </div>

      <div className="columns" style={{ margin: "2%" }}>
        <div className="columns is-centered">
          <div className="column is-two-thirds">
            <div className="card">
              <div className="card-content">
                <div className="columns is-centered is-vcentered">
                  <div className="column">
                    <p>
                      <strong>Note: </strong>Make sure to keep track of your
                      academic and extracurricular accomplishments in your
                      Locker!
                    </p>
                  </div>
                  <div className="column">
                    <a href="/profile" target="_blank">
                      <div className="button is-link">
                        Open Locker in a new tab
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <h5>Summer Activities</h5>
          <p>
            Yes, even during the summer you want to be working towards your
            college goals. Take this time to find a job that will help you save
            some money for college costs. Ideally, the job will also allow you
            to develop leadership skills and take on responsibility. If money
            isn't an issue then there are other options:
            <div className="content">
              <ul>
                <li>Get an internship in a field you're interested in</li>
                <li>
                  Find a community service activity that you're passionate about
                </li>
                <li>
                  Try out a summer camp for a subject you're interested in
                  (engineering, art, athletics, music, etc.)
                </li>
              </ul>
            </div>
          </p>
        </div>
      </div>
      <div className="columns is-centered is-vcentered">
        <div className="column is-two-thirds">
          <h5>Timelines and Tasks</h5>
          <p>
            After reading all this, you probably have a list of tasks and to-dos
            that you need to organize to stay on track for what to do next. If
            you already have an idea of what to do, great! If you don't, that's
            cool too! On the{" "}
            <a href="/roadmap" target="_blank">
              Roadmaps page
            </a>{" "}
            under
            <i>Task Suggestions</i> you'll find suggestions for important tasks
            to do depending on your grade (from freshman to senior year). You'll
            also be able to add your to-dos to your planner. Having a prepared
            to-do list for each grade will help you stay on track and make sure
            that nothing gets overlooked as you prep for college.
          </p>
        </div>
        <div className="column">
          <a href="/roadmap" target="_blank">
            {" "}
            <p>Add school year tasks to my planner</p>
          </a>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h6>Admissions Journey Timeline</h6>
          <p>
            You can go to the{" "}
            <a href="/roadmap" target="_blank">
              Roadmaps page
            </a>{" "}
            to see what tasks you should be doing depending on your year in
            school but here's a quick overview.
          </p>
          <p>
            <p>
              <strong>Freshman - </strong>This is when you can start planning
              ahead. It's never to early to plan for college. You can start by
              going through these Playbooks.
            </p>
            <p>
              At the start of your school year, you can make a list of all your
              classes, activities, and jobs and then prioritize them. Anything
              you get a grade in should be a top priority. Also, think about
              creating a routine. Have a weekly calendar where you can see what
              needs to be done and when.
            </p>
            <p>
              Some additional things you can do: get involved by joining a club,
              volunteer, try out for a sport, make summer plans for a job or
              internship or volunteering opportunity, etc.
            </p>
          </p>
          <p>
            <p>
              <strong>Sophmore - </strong> This year is similar to your freshman
              year but now you're not new to the process and hopefully, you've
              joined some clubs you're interested in.
            </p>
            <p>
              Some additional things you can do: talk to your guidance about
              taking the PSAT, meet with your guidance counselor to talk about
              the best classes to take for your junior year, do some reading,
              start searching for scholarships, etc.
            </p>
          </p>
          <p>
            <p>
              <strong>Junior - </strong> This when things start getting serious
              but not too serious. Just remember to breathe. You definitely
              don't need to have a finalized list of schools yet but it's time
              to start looking.
            </p>
            <p>
              Some additional things you can do: work through all the Playbooks,
              starting creating a college shortlist, register and take the PSAT,
              sign-up for test-prep courses, register and take the ACT or SAT,
              register and take AP exams if you're taking AP classes, focus on
              doing great work in any extracurricular activity you're involved
              in etc.
            </p>
          </p>
          <p>
            <p>
              <strong>Senior - </strong> Oh, so now it's serious. At this point,
              you've already hit the ground running during your freshman through
              your junior year. If you haven't, don't worry. As long as you
              manage your time and create a routine, you'll be able to conquer
              this final year and beat senioritis.
            </p>
            <p>
              Some additional things you can do: register and take the SAT or
              ACT if you haven't already, finalize your college list, ask for
              teacher recommendations in the fall, focus on doing great work in
              any extracurricular activity you're involved in, review all of you
              college application checklists via the college organizer (on the
              College List page), register and take AP exams, apply to colleges,
              apply to as many scholarships as you can (!!!), etc.
            </p>
          </p>
        </div>
      </div>
    </div>
  );
}

// function Myths(props) {
//   return (
//     <div style={{ textAlign: "left" }}>
//       <div className="columns">
//         <div className="column is-two-fifths">
//           <h5 className="title is-5">{props.title}</h5>
//         </div>
//       </div>
//     </div>
//   );
// }

// function Alternatives(props) {
//   const undergrad = (
//     <div>
//       <p>
//         An Undergraduate education is education that happens after secondary
//         education (high school) and before postgraduate education (Masters, PHD,
//         etc).{" "}
//       </p>
//     </div>
//   );
//   return (
//     <div style={{ textAlign: "left" }}>
//       <div className="columns">
//         <div className="column is-two-fifths">
//           <h5 className="title is-5">{props.title}</h5>
//         </div>
//       </div>
//       <p>
//         Alternatives are just things you can do after high school that isn't an{" "}
//         <Popover content={undergrad} title="Undergraduate education">
//           <strong>undergrad</strong>
//         </Popover>{" "}
//         program. If you figure out that college isn't right for you or you want
//         some time before jumping on the college train, here are some alternative
//         options:
//       </p>
//       <ul>
//         <li>
//           <strong>Gap Year</strong> - Taking a year off of school to explore
//           your interests. This option is usually best for students who have a
//           plan. During this year, students usually take up volunteering
//           opportunities like &nbsp;
//           <a
//             target="_blank"
//             href="https://www.nationalservice.gov/programs/americorps"
//           >
//             Americorps
//           </a>
//           &nbsp; or{" "}
//           <a target="_blank" href="https://www.peacecorps.gov/">
//             Peace Corps
//           </a>
//           .
//         </li>
//         <br />
//         <li>
//           <strong>Community College</strong> - Yes, still technically school but
//           hear us out. Community college is a great way to save money and figure
//           out what you want to study before committing to a 4 year program. You
//           can take the time to discover what you're interested and the transfer
//           your college credits to a 4 year school. Doing this will save you a
//           lot on tuition. You'd also have the ability to work part-time if you
//           wanted to.
//         </li>
//       </ul>
//     </div>
//   );
// }

function Resources(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">Assignment</h5>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <ol type="1">
                  <li>
                    Update your{" "}
                    <a href="/profile" target="_blank">
                      Locker
                    </a>{" "}
                    with your academic and extracurricular accomplishments.
                  </li>
                  <li>
                    Update your{" "}
                    <a href="/roadmap" target="_blank">
                      Planner
                    </a>{" "}
                    with your current school year tasks using{" "}
                    <i>Task Suggestions</i> to start.
                  </li>
                  <li>
                    Write a{" "}
                    <a href="/notes" target="_blank">
                      note
                    </a>{" "}
                    about your goals for college. Why do you want to go to
                    college?
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-two-fifths">
          <img src={require("../../../../../assets/book.svg")} alt="" />
        </div>
      </div>
      <hr />
      {/* <div className="columns">
          <div className="column is-two-fifths">
              <h5 className="title is-5">Resources</h5>
          </div>
      </div> */}
      <div className="columns">
        <div className="column">
          <div className="content">
            <div className="columns">
              <div className="column">
                <h5 className="title is-5">Resources</h5>
              </div>
            </div>
            <div className="columns">
              <div className="column is-three-fifths">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://roadtripnation.com/edu/careerfinder"
                    >
                      College Board and Road Trip Nation’s Roadmap to Careers
                    </a>
                    <p className="help">
                      Figure out what you're interested in and what careers
                      could best suit you
                    </p>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="http://www.cyberguidance.net/dpl/node/5"
                    >
                      What can I do with a major in...?
                    </a>
                    <p className="help">
                      A list of 46 majors with insights on what careers in that
                      field could be like
                    </p>
                  </li>
                </ul>
              </div>
              <div className="column">
                <div className="columns">
                  <div className="column">
                    <p className="help">
                      <strong>
                        Beat the SAT with prep that feels like a game!
                      </strong>
                    </p>
                    <p className="help">
                      Everydae helps students boost their SAT scores and
                      confidence, all through 10-minute challenges that feel
                      like a game, not like a chore.
                    </p>
                  </div>
                  <div className="column">
                    <a
                      href="https://www.everydae.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../../../../assets/everydae.jpg")}
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function BeforeMyths(props) {
  const ba = (
    <div>
      <p>
        An undergraduate academic degree awarded by colleges and universities
        after completing a course of study. <br />
        Usually a 4 year degree but programs can take between 3-7 years.{" "}
      </p>
    </div>
  );

  const studyabroad = (
    <div>
      <p>
        Going to another country to learn and take classes while you're in
        college.
      </p>
    </div>
  );

  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <p>
        Everyone knows college is expensive. <br /> <br />
        But even though college can cost more than most realize, college opens
        doors for many new opportunities like making new friends and{" "}
        <Popover content={studyabroad} title="Study Abroad">
          <strong>studying abroad</strong>
        </Popover>
        . There are also so many other awesome things a college degree can do
        for you.
        <br /> <br />
        For example, a college degree will help you earn more money in the long
        run. Today, a person with a{" "}
        <Popover content={ba} title="Bachelor's Degree">
          <strong>bachelor's degree</strong>
        </Popover>{" "}
        will usually earn almost twice as much as a person with a high school
        diploma. Actually, studies have shown that the more education you get
        beyond high school means more money you can expect to earn in the
        future.{" "}
      </p>

      <div className="columns">
        <div className="column">
          <img
            style={{ marginRight: "2%" }}
            alt=""
            src={require("../../../../../assets/gifs/money.gif")}
          />
          <p className="help">Giphy.</p>
        </div>
      </div>

      <p>
        But back to college being expensive. We'll go more in depth with paying
        for college in Playbook 4, <i>Making Your Final Choice</i>, but for now,
        let's clear up some myths and test your knowledge about Financial Aid.
      </p>
    </div>
  );
}

class AidQuiz extends Component {
  render() {
    return (
      <div style={{ textAlign: "left" }}>
        <div className="columns">
          <div className="column is-two-fifths">
            <h5 className="title is-5">{this.props.title}</h5>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            {this.props.result
              ? this.props.renderResult()
              : this.props.renderQuiz()}
          </div>
        </div>
      </div>
    );
  }
}
