import React from "react";
import "bulma/css/bulma.css";
import { Typography, Pagination } from "antd";
import ListStudents from "../components/ListStudents";

const { Title } = Typography;

function MentorStudents() {
  return (
    <div>
      <div className="columns">
        <div className="column">
          <Title style={{ textAlign: "center" }} level={1}>
            Students{" "}
          </Title>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <ListStudents />
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Pagination defaultCurrent={1} total={2} />
        </div>
      </div>
    </div>
  );
}
export default MentorStudents;
