import React, { useState, useEffect } from "react";
import {
  Typography,
  Tabs,
  Menu,
  Popconfirm,
  Icon,
  Dropdown,
  Modal,
  Alert,
} from "antd";
import { taskPerct } from "../../components/Schools/helpers";
import { getPath } from "./helpers";
import PersonalFit from "./PersonalFit";
import ApplicationTasks from "./ApplicationTasks";
import UpdateManager from "./UpdateManger";
import { updateManager, deleteManager, getValidLink } from "./helpers";
import { Link } from "react-router-dom";

const { Title } = Typography;
const { TabPane } = Tabs;
const statusStr = [
  "Started Application",
  "Applied",
  "Accepted",
  "Waitlisted",
  "Rejected",
];

function CollegeManager(props) {
  const [visible, setVisible] = useState(false);
  const [major, setMajor] = useState(props.data.intended);
  const [term, setTerm] = useState(props.data.term);
  const [fee, setFee] = useState(props.data.fee);
  const [due, setDue] = useState(props.data.due);
  const [link, setLink] = useState(props.data.link);
  const [fit, setFit] = useState(props.data.category);
  const [status, setStatus] = useState(props.data.status);

  useEffect(() => {
    setMajor(props.data.intended);
    setTerm(props.data.term);
    setFee(props.data.fee);
    setDue(props.data.due);
    setLink(props.data.link);
    setFit(props.data.category);
    setStatus(props.data.status);
  }, [props.data]);

  const orgMenu = (
    <Menu>
      {props.data ? (
        <Menu.Item>
          <Link
            to={{
              pathname: getPath(props.data["name"]),
              state: {
                id: props.data["schoolId"],
                name: props.data["name"],
              },
            }}
          >
            <p>More Info</p>
          </Link>
        </Menu.Item>
      ) : null}
      <Menu.Item onClick={() => setVisible(true)}>
        <p>Update Details</p>
      </Menu.Item>

      <Menu.Item>
        <Popconfirm
          title="Are you sure delete this orgnaizer?"
          onConfirm={() => submitDelete()}
          onCancel={() => setVisible(false)}
          okText="Yes"
          cancelText="No"
        >
          <strong>Delete Organizer</strong>
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const submitManagerUpdate = async () => {
    const updatedManager = {
      intended: major,
      term: term,
      fee: fee,
      due: due,
      link: link,
      category: fit,
      status: status,
      id: props.data.id,
    };
    await updateManager(updatedManager).then((data) => props.updateApplying());
  };

  const resetInputs = () => {
    setMajor(props.data.intended);
    setTerm(props.data.term);
    setFee(props.data.fee);
    setDue(props.data.due);
    setLink(props.data.link);
    setFit(props.data.category);
    setStatus(props.data.status);
  };

  const submitDelete = async () => {
    await deleteManager(props.data.id);
    window.location.reload();
  };

  const goToApp = () => {
    if (getValidLink(props.data.link)) {
      window.open(props.data.link, "_blank");
    }
  };

  return (
    <div>
      <Modal
        title={"Update your College Manager for " + props.data.name}
        visible={visible}
        onOk={() => {
          setVisible(false);
          submitManagerUpdate();
        }}
        onCancel={() => {
          setVisible(false);
          resetInputs();
        }}
        okText="Submit"
      >
        <UpdateManager
          setTerm={setTerm}
          term={term}
          setFee={setFee}
          fee={fee}
          setMajor={setMajor}
          major={major}
          setDue={setDue}
          due={due}
          setLink={setLink}
          link={link}
          setFit={setFit}
          fit={fit}
          setStatus={setStatus}
          status={status}
        />
      </Modal>

      <div className="columns">
        <div className="column is-one-fifth">
          <progress
            className="progress is-success"
            value={taskPerct(props.data.tasks)}
            max="100"
          ></progress>
          {taskPerct(props.data.tasks)}% of Tasks Complete
        </div>
        <div className="column is-two-fifths">
          <Title style={{ fontWeight: 900, margin: "2%" }} level={2}>
            {props.data.name}
          </Title>
        </div>
        <div className="column is-one-fifth">
          <div className="columns is-centered">
            <div className="column">
              <div
                className="button is-small is-fullwidth is-small is-rounded"
                onClick={goToApp}
              >
                Application Link
              </div>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column">
              <div className="tags are-small">
                {props.data.category === "Reach" ? (
                  <span className="tag is-danger">Reach</span>
                ) : props.data.category === "Match" ? (
                  <span className="tag is-warning">Match</span>
                ) : props.data.category === "Safety" ? (
                  <span className="tag is-success">Safety</span>
                ) : (
                  <span className="tag is-info">Unknown Academic Fit</span>
                )}
                <span className="tag is-black">
                  {props.data.status
                    ? statusStr[parseInt(props.data.status)]
                    : "Add App Status"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-one-fifth">
          <Dropdown overlay={orgMenu}>
            <div
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Options <Icon type="down" />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Tabs defaultActiveKey="0">
            <TabPane tab="Personal Fit" key="0">
              <div className="columns">
                <div className="column">
                  <Alert
                    message="Ratings"
                    type="info"
                    description="As you do your research on this school, keep in mind some of the factors listed below. How well does the school stack up? Are these factors important to you? These factors, among others, are important when making a decision."
                    showIcon
                    closable
                  />
                </div>
              </div>
              <PersonalFit
                data={props.data}
                updateApplying={props.updateApplying}
              />
            </TabPane>
            <TabPane tab="Application Tasks" key="1">
              {props.data.status === null ||
              props.data.intended === null ||
              props.data.term === null ||
              props.data.fee === null ||
              props.data.due === null ? (
                <div className="columns">
                  <div className="column">
                    <Alert
                      message="Add more details"
                      description="Update your college manager by adding more details about your application. 
											Go to the option dropdown above to add application status, fit, intended major, semester matriculation and other application details."
                      type="info"
                      showIcon
                      closable
                    />
                  </div>
                </div>
              ) : null}
              <ApplicationTasks
                data={props.data}
                updateApplying={props.updateApplying}
                setVisible={setVisible}
              />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
export default CollegeManager;
