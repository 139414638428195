import React, { Component } from "react";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import "react-toastify/dist/ReactToastify.css";
import Amplify from "aws-amplify";
import aws_exports from "../../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Icon } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

Amplify.configure(aws_exports);

class PlaybookThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      current: 0,
    };
  }

  async componentDidMount() {}

  getEssay = (title) => {
    return (
      <div style={{ textAlign: "left" }}>
        <div className="columns">
          <div className="column is-two-fifths">
            <h5 className="title is-5">{title}</h5>
          </div>
        </div>
        <div className="columns">
          <div className="column ">
            <p>
              The college essay is one of the biggest parts of the application.
              Kick starting your essay can seem scary and overwhelming but
              working on it early can help ease the stress. Colleges use these
              essays as a way to get to know you better and to determine your
              fit at the school. The best way to go about the essays is by being
              true to yourself and by showing your best writing ability.
            </p>
            <p>
              Let's walk through an activity to help you kick start your essays.
            </p>
            <hr />
            <div className="columns">
              <div className="column">
                <p>
                  Take a second to think about yourself. What are some of your
                  best qualities?
                </p>
                <input
                  className="input"
                  type="text"
                  name="qual1"
                  defaultValue={this.props.qual1}
                  onChange={this.props.handleChange}
                  placeholder="eg. Hard working"
                />
                <input
                  className="input"
                  type="text"
                  name="qual2"
                  defaultValue={this.props.qual2}
                  onChange={this.props.handleChange}
                  placeholder="eg. Team player"
                />
                <input
                  className="input"
                  type="text"
                  name="qual3"
                  defaultValue={this.props.qual3}
                  onChange={this.props.handleChange}
                  placeholder="eg. Determined"
                />
                <input
                  className="input"
                  type="text"
                  name="qual4"
                  defaultValue={this.props.qual4}
                  onChange={this.props.handleChange}
                  placeholder="eg. Easy going"
                />
                <input
                  className="input"
                  type="text"
                  name="qual5"
                  defaultValue={this.props.qual5}
                  onChange={this.props.handleChange}
                  placeholder="eg. Strong"
                />
              </div>
              <div className="column">
                <p>
                  Choose one of the qualities you listed.
                  <strong> I'm a very _____ person </strong>
                </p>
                <input
                  className="input"
                  name="best"
                  defaultValue={this.props.best}
                  onChange={this.props.handleChange}
                  placeholder="eg. Strong"
                />
                <p className="help">
                  Which quality would best describe you to a college admissions
                  committee?
                </p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                Take 20 minutes (uninterrupted) to write about this prompt.{" "}
                <br />
                <br />
                <strong>
                  {" "}
                  Tell a story about an experience or time when you showed you
                  were a very _________ person (using the characteristic
                  identified above.){" "}
                </strong>
                <p className="help">
                  Try to do this exercise for 20 minutes non-stop{" "}
                </p>
                <ReactQuill
                  theme="snow"
                  type="exp"
                  name="exp"
                  value={this.props.exp}
                  onChange={this.props.handleEditorChange_exp}
                />
              </div>
            </div>
            <p>
              Now you have your first draft for a college application essay!
            </p>
            <p>
              Make sure to check out{" "}
              <a
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.khanacademy.org/college-careers-more/college-admissions/applying-to-college#admissions-essays"
              >
                Khan Academy's resources
              </a>{" "}
              for tips and tricks on writing a great essay. They also have
              sample essays that you can look through.
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h5>
              <strong>Suggested timeline</strong>
            </h5>
            <div className="content">
              <h5>First Week - RESEARCH</h5>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <div className="columns">
                    <div className="column">
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Take 30 minutes a day to review the essay topics of all
                        your applications
                      </p>
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Use a journal/notebook to track what comes to mind when
                        you think about each of the prompts
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <h5>Second Week - WRITE</h5>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <div className="columns">
                    <div className="column">
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Pick a topic that you want to write about
                      </p>
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Outline your topic
                      </p>
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Write your first draft
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
              <h5>Third Week - REVISE</h5>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <div className="columns">
                    <div className="column">
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Make sure your essay answers the prompt that was asked
                      </p>
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Make sure your essay is clear and flows well
                      </p>
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Check for grammar and spelling errors
                      </p>
                      <p>
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                        />{" "}
                        Review your essay with someone you trust
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <h5>
              <strong>Tips</strong>
            </h5>
            <div className="content">
              <ul>
                <li>
                  Make sure to read each essay prompt and plan what you want to
                  write before you begin writing
                  <p className="help">
                    <strong>Note:</strong> Also, if the prompt is general
                    enough, you might be able to use it for multiple
                    applications
                  </p>
                </li>
                <li>
                  Try writing about something meaningful to you instead of what
                  you think an admissions office will want to hear
                  <p className="help">
                    <strong>Note:</strong> This will help showcase the real you.
                  </p>
                </li>
                <li>
                  Write about something specific that happened to you instead of
                  something general like your childhood
                </li>
                <li>
                  <strong>
                    Do not submit an essay you did not write yourself
                  </strong>
                </li>
                <li>
                  Have someone you trust review your essay like your high school
                  counselor, English teacher or parent
                </li>
                <li>Try writing about something unique to you</li>
                <li>
                  Write multiple drafts of an essay
                  <p className="help">
                    <strong>Note:</strong> Don't try to write a great essay on
                    the first try
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.stepNum === 0 ? (
          <Application title={this.props.title} />
        ) : this.props.stepNum === 1 ? (
          this.getEssay(this.props.title)
        ) : this.props.stepNum === 2 ? (
          <Testing title={this.props.title} />
        ) : this.props.stepNum === 3 ? (
          <Recs title={this.props.title} />
        ) : this.props.stepNum === 4 ? (
          <Expectations title={this.props.title} />
        ) : this.props.stepNum === 5 ? (
          <Resources title={this.props.title} />
        ) : null}
      </div>
    );
  }
}

export default PlaybookThree;

function Expectations(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column ">
          <p>
            At this point, you should know why you're applying to each college
            on your list. As you're using the{" "}
            <a
              target="_blank"
              href="/list"
              style={{ textDecoration: "underline" }}
            >
              college manager
            </a>
            , you should be keeping track of each college's admission
            requirements and deadlines. The goal is to have a realistic and
            flexible plan for completing applications.
          </p>
          <p>
            While you're completing applications, you also need to decide which
            admissions process you'd want to follow. The common admissions
            process that most students choose is regular decision. While
            developing your plan, you might find that you want to apply to your
            top choices through "early" plans. This admissions process is called
            early-admission which has three sub-types: early action,
            single-choice early action, and early decision.
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            There are two main categories of admissions plans:{" "}
            <strong>non-restrictive</strong> and <strong> restrictive</strong>
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h4>
            <strong>Non-restrictive</strong>
          </h4>
          <p>
            These plans don't restrict you from applying to other colleges.
            You'll have until May to consider all your options and pick a
            school.
          </p>
        </div>
        <div className="column">
          <div className="content">
            <ul>
              <li>
                <strong>Regular Decision - </strong> This means that you turn in
                your application by the college's regular deadline.
              </li>
              <li>
                <strong>Rolling Admission - </strong> Schools review
                applications as they're submitted and will make decisions on
                admissions throughout the year. With this admissions process,
                you'll want to send in your application early as you can since
                some colleges will fill their classes by early winter.
              </li>
              <li>
                <strong>Early Action - </strong> You can send in your
                application by an early deadline specified by the college and
                the college will give you an admissions decision early.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <h4>
            <strong>Restrictive</strong>
          </h4>
          <p>
            These plans restrict you from applying to other colleges if you are
            granted admission.
          </p>
        </div>
        <div className="column">
          <div className="content">
            <ul>
              <li>
                <strong>Early Decision - </strong> With this process, you are
                committing yourself to going to this school. This plan is for
                you if you know for sure what your first-choice college is. If
                you're still on the fence and deciding between multiple schools
                then early decision is not for you. Early decision is a binding
                contract between you and the school. If you were to get in, you
                would have to withdraw all your other college applications and
                enroll in the early decision college.
                <p className="help">
                  You can only apply to one college via Early Decision
                </p>
              </li>
              <li>
                <strong>Restrictive Early Action - </strong> This plan allows
                you to apply to your top school and get a decision early. This
                plan is non-binding and you're not obligated to attend the
                school, however, if you apply Restrictive Early Action, you are
                not able to apply to any other schools early.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <hr />
          <h4>
            <strong>Final Thoughts</strong>
          </h4>
          <p>
            Early applications are a great option if you know for sure what your
            top 2-3 choice colleges are. In some cases, applying early may even
            increase your chances of acceptance. It's also a solid option if you
            had amazing junior-year grades. However, if you think you're
            application would be stronger if you had additional grades for the
            fall of your senior year, then waiting for the regular decision
            deadline is most likely the way to go.
          </p>
        </div>
      </div>
    </div>
  );
}

function Testing(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            You've probably heard about the SAT and ACT. Colleges use scores on
            these tests to judge all students who apply by the same standard.
            These tests are a way for students to stand out among the rest,
            especially for schools with a high admissions bar. But remember,
            these tests are only one of many factors that colleges take into
            consideration when they review your application.
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="columns is-centered">
          <div className="column is-two-thirds">
            <div className="card">
              <div className="card-content">
                <div className="columns is-centered is-vcentered">
                  <div className="column">
                    <p>
                      <strong>Note: </strong>Don't forget to add important
                      deadlines to your calendar!
                    </p>
                  </div>
                  <div className="column">
                    <a href="/calendar" target="_blank">
                      <div className="button is-link">Go to Deadlines</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h5>
            <strong>SAT & ACT</strong>
          </h5>
          <p>
            All colleges accept at least one of these exams, however SAT is more
            widely known. You can choose to take either one of them. Taking both
            is a little excessive. Here are some differences between the two to
            help you make a decision:
            <div className="content">
              <ul>
                <li>
                  The writing section is not required on the ACT but it is for
                  the SAT
                </li>
                <li>
                  Each SAT is scored from 200-800 which and each ACT section is
                  scored from 1-36
                </li>
                <li>Wrong answers on the SAT are counted against you</li>
                <li>
                  Colleges can see all your SAT scores while they can only see
                  your highest ACT score
                </li>
                <li>
                  ACT includes trigonometry while the SAT only covers
                  arithmetic, algebra 1 & 2, and geometry
                </li>
              </ul>
            </div>
          </p>
          <p>
            <strong>SAT</strong> - This exam is 200 minutes long and has 3
            sections:
            <div className="content">
              <ul>
                <li>
                  Critical Reading - <strong>70 mins total</strong> made up of a
                  20 minute section and two 25 minute sections
                </li>
                <li>
                  Math - <strong>70 mins total</strong> made up of a 20 minute
                  section and two 25 minute sections
                </li>
                <li>
                  Writing - <strong>60 mins total</strong> made up of a 35
                  minute section and a 25 minute section
                </li>
              </ul>
            </div>
          </p>
          <p>
            <strong>ACT</strong> - This exam is 205 minutes long and has 4 main
            sections plus 1 optional section:
            <div className="content">
              <ul>
                <li>English - one 45 minute section</li>
                <li>Math - one 60 minute section</li>
                <li>Reading - one 35 minute section</li>
                <li>Science - one 35 minute section</li>
                <li>Writing (optional) - one 30 minute section</li>
              </ul>
            </div>
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h5>
            <strong>SAT II Subject Tests</strong>
          </h5>
          <p>
            These are tests that ask you questions on a specific subject. You
            can choose to take a subject test on math, english, sciences, etc.
            Usually, you would take these subject tests in June, right after you
            finish the corresponding course.
          </p>
          <p>
            Subject tests are not required at all colleges. They are only an
            hour long and they are scored from 200-800.
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h5>
            <strong>AP Exams</strong>
          </h5>
          <p>
            These exams are 2-3 hours long depending on the exam and test you on
            a specific subject after you've taken the corresponding AP course.
            AP Exams are graded on a 1-5 scale and most colleges accept AP
            scores of 3+ for college credit. You're not required to submit your
            AP scores but competitive schools like to see at least a couple AP
            courses on your transcript.
          </p>
          <p>
            As mentioned earlier, you don't have to send in your AP scores. You
            could even cancel your AP exam grade altogether.
          </p>
        </div>
      </div>
    </div>
  );
}

// function Essay(props) {
//   return (
//     <div style={{ textAlign: "left" }}>
//       <div className="columns">
//         <div className="column is-two-fifths">
//           <h5 className="title is-5">{props.title}</h5>
//         </div>
//       </div>
//       <div className="columns">
//         <div className="column ">
//           <p>
//             The college essay is one of the biggest parts of the application.
//             Kick starting your essay can seem scary and overwhelming but working
//             on it early can help ease the stress. Colleges use these essays as a
//             way to get to know you better and to determine your fit at the
//             school. The best way to go about the essays is by being true to
//             yourself and by showing your best writing ability.
//           </p>
//           <p>
//             Let's walk through an activity to help you kick start your essays.
//           </p>
//           <hr />
//           <div className="columns">
//             <div className="column">
//               <p>
//                 Take a second to think about yourself. What are some of your
//                 best qualities?
//               </p>
//               <input
//                 className="input"
//                 name="qual1"
//                 defaultValue={this.props.qual1}
//                 onChange={this.props.handleChange}
//                 placeholder="eg. Hard working"
//               />
//               <input
//                 className="input"
//                 name="qual2"
//                 defaultValue={this.props.qual2}
//                 onChange={this.props.handleChange}
//                 placeholder="eg. Team player"
//               />
//               <input
//                 className="input"
//                 name="qual3"
//                 defaultValue={this.props.qual3}
//                 onChange={this.props.handleChange}
//                 placeholder="eg. Determined"
//               />
//               <input
//                 className="input"
//                 name="qual4"
//                 defaultValue={this.props.qual4}
//                 onChange={this.props.handleChange}
//                 placeholder="eg. Easy going"
//               />
//               <input
//                 className="input"
//                 name="qual5"
//                 defaultValue={this.props.qual5}
//                 onChange={this.props.handleChange}
//                 placeholder="eg. Strong"
//               />
//             </div>
//             <div className="column">
//               <p>
//                 Choose one of the qualities you listed.
//                 <strong> I'm a very _____ person </strong>
//               </p>
//               <input className="input" type="text" placeholder="eg. Strong" />
//               <p className="help">
//                 Which quality would best describe you to a college admissions
//                 committee?
//               </p>
//             </div>
//           </div>
//           <div className="columns">
//             <div className="column">
//               Take 20 minutes (uninterrupted) to write about this prompt. <br />
//               <br />
//               <strong>
//                 {" "}
//                 Tell a story about an experience or time when you showed you
//                 were a very _________ person (using the characteristic
//                 identified above.){" "}
//               </strong>
//               <p className="help">
//                 Try to do this exercise for 20 minutes non-stop{" "}
//               </p>
//               <ReactQuill theme="snow" />
//               {/* <ReactQuill theme="snow" type="deeper" name="deeper" value={this.props.deeper} onChange={this.props.handleEditorChange}/> */}
//               {/* <ReactQuill theme="snow" type="exp" name="exp" value={this.props.exp} onChange={this.props.handleEditorChange_exp}/> */}
//             </div>
//           </div>
//           <p>Now you have your first draft for a college application essay!</p>
//           <p>
//             Make sure to check out{" "}
//             <a
//               style={{ textDecoration: "underline" }}
//               target="_blank"
//               href="https://www.khanacademy.org/college-careers-more/college-admissions/applying-to-college#admissions-essays"
//             >
//               Khan Academy's resources
//             </a>{" "}
//             for tips and tricks on writing a great essay. They also have sample
//             essays that you can look through.
//           </p>
//         </div>
//       </div>
//       <div className="columns">
//         <div className="column">
//           <h5>
//             <strong>Suggested timeline</strong>
//           </h5>
//           <div className="content">
//             <h5>First Week - RESEARCH</h5>
//             <ul style={{ listStyle: "none" }}>
//               <li>
//                 <div className="columns">
//                   <div className="column">
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Take 30 minutes a day to review the essay topics of all
//                       your applications
//                     </p>
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Use a journal/notebook to track what comes to mind when
//                       you think about each of the prompts
//                     </p>
//                   </div>
//                 </div>
//               </li>
//             </ul>
//             <h5>Second Week - WRITE</h5>
//             <ul style={{ listStyle: "none" }}>
//               <li>
//                 <div className="columns">
//                   <div className="column">
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Pick a topic that you want to write about
//                     </p>
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Outline your topic
//                     </p>
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Write your first draft
//                     </p>
//                   </div>
//                 </div>
//               </li>
//             </ul>
//             <h5>Third Week - REVISE</h5>
//             <ul style={{ listStyle: "none" }}>
//               <li>
//                 <div className="columns">
//                   <div className="column">
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Make sure your essay answers the prompt that was asked
//                     </p>
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Make sure your essay is clear and flows well
//                     </p>
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Check for grammar and spelling errors
//                     </p>
//                     <p>
//                       <Icon
//                         type="check-circle"
//                         theme="twoTone"
//                         twoToneColor="#52c41a"
//                       />{" "}
//                       Review your essay with someone you trust
//                     </p>
//                   </div>
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>

//       <div className="columns">
//         <div className="column">
//           <h5>
//             <strong>Tips</strong>
//           </h5>
//           <div className="content">
//             <ul>
//               <li>
//                 Make sure to read each essay prompt and plan what you want to
//                 write before you begin writing
//                 <p className="help">
//                   <strong>Note:</strong> Also, if the prompt is general enough,
//                   you might be able to use it for multiple applications
//                 </p>
//               </li>
//               <li>
//                 Try writing about something that is meaningful to you instead of
//                 what you think an admissions office will want to hear
//                 <p className="help">
//                   <strong>Note:</strong> This will help you really showcase the
//                   real you.
//                 </p>
//               </li>
//               <li>
//                 Write about something specific that happened to you instead of
//                 something general like your childhood
//               </li>
//               <li>
//                 <strong>
//                   Do not submit an essay you did not write yourself
//                 </strong>
//               </li>
//               <li>
//                 Have someone you trust review your essay like your high school
//                 counselor, English teacher or parent
//               </li>
//               <li>Try writing about something that is unique to you</li>
//               <li>
//                 Write multiple drafts of an essay
//                 <p className="help">
//                   <strong>Note:</strong> Don't try to write a great essay on the
//                   first try
//                 </p>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

function Recs(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            Asking for teacher recommendations can seem intimidating but you
            have a lot of control over how you go about it. You can make the
            process less stressful by asking teachers you know would give you a
            positive recommendation, asking ahead of time, and by providing
            helpful information to your teacher about your achievements and
            accomplishments.
          </p>
        </div>
        <div className="column">
          <img
            style={{ marginRight: "2%" }}
            alt=""
            src={require("../../../../../assets/gifs/yougotthis.gif")}
          />
          <p className="help">Giphy.</p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h4>Some questions to ask yourself</h4>
          <div className="content">
            <ul>
              <li>
                Can you think of at least two teachers who can write you a great
                recommendation? If so, add their info to your locker here{" "}
                <a href="/profile" target="_blank">
                  <Icon type="right-circle" />
                </a>
              </li>
              <li>
                Does your high school have instructions for asking teachers for
                recommendations? If not, download the template below to help
                with recommendation requests
              </li>
              <li>
                Does the school you're applying to require teacher
                recommendations? Make sure to double check the application
                requirements to make sure
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h4>Quick Tips</h4>
          <div className="content">
            <ul>
              <li>
                Remember, you can't ask to see your teacher's recommendation but
                you can help guide a recommendation by providing your teacher
                with good information about you. Use the template below to help.
              </li>
              <li>
                Make sure to provide your teacher with the email address or URL
                that the recommendation needs to be sent to
              </li>
              <li>
                Think about asking teachers who know you the best and would be
                delighted to give you a recommendation. These teachers should be
                from academic subjects from your junior or senior year.
              </li>
              <li>
                Ask teachers for a recommendation at least a month before the
                deadline date
              </li>
              <li>
                If the teacher agrees, then give them all the required forms
                they need
              </li>
              <li>
                Make sure to write a thank you note to each of the teachers who
                wrote a recommendation for you
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="button">
            Teacher Recommendation Template&nbsp;
            <Icon type="download" />
          </div>
        </div>
      </div>
    </div>
  );
}

function Application(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            Completing the college application can be daunting but looking at it
            piece by piece can make it easier to manage. For this section, we
            want to give to give you enough information and resources to make
            informed decisions, get the work done, and know who to turn to if
            you need help.
          </p>
        </div>
        <div className="column">
          <img
            style={{ marginRight: "2%" }}
            alt=""
            src={require("../../../../../assets/gifs/kitty.gif")}
          />
          <p className="help">Giphy.</p>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-two-thirds">
          <div className="card">
            <div className="card-content">
              <div className="columns is-centered is-vcentered">
                <div className="column">
                  <p>
                    <strong>Note: </strong>Stay on top of all the colleges you
                    plan on applying to with your college organizer!
                  </p>
                </div>
                <div className="column">
                  <a href="/list" target="_blank">
                    <div className="button is-link">
                      Go to College Organizer
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h5>
            <strong>What makes up the college application?</strong>
          </h5>
          <p>
            <strong>Official Transcript</strong> - This is usually sent from
            your high school to the college. Your transcript has all your grades
            from all the classes you've taken so far in high school. It might
            also include information about your GPA, class rank, and
            standardized test scores.
          </p>
          <p>
            <strong>Standardized Test Scores</strong> - These are tests,
            typically the SAT and/or ACT that are usually required by the
            college. When you take the tests, the college will require that you
            send them directly from College Board or any other service you used
            to take them. We'll go more in depth on testing in another section.
          </p>
          <p>
            <strong>Application Form</strong>
            <p>
              Some colleges have their own application form. Other colleges use
              sites like Common Application and Universal College Applicaton
              which make applying to college much easier and less time consuming
              for you.
            </p>
            <p>
              You are responsible for filling this out. This is the official
              application form that needs to be completed for the college and
              submitted by the college deadline. You may be asked about personal
              and educational information as well as about honors, awards,
              volunteer activities, internships, essays etc.
            </p>
          </p>
          <p>
            <strong>Teacher Recommendation Form</strong> - Recommendations
            aren't always required by every college, but if it is, talk to your
            guidance counselor and teachers about your high school's policy for
            requesting and sending recommendations.
          </p>
          <hr />
          <h5>
            <strong>Final Thoughts</strong>
          </h5>
          <p>
            These are only a few of the many requirements needed for the college
            application. Make sure to read through the requirements for each of
            the colleges you plan on applying to. If you have any questions,
            reach out to your guidance counselor or call the college admissions
            office to confirm application requirements.
          </p>
        </div>
      </div>
    </div>
  );
}

function Resources(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">Assignment</h5>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <ol type="1">
                  <li>
                    Update each college on your applying{" "}
                    <a href="/list" target="_blank">
                      list
                    </a>{" "}
                    with important information about the application (deadline,
                    requirements, etc.).
                  </li>
                  <li>
                    Look up the admissions timeline that your prefer for each
                    college on your applying list and{" "}
                    <a href="/list" target="_blank">
                      update
                    </a>{" "}
                    your manager accordingly.
                  </li>
                  <li>Finish prompts in the Essay section of this Playbook.</li>
                  <li>
                    Add at least 2 teachers that you plan on asking for a
                    recommendation to your{" "}
                    <a href="/profile" target="_blank">
                      Locker
                    </a>
                    .
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-two-fifths">
          <img src={require("../../../../../assets/book.svg")} alt="" />
        </div>
      </div>
      <hr />
      <div className="columns">
        <div className="column">
          <div className="content">
            <h5 className="title is-5">Resources</h5>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.khanacademy.org/college-careers-more/college-admissions/applying-to-college#admissions-essays"
                >
                  Khan Academy{" "}
                </a>
                <p className="help">
                  College admissions essays - advice and examples
                </p>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://collegereadiness.collegeboard.org/sat/practice"
                >
                  College Board Test Prep
                </a>
                <p className="help">
                  Practice tests and daily questions to help you with the SAT
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
