import React from "react";
import { Button } from "antd";

export function Complete() {
  return (
    <div>
      <div className="columns">
        <div className="column">
          <h4 style={{ fontWeight: 900, marginBottom: "5%" }}>
            {" "}
            And you're done!
          </h4>
          <h6> Here's what you can do next.</h6>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Button block href="/roadmap">
            Go to Roadmap and view my recently added tasks
          </Button>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Button block href="/schools">
            Start adding colleges to my Applying and/or Favorites list
          </Button>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Button block href="playbooks">
            Start working through the Playbooks
          </Button>
        </div>
      </div>
    </div>
  );
}
