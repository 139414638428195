import React from "react";
import { Alert, DatePicker } from "antd";
import moment from "moment";

function UpdateManager(props) {
  return (
    <div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">College Fit</label>
            <div className="control">
              <div className="select">
                <select
                  defaultValue={props.fit}
                  value={props.fit}
                  onChange={(e) => props.setFit(e.target.value)}
                  type="fit"
                  name="fit"
                >
                  <option value={null}>Pick an Academic Fit</option>
                  <option value="Safety">Safety</option>
                  <option value="Match">Match</option>
                  <option value="Reach">Reach</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label className="label">Application Status</label>
            <div className="control">
              <div className="select">
                <select
                  defaultValue={props.status}
                  value={props.status}
                  onChange={(e) => props.setStatus(e.target.value)}
                  type="status"
                  name="status"
                >
                  <option value={0}>Started Application</option>
                  <option value={1}>Applied</option>
                  <option value={2}>Accepted</option>
                  <option value={3}>Waitlisted</option>
                  <option value={4}>Rejected</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label className="label">Application Link</label>
            <Alert
              message="Please make sure this link is valid. Eg. https://www.hamilton.edu"
              type="warning"
            />
            <br />
            <input
              defaultValue={props.link}
              onChange={(e) => props.setLink(e.target.value)}
              className="input"
              pattern="https?://.+"
              type="link"
              name="link"
              placeholder="Eg. https://apply.commonapp.org/"
            />
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <label className="label">Intended Major</label>
          <input
            className="input"
            defaultValue={props.major}
            onChange={(e) => props.setMajor(e.target.value)}
            type="major"
            name="major"
            placeholder="Eg. Computer Science"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <label className="label">Application Term</label>
          <input
            className="input"
            defaultValue={props.term}
            onChange={(e) => props.setTerm(e.target.value)}
            type="term"
            name="term"
            placeholder="Eg. Fall 2020"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <label className="label">Application Fee</label>
          <input
            className="input"
            defaultValue={props.fee}
            onChange={(e) => props.setFee(e.target.value)}
            type="fee"
            name="fee"
            placeholder="Eg. $75"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <label className="label">Due Date</label>
          <DatePicker
            value={moment(new Date(props.due))}
            onChange={(date) => props.setDue(date)}
          />
        </div>
      </div>
    </div>
  );
}
export default UpdateManager;
