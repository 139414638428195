import React, { Component } from "react";
import "../../App.css";
import "bulma/css/bulma.css";
import NavBar from "./navbar";
import { Typography } from "antd";
import moment from "moment";
import { createClient } from "@supabase/supabase-js";
import FooterPage from "./footer";

import { Link } from "react-router-dom";

const { Title } = Typography;
const supabaseUrl = "https://rnpcsynkfxzorrreypqf.supabase.co";
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      token: "",
    };
  }

  async componentDidMount() {
    let { body } = await supabase.from("posts").select("*");
    this.setState({ posts: body });
  }

  getPostPath(name, id) {
    const path =
      "/blog/" +
      id +
      "/" +
      name
        .toLowerCase()
        .replace(/[^a-zA-Z ]/g, "")
        .replace(/ /g, "-");
    return path;
  }

  render() {
    const BlogPosts = [].concat(this.state.posts).map((post, i) => (
      <div className="columns is-centered is-vcentered">
        <div className="column is-one-fifth">
          {moment(post.inserted_at).format("MMM Do YYYY")}
        </div>
        <div className="column is-one-fifth">
          <Link
            to={{
              pathname: this.getPostPath(post.title, post.id),
              state: { post: post },
            }}
          >
            <Title
              level={3}
              style={{ fontWeight: 600, textDecoration: "underline" }}
            >
              {post.title}
            </Title>
          </Link>
        </div>
      </div>
    ));

    return (
      <div>
        <NavBar />
        <div className="columns">
          <div className="column">
            <Title style={{ fontSize: 40, fontWeight: 900, marginTop: "2%" }}>
              Blog
            </Title>
            <br />
          </div>
        </div>
        {BlogPosts}
        <FooterPage />
      </div>
    );
  }
}
export default Blog;
