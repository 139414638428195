import React from "react";
import "bulma/css/bulma.css";
import { Typography, PageHeader, Tag } from "antd";

const { Title } = Typography;

function MentorStudent(props) {
  return (
    <div>
      <div className="columns">
        <div className="column">
          <PageHeader
            onBack={() => props.history.push(`/mentor/students`)}
            title="Back to All Students"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Title style={{ textAlign: "center" }} level={2}>
            <Tag color="green">Active Student</Tag>&nbsp;
            {props.location.state.name}
          </Title>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="box">
            <div className="columns">
              <div className="column"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MentorStudent;
