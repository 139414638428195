import React, { Component } from "react";
// import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Typography, PageHeader, Modal, message, Spin } from "antd";
import { Icon } from "antd";
import parkImage from "../../../../assets/park.svg";
import Chat from "./chat";
import PrivateMessages from "./privatemessage";

import * as mutations from "../../../../graphql/mutations";
import * as queries from "../../../../graphql/queries";
import * as subscriptions from "../../../../graphql/subscriptions";

import { getPath } from "../Schools/helpers";

import { Link } from "react-router-dom";

const { Title } = Typography;

const getPmMessages = `query GetConvo($id: ID! $nextMessages: String) {
  getConvo(id: $id) {
    id
    group {
      id
      name
      owner
      members
      requests
      channels {
        nextToken
      }
      tasks {
        nextToken
      }
      scholarships {
        nextToken
      }
      colleges {
        nextToken
      }
      conversations {
        nextToken
      }
    }
    messages (sortDirection: DESC, nextToken: $nextMessages){
      items {
        id
        user_id
        name
        messageConversationId
        content
        createdAt
      }
      nextToken
    }
    associated {
      items {
        id
        convoLinkMember
        convoLinkConversationId
        createdAt
        updatedAt
      }
      nextToken
    }
    name
    members
    createdAt
    updatedAt
  }
}
`;
const getMessageList = `
query GetChannel($id: ID!  $nextMessages: String) {
  getChannel(id: $id) {
    id
    group {
      id
      name
      owner
      members
      requests
      channels {
        nextToken
      }
      tasks {
        nextToken
      }
      scholarships {
        nextToken
      }
      colleges {
        nextToken
      }
    }
    name
    header
    messages (sortDirection: DESC, nextToken: $nextMessages) {
      items {
        id
        user_id
        name
        content
        createdAt
      }
      nextToken
    }
  }
}
`;

Amplify.configure(aws_exports);

class Picnic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      visible: false,
      channelName: "",
      channelDescrip: "",
      channels: [],
      currentChannel: null,
      currentMessages: null,
      newMessage: null,
      messageToken: null,
      visiblePM: false,
      member: "",
      pmClicked: false,
      currentMember: null,
      conversationLinks: [],
      conversations: [],
      currentConversation: null,
      pmMessages: null,
      pmToken: null,
      convosLoading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.changeChannel = this.changeChannel.bind(this);
  }

  async componentDidMount() {
    // if (typeof this.props.location.state === 'undefined' ){
    //   this.props.history.push('/groups')
    // }

    this.getGroupMember()
      .then((data) => this.createOrNot())
      .then(this.getConversationLinks());

    if (this.props.location.state) {
      this.getChannels(this.props.location.state.group.id);
    }
  }

  async listConversation(link) {
    try {
      const convo = await API.graphql(
        graphqlOperation(queries.getConvo, { id: link })
      );

      if (convo.data.getConvo.group.id === this.props.location.state.group.id) {
        return convo.data.getConvo;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getConversationLinks() {
    const getLinks = await API.graphql(
      graphqlOperation(queries.listConvoLinks, {
        filter: {
          convoLinkMember: {
            eq: this.props.location.state.user,
          },
        },
      })
    );
    const links = getLinks.data.listConvoLinks.items;
    var conversations = this.state.conversations;
    for (var i = 0; i < links.length; i++) {
      if (!links[i].member) {
        try {
          await API.graphql(
            graphqlOperation(mutations.updateConvoLink, {
              input: {
                id: links[i].id,
                convoLinkMemberId: this.props.location.state.userID,
              },
            })
          );
        } catch (err) {
          console.log(err);
        }
      }

      const convo = await this.getPms(links[i].convoLinkConversationId);

      if (convo.data.getConvo.group.id === this.props.location.state.group.id) {
        conversations.push(convo.data.getConvo);
      }

      // this.getPms(links[i].convoLinkConversationId).then(data => conversations.push(data.data.getConvo))
    }
    this.setState({ conversations: conversations, convosLoading: false });
  }

  async getPms(id) {
    var variable = { id: id };
    try {
      return await API.graphql(graphqlOperation(getPmMessages, variable));
      // await API.graphql(graphqlOperation(getPmMessages, variable)).then(data => this.setState({currentConversation:data.data.getConvo,
      //   pmToken:data.data.getConvo.messages.nextToken, pmMessages:data.data.getConvo.messages.items}))
    } catch (err) {
      console.log(err);
    }
    // console.log(messages)
  }

  pmClicked(item) {
    // get pm messages
    this.setState(
      {
        pmClicked: true,
        currentConversation: item,
        pmToken: item.messages.nextToken,
        pmMessages: item.messages.items,
      },
      () => {
        // this.getPms();
      }
    );
  }

  // Subscribe to creation of a message - needs to be changed to filter for specific/current group
  subscription = API.graphql(
    graphqlOperation(subscriptions.onCreateCMessage)
  ).subscribe({
    next: (data) => this.saveSubs(data),
  });

  // Subscribe to creation of a channel - This needs to be changed to filter for specific/current group
  subscription = API.graphql(
    graphqlOperation(subscriptions.onCreateChannel)
  ).subscribe({
    next: (data) => this.saveChannel(data),
  });

  // Subscribe to updateconvo - this subscribtion gets ALL private messages. might need to see if theres a way to filter
  subscription = API.graphql(
    graphqlOperation(subscriptions.onCreatePrivateMessage)
  ).subscribe({
    next: (data) => this.updatePrivateConvo(data),
  });

  updatePrivateConvo(data) {
    if (
      data.value.data.onCreatePrivateMessage.messageConversationId ===
        this.state.currentConversation.id &&
      data.value.data.onCreatePrivateMessage.conversation.group.id ===
        this.props.location.state.group.id
    ) {
      const message = data.value.data.onCreatePrivateMessage;
      var stateprivateMessages = this.state.pmMessages;
      stateprivateMessages.unshift(message);
      this.setState({ pmMessages: stateprivateMessages });
    }
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  showModalPM = () => {
    this.setState({
      visiblePM: true,
    });
  };

  handleOkPM = (e) => {
    this.createPM();
    this.setState({
      visiblePM: false,
    });
  };

  handleCancelPM = (e) => {
    this.setState({
      visiblePM: false,
      member: "",
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  async createPM() {
    if (this.props.location.state.user === this.state.member) {
      message.error("Sorry, you can't start a direct message with yourself");
    } else if (
      this.state.conversations.find((o) =>
        o.members.includes(this.state.member)
      )
    ) {
      message.error("You've already started a direct message with this user");
    } else {
      // add a check to make sure there's not already a convo with the selected user
      try {
        const convoName =
          this.props.location.state.user.split("@")[0] +
          " & " +
          this.state.member.split("@")[0];
        const members = [this.props.location.state.user, this.state.member];
        const convo = {
          conversationGroupId: this.props.location.state.group.id,
          name: convoName,
          members: members,
        };
        const createdConvo = await API.graphql(
          graphqlOperation(mutations.createConvo, { input: convo })
        );
        const convoLink1 = {
          convoLinkMember: this.props.location.state.user,
          convoLinkConversationId: createdConvo.data.createConvo.id,
          convoLinkMemberId: this.props.location.state.userID,
        };
        const convoLink2 = {
          convoLinkMember: this.state.member,
          convoLinkConversationId: createdConvo.data.createConvo.id,
        };
        await API.graphql(
          graphqlOperation(mutations.createConvoLink, { input: convoLink1 })
        );
        await API.graphql(
          graphqlOperation(mutations.createConvoLink, { input: convoLink2 })
        );
        this.getGroupMember().then(window.location.reload());
      } catch (err) {
        console.log("error creating conversation...", err);
      }
    }
  }

  async getGroupMember() {
    await API.graphql(
      graphqlOperation(queries.getMember, {
        id: this.props.location.state.userID,
      })
    )
      .then((data) =>
        this.setState({
          currentMember: data.data.getMember,
          conversationLinks: data.data.getMember.conversations,
        })
      )
      .catch((err) => console.log(err));
    // await API.graphql(graphqlOperation(queries.listMembers)).then(data => this.setState({currentMember:data.data.listMembers.items[0]})).catch(err => console.log(err))
  }

  async createNewMember() {
    return await API.graphql(
      graphqlOperation(mutations.createMember, {
        input: {
          id: this.props.location.state.userID,
          email: this.props.location.state.user,
        },
      })
    );
  }

  async createOrNot() {
    if (!this.state.currentMember) {
      this.createNewMember()
        .then((data) =>
          this.setState({ currentMember: data.data.createMember })
        )
        .catch((err) => console.log(err));
    }
  }

  saveSubs(data) {
    const message = data.value.data.onCreateCMessage;
    var stateMessages = this.state.currentMessages;
    stateMessages.unshift(message);
    this.setState({ currentMessages: stateMessages });
  }

  handleOk = (e) => {
    this.createChannel().then(
      this.setState({
        visible: false,
        channelDescrip: "",
        channelName: "",
      })
    );
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      channelDescrip: "",
      channelName: "",
    });
  };

  async getChannels(groupId) {
    const variables = {
      id: groupId, // key is "input" based on the mutation above
    };
    var channels = await API.graphql(
      graphqlOperation(queries.getGroup, variables)
    ).catch((err) => console.log(err));
    channels = channels.data.getGroup.channels.items;
    if (channels.length > 0) {
      this.setState(
        {
          channels: channels,
          currentChannel: channels[0],
        },
        () => {
          // if (!this.state.currentChannel == null){
          this.getChannelMessages();

          // }
        }
      );
    }
  }

  async createChannel() {
    if (/^ *$/.test(this.state.channelName)) {
      message.error("Please enter a valid Site name.");
    } else if (this.state.channelName.length > 80) {
      message.error("Please enter a Site with less than 80 chars.");
    } else if (this.state.channels.length === 10) {
      message.error("Sorry, Picnic Chats can only have up to 10 Sites. ", 5);
    } else {
      var channel = this.state.channelName
        .replace(/[^a-zA-Z ]/g, "")
        .replace(/ +/g, "-")
        .toLowerCase();
      const newChannel = {
        channelGroupId: this.props.location.state.group.id,
        name: channel,
        header: this.state.channelDescrip,
      };
      // this.handleLoading(id);
      await API.graphql(
        graphqlOperation(mutations.createChannel, { input: newChannel })
      ).catch((err) => console.log(err));
      message.success("Site successfully created.");
      this.setState({
        visible: false,
        channelDescrip: "",
        channelName: "",
      });
      // this.Groups();
    }
  }

  saveChannel(data) {
    var channel = data.value.data.onCreateChannel;
    var stateChannels = this.state.channels;
    stateChannels.push(channel);
    if (this.state.currentChannel == null) {
      this.setState({
        currentChannel: channel,
        channels: stateChannels,
        currentMessages: channel.messages.items,
      });
    } else {
      this.setState({ channels: stateChannels });
    }
  }

  async getChannelMessages() {
    await API.graphql(
      graphqlOperation(getMessageList, { id: this.state.currentChannel.id })
    )
      .then((data) =>
        this.setState({
          currentMessages: data.data.getChannel.messages.items,
          messageToken: data.data.getChannel.messages.nextToken,
        })
      )
      .catch((err) => console.log(err));
  }

  changeChannel(item) {
    this.setState(
      {
        currentChannel: item,
        currentConversation: null,
        pmMessages: null,
        pmClicked: false,
        pmToken: null,
      },
      () => {
        this.getChannelMessages();
      }
    );
  }

  async getMoreMessages() {
    var token = this.state.messageToken;
    var currentData = this.state.currentMessages;
    var variable = { id: this.state.currentChannel.id, nextMessages: token };
    if (token) {
      const data = await API.graphql(
        graphqlOperation(getMessageList, variable)
      );
      for (
        let itemIndex = 0;
        itemIndex < data.data.getChannel.messages.items.length;
        itemIndex++
      ) {
        let newData = data.data.getChannel.messages.items[itemIndex];
        currentData.push(newData);
      }
      this.setState({
        currentMessages: currentData,
        messageToken: data.data.getChannel.messages.nextToken,
      });
    }
  }

  async getMorePmMessages() {
    var token = this.state.pmToken;
    var currentMessages = this.state.pmMessages;
    var variable = {
      id: this.state.currentConversation.id,
      nextMessages: token,
    };

    if (token) {
      const data = await API.graphql(graphqlOperation(getPmMessages, variable));
      for (
        let itemIndex = 0;
        itemIndex < data.data.getConvo.messages.items.length;
        itemIndex++
      ) {
        let newData = data.data.getConvo.messages.items[itemIndex];
        currentMessages.push(newData);
      }

      this.setState({
        pmMessages: currentMessages,
        pmToken: data.data.getConvo.messages.nextToken,
      });
    }
  }

  render() {
    const channels = [].concat(this.state.channels).map((item, i) => (
      <div style={{ margin: "5%" }}>
        {item.id === this.state.currentChannel.id && !this.state.pmClicked ? (
          <li
            style={{ backgroundColor: "#3273dc", fontWeight: 900 }}
            onClick={this.changeChannel.bind(this, item)}
          >
            #{item.name}
          </li>
        ) : (
          <li onClick={this.changeChannel.bind(this, item)}>#{item.name}</li>
        )}
      </div>
    ));

    const getMembers = []
      .concat(this.props.location.state.group.members)
      .map((item, i) => <option value={item}>{item}</option>);

    return (
      <div>
        {/* Also make sure to check for current user is the same as the user passed in the props */}
        {this.props.location.state == null ? (
          this.props.history.push(`/groups`)
        ) : (
          <div>
            <div className="columns">
              <div className="column">
                <PageHeader
                  style={{
                    border: "1px solid rgb(235, 237, 240)",
                  }}
                  onBack={() => this.props.history.push(`/groups`)}
                  title="Back"
                  extra={[
                    <Link
                      to={{
                        pathname: getPath(
                          this.props.location.state.name,
                          "basket"
                        ),
                        state: {
                          group: this.props.location.state.group,
                          name: this.props.location.state.name,
                          userID: this.props.location.state.userID,
                          user: this.props.location.state.user,
                        },
                      }}
                    >
                      <div className="button is-fullwidth" key="1">
                        <Icon type="shopping" />
                        &nbsp;Basket
                      </div>
                    </Link>,
                  ]}
                  subTitle="Groups"
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div
                  className="card"
                  style={
                    this.state.currentChannel
                      ? {
                          backgroundColor: "#fcbc3a",
                          borderColor: "#fcbc3a",
                          color: "white",
                        }
                      : null
                  }
                >
                  <div className="card-content">
                    <div className="columns">
                      <div className="column">
                        <Title
                          style={{ fontWeight: 900, color: "white" }}
                          level={3}
                        >
                          {this.props.location.state.name}'s Picnic Chat
                        </Title>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column is-one-third">
                        <div className="columns">
                          <div className="column">
                            <p
                              style={{
                                fontWeight: 900,
                                textAlign: "left",
                                margin: "5%",
                              }}
                            >
                              Park Sites
                            </p>
                          </div>
                          <div className="column">
                            <Icon
                              type="plus-circle"
                              onClick={this.showModal}
                              style={{ scale: "1.5" }}
                            />

                            {/* <div onClick={this.showModal} className="button is-small is-rounded is-fullwidth"><Icon style={{scale:'1.5', margin:'2%'}} type="plus-circle"/></div> */}
                            <Modal
                              title="Create a new Park Site!"
                              visible={this.state.visible}
                              onOk={this.handleOk}
                              onCancel={this.handleCancel}
                              okText="Create Site"
                            >
                              <p>
                                Park Sites are where you and your group
                                communicates. They’re best when organized around
                                a topic — #college-applications, for example.
                              </p>
                              <h5>Name</h5>
                              <input
                                className="input"
                                type="channelName"
                                name="channelName"
                                value={this.state.channelName}
                                onChange={this.handleChange.bind(this)}
                                placeholder="Eg. college-applications"
                              />{" "}
                              <br />
                              <br />
                              <h5>Description</h5>
                              <p>(optional)</p>
                              <input
                                className="input"
                                type="channelDescrip"
                                name="channelDescrip"
                                value={this.state.channelDescrip}
                                onChange={this.handleChange.bind(this)}
                                placeholder="Eg. A place to talk about our applications"
                              />
                              <p className="help">What is this site about?</p>
                              {/* <input className="input is-rounded" className="input" type="groupName" name="groupName" value={this.state.groupName} onChange={this.handleChange.bind(this)} placeholder="Eg. Frosh 123"/> */}
                            </Modal>
                          </div>
                        </div>
                        {/* <hr/> */}

                        <div className="columns">
                          <div className="column">
                            <ul style={{ listStyleType: "none" }}>
                              {channels}
                            </ul>
                          </div>
                        </div>
                        <div className="columns" style={{ marginTop: "5%" }}>
                          <div className="column">
                            <p
                              style={{
                                fontWeight: 900,
                                textAlign: "left",
                                margin: "5%",
                              }}
                            >
                              Direct Messages
                            </p>
                          </div>
                          <div className="column">
                            <Icon
                              type="plus-circle"
                              onClick={this.showModalPM}
                              style={{ scale: "1.5" }}
                            />
                            {/* <div className="button is-small is-rounded is-fullwidth" onClick={this.showModalPM}><Icon style={{scale:'1.5', margin:'2%'}} type="plus-circle" /></div> */}
                            <Modal
                              title="Start a New Direct Message"
                              visible={this.state.visiblePM}
                              onOk={this.handleOkPM}
                              onCancel={this.handleCancelPM}
                              okText="Create DM"
                            >
                              <p>
                                Choose a member from{" "}
                                {this.props.location.state.group.name} to start
                                a Direct Message with
                              </p>

                              <div className="select is-rounded">
                                <select
                                  value={this.state.member}
                                  name="member"
                                  onChange={this.handleChange}
                                >
                                  <option value="">Choose a Member</option>
                                  {getMembers}
                                </select>
                              </div>
                            </Modal>
                          </div>
                        </div>
                        <div className="columns">
                          <div className="column">
                            <ul style={{ listStyleType: "none" }}>
                              <Spin
                                tip="Loading DMs.."
                                spinning={this.state.convosLoading}
                              >
                                <ListConversations
                                  conversations={this.state.conversations}
                                  pmClicked={this.pmClicked.bind(this)}
                                  currentConvo={this.state.currentConversation}
                                  currentMember={this.props.location.state.user}
                                />
                              </Spin>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        {this.state.pmClicked ? (
                          <PrivateMessages
                            getMorePmMessages={this.getMorePmMessages.bind(
                              this
                            )}
                            conversation={this.state.currentConversation}
                            currentMember={this.state.currentMember}
                            messages={this.state.pmMessages}
                            pmToken={this.state.pmToken}
                          />
                        ) : (
                          <div>
                            {this.state.currentChannel &&
                            this.state.currentMessages ? (
                              <div>
                                <Chat
                                  getMoreMessages={this.getMoreMessages.bind(
                                    this
                                  )}
                                  messageToken={this.state.messageToken}
                                  user={this.props.location.state.user}
                                  userID={this.props.location.state.userID}
                                  channel={this.state.currentChannel}
                                  messages={this.state.currentMessages}
                                  currentMember={this.state.currentMember}
                                  numOfChannels={this.state.channels.length}
                                />{" "}
                              </div>
                            ) : (
                              <div className="columns is-centered is-vcentered">
                                <div className="column is-half">
                                  <img
                                    src={parkImage}
                                    alt="Logo"
                                    style={{ width: "100%" }}
                                  />
                                </div>
                                <div className="column">
                                  <h5>
                                    {this.props.location.state.name} doesn't
                                    have any Park Sites
                                  </h5>
                                  <p>
                                    Create a new Park Site to talk in with your
                                    friends or pick a person to privately
                                    message.
                                  </p>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Picnic;

class ListConversations extends Component {
  render() {
    const conversations = [].concat(this.props.conversations).map((item, i) => (
      <div style={{ margin: "5%" }}>
        {item &&
        this.props.currentConvo &&
        this.props.currentConvo.id === item.id ? (
          <li
            style={{ backgroundColor: "#3273dc", fontWeight: 900 }}
            onClick={this.props.pmClicked.bind(this, item)}
          >
            {
              item.members
                .find((m) => m !== this.props.currentMember)
                .split("@")[0]
            }
          </li>
        ) : item ? (
          <li onClick={this.props.pmClicked.bind(this, item)}>
            {" "}
            {
              item.members
                .find((m) => m !== this.props.currentMember)
                .split("@")[0]
            }
          </li>
        ) : null}
      </div>
    ));
    return conversations;
  }
}
