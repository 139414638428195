import React from "react";
import "bulma/css/bulma.css";
import { Typography, Card, Button, Badge, Tag, Progress } from "antd";
// import { createClient } from "@supabase/supabase-js";
// import {SUPABASE_FROSH_URL} from "../../general/Constants/api"
import { Link } from "react-router-dom";
const roundedCard = {
  borderRadius: "10px 10px 10px 10px",
  height: "100%",
  backgroundColor: "#f5f5f5",
};
const { Title } = Typography;
// const supabaseUrl = SUPABASE_FROSH_URL
// const supabaseKey = process.env.REACT_APP_SUPABASE_FROSH_KEY
// const supabase = createClient(supabaseUrl, supabaseKey)

function ListStudents() {
  return (
    <div>
      <div className="columns is-vcentered">
        <div className="column">
          <div className="columns">
            <div className="column">
              <Card style={roundedCard}>
                <div className="columns is-vcentered">
                  <div className="column">
                    <div className="columns is-centered ">
                      <div className="column">
                        <Tag color="green">Active</Tag>
                      </div>
                    </div>
                    <div className="columns is-centered is-vcentered">
                      <div className="column">
                        <figure class="image is-128x128">
                          <img
                            alt="Static pic"
                            class="is-rounded"
                            src="https://bulma.io/images/placeholders/128x128.png"
                          />
                        </figure>
                      </div>
                      <div className="column">
                        <p className="help">Assigned Tasks Completed</p>
                        <Progress percent={50} size="small" status="active" />
                      </div>
                      <div className="column">
                        <Title level={3}>John Applebees</Title>
                        <p className="help">Started March 1st 2020</p>
                        <Tag>
                          <strong>Group:&nbsp;</strong>SAT Mentoring
                        </Tag>
                        <Tag>
                          <strong>Meeting:&nbsp;</strong>Thursdays at 4pm
                        </Tag>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <Link
                          to={{
                            pathname: "students/01",
                            state: {
                              name: "John Applebees",
                            },
                          }}
                        >
                          {" "}
                          <Button block>View Student</Button>
                        </Link>
                      </div>
                      <div className="column">
                        <Badge count={1}>
                          <Button block type="primary">
                            Answer Questions
                          </Button>
                        </Badge>
                      </div>
                      <div className="column">
                        <Button block type="danger">
                          Remove Student
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="column">
              <Card style={roundedCard}>
                <div className="columns is-vcentered">
                  <div className="column">
                    <div className="columns is-centered ">
                      <div className="column">
                        <Tag color="geekblue">Inactive</Tag>
                      </div>
                    </div>
                    <div className="columns is-centered is-vcentered">
                      <div className="column">
                        <figure class="image is-128x128">
                          <img
                            alt="Static pic"
                            class="is-rounded"
                            src="https://bulma.io/images/placeholders/128x128.png"
                          />
                        </figure>
                      </div>
                      <div className="column">
                        <p className="help">Assigned Tasks Completed</p>
                        <Progress percent={100} size="small" />
                      </div>
                      <div className="column">
                        <Title level={3}>Kate Applebees</Title>
                        <p className="help">Started April 1st 2020</p>
                        <Tag>
                          <strong>Group:&nbsp;</strong>College Mentoring
                        </Tag>
                        <Tag>
                          <strong>Meeting:&nbsp;</strong>Wednesdays at 4pm
                        </Tag>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <Link
                          to={{
                            pathname: "students/02",
                            state: {
                              name: "Kate Applebees",
                            },
                          }}
                        >
                          {" "}
                          <Button block>View Student</Button>
                        </Link>
                      </div>
                      <div className="column">
                        <Badge count={5}>
                          <Button block type="primary">
                            Answer Questions
                          </Button>
                        </Badge>
                      </div>
                      <div className="column">
                        <Button block type="danger">
                          Remove Student
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListStudents;
