import React, { useState, useEffect } from "react";
import "bulma/css/bulma.css";
import { Button, Input, Tag } from "antd";
import ReactQuill from "react-quill";

function EditPost(props) {
  const [post, setPost] = useState([]);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    setPost(props.post);
    setContent(props.post.content);
    setTitle(props.post.title);
  }, [props.post]);

  return (
    <div>
      <div className="columns">
        <div className="column">
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="column">
          <Tag>Created: {post.inserted_at}</Tag>
        </div>
        <div className="column">
          <Tag>Last Updated: {post.updated_at}</Tag>
        </div>
        <div className="column is-one-fifth">
          <Button onClick={() => props.deletePost(post.id)} type="danger">
            Delete Post
          </Button>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <ReactQuill
            theme="snow"
            type="deeper"
            name="deeper"
            value={content}
            onChange={(val) => setContent(val)}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Button
            block
            onClick={() => props.updatePost(post.id, content, title)}
          >
            Update
          </Button>
        </div>
        <div className="column">
          <Button block onClick={() => props.setCurrentView(0)}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
export default EditPost;
