import React from "react";
import "bulma/css/bulma.css";

function ListSuggestions() {
  return (
    <div>
      <div class="columns">
        <div class="column">
          <p>
            Here are some schools we think you should check out based on your
            test scores and GPA.
          </p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="card">
            <div class="card-content">
              <span class="tag is-success is-normal">Safety</span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <span class="tag is-warning is-normal">Match</span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <span class="tag is-danger is-normal">Reach</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListSuggestions;
