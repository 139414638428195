import React from "react";

const Emoji = (props) => (
  // Copy and paste emojis from here: https://getemoji.com/
  <span
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);
export default Emoji;
