import React from "react";
import { API, graphqlOperation, Cache } from "aws-amplify";
import * as queries from "../../../../graphql/queries";
import * as mutations from "../../../../graphql/mutations";
import { message, Avatar, notification } from "antd";

export var ID = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

export const pushFirstTasks = async (id) => {
  const tasks = [
    "High school transcript submitted",
    "ACT or SAT scores sent",
    "Paid application fee",
    "Gave teacher(s) form for recommendation",
    "Wrote thank-you notes to recommenders",
    "FAFSA completed",
    "Had two people proofread essay",
    "Essay or personal statement completed",
    "Letters of recommendations sent",
    "Financial aid form sent",
    "Financial aid award letter received",
    "CSS/PROFILE form completed",
    "Scheduled visit/interview",
    "I applied!",
  ];

  for (var i = 0; i < tasks.length; i++) {
    const createdTask = {
      appTaskAppId: id,
      task: tasks[i],
      complete: false,
      // id: ID()
    };
    await API.graphql(
      graphqlOperation(mutations.createAppTask, { input: createdTask })
    );
  }
};

export const addToFavs = async (id, name) => {
  const fav = { schoolid: id, name: name };
  var mess = "";
  const getPossibleFav = await API.graphql(
    graphqlOperation(queries.listFavs, {
      filter: {
        schoolid: {
          eq: id,
        },
      },
    })
  );
  if (getPossibleFav.data.listFavs.items.length > 0) {
    mess = name + " already on Favorite List";
    message.error(mess);
  } else {
    try {
      await API.graphql(graphqlOperation(mutations.createFav, { input: fav }));
      mess = name + " added to Favorite List";
      message.success(mess);
      Cache.removeItem("favsList"); // do this for now so that the college list picks up the new favsList
    } catch (e) {
      message.error(e);
    }
  }
};

export const checkApplying = async (id, name) => {
  const getPossibleApply = await API.graphql(
    graphqlOperation(queries.listCollegeApps, {
      filter: {
        schoolId: {
          eq: id,
        },
      },
    })
  );
  return getPossibleApply.data.listCollegeApps.items.length > 0;
};

export const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "You need to upgrade your account.",
    description:
      "Sorry, to add more Colleges, you'll need to upgrade your account.",
    duration: 0,
  });
};

export const addToApplying = async (id, name) => {
  // Filter for schools that might already be on a user's applying list
  const apply = { schoolId: id, name: name, status: 0 };
  var mess = "";
  const getPossibleApply = await API.graphql(
    graphqlOperation(queries.listCollegeApps, {
      filter: {
        schoolId: {
          eq: id,
        },
      },
    })
  );

  // Get all applications
  const allApps = await API.graphql(graphqlOperation(queries.listCollegeApps));
  console.log(allApps);

  // Get current user
  var isPro = false;
  const getUser = await API.graphql(graphqlOperation(queries.listUsers));
  var checkPro = getUser.data.listUsers.items;
  if (checkPro.length !== 0) {
    checkPro = checkPro[0];
    if (checkPro.subscription && checkPro.subscription.startsWith("sub")) {
      isPro = true;
    }
  }

  if (getPossibleApply.data.listCollegeApps.items.length > 0) {
    mess = name + " already on Applying List";
    message.error(mess);
  } else if (allApps.data.listCollegeApps.items.length >= 5 && isPro !== true) {
    openNotificationWithIcon("error");
  } else if (allApps.data.listCollegeApps.items.length >= 20 && isPro) {
    message.error("Sorry, you've reached the college manager limit", 5);
  } else {
    try {
      await API.graphql(
        graphqlOperation(mutations.createCollegeApp, { input: apply })
      ).then((data) => pushFirstTasks(data.data.createCollegeApp.id));
      mess = name + " added to Applying List";
      Cache.removeItem("applyingList"); // do this for now so that the college list picks up the new applyingList
      message.success(mess).then(window.location.reload());
    } catch (e) {
      message.error(e);
    }
  }
};

export const taskPerct = function (tasks) {
  var per = 0;
  if (tasks) {
    var tasksNum = tasks.items.length;
    if (tasksNum > 0) {
      const completed = tasks.items.filter(function (x) {
        return x.complete === true;
      });
      // const uncompleted = tasksNum - completed.length;
      per = Math.ceil((completed.length / tasksNum) * 100);
    }
  }
  return per;
};

export const handleFavDelete = async (id) => {
  const favid = { id: id };
  await API.graphql(graphqlOperation(mutations.deleteFav, { input: favid }));
  Cache.removeItem("favsList"); // do this for now so that the college list picks up the new favsList
  message.success("Fav successfully deleted.");
};

export const getPath = (name, page) => {
  name = name.replace("-", " ");
  var path = name.toLowerCase();
  path = "/" + page + "/" + path.replace(/ /g, "-");
  return path;
};

export const getCollegeApp = `query GetCollegeApp($id: ID! $next: String) {
    getCollegeApp(id: $id) {
      id
      schoolId
      name
      intended
      term
      fee
      note
      due
      category
      link
      status
      location
      academics
      size
      distance
      extras
      social
      tasks(nextToken: $next) {
      items{
        task
        task
        complete
        id
      }
      nextToken
      }
      owner
    }
    }
    `;
export const isUrl = (url) => {
  var strRegex =
    "^((https|http|ftp|rtsp|mms)?://)" +
    "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" + //ftp的user@
    "(([0-9]{1,3}.){3}[0-9]{1,3}" + // IP形式的URL- 199.194.52.184
    "|" + // 允许IP和DOMAIN（域名）
    "([0-9a-z_!~*'()-]+.)*" + // 域名- www.
    "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]." + // 二级域名
    "[a-z]{2,6})" + // first level domain- .com or .museum
    "(:[0-9]{1,4})?" + // 端口- :80
    "((/?)|" + // a slash isn't required if there is no file name
    "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
  var re = new RegExp(strRegex);
  return re.test(url);
};

export const getAvatar = (name) => {
  var colorList = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
    "#f56a00",
    "#7265e6",
    "#ffbf00",
    "#00a2ae",
  ];
  const avatarColor =
    colorList[name.substring(0, 1).toLowerCase().charCodeAt(0) - 97];
  return (
    <Avatar style={{ backgroundColor: avatarColor }}>
      {name.substring(0, 1).toUpperCase()}{" "}
    </Avatar>
  );
};
