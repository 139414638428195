import React, { useState, useEffect } from "react";
import "bulma/css/bulma.css";
import { Typography, Table, message, Radio, Spin, Button } from "antd";
import EditPost from "../components/EditPost";
import CreatePost from "../components/CreatePost";
import { createClient } from "@supabase/supabase-js";
import { SUPABASE_BLOG_URL } from "../../general/Constants/api";
import moment from "moment";

const { Title } = Typography;
const supabaseUrl = SUPABASE_BLOG_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

function Blog() {
  const [data, setData] = useState([]);
  const [currentView, setCurrentView] = useState(0);
  const [currentPost, setCurrentPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
    },
    {
      title: "Date Created",
      dataIndex: "inserted_at",
      key: "inserted_at",
    },
    {
      title: "Date Updated",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Actions",
      key: "action",
      render: (post) => (
        <span>
          <Button
            type="link"
            onClick={() => {
              setCurrentView(2);
              setCurrentPost(data.find((x) => x.id === post.id));
            }}
          >
            Edit
          </Button>
        </span>
      ),
    },
  ];

  useEffect(() => {
    async function getBlogPosts() {
      try {
        let { body } = await supabase.from("posts").select("*");
        createData(body);
      } catch (err) {
        message.error(err);
      }
    }
    getBlogPosts();
  }, []);

  const createData = (posts) => {
    let d = [];
    for (let i = 0; i < posts.length; i++) {
      d.push({
        key: i,
        id: posts[i].id,
        title: posts[i].title,
        author: posts[i].author,
        content: posts[i].content,
        inserted_at: moment(posts[i].inserted_at).format("MMMM Do YYYY"),
        updated_at: moment(posts[i].updated_at).format("MMMM Do YYYY"),
      });
    }
    setData(d);
    setLoading(false);
  };

  const updatePost = async (id, content, title) => {
    setLoading(true);
    try {
      await supabase
        .from("posts")
        .update({ content: content, title: title, updated_at: new Date() })
        .eq("id", id)
        .then((d) => message.success("Post sucessfully updated"));
    } catch (err) {
      message.error("Error updating post");
      console.log(err);
    }
    setLoading(false);
  };

  function isEmpty(str) {
    return !str || 0 === str.length || /^\s*$/.test(str);
  }

  const createPost = async (content, title, author) => {
    setLoading(true);
    if (!isEmpty(content) && !isEmpty(title) && !isEmpty(author)) {
      try {
        await supabase
          .from("posts")
          .insert([
            {
              id: data.length + 1,
              author: author,
              content: content,
              title: title,
              inserted_at: new Date(),
              updated_at: new Date(),
            },
          ])
          .then((e) =>
            message
              .success("Post sucessfully created")
              .then((p) => window.location.reload())
          );
      } catch (err) {
        message.error("Error posting to blog");
        console.log(err);
      }
    } else {
      message.error("Content, Title, and Author cannot be empty");
    }
    setLoading(false);
  };

  const deletePost = async (id) => {
    setLoading(true);
    try {
      await supabase.from("posts").delete().eq("id", id);
      message
        .success("Post sucessfully deleted")
        .then((e) => window.location.reload());
    } catch (err) {
      message.error("Error deleting post");
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div>
      <Title style={{ textAlign: "center" }} level={1}>
        Blog Posts{" "}
      </Title>
      <div className="columns">
        <div className="column">
          <div className="box">
            {loading ? (
              <Spin />
            ) : (
              <div>
                <div className="columns">
                  <div className="column">
                    <Radio.Group
                      value={currentView}
                      defaultValue={currentView}
                      buttonStyle="solid"
                      onChange={(e) => setCurrentView(e.target.value)}
                    >
                      <Radio.Button value={0}>All Posts</Radio.Button>
                      <Radio.Button value={1}>Create Post</Radio.Button>
                      <Radio.Button value={2} disabled={currentView !== 2}>
                        Edit Post
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
                {currentView === 0 ? (
                  <Table columns={columns} dataSource={data} />
                ) : currentView === 1 ? (
                  <CreatePost
                    createPost={createPost}
                    setCurrentView={setCurrentView}
                  />
                ) : currentView === 2 ? (
                  <EditPost
                    post={currentPost}
                    deletePost={deletePost}
                    setCurrentView={setCurrentView}
                    updatePost={updatePost}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Blog;
