import React, { Component } from "react";
import "../../../App.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../aws-exports"; // specify the location of aws-exports.js file on your project
import {
  Typography,
  Card,
  DatePicker,
  Alert,
  Icon,
  Modal,
  Input,
  message,
  Tag,
} from "antd";
import calendarImage from "../../../assets/calendar.svg";
import moment from "moment";
import ReactCardFlip from "react-card-flip";
import * as dayjs from "dayjs";

import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

const { MonthPicker } = DatePicker;
const { Title } = Typography;

Amplify.configure(aws_exports);

class CalendarComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      events: [],
      filteredEvents: [],
      isFlipping: [],
      isFlipped: false,
      user_id: "",
      eventId: "",
      monthFormat: moment(new Date()).format("MM-YYYY"),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      submitLoading: false,
      noEventsMes: "No events or deadlines for ",
      isLoading: [],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      visible: false,
      foundEvents: false,
      dateInput: moment().format("YYYY-MM-DD"),
      eventInput: "",
      date: new Date(),
      value: moment("2017-01-25"),
      selectedValue: moment("2017-01-25"),
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInputDateChange = this.handleInputDateChange.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
  }

  handleCardClick(item) {
    // let flipped = this.state.isFlipping.slice();
    // flipped[id] = !flipped[id];
    this.setState(
      {
        selectedEvent: item.id,
      },
      () => {
        let flipped = !this.state.isFlipped;
        this.setState({
          isFlipped: flipped,
        });
      }
    );
  }

  async componentDidMount() {
    // Get the current date and format it
    const date = new Date();
    var m = this.state.months[date.getMonth()];
    const month = date.getMonth();
    var y = date.getFullYear();
    var textDate = m + " " + y;
    this.setState({ monthFormat: textDate });

    this.getDeadlines(month, y);
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  getDeadlines = async (m, y) => {
    try {
      m = m + 1;
      let nextMonth;
      if (m === 12) {
        var now = new Date();
        nextMonth = new Date(now.getFullYear() + 1, 0, 1).getMonth() + 1;
      } else {
        nextMonth = m + 1;
      }

      var endingYear = m === 12 ? (endingYear = y + 1) : y;

      const startingDate = y + "-" + m + "-1";
      const endingDate = endingYear + "-" + nextMonth + "-1";

      const sortedDeadlines = await API.graphql(
        graphqlOperation(queries.datesSorted, {
          limit: 20,
          type: "SET",
          date: {
            between: [dayjs(startingDate).format(), dayjs(endingDate).format()],
          },
          sortDirection: "ASC", // TODO: doesn't fit the generic setup
        })
      );

      this.setState({ events: sortedDeadlines.data.datesSorted });
      let returnDate = this.state.monthFormat.split(" ");
      this.getEvents(returnDate);
    } catch (err) {
      console.log(err);
      message.error("Error gettiing desdlines" + err);
    }
  };

  async updateDeadline(e) {
    e.preventDefault();
    e.stopPropagation();
    try {
      this.setState({
        visible: false,
        dateInput: "",
        selectedEvent: "",
        eventInput: "",
        displayUpdate: false,
        eventId: "",
      });
      const eventUpdate = {
        id: this.state.eventId,
        event: this.state.eventInput,
        date: this.state.dateInput,
      };
      await API.graphql(
        graphqlOperation(mutations.updateCalendar, { input: eventUpdate })
      ).then((data) => message.success("Event updated"));
      // console.log(response)

      // window.location.reload(false);

      const date = new Date(this.state.date);
      const month = date.getMonth();
      const year = date.getFullYear();

      this.getDeadlines(month, year);
    } catch (err) {
      message.error("Error updating deadline" + err);
    }
  }

  async deleteDeadline(event) {
    try {
      await API.graphql(
        graphqlOperation(mutations.deleteCalendar, { input: { id: event.id } })
      );
      message.success("Task successfully deleted.");

      const date = new Date(this.state.date);
      const month = date.getMonth();
      const year = date.getFullYear();

      this.getDeadlines(month, year);
    } catch (err) {
      console.log(err);
    }
  }

  async createDeadline(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.eventInput === "" || this.state.dateInput === "") {
      message.error("Event and Date required.");
      // this.setState({submitLoading:false});
    } else {
      let event = this.state.eventInput;
      let date = this.state.dateInput;

      const input = { date: date, event: event, type: "SET" };
      try {
        this.setState({
          visible: false,
          dateInput: "",
          eventInput: "",
        });
        await API.graphql(
          graphqlOperation(mutations.createCalendar, { input: input })
        ).then((data) => message.success("New event created"));

        const date = new Date(this.state.date);
        const month = date.getMonth();
        const year = date.getFullYear();

        this.getDeadlines(month, year);

        // this.getDeadlines();
      } catch (err) {
        console.log(err);
      }
    }
  }

  getMonthFromString(mon) {
    var d = Date.parse(mon + "1, 2012");
    if (!isNaN(d)) {
      return new Date(d).getMonth() + 1;
    }
    return -1;
  }

  handleInputDateChange(date, dateString) {
    this.setState({ dateInput: dateString });
  }

  handleDateChange(date, dateString) {
    // this.getEvents()
    if (date) {
      var m = this.state.months[date.month()];
      var y = date.year();
      const textDate = m + " " + y;
      this.setState({ date: dateString, monthFormat: textDate }, () => {
        this.getDeadlines(date.month(), y);
      });
      // this.getEvents(textDate.split(" "))
      // );
    }
  }

  getDiffTime = (inputDate) => {
    var currentDateObj = moment(new Date());
    var diffTime = moment(inputDate).diff(currentDateObj, "days");
    return diffTime;
  };

  getDateTag = (inputDate) => {
    const diffTime = this.getDiffTime(inputDate);
    if (diffTime >= 0 && diffTime <= 7) {
      return ["red", <Tag color="red">This Week</Tag>];
    }
    if (diffTime > 7) {
      return ["green", <Tag color="green">Upcoming</Tag>];
    } else {
      return ["grey", <Tag color="grey">Past</Tag>];
    }
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  selectEvent(event) {
    const dateFormat = "YYYY-MM-DD";
    var dateString = moment(event.date).format(dateFormat);
    this.setState({
      eventId: event.id,
      eventInput: event.event,
      displayAdd: false,
      dateInput: dateString,
      visible: true,
      displayUpdate: true,
    });
  }

  handleOk = (e) => {
    // this.setState({
    //   submitLoading: true,
    // });

    if (this.state.displayUpdate) {
      this.updateDeadline(e);
    } else {
      this.createDeadline(e);
    }
  };

  handleUpdate = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      dateInput: moment().format("YYYY-MM-DD"),
      eventInput: "",
    });
  };

  getEvents = (array) => {
    //Get the currentYear and the currentMonth
    var selectedMonth = this.getMonthFromString(array[0]);
    var selectedYear = array[1];
    var events = [];

    //Then filter the dates
    events = this.state.events.items.filter((e) => {
      var eventDate = new Date(e.date); // Or, var month = e.date.split('-')[1];
      var eventY = eventDate.getFullYear();
      var eventM = eventDate.getMonth() + 1;
      return selectedMonth === eventM && selectedYear === eventY;
    });

    this.setState({ filteredEvents: events });
  };

  render() {
    const noEventsMessage =
      "No events or deadlines for " + this.state.monthFormat;

    return (
      <div>
        <div className="columns">
          <div className="column is-offset-one-quarter">
            <Title style={{ textAlign: "center" }} level={1}>
              Calendar & Deadlines
            </Title>
          </div>

          <div className="column is-one-fifth">
            <button
              onClick={this.showModal}
              className="button is-link is-fullwidth"
            >
              <Icon
                type="plus"
                style={{ scale: "1.5", color: "white", margin: "10px" }}
              />
              &nbsp;Create a Deadline
            </button>
            <Modal
              title="Add & Update Deadline"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okText="Submit"
            >
              <div>
                <div className="columns">
                  <div className="column">
                    <p>
                      This is a place where you can store all you upcoming
                      deadlines and events. From SAT exams to college tours,
                      keeping track of it all is key to staying on top of the
                      college planning process.
                    </p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="columns">
                      <div className="column">
                        <Title style={{ fontSize: "20px" }} level={4}>
                          Event
                        </Title>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <Input
                          className="input"
                          type="eventInput"
                          name="eventInput"
                          value={this.state.eventInput}
                          onChange={this.handleChange.bind(this)}
                          placeholder="Eg. Sign up for the SAT"
                        />
                        <p className="help">What's happening?</p>
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="columns">
                      <div className="column">
                        <Title level={4}>Due Date</Title>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <DatePicker
                          placeholder={this.state.dateInput}
                          onChange={this.handleInputDateChange}
                        />
                        <p className="help">When is it due?</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <Card style={{ width: "100%" }}>
              <div style={{ margin: "20px" }}>
                <strong style={{ fontSize: "20px", color: "#565656" }}>
                  {this.state.monthFormat}
                </strong>
              </div>
              <div className="columns">
                <div className="column">
                  <MonthPicker
                    onChange={this.handleDateChange}
                    placeholder="Select month"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <img className="center" src={calendarImage} alt="Calendar" />
                </div>
              </div>
            </Card>
          </div>
          <div className="column is-two-thirds">
            {this.state.filteredEvents.length !== 0 ? (
              <div className="columns is-multiline">
                {this.state.filteredEvents.map((item) => {
                  const tag = this.getDateTag(item.date);
                  const date = item.date;
                  const diffTime = this.getDiffTime(date);
                  let styleTop = {
                    borderRadius: "25px",
                    height: "100%",
                  };

                  if (diffTime >= 0 && diffTime <= 7) {
                    styleTop = {
                      borderRadius: "25px",
                      height: "100%",
                      borderLeft: "10px solid #ff3860",
                    };
                  }
                  if (diffTime > 7) {
                    styleTop = {
                      borderRadius: "25px",
                      height: "100%",
                      borderLeft: "10px solid #87d068",
                    };
                  }
                  if (diffTime < 0) {
                    styleTop = {
                      borderRadius: "25px",
                      height: "100%",
                      borderLeft: "10px solid grey",
                    };
                  }
                  return (
                    <div className="column is-one-third">
                      <ReactCardFlip
                        isFlipped={
                          this.state.selectedEvent === item.id
                            ? this.state.isFlipped
                            : false
                        }
                        flipDirection="vertical"
                      >
                        <div className="card" style={styleTop}>
                          <div className="card-content">
                            <div className="content">
                              <div className="columns">
                                <div className="column">
                                  <div>{tag ? tag[1] : null}</div>
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column">
                                  <p className="title is-6">
                                    {this.state.days[new Date(date).getDay()]}
                                  </p>
                                </div>
                                <div className="column">
                                  <p className="subtitle is-6">
                                    {new Date(date)
                                      .toDateString()
                                      .split(" ")
                                      .slice(1)
                                      .join(" ")}
                                  </p>
                                </div>
                              </div>
                              <div className="columns">
                                <div className="column">{item.event}</div>
                              </div>
                              <div className="columns">
                                <div className="column">
                                  <Icon
                                    onClick={this.handleCardClick.bind(
                                      this,
                                      item
                                    )}
                                    type="swap"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="card" style={styleTop}>
                          <div className="card-content">
                            <div className="columns">
                              <div className="column">
                                <div
                                  onClick={this.selectEvent.bind(this, item)}
                                  className="button is-fullwidth"
                                >
                                  Edit
                                </div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column">
                                <div
                                  onClick={this.deleteDeadline.bind(this, item)}
                                  className="button is-fullwidth"
                                >
                                  Delete
                                </div>
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column">
                                <Icon
                                  onClick={this.handleCardClick.bind(
                                    this,
                                    item
                                  )}
                                  type="swap"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </ReactCardFlip>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                <Alert
                  message={noEventsMessage}
                  description="Add a deadline or event using the button above or choose a new month on the left hand side"
                  type="success"
                  showIcon
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default CalendarComp;
