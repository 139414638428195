import React, { Component } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import NavBar from "./navbar";
import { Typography } from "antd";
import notfoundImage from "../../assets/landingpage/404.svg";
import FooterPage from "./footer";

const { Title } = Typography;

class NotFoundPage extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div
          style={{ backgroundColor: "white" }}
          className="columns is-centered is-vcentered"
        >
          <div style={{ padding: "10%" }} className="column is-half">
            <div className="columns">
              <Title
                style={{ fontSize: 40, fontWeight: 900, textAlign: "left" }}
              >
                Not Found
              </Title>
            </div>
            <div className="columns">
              <h5 style={{ textAlign: "left" }} className="subtitle is-5">
                Uh oh. We can't find the page you're looking for.
              </h5>
            </div>
            <div className="columns is-centered">
              <div className="column is-half ">
                <a href="/dashboard" className="button is-text">
                  Back to Dashboard
                </a>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="container">
              <figure className="image is-16by9">
                <img
                  className="has-ratio"
                  width="640"
                  height="360"
                  src={notfoundImage}
                  alt="404"
                />
              </figure>
            </div>
          </div>
        </div>

        <FooterPage />

        {/* <div className="button" onClick={() => this.signUp()}>Sign In</div> */}
      </div>
    );
  }
}
export default NotFoundPage;
