import Amplify, { Cache } from "aws-amplify";
import aws_exports from "../aws-exports";
import {
  listApplying,
  listFavs,
} from "./Students/components/CollegeList/helpers";
Amplify.configure(aws_exports);

/* The Amplify Cache module provides a generic LRU cache for JavaScript developers 
   to store data with priority and expiration settings. For more info, go here:
   https://docs.amplify.aws/lib/utilities/cache/q/platform/js

   Default TTL is 72 hours
*/

// COLLEGE LIST HELPERS

export const getFavsFromCache = async () => {
  var found = Cache.getItem("favsList");
  if (!found) {
    const schools = await listFavs();
    Cache.setItem("favsList", schools);
    return schools;
  } else {
    return found;
  }
};

export const removeFavsInCache = async (id) => {
  var schools = Cache.getItem("favsList");
  var newList = schools.filter(function (x) {
    return x.id !== id;
  });

  Cache.setItem("favsList", newList);
};

export const getApplyingFromCache = async () => {
  var found = Cache.getItem("applyingList");
  if (!found) {
    const schools = await listApplying();
    Cache.setItem("applyingList", schools);
    return schools;
  } else {
    return found;
  }
};
