import React, { useEffect, useState } from "react";
import "../../../App.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../aws-exports";
import { deleteScholarship } from "../components/Scholarships/helpers";
import { Typography, Icon, Radio, Tooltip, Spin, Statistic, Alert } from "antd";
import ListScholarships from "../components/Scholarships/ListScholarships";
import AddScholarship from "../components/Scholarships/AddScholarship";
import EditScholarship from "../components/Scholarships/EditScholarship";
import Search from "../components/Scholarships/Search";
import * as queries from "../../../graphql/queries";

const { Title } = Typography;
Amplify.configure(aws_exports);

function Scholarships() {
  const [scholarships, setScholarships] = useState([]);
  const [currentView, setCurrentView] = useState(0);
  const [selectedScholarship, setSelectedScholarship] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getScholarships() {
      const data = await API.graphql(
        graphqlOperation(queries.listScholarships)
      );
      setScholarships(data.data.listScholarships.items);
    }
    getScholarships();
    setLoading(false);
  }, []);

  return (
    <div>
      <div className="columns">
        <div className="column">
          <Title style={{ textAlign: "center" }} level={1}>
            Scholarships
          </Title>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column">
          <Radio.Group
            buttonStyle="solid"
            name="radiolist"
            value={currentView}
            onChange={(e) => setCurrentView(e.target.value)}
          >
            <Radio.Button value={0}>
              <Icon type="unordered-list" />
              &nbsp;&nbsp;My Scholarships
            </Radio.Button>
            <Radio.Button value={1}>
              <Icon type="plus-circle" />
              &nbsp;&nbsp;Add a Scholarship
            </Radio.Button>
            <Tooltip title="Coming Soon!">
              <Radio.Button value={2} disabled={true}>
                <Icon type="search" />
                &nbsp;&nbsp;Search
              </Radio.Button>
            </Tooltip>
            <Tooltip title="Coming Soon!">
              <Radio.Button value={3} disabled={true}>
                {/* // NOTE: Implement this next */}
                <Icon type="bulb" />
                &nbsp;&nbsp;Suggestions
              </Radio.Button>
            </Tooltip>
          </Radio.Group>
        </div>
      </div>
      {currentView === 0 ? (
        <div className="columns is-centered">
          <div className="column is-2">
            <Statistic title="Total Scholarships" value={scholarships.length} />
          </div>
          <div className="column is-2">
            <Statistic
              title="Sent Applications"
              value={
                scholarships.filter((item) => item.appSent === true).length
              }
            />
          </div>
        </div>
      ) : null}
      <div className="columns is-centered">
        <div className="column is-three-quarters">
          <div className="card">
            {loading ? (
              <Spin size="large" style={{ padding: "2%" }} />
            ) : (
              <div className="card-content">
                {scholarships.length === 0 && currentView === 0 ? (
                  <Alert
                    message="No Scholarships"
                    description="Click on the buttons above to add or search for a new scholarship."
                    type="info"
                  />
                ) : currentView === 0 ? (
                  <ListScholarships
                    deleteScholarship={deleteScholarship}
                    setSelectedScholarship={setSelectedScholarship}
                    scholarships={scholarships}
                    setCurrentView={setCurrentView}
                    setLoading={setLoading}
                  />
                ) : currentView === 1 ? (
                  <AddScholarship
                    setCurrentView={setCurrentView}
                    setSelectedScholarship={setSelectedScholarship}
                    scholarship={selectedScholarship}
                    setLoading={setLoading}
                  />
                ) : currentView === 2 ? (
                  <Search setLoading={setLoading} />
                ) : currentView === 4 ? (
                  <EditScholarship
                    setCurrentView={setCurrentView}
                    setSelectedScholarship={setSelectedScholarship}
                    scholarship={selectedScholarship}
                    setLoading={setLoading}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Scholarships;
