import React, { Component } from "react";
import "../../../App.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation, Storage } from "aws-amplify";
import aws_exports from "../../../aws-exports"; // specify the location of aws-exports.js file on your project
import {
  Divider,
  Card,
  Modal,
  message,
  Spin,
  Menu,
  Dropdown,
  Skeleton,
  Typography,
  Icon,
  Upload,
} from "antd";

import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

import {
  createTestScore,
  deleteScore,
  createClass,
  readClass,
  deleteClass,
  updateClass,
  createExtra,
  readExtra,
  deleteExtra,
  createExp,
  readExp,
  deleteExp,
  createRec,
  readRec,
  deleteRec,
  updateRec,
  updatePerformance,
} from "../components/AcademicProfile/profileMutations";

const { Title, Text } = Typography;
const { Dragger } = Upload;

Amplify.configure(aws_exports);

class AcademicProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      testScore: "",
      classes: "",
      extras: "",
      exps: "",
      recs: "",
      value: "",
      note: "",
      teacher: "",
      teacherRec: "",
      isLoading: [],
      profile: [],
      sectionLoading: {
        test: false,
        class: false,
        extra: false,
        exps: false,
        recs: false,
      },
      email: "",
      grade: "",
      weightedGPA: "",
      submitLoading: "",
      scores: [],
      getClasses: [],
      getExtras: [],
      getExp: [],
      getRecs: [],
      unweightedGPA: "",
      updateCard: false,
      rank: "",
      test: "sat",
      selectValue: "scores",
      displaySave: false,
      displayHighSchoolForm: false,
      displayTestForm: false,
      displayClassesForm: false,
      displayExperienceForm: false,
      displayExtraForm: false,
      displayRecommendations: false,
      visible: false,
      files: [],
    };
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(evt) {
    // check it out: we get the evt.target.name (which will be either "email" or "password")
    // and use it to target the key on our `state` object with the same name, using bracket syntax
    this.setState({ [evt.target.name]: evt.target.value });
  }

  async createProfile() {
    const profile = { rank: 0, weighted: 0, unweighted: 0 };
    try {
      await API.graphql(
        graphqlOperation(mutations.createPerformance, { input: profile })
      ).then((data) => this.listProfile());
    } catch (err) {
      console.log(err);
    }
  }

  async setProfile() {
    const getProfile = this.state.profile[0];
    this.setState({
      weightedGPA: getProfile.weighted,
      unweightedGPA: getProfile.unweighted,
      rank: getProfile.rank,
    });
    this.listProfile();
  }

  async componentDidMount() {
    Storage.list(`${this.props.user_id}/locker/`, { level: "private" })
      .then((result) => this.setState({ files: result }))
      .catch((err) => console.log(err));

    const scores = await API.graphql(graphqlOperation(queries.listTestScores));
    const classes = await API.graphql(graphqlOperation(readClass));
    const extras = await API.graphql(graphqlOperation(readExtra));
    const exps = await API.graphql(graphqlOperation(readExp));
    const recs = await API.graphql(graphqlOperation(readRec));
    const profile = await API.graphql(
      graphqlOperation(queries.listPerformances)
    );

    let wholeSection = this.state.sectionLoading;

    // Get user's academic profile. We want to make sure the state is set before
    // attempting to create a profile if there isn't already one
    this.setState({ profile: profile.data.listPerformances.items }, () => {
      if (this.state.profile.length === 0) {
        this.createProfile();
      } else {
        this.setProfile();
      }
    });

    // Get test scores and load the section
    this.setState({ scores: scores.data.listTestScores.items }, () => {
      Object.assign(wholeSection, { test: true });
      this.setState({ sectionLoading: wholeSection });
    });

    // Get classes and load the section
    this.setState({ getClasses: classes.data.listClasss.items }, () => {
      Object.assign(wholeSection, { class: true });
      this.setState({ sectionLoading: wholeSection });
    });

    // Get extracurriculars and load the section
    this.setState({ getExtras: extras.data.listExtras.items }, () => {
      Object.assign(wholeSection, { extra: true });
      this.setState({ sectionLoading: wholeSection });
    });

    // Get experiences and load the section
    this.setState({ getExp: exps.data.listExps.items }, () => {
      Object.assign(wholeSection, { exps: true });
      this.setState({ sectionLoading: wholeSection });
    });

    // Get recommendations and load the section
    this.setState({ getRecs: recs.data.listRecs.items }, () => {
      Object.assign(wholeSection, { recs: true });
      this.setState({ sectionLoading: wholeSection });
    });
  }

  async listScores() {
    const scores = await API.graphql(graphqlOperation(queries.listTestScores));
    this.setState({ scores: scores.data.listTestScores.items });
  }

  async listClasses() {
    const classes = await API.graphql(graphqlOperation(readClass));
    this.setState({ getClasses: classes.data.listClasss.items });
  }

  async listExtras() {
    const extras = await API.graphql(graphqlOperation(readExtra));
    this.setState({ getExtras: extras.data.listExtras.items });
  }

  async listExps() {
    const exps = await API.graphql(graphqlOperation(readExp));
    this.setState({ getExp: exps.data.listExps.items });
  }

  async listRecs() {
    const recs = await API.graphql(graphqlOperation(readRec));
    this.setState({ getRecs: recs.data.listRecs.items });
  }

  async listProfile() {
    const profile = await API.graphql(
      graphqlOperation(queries.listPerformances)
    );
    this.setState({ profile: profile.data.listPerformances.items });
    const getProfile = this.state.profile[0];
    this.setState({
      weightedGPA: getProfile.weighted,
      unweightedGPA: getProfile.unweighted,
      rank: getProfile.rank,
    });
  }

  handleLoading(id) {
    let loading = this.state.isLoading.slice();
    loading[id] = true;
    this.setState({
      isLoading: loading,
    });
  }

  clickedEditProfile = () => {
    this.setState({ displaySave: true });
  };

  saveProfile = () => {
    this.handleProfileUpdate();
  };

  cancelProfileChange = () => {
    this.setState({ displaySave: false });
    this.listProfile();
  };

  getFile(filePath) {
    Storage.get(filePath, { level: "private" })
      .then((result) => window.open(result, "_blank"))
      .catch((err) => message.error(err));
  }

  deleteFile(filePath) {
    Storage.remove(filePath, { level: "private" })
      .then(
        message.success("File removed").then((data) => window.location.reload())
      )
      .catch((err) => message.error(err));
  }

  async handleProfileUpdate(event) {
    // event.preventDefault();
    // event.stopPropagation();
    const getProfile = this.state.profile[0];
    const profileUpdate = {
      id: getProfile.id,
      rank: this.state.rank,
      weighted: this.state.weightedGPA,
      unweighted: this.state.unweightedGPA,
    };
    await API.graphql(graphqlOperation(updatePerformance, profileUpdate));
    message.success("Performance Profile updated");

    this.setState({
      displaySave: false,
    });
  }

  async handleUpdate(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.selectValue === "classes") {
      const classUpdate = {
        id: this.state.id,
        name: this.state.classes,
        teacher: this.state.teacher,
        grade: this.state.grade,
      };
      await API.graphql(graphqlOperation(updateClass, classUpdate));
      message.success("Class updated");
      this.listClasses();
    }

    if (this.state.selectValue === "recs") {
      const recUpdate = {
        id: this.state.id,
        teacher: this.state.teacherRec,
        email: this.state.email,
        req: false,
      };
      await API.graphql(graphqlOperation(updateRec, recUpdate));
      message.success("Recommendation updated");
      this.listRecs();
    }

    this.setState({
      visible: false,
      testScore: "",
      classes: "",
      teacher: "",
      grade: "",
      extras: "",
      exp: "",
      teacherRec: "",
      email: "",
      note: "",
      updateCard: false,
      submitLoading: false,
      id: "",
    });
  }

  async handleDelete(id, type) {
    const getID = { id: id };

    // Set the element to be loading in the array
    this.handleLoading(id);

    if (type === "score") {
      await API.graphql(graphqlOperation(deleteScore, getID));
      message.success("Score successfully deleted.");
      this.listScores();
    }
    if (type === "class") {
      await API.graphql(graphqlOperation(deleteClass, getID));
      message.success("Class successfully deleted.");
      this.listClasses();
    }

    if (type === "extra") {
      await API.graphql(graphqlOperation(deleteExtra, getID));
      message.success("Extracurricular successfully deleted.");
      this.listExtras();
    }

    if (type === "exps") {
      await API.graphql(graphqlOperation(deleteExp, getID));
      message.success("Experience or award successfully deleted.");
      this.listExps();
    }

    if (type === "recs") {
      await API.graphql(graphqlOperation(deleteRec, getID));
      message.success("Recommendation successfully deleted.");
      this.listRecs();
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      testScore: "",
      classes: "",
      teacher: "",
      grade: "",
      extras: "",
      exp: "",
      teacherRec: "",
      email: "",
      note: "",
      updateCard: false,
      id: "",
    });
  };

  uploadOnChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      console.log(info.fileList);

      for (var i = 0; i < info.fileList.length; i++) {
        if (info.fileList[i].size < 5000000) {
          const file = info.fileList[i];
          const fileObj = file.originFileObj;
          const fileName = this.state.user_id + "/locker/" + info.file.name;

          if (this.state.files.find((x) => x.key === fileName)) {
            message.error("File already uploaded");
          } else {
            Storage.vault
              .put(fileName, fileObj, "Private Content", {
                level: "private",
                contentType: file.type,
              })
              .then((result) =>
                message
                  .success(`${info.file.name} file uploaded successfully.`)
                  .then(window.location.reload())
              )
              .catch((err) => console.log(err));
          }
        } else {
          message.error("Please choose a smaller file.");
        }
      }
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  }

  handleUpdateChange(event) {
    this.setState({ selectValue: event.target.value });
  }

  async handleSubmit(event) {
    this.setState({
      submitLoading: true,
    });

    event.preventDefault();
    event.stopPropagation();

    if (this.state.updateCard) {
      this.handleUpdate(event);
    } else {
      if (this.state.selectValue === "scores") {
        const scoreInput = {
          test: this.state.test,
          score: this.state.testScore,
        };
        await API.graphql(graphqlOperation(createTestScore, scoreInput));
        this.setState({ test: "", score: "", visible: false });
        message.success("New score successfully added");
        this.listScores();
      }

      if (this.state.selectValue === "classes") {
        const classInput = {
          name: this.state.classes,
          teacher: this.state.teacher,
          grade: this.state.grade,
        };
        await API.graphql(graphqlOperation(createClass, classInput));
        this.setState({ teacher: "", grade: "", classes: "", visible: false });
        message.success("New class successfully added");
        this.listClasses();
      }

      if (this.state.selectValue === "extra") {
        const extraInput = { extra: this.state.extras };
        await API.graphql(graphqlOperation(createExtra, extraInput));
        this.setState({ extras: "", visible: false });
        message.success("New extracurricular successfully added");
        this.listExtras();
      }

      if (this.state.selectValue === "experience") {
        const expInput = { exp: this.state.exps };
        await API.graphql(graphqlOperation(createExp, expInput));
        this.setState({ exps: "", visible: false });
        message.success("New experience or award successfully added");
        this.listExps();
      }

      if (this.state.selectValue === "recs") {
        const recsInput = {
          teacher: this.state.teacherRec,
          email: this.state.email,
          req: false,
        };
        await API.graphql(graphqlOperation(createRec, recsInput));
        this.setState({ recs: "", teacher: "", email: "", visible: false });
        message.success("New recommendation successfully added");
        this.listRecs();
      }

      this.setState({
        submitLoading: false,
        testScore: "",
        classes: "",
        teacher: "",
        grade: "",
        extras: "",
        exp: "",
        teacherRec: "",
        email: "",
        id: "",
      });
    }
  }

  getSubmitButton = () => {
    return (
      <div>
        <div className="columns">
          <div className="column">
            <p className="control">
              <div className="button is-fullwidth">+</div>
            </p>
          </div>
        </div>
      </div>
    );
  };

  getTestForm = () => {
    return (
      <div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Test Type</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    value={this.state.test}
                    name="test"
                    onChange={this.handleChange}
                  >
                    <option value="sat">SAT</option>
                    <option value="act">ACT</option>
                    <option value="psat">PSAT</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label">Test Score</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="E.g 1240"
                  name="testScore"
                  value={this.state.testScore}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getClassesForm = () => {
    return (
      <div>
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-fullwidth">
              <label className="label">Class Name</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="E.g AP English"
                  name="classes"
                  value={this.state.classes}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="field is-fullwidth">
              <label className="label">Teacher</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="E.g Mr. John Doe"
                  name="teacher"
                  value={this.state.teacher}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="field is-fullwidth">
              <label className="label">Grade</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="E.g 10th"
                  name="grade"
                  value={this.state.grade}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getExtrasForm = () => {
    return (
      <div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Extracurricular or Activity</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="E.g Soccer"
                  name="extras"
                  value={this.state.extras}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getExpForm = () => {
    return (
      <div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Experience & Awards</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="E.g National Merit Scholar"
                  name="exps"
                  value={this.state.exps}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getRecsForm = () => {
    return (
      <div>
        <div className="columns">
          <div className="column is-one-half">
            <div className="field is-fullwidth">
              <label className="label">Teacher</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="E.g Mr. John Doe"
                  name="teacherRec"
                  value={this.state.teacherRec}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="column is-one-half">
            <div className="field is-fullwidth">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="E.g jdoe@highschool.com"
                  value={this.state.email}
                  name="email"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getNotesForm = () => {
    return (
      <div>
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Quick Notes</label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Take a note about your profile."
                  name="note"
                  value={this.state.note}
                  onChange={this.handleChange}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  showUpdateCard = (item, type) => {
    this.setState({
      visible: true,
      updateCard: true,
      id: item.id,
    });
    if (type === "class") {
      this.setState({
        selectValue: "classes",
        classes: item.name,
        teacher: item.teacher,
        grade: item.grade,
      });
    }
    if (type === "recs") {
      this.setState({
        selectValue: "recs",
        teacherRec: item.teacher,
        email: item.email,
      });
    }
  };

  cardMenu = (item, type) => {
    return (
      <Menu>
        <Menu.Item key="0" onClick={this.showUpdateCard.bind(this, item, type)}>
          Update
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={this.handleDelete.bind(this, item.id, type)}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  toUpperCases(str) {
    return str.toUpperCase();
  }

  getUpdateForm = (selectValue) => {
    if (selectValue === "scores") {
      return <div>{this.getTestForm()}</div>;
    }
    if (selectValue === "classes") {
      return <div>{this.getClassesForm()}</div>;
    }
    if (selectValue === "extra") {
      return <div>{this.getExtrasForm()}</div>;
    }
    if (selectValue === "experience") {
      return <div>{this.getExpForm()}</div>;
    }
    if (selectValue === "recs") {
      return <div>{this.getRecsForm()}</div>;
    }
    // if (selectValue === "notes") {
    //   return <div>
    //    {this.getNotesForm()}
    //   </div>
    // }
  };

  loadingButton = (item) => {
    if (this.state.isLoading) {
      return <Icon type="loading" style={{ fontSize: 24 }} spin />;
    } else {
      return (
        <Icon
          type="close"
          onClick={this.handleDelete.bind(this, item.id, "score")}
        />
      );
    }
  };

  render() {
    const Draggerprops = {
      name: "file",
      multiple: true,
      headers: {
        authorization: "authorization-text",
      },
      action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
      onChange: this.uploadOnChange.bind(this),
    };

    const fileCards = [].concat(this.state.files).map((file, i) => (
      <div className="column is-quarter">
        {console.log(file.key)}
        <div className="card ">
          <div className="card-content">
            <h6 level={4}>
              {file["key"].substring(
                file["key"].lastIndexOf("/") + 1,
                file["key"].length
              )}
            </h6>
          </div>
          <footer className="card-footer">
            <div
              onClick={this.getFile.bind(this, file.key)}
              className="card-footer-item"
            >
              Download
            </div>
            <div
              onClick={this.deleteFile.bind(this, file.key)}
              className="card-footer-item"
            >
              Delete
            </div>
          </footer>
        </div>
      </div>
    ));

    return (
      <div>
        <Title style={{ textAlign: "center" }} level={1}>
          Academic Locker{" "}
        </Title>
        {/* <h2 className="subtitle" style={{padding:'20px'}}>All the awesome things you've achieved. Big and small.</h2> */}
        <div className="columns is-centered">
          <div className="column is-three-quarters">
            <Card style={{ width: "100%" }}>
              <div className="columns">
                <div className="column is-2">
                  <div className="field is-grouped">
                    <p className="control">
                      {/* <button className="button"><Icon type="download" />&nbsp;DOWNLOAD</button> */}
                    </p>
                    <p className="control">
                      <div className="button is-link" onClick={this.showModal}>
                        Update Locker
                      </div>
                      <Modal
                        title="Update Locker"
                        visible={this.state.visible}
                        onOk={this.handleSubmit}
                        onCancel={this.handleCancel}
                        okText="Submit"
                      >
                        <Spin spinning={this.state.submitLoading}>
                          <div className="columns">
                            <div className="column">
                              <p>
                                This a place where you can track your academic
                                and extracurricular achievements. You can also
                                use your locker to keep track of your teacher
                                recommendations and the classes you've taken
                                throughout high school.
                              </p>
                              <Title level={4}>
                                Pick a section to add an update to.
                              </Title>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <div className="control">
                                <div className="select is-rounded">
                                  <select
                                    value={this.state.selectValue}
                                    onChange={this.handleUpdateChange}
                                  >
                                    <option value="scores">Test Scores</option>
                                    <option value="classes">Classes</option>
                                    <option value="extra">
                                      Extracurriculars
                                    </option>
                                    <option value="experience">
                                      Experience & Awards
                                    </option>
                                    <option value="recs">
                                      Recommendations
                                    </option>
                                    {/* <option value="notes">Notes</option> */}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              {this.getUpdateForm(this.state.selectValue)}
                            </div>
                          </div>
                        </Spin>
                      </Modal>
                    </p>
                  </div>
                </div>
              </div>
              <Divider orientation="left">High School Performance</Divider>
              <div className="columns">
                <div className="column is-4 is-offset-8">
                  <button className="button" onClick={this.clickedEditProfile}>
                    <Icon type="edit" />
                    &nbsp;Edit Performance
                  </button>
                </div>
              </div>

              <div className="columns">
                <div className="column is-one-third">
                  <Card style={{ borderTop: "6px solid #f2b63f" }}>
                    <Text level={4}>{this.toUpperCases("Weighted GPA")}</Text>
                    {this.state.displaySave ? (
                      <input
                        className="input"
                        type="text"
                        placeholder="E.g 3.5"
                        value={this.state.weightedGPA}
                        name="weightedGPA"
                        onChange={this.handleChange}
                      />
                    ) : (
                      <Title strong level={4}>
                        {this.state.weightedGPA}
                      </Title>
                    )}
                  </Card>
                </div>
                <div className="column is-one-third">
                  <Card style={{ borderTop: "6px solid #f2b63f" }}>
                    <Text>{this.toUpperCases("Unweighted GPA")}</Text>
                    {this.state.displaySave ? (
                      <input
                        className="input"
                        type="text"
                        placeholder="E.g 3.5"
                        value={this.state.unweightedGPA}
                        name="unweightedGPA"
                        onChange={this.handleChange}
                      />
                    ) : (
                      <Title level={4}>{this.state.unweightedGPA}</Title>
                    )}
                  </Card>
                </div>
                <div className="column is-one-third">
                  <Card style={{ borderTop: "6px solid #f2b63f" }}>
                    <Text>{this.toUpperCases("Rank")}</Text>
                    {this.state.displaySave ? (
                      <input
                        className="input"
                        type="text"
                        placeholder="E.g 123"
                        value={this.state.rank}
                        name="rank"
                        onChange={this.handleChange}
                      />
                    ) : (
                      <Title level={4}>{this.state.rank}</Title>
                    )}
                  </Card>
                </div>
              </div>
              {this.state.displaySave ? (
                <div className="columns">
                  <div className="column is-4 is-offset-8">
                    <div className="buttons">
                      <div
                        className="button is-success is-small"
                        onClick={this.saveProfile}
                      >
                        Save Changes
                      </div>
                      <button
                        className="button is-small"
                        onClick={this.cancelProfileChange}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              <Divider orientation="left">Test Scores</Divider>
              {this.state.sectionLoading["test"] ? (
                <div className="columns is-multiline">
                  {this.state.scores.map((item) => {
                    return (
                      <div className="column is-one-quarter">
                        <Spin spinning={this.state.isLoading[item.id] || false}>
                          <Card
                            style={{ borderTop: "6px solid #f37748" }}
                            key={item.id}
                            extra={
                              <Icon
                                type="close"
                                onClick={this.handleDelete.bind(
                                  this,
                                  item.id,
                                  "score"
                                )}
                              />
                            }
                          >
                            <Title style={{ textAlign: "center" }} level={4}>
                              {item.score}
                            </Title>
                            <p>{this.toUpperCases(item.test)}</p>
                          </Card>
                        </Spin>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Skeleton active />
              )}

              <Divider orientation="left">Classes</Divider>
              {this.state.sectionLoading["class"] ? (
                <div className="columns is-multiline">
                  {this.state.getClasses.map((item) => {
                    return (
                      <div className="column is-one-quarter">
                        <Spin spinning={this.state.isLoading[item.id] || false}>
                          <Card
                            style={{ borderTop: "6px solid #067BC2" }}
                            key={item.id}
                            extra={
                              <Dropdown
                                overlay={this.cardMenu.bind(
                                  this,
                                  item,
                                  "class"
                                )}
                                trigger={["click"]}
                              >
                                <div
                                  className="ant-dropdown-link"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <Icon type="ellipsis" />
                                </div>
                              </Dropdown>
                            }
                          >
                            <div>
                              <Title style={{ textAlign: "center" }} level={4}>
                                {item.name}
                              </Title>
                              <p>
                                {item.grade} / {item.teacher}{" "}
                              </p>{" "}
                            </div>
                          </Card>
                        </Spin>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Skeleton active />
              )}

              <Divider orientation="left">Extracurriculars</Divider>
              {this.state.sectionLoading["extra"] ? (
                <div className="columns is-multiline">
                  {this.state.getExtras.map((item) => {
                    return (
                      <div className="column is-one-third">
                        <Spin spinning={this.state.isLoading[item.id] || false}>
                          <Card
                            style={{ borderTop: "6px solid #007991" }}
                            key={item.id}
                            extra={
                              <Icon
                                type="close"
                                onClick={this.handleDelete.bind(
                                  this,
                                  item.id,
                                  "extra"
                                )}
                              />
                            }
                          >
                            <div>
                              <Title style={{ textAlign: "center" }} level={4}>
                                {item.extra}
                              </Title>
                            </div>
                          </Card>
                        </Spin>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Skeleton active />
              )}

              <Divider orientation="left">Experience & Awards</Divider>
              {this.state.sectionLoading["exps"] ? (
                <div className="columns is-multiline">
                  {this.state.getExp.map((item) => {
                    return (
                      <div className="column is-one-third">
                        <Spin spinning={this.state.isLoading[item.id] || false}>
                          <Card
                            style={{ borderTop: "6px solid #074F57" }}
                            key={item.id}
                            extra={
                              <Icon
                                type="close"
                                onClick={this.handleDelete.bind(
                                  this,
                                  item.id,
                                  "exps"
                                )}
                              />
                            }
                          >
                            <div>
                              <Title style={{ textAlign: "center" }} level={4}>
                                {item.exp}
                              </Title>
                            </div>
                          </Card>
                        </Spin>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Skeleton active />
              )}

              <Divider orientation="left">Recommendations</Divider>
              {this.state.sectionLoading["recs"] ? (
                <div className="columns is-multiline">
                  {this.state.getRecs.map((item) => {
                    return (
                      <div className="column is-one-third">
                        <Spin spinning={this.state.isLoading[item.id] || false}>
                          <Card
                            style={{ borderTop: "6px solid black" }}
                            key={item.id}
                            extra={
                              <Dropdown
                                overlay={this.cardMenu.bind(this, item, "recs")}
                                trigger={["click"]}
                              >
                                <div
                                  className="ant-dropdown-link"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <Icon type="ellipsis" />
                                </div>
                              </Dropdown>
                            }
                          >
                            <div>
                              <Title style={{ textAlign: "center" }} level={4}>
                                {item.teacher}
                              </Title>
                            </div>
                            <a
                              href={
                                "mailto:" +
                                item.email +
                                "?Subject=Recommendation%20Request"
                              }
                              target="_top"
                            >
                              {this.toUpperCases("Send Mail")}
                            </a>
                          </Card>
                        </Spin>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Skeleton active />
              )}

              <Divider orientation="left">Files</Divider>
              <div className="columns">
                <div className="column">{fileCards}</div>
              </div>

              <div className="columns" style={{ margin: "5%" }}>
                <div className="column">
                  <Dragger {...Draggerprops}>
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or multi-file upload.
                    </p>
                  </Dragger>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
export default AcademicProfile;
