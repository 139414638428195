export const createClass = `mutation createClass($name:String! $teacher: String! $grade: String!) {
    createClass(input:{
      name:$name
      teacher:$teacher
      grade:$grade
    }){
      __typename
      id
      name
      teacher
      grade
    }
  }`;

export const updateClass = `mutation updateClass($id: ID! $name:String! $teacher: String! $grade: String!){
    updateClass(input:{
      id: $id
      name:$name
      teacher:$teacher
      grade:$grade
    }){
      __typename
      id
      name
      teacher
      grade
    }
  }`;

export const readClass = `query listClasses{
    listClasss{
      items{
        __typename
        id
        name
        teacher
        grade
      }
    }
  }`;

export const deleteClass = `mutation deleteClass($id: ID!){
    deleteClass(input:{
      id: $id
    }){
      __typename
      id
      name
      teacher
      grade
    }
  }`;

export const createExtra = `mutation createExtra($extra:String!) {
    createExtra(input:{
      extra:$extra
    }){
      __typename
      id
      extra
    }
  }`;

export const createPerformance = `mutation createPerformance($weighted:String! $unweighted:String! $rank: String!) {
    createPerformance(input:{
        weighted:$weighted
        unweighted:$unweighted
        rank:$rank
    }){
      __typename
      id
      weighted
      unweighted
      rank
    }
}`;

export const updatePerformance = `mutation updatePerformance($id: ID! $weighted:String! $unweighted:String! $rank: String!){
  updatePerformance(input:{
    id: $id
    weighted:$weighted
    unweighted:$unweighted
    rank:$rank
  }){
    __typename
    id
    weighted
    unweighted
    rank
  }
}`;

export const readPerformance = `query listPerformances{
  listPerformances{
    items{
      __typename
      id
      weighted
      unweighted
      rank
    }
  }
}`;

export const createRec = `mutation createRec($teacher:String! $email:String! $req: Boolean!) {
    createRec(input:{
        teacher:$teacher
        email:$email
        req:$req

    }){
      __typename
      id
      teacher
      email
      req
    }
  }`;

export const updateRec = `mutation updateRec($id: ID! $teacher:String! $email:String! $req: Boolean!){
    updateRec(input:{
      id: $id
      teacher:$teacher
      email:$email
      req:$req
    }){
      __typename
      id
      teacher
      email
      req
    }
  }`;

export const deleteRec = `mutation deleteRec($id: ID!){
    deleteRec(input:{
      id: $id
    }){
      __typename
      id
      teacher
      email
      req
    }
  }`;

export const readRec = `query listRecs{
    listRecs{
      items{
        __typename
        id
        teacher
        email
        req
      }
    }
  }`;

export const createExp = `mutation createExp($exp:String!) {
    createExp(input:{
      exp:$exp
    }){
      __typename
      id
      exp
    }
  }`;

export const readExp = `query listExp{
    listExps{
      items{
        __typename
        id
        exp
      }
    }
  }`;

export const deleteExp = `mutation deleteExp($id: ID!){
    deleteExp(input:{
      id: $id
    }){
      __typename
      id
      exp
    }
  }`;

export const readExtra = `query listExtras{
    listExtras{
      items{
        __typename
        id
        extra
      }
    }
  }`;

export const deleteExtra = `mutation deleteExtra($id: ID!){
    deleteExtra(input:{
      id: $id
    }){
      __typename
      id
      extra
    }
  }`;

export const createTestScore = `mutation createTestScore($test:String! $score: String!) {
    createTestScore(input:{
      test:$test
      score:$score
    }){
      __typename
      id
      test
      score
    }
  }`;

export const readScore = `query listScores{
    listTestScores{
      items{
        __typename
        id
        test
        score
      }
    }
  }`;

export const deleteScore = `mutation deleteScore($id: ID!){
    deleteTestScore(input:{
      id: $id
    }){
      __typename
      id
      test
      score
    }
  }`;
