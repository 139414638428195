import React from "react";
import "bulma/css/bulma.css";
import { Typography } from "antd";
import SettingsProfile from "./Profile/Settings";

const { Title } = Typography;

function Settings(props) {
  return (
    <div>
      <Title style={{ textAlign: "center" }} level={1}>
        Settings
      </Title>
      <SettingsProfile />
    </div>
  );
}
export default Settings;
