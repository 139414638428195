import React, { Component } from "react";
// // import '../App.css';
// import "../Playbooks/Playbook3/node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../Playbooks/Playbook3/node_modules/bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import {
  Typography,
  PageHeader,
  Tabs,
  message,
  Modal,
  Spin,
  DatePicker,
  Card,
  Icon,
  Alert,
} from "antd";

import * as mutations from "../../../../graphql/mutations";
import * as queries from "../../../../graphql/queries";

import { getPath, isUrl } from "../Schools/helpers";

import { Link } from "react-router-dom";

const { Title } = Typography;
const { TabPane } = Tabs;

Amplify.configure(aws_exports);

class Basket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      suggestion: "task",
      task: "",
      scholarshipname: "",
      link: "",
      note: "",
      deadline: "",
      tasks: [],
      scholarships: [],
      colleges: [],
      college: "",
      collegenote: "",
      submitLoading: false,
      isLoading: [],
      scholarshipToken: "",
      taskToken: "",
      collegeToken: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  async componentDidMount() {
    if (this.props.location.state) {
      this.getExtras();
    }
  }

  async getExtras() {
    const variables = {
      id: this.props.location.state.group.id, // key is "input" based on the mutation above
    };
    await API.graphql(graphqlOperation(queries.getGroup, variables))
      .then((data) =>
        this.setState({
          tasks: data.data.getGroup.tasks.items,
          taskToken: data.data.getGroup.tasks.nextToken,
          colleges: data.data.getGroup.colleges.items,
          collegeToken: data.data.getGroup.colleges.nextToken,
          scholarships: data.data.getGroup.scholarships.items,
          scholarshipToken: data.data.getGroup.scholarships.nextToken,
        })
      )
      .catch((err) => console.log(err));
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
    this.submitSuggestion();
  };

  handleLoading(id) {
    let loading = this.state.isLoading.slice();
    loading[id] = true;
    this.setState({
      isLoading: loading,
    });
  }

  async handleDeleteTask(id) {
    const taskId = { id: id };
    // Set the element to be loading in the array
    this.handleLoading(id);
    await API.graphql(
      graphqlOperation(mutations.deleteGTask, { input: taskId })
    )
      .then((data) => message.success("Task successfully deleted."))
      .catch((err) => console.log(err));
    // Update the props in the parent
    this.getExtras();
  }

  async handleDeleteCollege(id) {
    const collegeid = { id: id };
    // Set the element to be loading in the array
    this.handleLoading(id);
    await API.graphql(
      graphqlOperation(mutations.deleteGCollege, { input: collegeid })
    )
      .then((data) => message.success("College successfully deleted."))
      .catch((err) => console.log(err));
    // Update the props in the parent
    this.getExtras();
  }

  async handleDeleteScholarship(id) {
    const scholarshipid = { id: id };
    // Set the element to be loading in the array
    this.handleLoading(id);
    await API.graphql(
      graphqlOperation(mutations.deleteGScholarship, { input: scholarshipid })
    )
      .then((data) => message.success("Scholarship successfully deleted."))
      .catch((err) => console.log(err));
    // Update the props in the parent
    this.getExtras();
  }

  async showMoreClicked(type) {
    var token;
    var currentData;
    var variable;
    const getRefinedCGroup = `query GetGroup($id: ID!  $nextTask: String $nextScholarship: String $nextCollege: String) {
            getGroup(id: $id) {
              id
              name
              owner
              members
              requests
              channels {
                items {
                  id
                  name
                  header
                }
                nextToken
              }
              tasks (nextToken: $nextTask){
                items {
                  id
                  task
                  suggestedby
                  owner
                }
                nextToken
              }
              scholarships (nextToken: $nextScholarship){
                items {
                  id
                  name
                  suggestedby
                  deadline
                  link
                  note
                  owner
                }
                nextToken
              }
              colleges (nextToken: $nextCollege){
                items {
                  id
                  college
                  suggestedby
                  note
                  owner
                }
                nextToken
              }
            }
          }
          `;

    if (type === "scholarship") {
      token = this.state.scholarshipToken;
      currentData = this.state.scholarships;
      variable = {
        id: this.props.location.state.group.id,
        nextScholarship: token,
      };

      if (token) {
        const data = await API.graphql(
          graphqlOperation(getRefinedCGroup, variable)
        );
        for (
          let itemIndex = 0;
          itemIndex < data.data.getGroup.scholarships.items.length;
          itemIndex++
        ) {
          let newData = data.data.getGroup.scholarships.items[itemIndex];
          currentData.push(newData);
        }
        this.setState({
          scholarships: currentData,
          scholarshipToken: data.data.getGroup.scholarships.nextToken,
        });
      }
    } else if (type === "college") {
      token = this.state.collegeToken;
      currentData = this.state.colleges;
      variable = { id: this.props.location.state.group.id, nextCollege: token };

      if (token) {
        const data = await API.graphql(
          graphqlOperation(getRefinedCGroup, variable)
        );
        for (
          let itemIndex = 0;
          itemIndex < data.data.getGroup.colleges.items.length;
          itemIndex++
        ) {
          let newData = data.data.getGroup.colleges.items[itemIndex];
          currentData.push(newData);
        }
        this.setState({
          colleges: currentData,
          collegeToken: data.data.getGroup.colleges.nextToken,
        });
      }
    } else if (type === "task") {
      token = this.state.taskToken;
      currentData = this.state.tasks;
      variable = { id: this.props.location.state.group.id, nextTask: token };

      if (token) {
        const data = await API.graphql(
          graphqlOperation(getRefinedCGroup, variable)
        );
        for (
          let itemIndex = 0;
          itemIndex < data.data.getGroup.tasks.items.length;
          itemIndex++
        ) {
          let newData = data.data.getGroup.tasks.items[itemIndex];
          currentData.push(newData);
        }
        this.setState({
          tasks: currentData,
          taskToken: data.data.getGroup.tasks.nextToken,
        });
      }
    }
  }

  async submitSuggestion() {
    this.setState({ submitLoading: true });
    if (this.state.suggestion === "task") {
      if (/^ *$/.test(this.state.task)) {
        message.error("Task must not be empty");
      } else {
        // Submit the current task
        await API.graphql(
          graphqlOperation(mutations.createGTask, {
            input: {
              gTaskGroupId: this.props.location.state.group.id,
              task: this.state.task,
              suggestedby: this.props.location.state.user,
              owner: this.props.location.state.userID,
            },
          })
        )
          .then((data) => this.setState({ task: "", submitLoading: false }))
          .catch((err) => console.log(err));
        message.success("Task added.");
        this.getExtras();
      }
    } else if (this.state.suggestion === "scholarship") {
      if (
        /^ *$/.test(this.state.scholarshipname) ||
        /^ *$/.test(this.state.deadline)
      ) {
        message.error("Scholarship name and deadline must not be empty");
      } else if (
        !/^ *$/.test(this.state.link) &&
        isUrl(this.state.link) === false
      ) {
        message.error("Please enter a valid scholarhip link");
      } else {
        // Submit the current scholarship
        await API.graphql(
          graphqlOperation(mutations.createGScholarship, {
            input: {
              gScholarshipGroupId: this.props.location.state.group.id,
              name: this.state.scholarshipname,
              suggestedby: this.props.location.state.user,
              owner: this.props.location.state.userID,
              note: this.state.note,
              link: this.state.link,
              deadline: this.state.deadline,
            },
          })
        )
          .then((data) =>
            this.setState({
              scholarshipname: "",
              link: "",
              date: "",
              note: "",
              submitLoading: false,
            })
          )
          .catch((err) => console.log(err));
        message.success("Scholarship added.");
        this.getExtras();
      }
    } else if (this.state.suggestion === "college") {
      if (/^ *$/.test(this.state.college)) {
        message.error("College name must not be empty");
      } else {
        // Sbutmit current college
        await API.graphql(
          graphqlOperation(mutations.createGCollege, {
            input: {
              gCollegeGroupId: this.props.location.state.group.id,
              college: this.state.college,
              suggestedby: this.props.location.state.user,
              owner: this.props.location.state.userID,
              note: this.state.collegenote,
            },
          })
        )
          .then((data) =>
            this.setState({
              college: "",
              collegenote: "",
              submitLoading: false,
            })
          )
          .catch((err) => console.log(err));
        message.success("College added.");
        this.getExtras();
      }
    }
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
      task: "",
      scholarshipname: "",
      note: "",
      deadline: "",
      link: "",
      college: "",
      collegenote: "",
      submitLoading: false,
    });
  };

  onDateChange(date, dateString) {
    this.setState({
      deadline: dateString,
    });
  }

  getForm = () => {
    if (this.state.suggestion === "task") {
      return (
        <div>
          <label className="label">Task</label>
          <input
            className="input"
            type="task"
            placeholder="E.g Start your college applications"
            name="task"
            value={this.state.task}
            onChange={this.handleChange}
          />
        </div>
      );
    } else if (this.state.suggestion === "scholarship") {
      return (
        <div>
          <div className="columns">
            <div className="column">
              <label className="label">Name</label>
              <input
                className="input"
                type="scholarshipname"
                placeholder="E.g National Merit Scholarship"
                name="scholarshipname"
                value={this.state.scholarshipname}
                onChange={this.handleChange}
              />
              <p className="help is-danger">This is required</p>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <label className="label">Deadline</label>
              <DatePicker onChange={this.onDateChange} />
              <p className="help is-danger">This is required</p>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <label className="label">Link</label>
              <input
                className="input"
                type="link"
                placeholder="E.g https://www.nationalmerit.org"
                name="link"
                value={this.state.link}
                onChange={this.handleChange}
              />
              <p className="help">
                A link is not required but it would helpful to include a place
                where other members can find the scholarship
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <label className="label">Additional Information</label>
              <textarea
                className="input"
                type="note"
                placeholder="E.g Requirements: 13+"
                name="note"
                value={this.state.note}
                onChange={this.handleChange}
              />
              <p className="help">
                Feel free to add any additional information that might be
                helpful to other members in the group
              </p>
            </div>
          </div>
        </div>
      );
    } else if (this.state.suggestion === "college") {
      return (
        <div className="columns">
          <div className="column">
            <label className="label">Name</label>
            <input
              className="input"
              type="college"
              placeholder="E.g Hamilton College"
              name="college"
              value={this.state.college}
              onChange={this.handleChange}
            />
          </div>
          <div className="column">
            <label className="label">Additional Information</label>
            <textarea
              className="textarea"
              type="collegenote"
              name="collegenote"
              value={this.state.collegenote}
              onChange={this.handleChange}
              placeholder="e.g. One of the few schools in the US with an open curriculum"
            ></textarea>
            <p className="help">
              Feel free to add any additional information that might be helpful
              to other members in the group
            </p>
          </div>
        </div>
      );
    }
  };

  render() {
    const scholarshipsCard = []
      .concat(this.state.scholarships)
      .map((item, i) => (
        <div className="column is-one-quarter">
          <Card>
            <Spin spinning={this.state.isLoading[item.id] || false}>
              <div className="columns">
                <div className="column">
                  <h4>{item.name}</h4>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  {item.note !== "" ? (
                    <p>{item.note}</p>
                  ) : (
                    <span className="tag">No Note Provided</span>
                  )}
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <p>{item.deadline}</p>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  {item.link !== "" ? (
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </a>
                  ) : (
                    <span className="tag">No Link Provided</span>
                  )}
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <p>
                    <Icon type="user" /> {item.suggestedby.split("@")[0]}
                  </p>
                </div>
              </div>
              {item.owner === this.props.location.state.userID ? (
                <div className="columns is-centered">
                  <div className="column">
                    <Icon
                      type="delete"
                      onClick={this.handleDeleteScholarship.bind(this, item.id)}
                    />
                  </div>
                </div>
              ) : null}
            </Spin>
          </Card>
        </div>
      ));

    const collegesCard = [].concat(this.state.colleges).map((item, i) => (
      <div className="column is-one-quarter">
        <Card>
          <Spin spinning={this.state.isLoading[item.id] || false}>
            <div className="columns">
              <div className="column">
                <h4>{item.college}</h4>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <p>{item.note}</p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <p>
                  <Icon type="user" /> {item.suggestedby.split("@")[0]}
                </p>
              </div>
            </div>
            {item.owner === this.props.location.state.userID ? (
              <div className="columns is-centered">
                <div className="column">
                  <Icon
                    type="delete"
                    onClick={this.handleDeleteCollege.bind(this, item.id)}
                  />
                </div>
              </div>
            ) : null}
          </Spin>
        </Card>
      </div>
    ));

    const tasksCard = [].concat(this.state.tasks).map((item, i) => (
      <div className="column is-one-quarter">
        <Card>
          <Spin spinning={this.state.isLoading[item.id] || false}>
            <div className="columns">
              <div className="column">
                <h4>{item.task}</h4>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <p>
                  <Icon type="user" /> {item.suggestedby.split("@")[0]}
                </p>
              </div>
            </div>
            {item.owner === this.props.location.state.userID ? (
              <div className="columns is-centered">
                <div className="column">
                  <Icon
                    type="delete"
                    onClick={this.handleDeleteTask.bind(this, item.id)}
                  />
                </div>
              </div>
            ) : null}
          </Spin>
        </Card>
      </div>
    ));

    return (
      <div>
        {/* Also make sure to check for current user is the same as the user passed in the props */}
        {this.props.location.state == null ? (
          this.props.history.push(`/groups`)
        ) : (
          <div>
            <div className="columns">
              <div className="column">
                <PageHeader
                  style={{
                    border: "1px solid rgb(235, 237, 240)",
                  }}
                  onBack={() => this.props.history.push(`/groups`)}
                  title="Back"
                  extra={[
                    <Link
                      to={{
                        pathname: getPath(
                          this.props.location.state.name,
                          "picnic"
                        ),
                        state: {
                          group: this.props.location.state.group,
                          name: this.props.location.state.name,
                          userID: this.props.location.state.userID,
                          user: this.props.location.state.user,
                        },
                      }}
                    >
                      <div className="button is-fullwidth" key="1">
                        <Icon type="message" />
                        &nbsp;Picnic Chat
                      </div>
                    </Link>,
                  ]}
                  subTitle="Groups"
                />
                <Modal
                  title="Adding a Group Suggestion"
                  visible={this.state.visible}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  okText="Submit Suggestion"
                >
                  <Spin spinning={this.state.submitLoading}>
                    <div className="columns">
                      <div className="column">
                        <p>
                          The Basket is a place where you can contribute
                          suggestions to the group. Each of the suggestions from
                          any member of the group (in this case,{" "}
                          <strong>{this.props.location.state.name}</strong>)
                          will be publicly shown to the rest of the group.
                        </p>
                        <p>
                          Pick a suggestion you'd like to add to the group's
                          Basket.
                        </p>
                        <div className="select is-rounded">
                          <select
                            value={this.state.suggestion}
                            name="suggestion"
                            onChange={this.handleChange}
                          >
                            <option value="task">Task</option>
                            <option value="college">College</option>
                            <option value="scholarship">Scholarship</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">{this.getForm()}</div>
                    </div>
                  </Spin>
                </Modal>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div className="card">
                  <div className="card-content">
                    <div className="columns">
                      <div className="column is-three-quarters">
                        <Title style={{ fontWeight: 900 }} level={3}>
                          {this.props.location.state.group.name}'s Basket
                        </Title>
                      </div>
                      <div className="column is-one-quarter">
                        <div
                          onClick={this.showModal}
                          className="button is-link is-fullwidth"
                          key="1"
                        >
                          <Icon type="plus" />
                          &nbsp;Add a Group Suggestion{" "}
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <div className="columns">
                          <div className="column">
                            <Tabs defaultActiveKey="1" tabPosition="left">
                              <TabPane tab="Tasks" key="1">
                                <div className="content">
                                  {this.state.tasks.length > 0 ? (
                                    <div>
                                      <div className="columns is-multiline">
                                        {tasksCard}
                                      </div>
                                      {this.state.taskToken == null ? null : (
                                        <button
                                          className="button is-text is-fullwidth"
                                          onClick={this.showMoreClicked.bind(
                                            this,
                                            "task"
                                          )}
                                        >
                                          Show More
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <Alert
                                      message="No Task Suggestions"
                                      description="No one has added tasks to the basket. Feel free to add one!"
                                      type="info"
                                      showIcon
                                    />
                                  )}
                                </div>
                              </TabPane>

                              <TabPane tab="Colleges" key="3">
                                <div className="content">
                                  {this.state.colleges.length > 0 ? (
                                    <div>
                                      <div className="columns is-multiline">
                                        {collegesCard}
                                      </div>
                                      {this.state.collegeToken ==
                                      null ? null : (
                                        <button
                                          className="button is-text is-fullwidth"
                                          onClick={this.showMoreClicked.bind(
                                            this,
                                            "college"
                                          )}
                                        >
                                          Show More
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <Alert
                                      message="No College Suggestions"
                                      description="No one has added colleges to the basket. Feel free to add one!"
                                      type="info"
                                      showIcon
                                    />
                                  )}
                                </div>
                              </TabPane>

                              <TabPane tab="Scholarships" key="2">
                                <div className="content">
                                  {this.state.scholarships.length > 0 ? (
                                    <div>
                                      <div className="columns is-multiline">
                                        {scholarshipsCard}
                                      </div>
                                      {this.state.scholarshipToken ==
                                      null ? null : (
                                        <button
                                          className="button is-text is-fullwidth"
                                          onClick={this.showMoreClicked.bind(
                                            this,
                                            "scholarship"
                                          )}
                                        >
                                          Show More
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <Alert
                                      message="No Scholarship Suggestions"
                                      description="No one has added scholarhips to the basket. Feel free to add one!"
                                      type="info"
                                      showIcon
                                    />
                                  )}
                                </div>
                              </TabPane>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default Basket;
