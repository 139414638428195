import React, { useState } from "react";
import { Steps, Button, Icon, message, Spin } from "antd";
import { Survey } from "../Onboarding/Survey";
import { Welcome } from "../Onboarding/Welcome";
import { Complete } from "../Onboarding/Complete";
import { Suggestions } from "../Onboarding/Suggestions";
import * as mutations from "../../../graphql/mutations";
import * as queries from "../../../graphql/queries";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../aws-exports";

Amplify.configure(aws_exports);
const { Step } = Steps;

function Onboarding() {
  const [year, setYear] = useState("9"); // default to freshmen
  const [count, setCount] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [finalTasks, setTasks] = useState(null);
  const [submitTasks, setSubmit] = useState(false);

  const onboardingCard = {
    borderRadius: "10px 10px 10px 10px",
    height: "100%",
    marginTop: "5%",
  };

  const steps = [
    {
      title: "Welcome",
      content: <Welcome year={year} onChange={handleYearChange} />,
      icon: "smile-o",
    },
    {
      title: "About You",
      content: <Survey year={year} onChange={handleAnswerChange} />,
      icon: "highlight",
    },
    {
      title: "Suggestions",
      content: (
        <Suggestions
          year={year}
          answers={answers}
          getTasks={handleTasksChange}
          submit={handleTaskSubmit}
        />
      ),
      icon: "bulb",
    },
    {
      title: "Complete!",
      content: <Complete />,
      icon: "check-circle",
    },
  ];

  function handleYearChange(newValue) {
    setYear(newValue);
  }

  function handleTasksChange(tasks) {
    setTasks(tasks);
  }

  function handleTaskSubmit(s) {
    setSubmit(s);
    pushTasks();
  }

  async function pushTasks() {
    setSubmit(true);

    for (var i in finalTasks) {
      let date;
      let month;
      let task = finalTasks[i];

      if (task.season === "Fall") {
        month = 8;
      } else if (task.season === "Winter") {
        month = 10;
      } else if (task.season === "Spring") {
        month = 3;
      } else {
        month = 7;
      }

      date = new Date(new Date().getFullYear(), month);
      const t = { task: task.task, year: year, due: date };
      try {
        await API.graphql(graphqlOperation(mutations.createTask, { input: t }));
      } catch (error) {
        message.error(error.errors[0].message);
      }
    }
    const onboarding = await API.graphql(
      graphqlOperation(queries.listOnboardings)
    );
    if (onboarding.data.listOnboardings.items.length === 0) {
      try {
        await API.graphql(
          graphqlOperation(mutations.createOnboarding, {
            input: { onboarded: true },
          })
        );
      } catch (error) {
        message.error(error.errors[0].message);
      }
    } else {
      try {
        await API.graphql(
          graphqlOperation(mutations.updateOnboarding, {
            input: {
              onboarded: true,
              id: onboarding.data.listOnboardings.items[0].id,
            },
          })
        );
      } catch (error) {
        message.error(error.errors[0].message);
      }
    }

    setTimeout(function () {
      setSubmit(false);
      setCount(count + 1);
    }, 5000); // short delay
  }

  function handleAnswerChange(newValue, id) {
    const found = answers.some((answer) => answer.id === id);
    const newAnswers = answers;

    if (!found) {
      const answer = { id: id, answer: newValue, year: year };
      newAnswers.push(answer);
      setAnswers(answers);
    } else {
      const prevIndex = answers.findIndex((answer) => answer.id === id);
      answers[prevIndex].answer = newValue;
    }
  }

  return (
    <div>
      <div className="card" style={onboardingCard}>
        <div className="card-content">
          <div className="columns is-centered">
            <div className="column is-two-thirds">
              <Steps current={count}>
                {steps.map((item) => (
                  <Step
                    key={item.title}
                    title={item.title}
                    icon={<Icon type={item.icon} />}
                  />
                ))}
              </Steps>
              {submitTasks ? (
                <Spin />
              ) : (
                <div style={{ margin: "5%" }}>{steps[count].content}</div>
              )}

              {submitTasks ? null : (
                <div style={{ marginTop: "5%" }}>
                  {count > 0 && count !== steps.length - 1 && (
                    <Button
                      style={{ marginRight: 8 }}
                      onClick={() => setCount(count - 1)}
                    >
                      Previous
                    </Button>
                  )}

                  {count < steps.length - 1 && (
                    <Button
                      type="primary"
                      disabled={count === 2 && !submitTasks}
                      onClick={() => setCount(count + 1)}
                    >
                      Next
                    </Button>
                  )}
                  {count === steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => window.location.replace("/dashboard")}
                    >
                      Back to Dashboard
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
