import React, { Component } from "react";
import "../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Tooltip, Comment } from "antd";
import waitingImage from "../../../../assets/waiting.svg";
import moment from "moment";

import { getAvatar } from "../Schools/helpers";

import * as mutations from "../../../../graphql/mutations";

Amplify.configure(aws_exports);

class PrivateMesaages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      visible: false,
      groups: [],
      user: null,
      userID: null,
      groupName: "",
      groupsToken: "",
      message: [],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  async sendMessage() {
    var name = this.props.currentMember.email.split("@");
    name = name[0];
    if (this.props.currentMember.name) {
      name = this.props.currentMember.name;
    }
    const pmessage = {
      user_id: this.props.currentMember.id,
      name: name,
      privateMessageConversationId: this.props.conversation.id,
      content: this.state.message,
      messageConversationId: this.props.conversation.id,
      privateMessageUserId: this.props.currentMember.id,
    };

    await API.graphql(
      graphqlOperation(mutations.createPrivateMessage, { input: pmessage })
    ).then(this.setState({ message: "" }));
  }

  render() {
    const privatemessages = []
      .concat(this.props.messages)
      .map((item, i) => (
        <Comment
          author={<div>{item.name}</div>}
          avatar={getAvatar(item.name)}
          content={<p style={{ textAlign: "left" }}>{item.content}</p>}
          datetime={
            <Tooltip
              title={moment(item.createdAt).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}
            >
              <span>
                {moment(item.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
              </span>
            </Tooltip>
          }
        />
      ))
      .reverse();
    return (
      <div>
        <div className="box">
          <div className="columns is-centered" style={{ padding: "2%" }}>
            <div className="column is-four-fifths">
              <strong>{this.props.conversation.name}</strong>
            </div>
          </div>
        </div>

        <div
          className="box"
          style={{
            maxHeight: "75vh",
            overflow: "auto",
            marginTop: "2%",
            overflowY: "auto",
          }}
        >
          <div className="card-content">
            <div className="columns">
              <div className="column">
                {/* COMMNEST */}
                {this.props.conversation.messages.items.length > 0 ? (
                  <div>
                    {" "}
                    {this.props.pmToken ? (
                      <div
                        onClick={this.props.getMorePmMessages}
                        className="button is-rounded is-small"
                      >
                        Show Older Messages{" "}
                      </div>
                    ) : null}{" "}
                    {privatemessages}{" "}
                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        this.messagesEnd = el;
                      }}
                    ></div>{" "}
                  </div>
                ) : (
                  // {this.props.conversation.messages.items.length > 0 ? this.props.conversation.messages.nextToken ? :   privatemessages :

                  <div className="columns is-centered is-vcentered">
                    <div className="column">
                      <img src={waitingImage} alt="Logo" />
                    </div>
                    <div className="column">
                      <h4>No messages</h4>
                      <p>
                        Seems like this is a new DM. Feel free to send a new
                        message to{" "}
                        {
                          this.props.conversation.members
                            .find((m) => m !== this.props.currentMember.email)
                            .split("@")[0]
                        }
                        .
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="columns" style={{ marginTop: "2%" }}>
          <div className="column is-four-fifths">
            <input
              className="input is-rounded"
              type="message"
              name="message"
              value={this.state.message}
              onChange={this.handleChange.bind(this)}
              placeholder="Hello.."
            />
          </div>
          <div className="column">
            <div
              className="button is-rounded is-link"
              onClick={this.sendMessage.bind(this)}
            >
              Send Message
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PrivateMesaages;
