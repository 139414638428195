import React, { useState, useEffect } from "react";
import { Button, Form, Input, Radio, DatePicker, message } from "antd";
import moment from "moment";
import isUrl from "is-url";

const { TextArea } = Input;

function ScholarshipForm(props) {
  const [name, setName] = useState(props.scholarship.name);
  const [appSent, setAppSent] = useState(props.scholarship.appSent);
  const [refSent, setRefSent] = useState(props.scholarship.refSent);
  const [tranSent, setTranSent] = useState(props.scholarship.tranSent);
  const [recieved, setRecieved] = useState(props.scholarship.recieved);
  const [deadline, setDeadline] = useState(props.scholarship.deadline);
  const [org, setOrg] = useState(props.scholarship.org);
  const [essay, setEssay] = useState(props.scholarship.essay);
  const [amount, setAmount] = useState(props.scholarship.amount);
  const [contact, setContact] = useState(props.scholarship.contact);
  const [criteria, setCriteria] = useState(props.scholarship.criteria);
  const [documents, setDocuments] = useState(props.scholarship.documents);
  const [note, setNote] = useState(props.scholarship.note);
  const [source, setSource] = useState(props.scholarship.source);

  useEffect(() => {
    setName(props.scholarship.name);
    setAppSent(props.scholarship.appSent);
    setRefSent(props.scholarship.refSent);
    setTranSent(props.scholarship.tranSent);
    setRecieved(props.scholarship.recieved);
    setDeadline(props.scholarship.deadline);
    setOrg(props.scholarship.org);
    setEssay(props.scholarship.essay);
    setAmount(props.scholarship.amount);
    setContact(props.scholarship.contact);
    setCriteria(props.scholarship.criteria);
    setDocuments(props.scholarship.documents);
    setNote(props.scholarship.note);
    setSource(props.scholarship.source);
  }, [props.scholarship]);

  const validateAndSubmit = () => {
    if (!name || name.trim().length === 0) {
      message.error("Name of Scholarship cannot be empty.");
      props.setLoading(false);
    } else if (!source.trim().length === 0 && source && !isUrl(source)) {
      message.error("Please add a valid link");
      props.setLoading(false);
    } else {
      const data = {
        name: name,
        appSent: appSent,
        refSent: refSent,
        tranSent: tranSent,
        recieved: recieved,
        deadline: deadline,
        org: org,
        essay: essay,
        amount: amount,
        contact: contact,
        criteria: criteria,
        documents: documents,
        note: note,
        source: source,
      };

      if (props.scholarship.id) {
        // this is an update
        data["id"] = props.scholarship.id;
      }

      props.action(data);
    }
  };

  return (
    <div>
      <div className="columns">
        <div className="column">
          <h3>{props.scholarship.name}</h3>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Form layout="inline">
            <Form.Item label="Application Sent">
              <Radio.Group
                value={appSent}
                onChange={(e) => {
                  setAppSent(e.target.value);
                }}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="References Sent">
              <Radio.Group
                value={refSent}
                onChange={(e) => {
                  setRefSent(e.target.value);
                }}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Transcripts Sent">
              <Radio.Group
                value={tranSent}
                onChange={(e) => {
                  setTranSent(e.target.value);
                }}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="Recieved Scholarship">
              <Radio.Group
                value={recieved}
                onChange={(e) => {
                  setRecieved(e.target.value);
                }}
              >
                <Radio.Button value={true}>Yes</Radio.Button>
                <Radio.Button value={false}>No</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
          <div className="columns" style={{ margin: "2%" }}>
            <div className="column">
              <Form.Item label="Name">
                <Input
                  value={name}
                  placeholder={"Ex. Girls Inc. National Scholar"}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Deadline">
                <DatePicker
                  value={moment(deadline)}
                  onChange={(date, dateString) => {
                    setDeadline(dateString);
                  }}
                />
              </Form.Item>
              <Form.Item label="Organization">
                <Input
                  value={org}
                  placeholder={"Ex. Girls Inc."}
                  onChange={(e) => {
                    setOrg(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Award Amount">
                <Input
                  value={amount}
                  placeholder={"Ex. 5000"}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Essay Length (if any)">
                <Input
                  value={essay}
                  placeholder={"Ex. 400 words"}
                  onChange={(e) => {
                    setEssay(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
            <div className="column">
              <Form.Item label="Contact">
                <Input
                  value={contact}
                  placeholder={"Ex. hello@girlsinc.com"}
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Link">
                <Input
                  value={source}
                  placeholder={"Ex. https://girlsinc.org/"}
                  onChange={(e) => {
                    setSource(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Criteria">
                <TextArea
                  rows={4}
                  value={criteria}
                  placeholder={"Ex. Must be a Junior"}
                  onChange={(e) => {
                    setCriteria(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item label="Documents Needed">
                <TextArea
                  rows={4}
                  value={documents}
                  placeholder={"Ex. Transcripts, recommendation letters, essay"}
                  onChange={(e) => {
                    setDocuments(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Form.Item label="Notes">
                <TextArea
                  rows={4}
                  placeholder={"Good luck! You got this :)"}
                  value={note}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Button
            type="danger"
            block
            onClick={() => {
              props.setCurrentView(0);
              props.setSelectedScholarship(null);
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="column">
          {/* // make sure to validate that certain inputs were recieved */}
          <Button
            type="primary"
            onClick={() => {
              props.setLoading(true);
              try {
                validateAndSubmit();
              } catch (err) {
                message.error("There was an error saving this Scholarship");
                props.setLoading(false);
              }
            }}
            block
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
export default ScholarshipForm;
