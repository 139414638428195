import React, { Component } from "react";
import "../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import "react-toastify/dist/ReactToastify.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Events, animateScroll as scroll, scrollSpy } from "react-scroll";

import {
  Typography,
  PageHeader,
  Steps,
  Button,
  Icon,
  notification,
} from "antd";
import PlaybookOne from "./Playbook1/playbook1";
import PlaybookTwo from "./Playbook2/playbook2";
import PlaybookThree from "./Playbook3/playbook3";
import PlaybookFour from "./Playbook4/playbook4";
import * as mutations from "../../../../graphql/mutations";

import { done } from "./helpers";
import Confetti from "react-confetti";

const { Title } = Typography;
const ButtonGroup = Button.Group;
const { Step } = Steps;

Amplify.configure(aws_exports);

class PlaybookContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      current: 0,
      doneClicked: false,
      unCheck: false,
      save: false,
      inputFound: false,
      shortgoals: "",
      longgoals: "",
      moregoals: "",
      deeper: "",
      rankings: "",
      quals: "",
      best: "",
      shortgoal1: "",
      shortgoal2: "",
      shortgoal3: "",
      longgoal1: "",
      longgoal2: "",
      longgoal3: "",
      how: "",
      moregoal1: "",
      moregoal2: "",
      moregoal3: "",
      moregoal4: "",
      moregoal5: "",
      moregoal6: "",
      qual1: "",
      qual2: "",
      qual3: "",
      qual4: "",
      qual5: "",
    };

    this.unCheckClicked = this.unCheckClicked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.handleEditorChange = this.handleEditorChange(this)
  }

  async componentDidMount() {
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    document.addEventListener("input", this.handleInputFound);

    if (this.props.location.state.number === 0) {
      this.setState(
        {
          shortgoals: this.props.location.state.playbook.shortgoals,
          longgoals: this.props.location.state.playbook.longgoals,
          how: this.props.location.state.playbook.how,
          moregoals: this.props.location.state.playbook.moregoals,
          deeper: this.props.location.state.playbook.deeper,
        },
        () => {
          this.setGoals(0);
        }
      );
    }

    if (this.props.location.state.number === 1) {
      this.setState(
        {
          rankings: this.props.location.state.playbook.rankings,
        },
        () => {
          this.setGoals(1);
        }
      );
    }

    if (this.props.location.state.number === 2) {
      this.setState(
        {
          quals: this.props.location.state.playbook.qualities,
          exp: this.props.location.state.playbook.experience,
          best: this.props.location.state.playbook.best,
        },
        () => {
          this.setGoals(2);
        }
      );
    }
  }

  setGoals(playbook) {
    if (playbook === 0) {
      if (this.state.shortgoals) {
        this.setState({
          shortgoal1: this.state.shortgoals[0],
          shortgoal2: this.state.shortgoals[1],
          shortgoal3: this.state.shortgoals[2],
        });
      }
      if (this.state.longgoals) {
        this.setState({
          longgoal1: this.state.longgoals[0],
          longgoal2: this.state.longgoals[1],
          longgoal3: this.state.longgoals[2],
        });
      }
      if (this.state.moregoals) {
        this.setState({
          moregoal1: this.state.moregoals[0],
          moregoal2: this.state.moregoals[1],
          moregoal3: this.state.moregoals[2],
          moregoal4: this.state.moregoals[3],
          moregoal5: this.state.moregoals[4],
          moregoal6: this.state.moregoals[5],
        });
      }
    }

    if (playbook === 1) {
      if (!this.state.rankings) {
        const defaultRanks = [
          "Distance from home",
          "Tuition / Fees",
          "Athletics",
          "Your Major Offered",
          "Student-Faculty Ratio",
          "Physical Size of Campus",
        ];
        this.setState({ rankings: defaultRanks });
      }
    }

    if (playbook === 2) {
      if (this.state.quals) {
        this.setState({
          qual1: this.state.quals[0],
          qual2: this.state.quals[1],
          qual3: this.state.quals[2],
          qual4: this.state.quals[3],
          qual5: this.state.quals[4],
        });
      }
    }
  }

  handleEditorChange(note) {
    this.setState({
      deeper: note,
      // updateNote: true
    });
  }

  handleEditorChange_exp(note) {
    this.setState({
      exp: note,
      // updateNote: true
    });
  }

  handleRankChange(rankings) {
    this.setState({
      rankings: rankings,
      // updateNote: true
    });
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
    document.removeEventListener("input", this.handleInputFound);
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  next() {
    this.scrollToTop();

    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    this.scrollToTop();

    this.setState({ doneClicked: false });
    const current = this.state.current - 1;
    this.setState({ current });
  }

  finished(playbook, completed, playbookNum) {
    this.setState({ doneClicked: true });
    this.saveProgress(playbook, completed, playbookNum).then((data) => done());
  }

  async saveProgress(playbook, completed, playbookNum) {
    var mutation = "";
    var complete = true;
    var steps = playbook.steps;
    var play = { id: playbook.id, steps: steps };

    if (playbookNum === 0) {
      mutation = mutations.updatePlaybook1;
    } else if (playbookNum === 1) {
      mutation = mutations.updatePlaybook2;
    } else if (playbookNum === 2) {
      mutation = mutations.updatePlaybook3;
    } else if (playbookNum === 3) {
      mutation = mutations.updatePlaybook4;
    }

    if (this.state.unCheck) {
      complete = false;
    } else if (this.state.save) {
      complete = steps[completed];

      if (this.props.location.state.number === 0 && completed === 1) {
        const shortGoals = [
          this.state.shortgoal1,
          this.state.shortgoal2,
          this.state.shortgoal3,
        ];
        const longGoals = [
          this.state.longgoal1,
          this.state.longgoal2,
          this.state.longgoal3,
        ];
        play["shortgoals"] = shortGoals;
        play["longgoals"] = longGoals;
        play["how"] = this.state.how;
      } else if (this.props.location.state.number === 0 && completed === 2) {
        const moreGoals = [
          this.state.moregoal1,
          this.state.moregoal2,
          this.state.moregoal3,
          this.state.moregoal4,
          this.state.moregoal5,
          this.state.moregoal6,
        ];
        play["moregoals"] = moreGoals;
        play["deeper"] = this.state.deeper;
      } else if (this.props.location.state.number === 2 && completed === 1) {
        const qualities = [
          this.state.qual1,
          this.state.qual2,
          this.state.qual3,
          this.state.qual4,
          this.state.qual5,
        ];
        play["qualities"] = qualities;
        play["experience"] = this.state.exp;
        play["best"] = this.state.best;
      }
    }

    steps[completed] = complete;
    try {
      await API.graphql(
        graphqlOperation(mutation, { input: play })
      ).then((data) => this.openNotificationWithIcon("success"));
    } catch (err) {
      console.log(err);
    }
    this.setState({ unCheck: false, save: false });
  }

  unCheckClicked = (playbook, completed, playbookNum) => {
    this.setState({ unCheck: true }, () => {
      this.saveProgress(playbook, completed, playbookNum);
    });
  };

  saveClicked = (playbook, completed, playbookNum) => {
    this.setState({ save: true }, () => {
      this.saveProgress(playbook, completed, playbookNum);
    });
  };

  openNotificationWithIcon = (type) => {
    if (this.state.unCheck) {
      notification[type]({
        message: "Section has been marked as Incomplete",
        description:
          "You can mark this section as complete again when you're ready.",
      });
    } else if (this.state.save) {
      notification[type]({
        message: "Progress Saved",
        description: "Your progress for this step has been saved.",
      });
    } else {
      notification[type]({
        message: "Section has been marked as Complete",
        description: "Nice job. Feel free to move on to the next section!",
      });
    }
  };

  handleInputFound = () => {
    this.setState({ inputFound: true });
  };

  onStepChange = (current) => {
    this.setState({ current, doneClicked: false });
  };

  render() {
    const { current } = this.state;
    const steps = this.props.location.state.content;

    return (
      <div>
        <div className="columns">
          <div className="column">
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              onBack={() => this.props.history.push(`/playbooks`)}
              title="Back"
              subTitle="Playbooks"
            />
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Title style={{ textAlign: "center" }} level={2}>
              {this.props.location.state.name}{" "}
            </Title>
          </div>
        </div>

        {this.state.doneClicked ? <Confetti /> : null}

        <div className="columns">
          <div className="column">
            <div className="box">
              <div className="columns">
                <div className="column">
                  <div>
                    <div className="columns">
                      <div className="column is-one-fifth">
                        <Steps
                          progressDot
                          current={current}
                          direction="vertical"
                          onChange={this.onStepChange}
                        >
                          {steps.map((item) => (
                            <Step
                              style={{ marginBottom: "10%" }}
                              key={item.title}
                              title={item.title}
                            />
                          ))}
                        </Steps>
                      </div>
                      <div className="column">
                        <div className="container is-fluid">
                          <div className="notification">
                            {this.props.location.state.playbook.steps[
                              current
                            ] ? (
                              <div className="tag is-success is-medium">
                                {" "}
                                Marked as Complete
                              </div>
                            ) : null}
                            {this.props.location.state.number === 0 ? (
                              <PlaybookOne
                                title={steps[current].title}
                                stepNum={current}
                                handleChange={this.handleChange.bind(this)}
                                handleEditorChange={this.handleEditorChange.bind(
                                  this
                                )}
                                shortgoal1={this.state.shortgoal1}
                                shortgoal2={this.state.shortgoal2}
                                shortgoal3={this.state.shortgoal3}
                                longgoal1={this.state.longgoal1}
                                longgoal2={this.state.longgoal2}
                                longgoal3={this.state.longgoal3}
                                how={this.state.how}
                                moregoal1={this.state.moregoal1}
                                moregoal2={this.state.moregoal2}
                                moregoal3={this.state.moregoal3}
                                moregoal4={this.state.moregoal4}
                                moregoal5={this.state.moregoal5}
                                moregoal6={this.state.moregoal6}
                                deeper={this.state.deeper}
                              />
                            ) : this.props.location.state.number === 1 ? (
                              <PlaybookTwo
                                title={steps[current].title}
                                stepNum={current}
                                rankings={this.state.rankings}
                                handleRankChange={this.handleRankChange.bind(
                                  this
                                )}
                              />
                            ) : this.props.location.state.number === 2 ? (
                              <PlaybookThree
                                title={steps[current].title}
                                stepNum={current}
                                best={this.state.best}
                                qual1={this.state.qual1}
                                qual2={this.state.qual2}
                                qual3={this.state.qual3}
                                qual4={this.state.qual4}
                                qual5={this.state.qual5}
                                exp={this.state.exp}
                                handleChange={this.handleChange.bind(this)}
                                handleEditorChange_exp={this.handleEditorChange_exp.bind(
                                  this
                                )}
                              />
                            ) : // this.props.location.state.number === 3?
                            // <PlaybookFour title={steps[current].title} stepNum={current} /> :
                            this.props.location.state.number === 3 ? (
                              <PlaybookFour
                                title={steps[current].title}
                                stepNum={current}
                              />
                            ) : null}
                            {/* {steps[current].content} */}
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="steps-action">
                      {current > 0 && (
                        <Button
                          style={{ marginLeft: 8 }}
                          onClick={() => this.prev()}
                        >
                          Previous Section
                        </Button>
                      )}
                      &nbsp;
                      {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => this.next()}>
                          Next Section
                        </Button>
                      )}
                      {current === steps.length - 1 && (
                        <ButtonGroup>
                          <Button
                            type="primary"
                            onClick={this.finished.bind(
                              this,
                              this.props.location.state.playbook,
                              current,
                              this.props.location.state.number
                            )}
                          >
                            Done with this Playbook
                          </Button>

                          <Button href="/playbooks">Back to Playbooks</Button>
                        </ButtonGroup>
                      )}
                    </div>
                    {current !== steps.length - 1 && (
                      <Button
                        style={{ marginTop: "2%" }}
                        onClick={(e) =>
                          this.props.location.state.playbook.steps[current]
                            ? this.unCheckClicked(
                                this.props.location.state.playbook,
                                current,
                                this.props.location.state.number
                              )
                            : this.saveProgress(
                                this.props.location.state.playbook,
                                current,
                                this.props.location.state.number
                              )
                        }
                      >
                        {this.props.location.state.playbook.steps[current] ? (
                          <div>
                            Mark as Incomplete{" "}
                            <Icon
                              type="close-circle"
                              theme="twoTone"
                              twoToneColor="#eb2f96"
                              style={{ scale: "1.5", padding: "2%" }}
                            />{" "}
                          </div>
                        ) : (
                          <div>
                            Mark as Complete{" "}
                            <Icon
                              type="check-circle"
                              theme="twoTone"
                              twoToneColor="#52c41a"
                              style={{ scale: "1.5", padding: "2%" }}
                            />{" "}
                          </div>
                        )}
                      </Button>
                    )}
                    &nbsp;
                    {steps[current].hasInput ? (
                      <Button
                        style={{ marginTop: "2%" }}
                        onClick={() =>
                          this.saveClicked(
                            this.props.location.state.playbook,
                            current,
                            this.props.location.state.number
                          )
                        }
                      >
                        Save Progress <Icon type="save" />
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="columns">
              <div className="column">
                  <p style={{fontSize:10}}>Information adapted from the National Association for College Admission Counseling </p>
              </div>
          </div>
            */}
      </div>
    );
  }
}

export default PlaybookContent;
