import React from "react";
import PropTypes from "prop-types";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { Alert } from "antd";

function Result(props) {
  var getAnswer = (id) =>
    filteredWrongs.find((obj) => {
      return obj.question.id === id;
    });

  const filteredWrongs = props.wrongAnswers.filter(
    (value) => Object.keys(value).length !== 0
  );

  const showRightandWrong = [].concat(props.questions).map((item, i) => (
    <div className="column">
      <div className="card">
        <div className="card-content">
          <p className="help">Question - {i + 1}</p>
          <h6 level={4}>{item.question}</h6>

          {filteredWrongs.some((wrongA) => wrongA.question.id === item.id) ? (
            <div>
              <p className="help">
                <strong>Your Answer - {getAnswer(item.id)["option"]} </strong>
              </p>
              <p className="help">
                <strong>Correct Answer - {item.answer} </strong>
              </p>
              <Alert
                message="Wrong."
                description={item.explanation}
                type="error"
                showIcon
              />
            </div>
          ) : (
            <div>
              <p className="help">
                <strong>Correct Answer - {item.answer} </strong>
              </p>
              <Alert
                message="Correct!"
                description={item.explanation}
                type="success"
                showIcon
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ));

  return (
    <CSSTransitionGroup
      className="container result"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={800}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}
    >
      <div>
        You got{" "}
        <strong>
          {props.questions.length - filteredWrongs.length}/
          {props.questions.length}
        </strong>{" "}
        correct! Don't worry if you got some wrong. This quiz was to test your
        current knowledge on financial aid and hopefully dispel some myths!
        {showRightandWrong}
      </div>
    </CSSTransitionGroup>
  );
}

Result.propTypes = {
  quizResult: PropTypes.string.isRequired,
};

export default Result;
