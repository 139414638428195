import React, { Component } from "react";
import "../../../App.css";
import "bulma/css/bulma.css";
import moment from "moment";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../aws-exports";
import {
  Typography,
  Icon,
  message,
  Modal,
  Alert,
  Tag,
  Spin,
  Radio,
  Select,
  Checkbox,
  Button,
  DatePicker,
} from "antd";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

const cardTasksBox = {
  marginTop: "20px",
  borderRadius: "10px 10px 10px 10px",
  height: "100%",
};

const { Title } = Typography;
const { Option } = Select;
Amplify.configure(aws_exports);

class Roadmap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      token: "",
      isLoading: [],
      value: "",
      mapYear: "9",
      task: "",
      season: "winter",
      date: new Date(),
      status: "upcoming",
      completed: true,
      displayAdd: true,
      displayUpdate: false,
      submitLoading: false,
      editItem: null,
      year: "",
      visible: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleSeasonChange = this.handleSeasonChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  async componentDidMount() {
    const currSeason = this.getSeason(new Date()).toLowerCase();
    this.setState({ season: currSeason });
    this.listTasks();
  }

  handleChange(e) {
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  handleSeasonChange(value) {
    this.setState({ season: value });
  }

  handleInputChange(event) {
    this.setState({ taskInput: event.target.value });
  }

  handleEditChange(event) {
    this.setState({ taskInput: event.target.value });
  }

  handleDateChange(date, dateString) {
    this.setState({ date: date });
  }

  handleLoading(id, status) {
    let loading = this.state.isLoading.slice();
    loading[id] = status;
    this.setState({
      isLoading: loading,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.year === "" || this.state.task === "") {
      message.error("Task and School Year required.");
      this.setState({ submitLoading: false });
    } else {
      const task = {
        task: this.state.task,
        due: this.state.date,
        complete: false,
        status: "Incomplete",
        year: this.state.year,
      };
      await API.graphql(
        graphqlOperation(mutations.createTask, { input: task })
      );
      this.listTasks();
      this.setState({
        task: "",
        date: new Date(),
        completed: "",
        status: "",
        year: "",
        visible: false,
        submitLoading: false,
      });
      message.success("Task successfully created.");
    }
  }

  async handleDelete(id) {
    const taskId = { id: id };
    this.handleLoading(id, true);
    await API.graphql(
      graphqlOperation(mutations.deleteTask, { input: taskId })
    );
    this.listTasks();
    message.success("Task successfully deleted.");
  }

  async handleUpdate(item, type) {
    this.handleLoading(item.id, true);

    if (type === "check") {
      if (item.complete) {
        const taskUncomplete = {
          status: "Incomplete",
          id: item.id,
          complete: false,
        };
        await API.graphql(
          graphqlOperation(mutations.updateTask, { input: taskUncomplete })
        );
      } else {
        const taskComplete = {
          status: "Complete",
          id: item.id,
          complete: true,
        };
        await API.graphql(
          graphqlOperation(mutations.updateTask, { input: taskComplete })
        );
      }
      message.success("Task successfully updated.");
      this.listTasks();
    } else if (type === "edit") {
      var task = this.state.task;
      var year = this.state.year;
      var date = this.state.date;

      if (task.length === 0) {
        task = this.state.editItem.task;
      }
      if (year.length === 0) {
        year = this.state.editItem.year;
      }
      if (
        moment(date).format("MM/DD/YYYY") ===
        moment(new Date()).format("MM/DD/YYYY")
      ) {
        date = this.state.editItem.due;
      }

      const taskUpdate = { id: item.id, task: task, due: date, year: year };
      await API.graphql(
        graphqlOperation(mutations.updateTask, { input: taskUpdate })
      );
      message.success("Task successfully updated.");
      this.listTasks();
      this.setState({
        displayUpdate: false,
        value: "",
        task: "",
        date: new Date(),
      });
    }

    this.handleLoading(item.id, false);
  }

  handleEdit(item, type) {
    if (type === "edit") {
      this.setState({ displayUpdate: true, editItem: item, year: item.year });
    } else {
      this.setState({
        displayUpdate: false,
        editItem: null,
        year: "",
        task: "",
        date: new Date(),
      });
    }
  }

  async listTasks() {
    const tasks = await API.graphql(graphqlOperation(queries.listTasks));
    this.setState({
      tasks: tasks.data.listTasks.items,
      token: tasks.data.listTasks.nextToken,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      submitLoading: true,
    });
    this.handleSubmit(e);
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      submitLoading: false,
      task: "",
      year: "",
      date: new Date(),
    });
  };

  getSeason = (inputDate) => {
    if (inputDate) {
      var month = new Date(inputDate);
      month = month.getMonth() + 1;
    }

    var season;

    switch (month) {
      default:
        season = "No date";
        break;
      case 12:
      case 1:
      case 2:
        season = "Winter";
        break;
      case 3:
      case 4:
      case 5:
        season = "Spring";
        break;
      case 6:
      case 7:
      case 8:
        season = "Summer";
        break;
      case 9:
      case 10:
      case 11:
        season = "Fall";
        break;
    }
    return season;
  };

  getSeasonTag = (inputDate) => {
    var tag;

    switch (this.getSeason(inputDate)) {
      default:
        tag = <Tag>No date</Tag>;
        break;
      case "Winter":
        tag = <Tag color="blue">Winter</Tag>;
        break;
      case "Fall":
        tag = <Tag color="orange">Fall</Tag>;
        break;
      case "Summer":
        tag = <Tag color="volcano">Summer</Tag>;
        break;
      case "Spring":
        tag = <Tag color="purple">Spring</Tag>;
        break;
    }

    return tag;
  };

  async showMoreTasks() {
    if (this.state.token) {
      const nextToken = { nextToken: this.state.token };

      const newData = await API.graphql(
        graphqlOperation(queries.listTasks, nextToken)
      );

      for (
        let itemIndex = 0;
        itemIndex < newData.data.listTasks.items.length;
        itemIndex++
      ) {
        let newTask = newData.data.listTasks.items[itemIndex];
        this.setState({ tasks: [...this.state.tasks, newTask] });
      }

      this.setState({ token: newData.data.listTasks.nextToken });
    }
  }

  render() {
    const allTasksData = []
      .concat(this.state.tasks)
      .filter((task) => {
        if (this.state.season === "na") {
          return task.year === this.state.mapYear && task.due == null;
        } else {
          return (
            task.year === this.state.mapYear &&
            this.getSeason(task.due).toLowerCase() ===
              this.state.season.toLowerCase()
          );
        }
      })
      .map((item, i) => (
        <div style={{ marginBottom: "2%" }}>
          <Spin spinning={this.state.isLoading[item.id] || false}>
            <div>
              <div className="columns">
                <div className="column is-three-fifths">
                  <div className="columns" style={{ textAlign: "left" }}>
                    <div className="column">
                      <Checkbox
                        style={{
                          textDecoration:
                            item.status === "Complete" ? "line-through" : "",
                        }}
                        onClick={this.handleUpdate.bind(this, item, "check")}
                        checked={item.status === "Complete"}
                      >
                        {item.task}
                      </Checkbox>
                    </div>
                  </div>
                </div>
                <div className="column is-1">{this.getSeasonTag(item.due)}</div>
                <div className="column is-2">
                  <label>
                    <p className="help">
                      {item.due ? moment(item.due).format("MM/DD/YYYY") : null}
                    </p>
                  </label>
                </div>
                <div className="column is-one-fifth">
                  <div className="buttons">
                    {this.state.displayUpdate &&
                    this.state.editItem.id === item.id ? null : (
                      <button
                        className="button is-white is-small"
                        onClick={this.handleEdit.bind(this, item, "edit")}
                      >
                        Edit
                      </button>
                    )}
                    <button
                      onClick={this.handleDelete.bind(this, item.id)}
                      className="button is-white is-small"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
              {this.state.displayUpdate &&
              this.state.editItem.id === item.id ? (
                <div className="columns is-centered is-vcentered">
                  <div className="column is-two-fifths">
                    <input
                      className="input"
                      type="text"
                      defaultValue={this.state.editItem.task}
                      name="task"
                      onChange={this.handleChange.bind(this)}
                    />
                  </div>
                  <div className="column is-one-fifth">
                    <div className="select is-rounded">
                      <select
                        name="year"
                        defaultValue={this.state.editItem.year}
                        onChange={this.handleChange.bind(this)}
                        value={this.state.year}
                      >
                        <option value="9">Freshman</option>
                        <option value="10">Sophmore</option>
                        <option value="11">Junior</option>
                        <option value="12">Senior</option>
                      </select>
                    </div>
                  </div>
                  <div className="column is-one-fifth">
                    <DatePicker
                      size={"large"}
                      onChange={this.handleDateChange}
                      defaultValue={moment(this.state.editItem.due)}
                    />
                  </div>
                  <div className="column is-one-fifth">
                    <div className="buttons">
                      <div
                        className="button is-small is-success"
                        onClick={this.handleUpdate.bind(this, item, "edit")}
                      >
                        Save
                      </div>
                      <button
                        className="button is-white is-small is-danger"
                        onClick={this.handleEdit.bind(this, item, "cancel")}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </Spin>
        </div>
      ));

    return (
      <div>
        <div className="columns is-centered">
          <div className="column">
            <Title level={1}>School Year Roadmap</Title>
          </div>
        </div>
        <div className="columns is-centered is-vcentered">
          <div className="column is-one-third">
            <div>
              <Radio.Group
                defaultValue={this.state.mapYear}
                buttonStyle="solid"
                name="mapYear"
                onChange={this.handleChange.bind(this)}
                value={this.state.mapYear}
              >
                <Radio.Button value="9">Freshman</Radio.Button>
                <Radio.Button value="10">Sophmore</Radio.Button>
                <Radio.Button value="11">Junior</Radio.Button>
                <Radio.Button value="12">Senior</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className="column is-one-third">
            <div className="columns is-centered is-vcentered is-gapless">
              <div className="column">
                <label>Pick a season:</label>
              </div>
              <div className="column">
                <Select
                  value={this.state.season}
                  style={{ width: "100%" }}
                  onChange={this.handleSeasonChange.bind(this)}
                >
                  <Option value="winter">Winter</Option>
                  <Option value="spring">Spring</Option>
                  <Option value="summer">Summer</Option>
                  <Option value="fall">Fall</Option>
                  <Option value="na">Not Labeled</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>

        <div className="columns is-centered is-vcentered">
          <div className="column is-two-thirds">
            <div className="card" style={cardTasksBox}>
              <div className="card-content">
                <div className="content">
                  <div className="columns is-centered">
                    <div className="column">
                      <Button type="primary" block onClick={this.showModal}>
                        <span>
                          <Icon
                            type="plus"
                            style={{
                              scale: "1.5",
                              color: "white",
                              padding: "10%",
                            }}
                          />
                          &nbsp;Create a Task
                        </span>
                      </Button>
                      <Modal
                        title="Create a new task"
                        visible={this.state.visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        okText="Submit"
                      >
                        <Spin spinning={this.state.submitLoading}>
                          <div className="columns">
                            <div className="column">
                              <p>
                                Getting organized and staying on task are some
                                of the most important ways to be successful in
                                the college planning process.
                              </p>
                              <p>
                                This is a place where you can keep track of all
                                your tasks - from your freshman to senior year.
                              </p>
                            </div>
                          </div>

                          <div className="columns">
                            <div className="column is-three-fifths">
                              <Title style={{ fontSize: "20px" }} level={4}>
                                Task
                              </Title>
                              <input
                                className="input"
                                type="task"
                                name="task"
                                value={this.state.task}
                                onChange={this.handleChange.bind(this)}
                                placeholder="Eg. Sign up for the SAT"
                              />
                            </div>

                            <div className="column is-two-fifths">
                              <div className="control">
                                <Title style={{ fontSize: "20px" }} level={4}>
                                  Grade
                                </Title>
                                <div className="select is-rounded">
                                  <select
                                    name="year"
                                    onChange={this.handleChange.bind(this)}
                                    value={this.state.year}
                                  >
                                    <option value="0">Select a Year</option>
                                    <option value="9">Freshman</option>
                                    <option value="10">Sophmore</option>
                                    <option value="11">Junior</option>
                                    <option value="12">Senior</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="columns">
                            <div className="column">
                              <Title style={{ fontSize: "20px" }} level={4}>
                                Due Date
                              </Title>
                              <p className="help">Optional</p>
                              <DatePicker
                                size="large"
                                onChange={this.handleDateChange}
                              />
                            </div>
                          </div>
                        </Spin>
                      </Modal>
                    </div>
                  </div>
                  <div>
                    {allTasksData === 0 ? (
                      <div className="columns is-centered">
                        <div
                          style={{ margin: "1%" }}
                          className="column is-half"
                        >
                          <Alert
                            description="Click on the button above to add a new task"
                            type="info"
                          />
                        </div>
                      </div>
                    ) : (
                      allTasksData
                    )}
                    {this.state.token == null ? null : (
                      <div
                        onClick={this.showMoreTasks.bind(this)}
                        className="button is-fullwidth"
                      >
                        Show More
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Roadmap;
