import { questions } from "./questions";
import happyImage from "../../../assets/happy.svg";
import { Select } from "antd";
import React from "react";

const { Option } = Select;

export function Survey(props) {
  function handleChange(value, id) {
    props.onChange(value, id);
  }

  const qList = []
    .concat(questions)
    .filter((q) => q.type.includes(props.year))
    .map((item, i) => (
      <div style={{ textAlign: "left", marginTop: "10%" }}>
        <h6>{item.question}</h6>
        <p>
          <Select
            style={{ width: "100%" }}
            onChange={(value) => handleChange(value, item.id)}
          >
            {item.choices.map((choice) => (
              <Option value={choice.toLowerCase()}>{choice}</Option>
            ))}
          </Select>
        </p>
      </div>
    ));
  return (
    <div>
      {props.year === "9" ? (
        <div className="columns is-centered is-vcentered">
          <div className="column">
            <img src={happyImage} alt="Happy" />
          </div>
          <div className="column">
            <h5 style={{ fontWeight: 900, marginBottom: "5%" }}>
              Wow, awesome! You're early in the process.
            </h5>
            <p style={{ textAlign: "left" }}>
              We don't have any questions for you since you still have a couple
              years before you start applying but we have some suggestions to
              help you get ahead of the game.
            </p>
          </div>
        </div>
      ) : (
        qList
      )}
    </div>
  );
}
