import React from "react";
import "bulma/css/bulma.css";
import ScholarshipForm from "./ScholarshipForm";
import { updateScholarship } from "./helpers";

function EditScholarship(props) {
  return (
    <ScholarshipForm
      setLoading={props.setLoading}
      action={updateScholarship}
      setCurrentView={props.setCurrentView}
      setSelectedScholarship={props.setSelectedScholarship}
      scholarship={props.scholarship}
    />
  );
}
export default EditScholarship;
