export const playbook1steps = [
  {
    title: "Doing What It Takes to Get In",
    hasInput: false,
  },
  {
    title: "Let's Talk About You",
    hasInput: true,
  },
  {
    title: "More About Your Goals",
    hasInput: true,
  },
  // {
  //   title: "Alternatives to College",
  //   content: 'first',
  // },
  {
    title: "The Ever-Increasing Costs of College",
    hasInput: false,
  },
  {
    title: " QUIZ - Financial Aid",
    hasInput: false,
  },
  {
    title: "Assignment and Resources",
    hasInput: false,
    resources: [],
  },
];

export const playbook2steps = [
  {
    title: "Starting Your Search",
    hasInput: false,
  },
  {
    title: "Types of Colleges",
    hasInput: false,
  },
  {
    title: "Other Factors to Consider",
    hasInput: false,
  },
  {
    title: "Rank Your Priorities",
    hasInput: false,
  },
  {
    title: "Make a College Short-list",
    hasInput: false,
  },
  {
    title: "Visiting Colleges",
    hasInput: false,
  },

  {
    title: "Assignment and Resources",
    hasInput: false,
    resources: [],
  },
];

export const playbook3steps = [
  {
    title: "Parts of the Application",
    hasInput: false,
    resources: [],
  },
  //  {
  //       title: "The Application Form",
  //       content: 'first',
  //     },
  {
    title: "The Essays",
    hasInput: true,
  },
  {
    title: "Standardized Testing",
    hasInput: false,
    resources: [],
  },
  // {
  //   title: "Common App vs. the Others",
  //   content: 'first',
  // },
  // {
  //   title: 'Volunteering and Extracurriculars',
  //   content: 'Last-content',
  // },
  {
    title: "Getting Teacher Recommendations",
    hasInput: false,
  },
  {
    title: "Admissions Timelines",
    hasInput: false,
  },
  {
    title: "Assignment and Resources",
    hasInput: false,
    resources: [],
  },
];

export const playbook4steps = [
  {
    title: "Financial Aid: Overview & Types",
    hasInput: false,
  },
  {
    title: "How to Apply for Aid",
    hasInput: false,
  },
  {
    title: "Understanding Your Financial Aid Package",
    hasInput: false,
  },

  // {
  //   title: "Choosing a School",
  //   content: 'first',
  // },
  {
    title: "Assignment and Resources",
    hasInput: false,
    resources: [],
  },
];
