import React, { Component } from "react";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements,
} from "react-stripe-elements";
import { Button, Alert } from "antd";

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Open Sans, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#c23d4b",
      },
    },
  };
};

class _CardForm extends Component {
  state = {
    errorMessage: "",
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    if (this.props.stripe) {
      const cardElement = this.props.elements.getElement("card");

      this.props.stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: { email: this.props.email },
        })
        .then(({ paymentMethod }) => {
          this.props.handleResult(paymentMethod);
        });
      //   this.props.stripe.createToken().then(this.props.handleResult);
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <div className="CardDemo">
        <form>
          <label>
            Enter a card to process your subscription. <br />
            Cancel any time.
          </label>

          <div className="columns is-centered is-vcentered is-gapless">
            <div className="column is-four-fifths">
              <CardElement onChange={this.handleChange} {...createOptions()} />
            </div>
            <div className="column">
              <Button
                onClick={this.handleSubmit.bind(this)}
                block
                size="large"
                type="primary"
              >
                Subscribe
              </Button>
            </div>
          </div>

          <div className="error" role="alert">
            {this.state.errorMessage !== "" ? (
              <Alert
                message="Payment Error"
                type="error"
                showIcon
                description={this.state.errorMessage}
              />
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class CardDemo extends Component {
  render() {
    return (
      // <div>
      //     {    console.log(this.props)}
      // </div>
      <div>
        {this.props.stripePublicKey ? (
          <StripeProvider stripe={this.props.stripePublicKey}>
            <Elements>
              <CardForm
                handleResult={this.props.handleResult}
                email={this.props.email}
              />
            </Elements>
          </StripeProvider>
        ) : null}
      </div>
    );
  }
}
