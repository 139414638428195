import React, { Component } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import NavBar from "./navbar";
import { Typography } from "antd";
import FooterPage from "./footer";

const { Title } = Typography;

class PrivacyPage extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div
          style={{ backgroundColor: "white" }}
          className="columns is-centered"
        >
          <div className="column">
            <Title style={{ fontSize: 40, fontWeight: 900 }}>
              Privacy Policy
            </Title>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "white",
            textAlign: "left",
            paddingLeft: "200px",
            paddingRight: "200px",
          }}
          className="columns is-centered"
        >
          <div className="column">
            <p>Last updated: May 3, 2020</p>
            <p>
              The privacy of your data — and it is your data, not ours! — is a
              big deal to us. In this policy, we lay out: what data we collect
              and why; how your data is handled; and your rights to your data.
            </p>
            <Title
              style={{ fontSize: 20, fontWeight: 600, paddingTop: "20px" }}
            >
              What we collect and why
            </Title>
            <p>
              Our guiding principle is to collect only what we need. Here’s what
              that means in practice:
            </p>
            <Title
              style={{ fontSize: 15, fontWeight: 600, paddingTop: "20px" }}
            >
              Identity & access
            </Title>
            <p>
              When you sign up for a Froshbite product, we typically ask for
              identifying information such as your name and your email address.
              That’s just so you can personalize your new account, and we can
              send you invoices, updates, or other essential information.
            </p>
            <Title
              style={{ fontSize: 15, fontWeight: 600, paddingTop: "20px" }}
            >
              Billing Information
            </Title>
            <p>
              When you pay for a Froshbite product, we ask for your credit card
              and billing address. That's so we can charge you for service,
              calculate taxes due, and send you invoices. Your credit card is
              passed directly to our payment processor and doesn't ever go
              through our servers. We store a record of the payment transaction,
              including the last 4 digits of the credit card number and as-of
              billing address, for account history, invoicing, and billing
              support. We store your billing address to calculate any sales tax
              due in the United States, to detect fraudulent credit card
              transactions, and to print on your invoices.
            </p>
            <Title
              style={{ fontSize: 15, fontWeight: 600, paddingTop: "20px" }}
            >
              Voluntary correspondence
            </Title>
            <p>
              When you write Froshbite with a question or to ask for help, we
              keep that correspondence, including the email address, so that we
              have a history of past correspondences to reference if you reach
              out in the future.
            </p>
            <p>
              We also store any information you volunteer like surveys.
              Sometimes when we do customer interviews, we may ask for your
              permission to record the conversation for future reference or use.
              We only do so if you give your express consent.
            </p>
            <Title
              style={{ fontSize: 15, fontWeight: 600, paddingTop: "20px" }}
            >
              When we access or share your information
            </Title>
            <p>
              Our default practice is to not access your information. The only
              times we’ll ever access or share your info are:
            </p>

            <li>
              To provide products or services you've requested. We do use some
              third-party services to run our applications and only to the
              extent necessary process some or all of your personal information
              via these third parties.
            </li>
            <br />
            <li>
              To help you troubleshoot or squash a software bug, with your
              permission. If at any point we need to access your account to help
              you with a Support case, we will ask for your consent before
              proceeding.
            </li>
            <br />
            <li>
              To investigate, prevent, or take action regarding restricted uses.
              Accessing a customer’s account when investigating potential abuse
              is a measure of last resort. We have an obligation to protect the
              privacy and safety of both our customers and the people reporting
              issues to us. We do our best to balance those responsibilities
              throughout the process. If we do discover you are using our
              products for a restricted purpose, we will report the incident to
              the appropriate authorities.
            </li>
            <br />
            <li>
              When required under applicable law. If the appropriate law
              enforcement authorities have the necessary warrant, criminal
              subpoena, or court order requiring we share data, we have to
              comply. Otherwise, we flat-out reject requests from local and
              federal law enforcement when they seek data. And unless we’re
              legally prevented from it, we’ll always inform you when such
              requests are made.
            </li>
            <br />
            <p>
              Have any questions, comments, or concerns about this privacy
              policy, your data, or your rights with respect to your
              information? Please get in touch by emailing us at{" "}
              <a
                href="mailto:hello@froshbite.com?Subject=Hello%20There!"
                target="_top"
              >
                hello@froshbite.com
              </a>{" "}
              and we’ll be happy to answer them!
            </p>
          </div>
        </div>
        <FooterPage />
      </div>
    );
  }
}
export default PrivacyPage;
