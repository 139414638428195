import React, { Component } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import ReactCardFlip from "react-card-flip";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation, Auth } from "aws-amplify";
import aws_exports from "../aws-exports"; // specify the location of aws-exports.js file on your project
import {
  Typography,
  Icon,
  Card,
  Menu,
  Alert,
  message,
  Button,
  Spin,
  notification,
  Modal,
} from "antd";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { Layout } from "antd";
import { CardDemo } from "../components/general/Stripe/Card";

const { Content, Sider } = Layout;
const { Title } = Typography;
const { confirm } = Modal;

Amplify.configure(aws_exports);

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      page: "/settings/notifications",
      notifications: [],
      user: "",
      user_id: "",
      year: "",
      name: "",
      apiResponse: "",
      stripe: null,
      isFlipped: false,
      stripeLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.createSubscription = this.createSubscription.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
  }

  async componentDidMount() {
    this.setState({
      stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUB_KEY),
    });

    const path = window.location.pathname;
    this.setState({ page: path });
    this.listNotifs();

    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => this.getCurrentUser(user))
      .catch((err) => console.log(err));
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  async getCurrentUser(user) {
    const creds = user.attributes.sub;
    try {
      const getUser = await API.graphql(graphqlOperation(queries.listUsers));
      if (getUser.data.listUsers.items.length === 0) {
        const input = { id: creds, email: user.attributes.email };
        const newUser = await API.graphql(
          graphqlOperation(mutations.createUser, { input: input })
        );
        this.setState(
          {
            user: newUser.data.createUser,
            name: newUser.data.createUser.name,
            year: newUser.data.createUser.year,
          },
          () => {
            this.createCustomer();
          }
        );
      } else {
        const user = getUser.data.listUsers.items[0];
        this.setState({ user: user, name: user.name, year: user.year });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async listNotifs() {
    const notifs = await API.graphql(
      graphqlOperation(queries.listNotificationss)
    );
    this.setState({ notifications: notifs.data.listNotificationss.items });
  }

  async handleNotifDelete(id) {
    const notifid = { id: id };
    // Set the element to be loading in the array
    // this.handleLoading(item.id);
    await API.graphql(
      graphqlOperation(mutations.deleteNotifications, { input: notifid })
    )
      .then((notifs) => this.listNotifs())
      .catch((err) => console.log(err));
  }

  async save(event) {
    event.preventDefault();
    event.stopPropagation();
    const updateUser = {
      id: this.state.user.id,
      year: this.state.year,
      name: this.state.name,
    };
    await API.graphql(
      graphqlOperation(mutations.updateUser, { input: updateUser })
    )
      .then((data) => message.success("Account updated."))
      .catch((err) => console.log(err));
  }

  handlePaymentResult = (token) => {
    this.createSubscription(
      this.state.user.stripeCustomerId,
      token.id,
      process.env.REACT_APP_STRIPE_STU_PRO
    );
  };

  createCustomer = () => {
    this.setState({ stripeLoading: true }, async () => {
      const apiName = "stripeAPI";
      const apiEndpoint = "/checkout/create-customer";
      const body = {
        email: this.state.user.email,
      };
      await API.post(apiName, apiEndpoint, { body })
        .then((response) =>
          this.setPro(response.customer.id).then((data) => this.getUpdateUser())
        )
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  setPro = async (id) => {
    const updateUser = { id: this.state.user.id, stripeCustomerId: id };
    await API.graphql(
      graphqlOperation(mutations.updateUser, { input: updateUser })
    )
      .then((data) =>
        this.setState({ stripeLoading: false, proUser: true, isFlipped: false })
      )
      .catch((err) => console.log(err));
  };

  getUpdateUser = async () => {
    const getUser = await API.graphql(graphqlOperation(queries.listUsers));
    this.setState({ user: getUser.data.listUsers.items[0] });
  };

  createSubscription = (customerId, paymentMethodId, priceId) => {
    const _this = this;
    this.setState({ stripeLoading: true }, async () => {
      const apiName = "stripeAPI";
      const apiEndpoint = "/checkout/create-subscription";
      const body = {
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      };
      await API.post(apiName, apiEndpoint, { body })
        .then((response) => _this.onSubscriptionComplete(response))
        .catch(function (error) {
          _this.openErrorNotificationWithIcon(
            "error",
            "There was an error with setting up your subscription. Please contact support at hello@froshbite.com."
          );
          _this.setState({ stripeLoading: false, isFlipped: false });

          console.log(error);
        });
    });
  };

  openErrorNotificationWithIcon = (type, error) => {
    notification[type]({
      message: "Subscription Error",
      description: error,
      duration: 0,
    });
  };

  openSuccessNotificationWithIcon = (type) => {
    notification[type]({
      message: "Success!",
      description: "Your subscription to Student Pro has been activated.",
      duration: 8,
    });
  };

  onSubscriptionComplete = async (result) => {
    const _this = this;
    console.log(result, "RESULT");
    if (result.latest_invoice.payment_intent.status === "succeeded") {
      message.success("Success! Subscription activated.");
      const updateUser = {
        id: this.state.user.id,
        product: result.items.data[0].plan.product,
        subscription: result.id,
      };
      await API.graphql(
        graphqlOperation(mutations.updateUser, { input: updateUser })
      )
        .then((data) =>
          _this.getUpdateUser().then((data) =>
            _this.setState({
              stripeLoading: false,
              proUser: true,
              isFlipped: false,
            })
          )
        )
        .catch((err) => console.log(err));
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
    } else {
      _this.setState({ stripeLoading: false, isFlipped: false });
      _this.openErrorNotificationWithIcon(
        "error",
        "There was an error with setting up your subscription. Please contact support at hello@froshbite.com."
      );
    }
  };

  handleCancel = async (response) => {
    const _this = this;
    message.success("Account successfully downgraded");
    const updateUser = { id: this.state.user.id, subscription: "cancelled" };
    await API.graphql(
      graphqlOperation(mutations.updateUser, { input: updateUser })
    )
      .then((data) =>
        _this
          .getUpdateUser()
          .then((data) =>
            _this.setState({ stripeLoading: false, proUser: false })
          )
      )
      .catch((err) => console.log(err));
  };

  confirm(e) {
    console.log(e);
    message.success("Click on Yes");
  }

  cancel(e) {
    message.info("Cool! You can continue to use your Student Pro account.");
  }

  cancelSubscription = (evt) => {
    evt.preventDefault();
    const _this = this;
    this.setState({ stripeLoading: true }, async () => {
      const apiName = "stripeAPI";
      const apiEndpoint = "/checkout/cancel-subscription";
      const body = {
        subscriptionId: this.state.user.subscription,
      };
      await API.post(apiName, apiEndpoint, { body })
        .then((response) => _this.handleCancel(response))
        .catch(function (error) {
          _this.openErrorNotificationWithIcon(
            "error",
            "There was an error with setting up your subscription. Please contact support at hello@froshbite.com."
          );
          _this.setState({ stripeLoading: false, isFlipped: false });

          console.log(error);
        });
    });
  };
  // onConfirm={props.cancelSubscription}
  // onCancel={props.cancel}

  showConfirm = (evt) => {
    const _this = this;
    confirm({
      title: "Are you sure you want to downgrade your account?",
      content:
        "You'll lose access to all Groups, Scholarships, and Colleges past the free tier limit.",
      okType: "danger",
      okText: "Yes, continue",
      cancelText: "No, don't downgrade",
      onOk() {
        _this.cancelSubscription(evt);
      },
      onCancel() {
        _this.cancel();
      },
    });
  };

  render() {
    var confirmString = "Are you sure you want to downgrade your account?";
    confirmString = confirmString + "\n";
    confirmString =
      confirmString +
      "You'll lose access to all Groups, Scholarships, and Colleges past the free tier limit.";
    const data = [].concat(this.state.notifications).map((item, i) => (
      <div className="columns is-centered">
        <div className="column is-two-thirds">
          <Card
            actions={[
              <Icon
                type="delete"
                onClick={() => this.handleNotifDelete(item.id)}
                key="delete"
              />,
            ]}
          >
            <div className="columns ">
              <div className="column ">{item.message}</div>
            </div>
          </Card>
        </div>
      </div>
    ));
    return (
      <div>
        <div className="columns">
          <div className="column">
            <Title style={{ textAlign: "center" }} level={1}>
              Settings{" "}
            </Title>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <Layout>
              <Content>
                <Layout style={{ padding: "2% 0", background: "#fff" }}>
                  <Sider style={{ background: "#fff" }}>
                    <Menu
                      mode="inline"
                      defaultSelectedKeys={this.state.page}
                      style={{ height: "100%" }}
                    >
                      <Menu.Item key={"/settings/notifications"}>
                        <Link to="/settings/notifications">Notifications</Link>
                      </Menu.Item>
                      <Menu.Item key={"/settings/account"}>
                        <Link to="/settings/account">Account</Link>
                      </Menu.Item>
                      <Menu.Item key={"/settings/subscriptions"}>
                        <Link to="/settings/subscriptions">Subscriptions</Link>
                      </Menu.Item>
                    </Menu>
                  </Sider>
                  <Content style={{ minHeight: 360 }}>
                    <div>
                      {/* {!this.state.user ? <Redirect to="/settings/notifications" /> : null
                            
                            } */}
                      <Route
                        path="/settings/notifications"
                        render={(props) => (
                          <Notifications notifsData={data} {...props} />
                        )}
                      />
                      <Route
                        path="/settings/subscriptions"
                        render={(props) => (
                          <Subscriptions
                            {...props}
                            showConfirm={this.showConfirm}
                            confirmString={confirmString}
                            cancel={this.cancel}
                            user={this.state.user}
                            email={this.state.user.email}
                            stripe={this.state.stripe}
                            isFlipped={this.state.isFlipped}
                            handleClick={this.handleClick}
                            cancelSubscription={this.cancelSubscription}
                            handlePayment={this.handlePaymentResult}
                            stripeLoading={this.state.stripeLoading}
                          />
                        )}
                      />
                      <Route
                        path="/settings/account"
                        render={(props) => (
                          <Account
                            {...props}
                            save={this.save.bind(this)}
                            user={this.state.user}
                            name={this.state.name}
                            year={this.state.year}
                            handleChange={this.handleChange}
                          />
                        )}
                      />
                      <Redirect
                        from="/settings/"
                        to="/settings/notifications"
                        exact
                      />
                    </div>
                  </Content>
                </Layout>
              </Content>
            </Layout>
          </div>
        </div>
      </div>
    );
  }
}
export default Settings;

const Notifications = (props) => (
  <div>
    <Title
      style={{ textAlign: "left", fontWeight: 900, margin: "2%" }}
      level={3}
    >
      Notifications{" "}
    </Title>
    {props.notifsData.length > 0 ? (
      props.notifsData
    ) : (
      <div>
        <div
          style={{ margin: "5%" }}
          className="columns is-centered is-vcentered is-gapless"
        >
          {/* <div className="column ">
                  <img src={nothingImage} />
                </div> */}
          <div className="column">
            <Alert message="No new notifications!" type="success" />
          </div>
        </div>
      </div>
    )}
  </div>
);

const Account = (props) => (
  <div>
    <Title
      style={{ textAlign: "left", fontWeight: 900, margin: "2%" }}
      level={3}
    >
      Account{" "}
    </Title>
    <div className="columns is-centered">
      <div className="column is-half">
        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input
                  className="input"
                  type="name"
                  name="name"
                  value={props.name ? props.name : null}
                  onChange={props.handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="field">
              <label className="label">Email</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  name="email"
                  value={props.user ? props.user.email : null}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="field is-pulled-left">
              <label className="label">Year</label>
              <div className="control">
                <div className="select">
                  <select
                    name="year"
                    value={props.year ? props.year : 0}
                    onChange={props.handleChange}
                  >
                    <option value={0}>Freshman</option>
                    <option value={1}>Sophmore</option>
                    <option value={2}>Junior</option>
                    <option value={3}>Senior</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="columns">
          <div className="column">
            <div className="control is-pulled-right">
              <button className="button is-primary" onClick={props.save}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const Subscriptions = (props) => (
  <div>
    <Title
      style={{ textAlign: "left", fontWeight: 900, margin: "2%" }}
      level={3}
    >
      Subscriptions{" "}
    </Title>

    <div clss="columns">
      <ReactCardFlip isFlipped={props.isFlipped} flipDirection="vertical">
        <div className="column is-one-third">
          <Card>
            {props.stripeLoading ? (
              <Spin tip="Downgrading Account..." />
            ) : (
              <div>
                <Title level={3}>Student Pro</Title>

                <p>
                  <p className="help">
                    <strong>Up to 20</strong>
                  </p>{" "}
                  <strong>Colleges</strong> on your Applying list
                </p>
                <p>
                  <p className="help">
                    <strong>Up to 30</strong>
                  </p>{" "}
                  <strong>Scholarships</strong> you can manage
                </p>
                <p>
                  <p className="help">
                    <strong>Up to 10</strong>
                  </p>{" "}
                  <strong>Groups</strong> you can create and join
                </p>
                <a href="/pricing" target="_blank">
                  More info on Pricing Page
                </a>

                <hr />
                <p>
                  <strong>$2.99/month</strong>
                </p>
                {props.user.subscription &&
                props.user.subscription.startsWith("sub") ? (
                  // <Popconfirm
                  // title={props.confirmString}
                  // onConfirm={props.cancelSubscription}
                  // onCancel={props.cancel}
                  // okText="Yes, continue"
                  // cancelText="Just kidding, don't downgrade"
                  <Button onClick={props.showConfirm} type="danger">
                    Downgrade to back to Free Account
                  </Button>
                ) : (
                  <Button type="primary" onClick={props.handleClick}>
                    Upgrade to Student Pro
                  </Button>
                )}
              </div>
            )}
          </Card>
        </div>
        <div className="column is-two-thirds">
          <Card style={{ backgroundColor: "#f6f9fc" }}>
            {props.stripeLoading ? (
              <Spin tip="Subbing..." />
            ) : (
              <div className="columns is-centered">
                <div className="column">
                  <div className="columns">
                    <div className="column">
                      <Title level={3}>Enter Card Details</Title>
                      <Title level={4} style={{ fontWeight: 900 }}>
                        Your subscription will start now.
                      </Title>
                      {/* <div style={{textAlign:'left'}}> */}
                      <p>
                        <Icon type="arrow-right" />
                        &nbsp;Total due now $2.99
                      </p>
                      <p>
                        <Icon type="arrow-right" />
                        &nbsp;Subscribing to <strong>Student Pro</strong>
                      </p>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <CardDemo
                        stripePublicKey={props.stripe}
                        handleResult={props.handlePayment}
                        email={props.email}
                      />
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <Button onClick={props.handleClick}>
                        <Icon type="arrow-left" />
                        &nbsp;Back to Plan Details
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </ReactCardFlip>
    </div>
  </div>
);
