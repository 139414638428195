export const generalTasks = [
  {
    id: 0,
    task: "Start working on the Playbooks",
    season: "Fall",
  },
  {
    id: 1,
    task: "Get involved with clubs and extracurricular activities at school",
    season: "Fall",
  },
  {
    id: 2,
    task:
      "Start thinking about what my interests are and what I would want to do in college",
    season: "Fall",
  },
  {
    id: 3,
    task:
      "Think about making summer plans for a job, internship, volunteer, or just participating in something I'm interested in",
    season: "Summer",
  },
  {
    id: 4,
    task: "Talk to my guidance counselor about registering for the PSAT",
    season: "Fall",
  },
  {
    id: 5,
    task:
      "Talk to my guidance counselor about the best courses to take for next year",
    season: "Winter",
  },
  {
    id: 6,
    task: "Go on a couple of college tours",
    season: "Spring",
  },
  {
    id: 7,
    task: "Complete any required summer reading and assignments",
    season: "Summer",
  },
  {
    id: 8,
    task: "Start searching for merit scholarships",
    season: "Summer",
  },
  {
    id: 9,
    task:
      "Create a list of colleges of that I'm interested in (a college wishlist)",
    season: "Fall",
  },
  {
    id: 10,
    task: "Register and take the PSAT",
    season: "Fall",
  },
  {
    id: 11,
    task: "Register and take SAT II Subject tests",
    season: "Fall",
  },
  {
    id: 12,
    task: "Take the practice SAT or ACT",
    season: "Fall",
  },
  {
    id: 13,
    task: "Sign up for a test-prep course or look into test-prep books",
    season: "Fall",
  },
  {
    id: 14,
    task:
      "Look for leadership opportunities in the extracurriculars I'm part of",
    season: "Winter",
  },
  {
    id: 15,
    task: "Continue prepping for the SAT or ACT",
    season: "Winter",
  },
  {
    id: 16,
    task: "Register for the SAT or ACT",
    season: "Spring",
  },
  {
    id: 17,
    task: "Ask my guidance counselor for a SAT and/or ACT waiver",
    season: "Spring",
  },
  {
    id: 18,
    task: "Take the SAT or ACT",
    season: "Spring",
  },
  {
    id: 19,
    task: "Register for AP Exams",
    season: "Spring",
  },
  {
    id: 20,
    task:
      "Start researching colleges and universities (open house, college fairs)",
    season: "Spring",
  },
  {
    id: 21,
    task:
      "Create a list of teachers who could write me a solid recommendation for college applications",
    season: "Spring",
  },
  {
    id: 22,
    task:
      "Review the 'Finding Colleges Youll Love' and 'Navigating the College Application' Playbooks",
    season: "Spring",
  },
  {
    id: 23,
    task: "Visit colleges",
    season: "Summer",
  },
  {
    id: 24,
    task: "Start first the draft of my application essay(s)",
    season: "Summer",
  },
  {
    id: 25,
    task: "Start filling out scholarship applications",
    season: "Summer",
  },
  {
    id: 26,
    task: "Create a resume based on my jobs, internships, and accomplishments",
    season: "Summer",
  },
  {
    id: 27,
    task: "Take the SAT or ACT practice test a couple of weeks before the exam",
    season: "Fall",
  },
  {
    id: 28,
    task:
      "Continue researching colleges and universities (open house, college fairs)",
    season: "Fall",
  },
  {
    id: 29,
    task: "Come up with my final list of colleges to apply to",
    season: "Fall",
  },
  {
    id: 30,
    task: "Register for the SAT or ACT (if I plan on taking it again)",
    season: "Fall",
  },
  {
    id: 31,
    task: "Register and take SAT II Subject tests",
    season: "Fall",
  },
  {
    id: 32,
    task:
      "Ask for teachers to write recommendations (make sure to ask at least a month in advance)",
    season: "Fall",
  },
  {
    id: 33,
    task: "Start first the draft of my application essay(s)",
    season: "Fall",
  },
  {
    id: 34,
    task: "Start applying to the colleges on my shortlist",
    season: "Winter",
  },
  {
    id: 35,
    task: "Review the 'Applying for Financial Aid' Playbook",
    season: "Winter",
  },
  {
    id: 36,
    task: "Go on more college visits",
    season: "Spring",
  },
  {
    id: 37,
    task: "Make a final decision about where I want to go to college",
    season: "Spring",
  },
];
