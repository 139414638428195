import React, { useState, useEffect } from "react";
import { Card, Rate, Icon } from "antd";
import { updateManager } from "./helpers";

const desc = ["Awful", "Bad", "Ok", "Good", "Perfect"];
const importance = [
  "Not Important",
  "Kinda Important",
  "Important",
  "Super Important",
];

function PersonalFit(props) {
  const [location, updateLocFit] = useState(
    !props.data.location ? [3, 3] : props.data.location
  );
  const [academics, updateAcaFit] = useState(
    !props.data.academics ? [3, 3] : props.data.academics
  );
  const [size, updateSizeFit] = useState(
    !props.data.size ? [3, 3] : props.data.size
  );
  const [distance, updateDistanceFit] = useState(
    !props.data.distance ? [3, 3] : props.data.distance
  );
  const [extras, updateExtrasFit] = useState(
    !props.data.extras ? [3, 3] : props.data.extras
  );
  const [social, updateSocialFit] = useState(
    !props.data.social ? [3, 3] : props.data.social
  );
  const [triggerFitChange, setTrigger] = useState(false);

  useEffect(() => {
    if (props.data) {
      setTrigger(false);
      updateLocFit(!props.data.location ? [3, 3] : props.data.location);
      updateAcaFit(!props.data.academics ? [3, 3] : props.data.academics);
      updateSizeFit(!props.data.size ? [3, 3] : props.data.size);
      updateDistanceFit(!props.data.distance ? [3, 3] : props.data.distance);
      updateExtrasFit(!props.data.extras ? [3, 3] : props.data.extras);
      updateSocialFit(!props.data.social ? [3, 3] : props.data.social);
    }
  }, [props.data]);

  function handleFitClick() {
    setTrigger(true);
  }

  function handleFitCancel() {
    updateLocFit(!props.data.location ? [3, 3] : props.data.location);
    updateAcaFit(!props.data.academics ? [3, 3] : props.data.academics);
    updateSizeFit(!props.data.size ? [3, 3] : props.data.size);
    updateDistanceFit(!props.data.distance ? [3, 3] : props.data.distance);
    updateExtrasFit(!props.data.extras ? [3, 3] : props.data.extras);
    updateSocialFit(!props.data.social ? [3, 3] : props.data.social);
    setTrigger(false);
  }

  const submitFits = async () => {
    const manager = {
      id: props.data.id,
      location: location,
      academics: academics,
      size: size,
      distance: distance,
      extras: extras,
      social: social,
    };
    updateManager(manager).then((data) => props.updateApplying());
    setTrigger(false);
  };
  return (
    <div>
      {triggerFitChange ? (
        <div className="columns">
          <div className="column is-one-half">
            <div
              className="button is-danger is-fullwidth"
              onClick={handleFitCancel}
            >
              Cancel
            </div>
          </div>
          <div className="column is-one-half">
            <div
              className="button is-success is-fullwidth"
              onClick={submitFits}
            >
              Submit
            </div>
          </div>
        </div>
      ) : null}
      <div className="columns">
        <div className="column is-one-third">
          <Card>
            <div className="columns">
              <div className="column">
                <strong>Location</strong>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={desc}
                    value={location[0]}
                    onChange={(value) => {
                      updateLocFit([value, location[1]]);
                      handleFitClick();
                    }}
                  />
                  {location[0] ? (
                    <span className="ant-rate-text">
                      {desc[location[0] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <strong>Importance</strong>
                <p className="help">How important is this?</p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={importance}
                    count={4}
                    character={<Icon type="alert" />}
                    onChange={(value) => {
                      updateLocFit([location[0], value]);
                      handleFitClick();
                    }}
                    value={location[1]}
                  />
                  {location[1] ? (
                    <span className="ant-rate-text">
                      {importance[location[1] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          </Card>
        </div>
        <div className="column is-one-third">
          <Card>
            <div className="columns">
              <div className="column">
                <strong>Academic Enviroment</strong>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={desc}
                    value={academics[0]}
                    onChange={(value) => {
                      updateAcaFit([value, academics[1]]);
                      handleFitClick();
                    }}
                  />
                  {academics[0] ? (
                    <span className="ant-rate-text">
                      {desc[academics[0] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <strong>Importance</strong>
                <p className="help">How important is this?</p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={importance}
                    count={4}
                    character={<Icon type="alert" />}
                    onChange={(value) => {
                      updateAcaFit([academics[0], value]);
                      handleFitClick();
                    }}
                    value={academics[1]}
                  />
                  {academics[1] ? (
                    <span className="ant-rate-text">
                      {importance[academics[1] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          </Card>
        </div>
        <div className="column is-one-third">
          <Card>
            <div className="columns">
              <div className="column">
                <strong>Size</strong>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={desc}
                    value={size[0]}
                    onChange={(value) => {
                      updateSizeFit([value, size[1]]);
                      handleFitClick();
                    }}
                  />
                  {size[0] ? (
                    <span className="ant-rate-text">{desc[size[0] - 1]}</span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <strong>Importance</strong>
                <p className="help">How important is this?</p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={importance}
                    count={4}
                    character={<Icon type="alert" />}
                    onChange={(value) => {
                      updateSizeFit([size[0], value]);
                      handleFitClick();
                    }}
                    value={size[1]}
                  />
                  {size[1] ? (
                    <span className="ant-rate-text">
                      {importance[size[1] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <div className="columns">
        <div className="column is-one-third">
          <Card>
            <div className="columns">
              <div className="column">
                <strong>Distance from home</strong>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={desc}
                    value={distance[0]}
                    onChange={(value) => {
                      updateDistanceFit([value, distance[1]]);
                      handleFitClick();
                    }}
                  />
                  {distance[0] ? (
                    <span className="ant-rate-text">
                      {desc[distance[0] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <strong>Importance</strong>
                <p className="help">How important is this?</p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={importance}
                    count={4}
                    character={<Icon type="alert" />}
                    onChange={(value) => {
                      updateDistanceFit([distance[0], value]);
                      handleFitClick();
                    }}
                    value={distance[1]}
                  />
                  {distance[1] ? (
                    <span className="ant-rate-text">
                      {importance[distance[1] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          </Card>
        </div>
        <div className="column is-one-third">
          <Card>
            <div className="columns">
              <div className="column">
                <strong>Extracurriculars</strong>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={desc}
                    value={extras[0]}
                    onChange={(value) => {
                      updateExtrasFit([value, extras[1]]);
                      handleFitClick();
                    }}
                  />
                  {extras[0] ? (
                    <span className="ant-rate-text">{desc[extras[0] - 1]}</span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <strong>Importance</strong>
                <p className="help">How important is this?</p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={importance}
                    count={4}
                    character={<Icon type="alert" />}
                    onChange={(value) => {
                      updateExtrasFit([extras[0], value]);
                      handleFitClick();
                    }}
                    value={extras[1]}
                  />
                  {extras[1] ? (
                    <span className="ant-rate-text">
                      {importance[extras[1] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          </Card>
        </div>
        <div className="column is-one-third">
          <Card>
            <div className="columns">
              <div className="column">
                <strong>Social Scene</strong>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={desc}
                    value={social[0]}
                    onChange={(value) => {
                      updateSocialFit([value, social[1]]);
                      handleFitClick();
                    }}
                  />
                  {social[0] ? (
                    <span className="ant-rate-text">{desc[social[0] - 1]}</span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <strong>Importance</strong>
                <p className="help">How important is this?</p>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <span>
                  <Rate
                    tooltips={importance}
                    count={4}
                    character={<Icon type="alert" />}
                    onChange={(value) => {
                      updateSocialFit([social[0], value]);
                      handleFitClick();
                    }}
                    value={social[1]}
                  />
                  {social[1] ? (
                    <span className="ant-rate-text">
                      {importance[social[1] - 1]}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}
export default PersonalFit;
