/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import onboardImage from "../../../assets/navigate.svg";
import { Button, Tag, Alert } from "antd";
import { generalTasks } from "./tasks";
import React, { useEffect } from "react";

export function Suggestions(props) {
  useEffect(() => {
    props.getTasks(getTasks());
  }, [props.answers]);

  function getTasks() {
    if (props.year === "9") {
      var freshmenTasks = [];
      const taskIds = [0, 1, 2, 3, 5, 7];

      for (var i = 0; i < taskIds.length; i++) {
        freshmenTasks.push(generalTasks.find((t) => t.id === taskIds[i]));
      }

      return freshmenTasks;
    } else {
      return filterTasks(generalTasks);
    }
  }

  function getTag(season) {
    // eslint-disable-next-line default-case
    switch (season) {
      case "Spring":
        return <Tag color="purple">Spring</Tag>;
      case "Summer":
        return <Tag color="volcano">Summer</Tag>;
      case "Fall":
        return <Tag color="orange">Fall</Tag>;
      case "Winter":
        return <Tag color="blue">Winter</Tag>;
    }
  }

  function filterTasks(taskInput) {
    var exclusions = [];
    var taskIds = [];
    var qIds = [];
    var getAns = null;

    if (props.year === "10") {
      qIds = [1, 2, 5, 9];
      taskIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 10];
      for (var id in qIds) {
        if (
          props.answers.some(
            // eslint-disable-next-line no-loop-func
            (answer) => answer.id === qIds[id] && answer.year === props.year
          )
        ) {
          getAns =
            props.answers[
              props.answers.findIndex(
                // eslint-disable-next-line no-loop-func
                (answer) => answer.id === qIds[id] && answer.year === props.year
              )
            ].answer;
          if (getAns === "yes" && qIds[id] === 2) {
            exclusions.push(5);
          } else if (getAns === "no" && qIds[id] === 9) {
            exclusions.push(10, 4);
          } else if (getAns === "yes" && qIds[id] === 5) {
            exclusions.push(1);
          }
        }
      }
    } else if (props.year === "11") {
      qIds = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      taskIds = [
        0,
        1,
        2,
        3,
        4,
        5,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
      ];
      for (let id in qIds) {
        if (
          props.answers.some(
            (answer) => answer.id === qIds[id] && answer.year === props.year
          )
        ) {
          getAns =
            props.answers[
              props.answers.findIndex(
                (answer) => answer.id === qIds[id] && answer.year === props.year
              )
            ].answer;
          if (getAns === "no" && qIds[id] === 0) {
            exclusions.push(11);
          } else if (getAns === "yes" && qIds[id] === 2) {
            exclusions.push(5);
          } else if (getAns === "no" && qIds[id] === 3) {
            exclusions.push(12, 13, 15, 16, 17, 18);
          } else if (getAns === "yes" && qIds[id] === 4) {
            exclusions.push(26);
          } else if (getAns === "yes" && qIds[id] === 5) {
            exclusions.push(1);
          } else if (getAns === "yes" && qIds[id] === 6) {
            exclusions.push(12, 13, 15);
          } else if (getAns === "yes" && qIds[id] === 7) {
            exclusions.push(17);
          } else if (getAns === "no" && qIds[id] === 8) {
            exclusions.push(19);
          } else if (getAns === "no" && qIds[id] === 9) {
            exclusions.push(4, 10);
          }
        }
      }
    } else if (props.year === "12") {
      qIds = [0, 1, 3, 4, 5, 6, 7, 8];
      taskIds = [
        0,
        1,
        2,
        3,
        8,
        11,
        13,
        14,
        17,
        19,
        22,
        26,
        27,
        28,
        29,
        30,
        32,
        33,
        34,
      ];
      for (let id in qIds) {
        if (
          props.answers.some(
            (answer) => answer.id === qIds[id] && answer.year === props.year
          )
        ) {
          getAns =
            props.answers[
              props.answers.findIndex(
                (answer) => answer.id === qIds[id] && answer.year === props.year
              )
            ].answer;
          if (getAns === "no" && qIds[id] === 0) {
            exclusions.push(11);
          } else if (getAns === "no" && qIds[id] === 3) {
            exclusions.push(13, 17, 30, 27);
          } else if (getAns === "yes" && qIds[id] === 4) {
            exclusions.push(26);
          } else if (getAns === "yes" && qIds[id] === 5) {
            exclusions.push(1);
          } else if (getAns === "yes" && qIds[id] === 6) {
            exclusions.push(13);
          } else if (getAns === "yes" && qIds[id] === 7) {
            exclusions.push(17);
          } else if (getAns === "no" && qIds[id] === 8) {
            exclusions.push(19);
          }
        }
      }
    }

    taskInput = taskInput.filter(
      (t) => taskIds.includes(t.id) && !exclusions.includes(t.id)
    );

    return taskInput;
  }

  const tasks = [].concat(getTasks()).map((item, i) => (
    <div className="columns is-vcentered" style={{ textAlign: "left" }}>
      <div className="column">{item.task}</div>
      <div className="column is-1">{getTag(item.season)}</div>
    </div>
  ));

  return (
    <div>
      <div className="columns">
        <div className="column">
          <h5 style={{ fontWeight: 900, marginBottom: "5%" }}>
            {" "}
            Here are our suggestions for your&nbsp;
            {props.year === "9"
              ? "freshman"
              : props.year === "10"
              ? "sophomore"
              : props.year === "11"
              ? "junior"
              : "senior"}{" "}
            year.
          </h5>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-half">
          <img src={onboardImage} alt="Onboard" />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="content">{tasks}</div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="content">
            <Button
              shape="round"
              type="primary"
              block
              onClick={() => props.submit(true)}
            >
              Add Suggestions
            </Button>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Alert
            message="Heads up: You can edit and delete any of these tasks later."
            type="info"
            closeText="Dismiss"
          />
        </div>
      </div>
    </div>
  );
}
