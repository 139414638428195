import React from "react";
import "../components.css";
import { Icon, Menu, Badge, message } from "antd";
import { Auth } from "aws-amplify";
import { API, graphqlOperation, Cache } from "aws-amplify";
import ThemeContext from "../../components/ThemeContext";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";

import * as queries from "../../graphql/queries";

const { SubMenu } = Menu;

class MentorNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      notifications: [],
      user: null,
      userID: null,
      isPro: false,
    };
  }
  async componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);

    // get current user
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) =>
        this.setState({
          user: data.attributes.email,
          userID: data.attributes.sub,
        })
      )
      .catch((err) => message.error(err));

    await API.graphql(graphqlOperation(queries.listNotificationss))
      .then((notifs) =>
        this.setState({ notifications: notifs.data.listNotificationss.items })
      )
      .catch((err) => console.log(err));
  }

  async signOut() {
    try {
      // push to dashboard first
      Cache.clear();
      await Auth.signOut();
    } catch (error) {
      message.error("Error signing out");
    }
  }

  handleClick = () => {
    this.signOut();
  };

  render() {
    const currentPage = window.location.pathname;
    const getCurrentPage = currentPage.substring(1, currentPage.length);

    return (
      <div>
        <ThemeContext.Consumer>
          {(value) => (
            <Menu selectedKeys={getCurrentPage} mode="horizontal">
              <Menu.Item style={{ float: "left" }} key="dashboard">
                <Link to="/org/dashboard"></Link>
                <Icon type="home" />
                Dashboard
              </Menu.Item>
              <Menu.Item style={{ float: "left" }} key="groups">
                <Link to="/org/groups"></Link>
                <Icon type="team" />
                Groups
              </Menu.Item>
              <Menu.Item style={{ float: "left" }} key="students">
                <Link to="/org/students"></Link>
                <Icon type="user" />
                Students
              </Menu.Item>
              <Menu.Item style={{ float: "left" }} key="mentors">
                <Link to="/org/mentors"></Link>
                <Icon type="smile" />
                Mentors
              </Menu.Item>
              <Menu.Item style={{ float: "left" }} key="reports">
                <Link to="/org/reports"></Link>
                <Icon type="pie-chart" />
                Reports
              </Menu.Item>

              <SubMenu
                style={{ float: "right" }}
                key="settings"
                title={
                  <span className="submenu-title-wrapper">
                    {/* <Link to="/settings"> */}
                    <Badge count={this.state.notifications.length} dot>
                      {this.state.user} (Org Account)
                    </Badge>
                    {/* </Link> */}
                  </span>
                }
              >
                <Menu.Item key="profile">
                  <Link to="/org/profile"></Link>
                  Profile
                </Menu.Item>
                <Menu.Item key="settings">
                  <Link to="/org/settings"></Link>
                  Settings
                </Menu.Item>
                <Menu.Item onClick={this.handleClick} key="signout">
                  Sign Out
                </Menu.Item>
              </SubMenu>
            </Menu>
          )}
        </ThemeContext.Consumer>
      </div>
    );
  }
}
export default MentorNav;
