import React from "react";
import "bulma/css/bulma.css";
import { Typography, Tabs } from "antd";
// import { createClient } from "@supabase/supabase-js";
// import {SUPABASE_FROSH_URL} from "../../general/Constants/api"

const { Title } = Typography;
const { TabPane } = Tabs;
// const supabaseUrl = SUPABASE_FROSH_URL
// const supabaseKey = process.env.REACT_APP_SUPABASE_FROSH_KEY
// const supabase = createClient(supabaseUrl, supabaseKey)

function Users() {
  function callback(key) {
    console.log(key);
  }
  return (
    <div>
      <Title style={{ textAlign: "center" }} level={1}>
        All Users{" "}
      </Title>
      <div className="columns">
        <div className="column">
          <div className="box">
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Add User" key="4">
                Content of Tab Pane 1
              </TabPane>
              <TabPane tab="Students" key="1">
                Content of Tab Pane 1
              </TabPane>
              <TabPane tab="Mentors" key="2">
                Content of Tab Pane 2
              </TabPane>
              <TabPane tab="Organizations" key="3">
                Content of Tab Pane 3
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Users;
