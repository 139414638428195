import React, { useState } from "react";
import { Typography, Steps, Icon, Button } from "antd";

const { Title } = Typography;
const { Step } = Steps;

function MatchMakingSurvey() {
  const [count, setCount] = useState(0);

  const steps = [
    {
      title: "Personal Information",
      content: <div>Personal info placeholder</div>,
      icon: "smile-o",
    },
    {
      title: "School Preferences",
      content: <div>School preferences placeholder</div>,
      icon: "bank",
    },
    {
      title: "Complete!",
      content: <div>Complete placeholder</div>,
      icon: "check-circle",
    },
  ];

  return (
    <>
      <Title style={{ textAlign: "center" }} level={1}>
        Match Making Survey
      </Title>
      <div className="card">
        <div className="card-content">
          <div className="columns is-centered">
            <div className="column is-two-thirds">
              <Steps current={count}>
                {steps.map((item) => (
                  <Step
                    key={item.title}
                    title={item.title}
                    icon={<Icon type={item.icon} />}
                  />
                ))}
              </Steps>
              <div style={{ margin: "5%" }}>{steps[count].content}</div>
              <div style={{ marginTop: "5%" }}>
                {count > 0 && (
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={() => setCount(count - 1)}
                  >
                    Previous
                  </Button>
                )}

                {count < steps.length - 1 && (
                  <Button
                    type="primary"
                    disabled={count === 2}
                    onClick={() => setCount(count + 1)}
                  >
                    Next
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MatchMakingSurvey;
