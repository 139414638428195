import React from "react";
import { Select } from "antd";

const { Option } = Select;

export function Welcome(props) {
  function handleChange(value) {
    props.onChange(value);
  }

  return (
    <div>
      <h4 style={{ fontWeight: 900, marginBottom: "5%" }}>
        {" "}
        Welcome! We're glad you're here.
      </h4>
      <p style={{ textAlign: "left" }}>
        To help you on your college planning journey, we need to get to know you
        a little better. From your answers, we'll be able to help you figure out
        what your next steps should be. Let's get you started.
      </p>

      <h6 style={{ textAlign: "left" }}>What year are you in?</h6>
      <p style={{ textAlign: "left" }}>
        <Select
          defaultValue={props.year}
          style={{ width: "100%" }}
          onChange={(value) => handleChange(value)}
        >
          <Option value="9">Freshman</Option>
          <Option value="10">Sophomore</Option>
          <Option value="11">Junior</Option>
          <Option value="12">Senior</Option>
        </Select>
      </p>
    </div>
  );
}
