import React, { Component } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import NavBar from "./navbar";
import { Typography } from "antd";
import FooterPage from "./footer";
import playbooks2 from "../../assets/landingpage/playbooks2.png";
import tasksImage from "../../assets/landingpage/tasks.png";
import deadlineImages from "../../assets/landingpage/deadlines.png";
import dashImages from "../../assets/landingpage/dashlist.png";
import schoolsImage from "../../assets/landingpage/schools.png";
import notesImage from "../../assets/landingpage/notes.png";

const { Title } = Typography;

class FeaturesPage extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div
          style={{ backgroundColor: "white" }}
          className="columns is-centered"
        >
          <div className="column">
            <Title style={{ fontSize: 40, fontWeight: 900 }}>Features</Title>
            <h5
              style={{ marginLeft: "30%", marginRight: "30%" }}
              className="subtitle is-5"
            >
              All the tools we've packed into Froshbite to help you crush the
              college search and admissions process.
            </h5>
          </div>
        </div>

        <div
          className="columns is-vcentered"
          style={{ margin: "20px", textAlign: "left" }}
        >
          <div className="column is-one-half">
            <Title style={{ fontSize: 30, fontWeight: 900 }}>Playbooks</Title>
            <Title style={{ fontSize: 20, fontWeight: 300 }}>
              There are many moving parts to college search and admissions.
              Playbooks are structured to help guide you through every stage of
              the process - starting from your freshman year.
            </Title>
          </div>
          <div className="column is-one-half">
            <div className="card">
              <div className="card-image">
                <img
                  src={playbooks2}
                  alt="Playbooks"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="columns is-vcentered"
          style={{ margin: "20px", textAlign: "left" }}
        >
          <div className="column is-one-half">
            <div className="card">
              <div className="card-image">
                <img
                  src={tasksImage}
                  alt="Playbooks"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className="column is-one-half">
            <Title style={{ fontSize: 30, fontWeight: 900 }}>Tasks</Title>
            <Title style={{ fontSize: 20, fontWeight: 300 }}>
              Keep track of all your tasks by grade level. You can create your
              own or pick from a list of suggested tasks and to-dos depending on
              your current grade.
            </Title>
          </div>
        </div>

        <div
          className="columns is-vcentered"
          style={{ margin: "20px", textAlign: "left" }}
        >
          <div className="column is-one-half">
            <Title style={{ fontSize: 30, fontWeight: 900 }}>
              Calendar & Deadlines
            </Title>
            <Title style={{ fontSize: 20, fontWeight: 300 }}>
              Stay on top of your deadlines and events with our built in
              calendar.
            </Title>
          </div>
          <div className="column is-one-half">
            <div className="card">
              <div className="card-image">
                <img
                  src={deadlineImages}
                  alt="Playbooks"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="columns is-vcentered"
          style={{ margin: "20px", textAlign: "left" }}
        >
          <div className="column is-one-half">
            <div className="card">
              <div className="card-image">
                <img
                  src={notesImage}
                  alt="Playbooks"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
          <div className="column is-one-half">
            <Title style={{ fontSize: 30, fontWeight: 900 }}>Notes</Title>
            <Title style={{ fontSize: 20, fontWeight: 300 }}>
              Keep track of your thoughts and ideas by creating and storing
              notes.
            </Title>
          </div>
        </div>

        <div className="columns" style={{ margin: "20px", textAlign: "left" }}>
          <div className="column is-one-half">
            <Title style={{ fontSize: 30, fontWeight: 900 }}>
              Your College List
            </Title>
            <Title style={{ fontSize: 20, fontWeight: 300 }}>
              Keep track of your thoughts by creating and storing notes.
            </Title>
          </div>
          <div className="column is-one-half">
            <div className="card">
              <div className="card-image">
                <img
                  src={schoolsImage}
                  alt="Playbooks"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="columns" style={{ margin: "20px" }}>
          <div className="column is-one-half"></div>
          <div className="column is-one-half">
            <div className="card">
              <div className="card-image">
                <img
                  src={dashImages}
                  alt="Playbooks"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          </div>
        </div>

        <FooterPage />

        {/* <div className="button" onClick={() => this.signUp()}>Sign In</div> */}
      </div>
    );
  }
}
export default FeaturesPage;
