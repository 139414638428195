import React, { Component } from "react";
import "../../App.css";
import aws_exports from "../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Layout, Icon } from "antd";
import Amplify from "aws-amplify";
import { withAuthenticator } from "aws-amplify-react";
import MentorNav from "./mentornav";
import logo from "../../assets/logos/froshbite-logo/cover.png";
import { Link } from "react-router-dom";

Amplify.configure(aws_exports);

const { Header, Footer, Content } = Layout;

const footerStyle = {
  position: "relative",
  left: "0",
  right: "0",
  bottom: "0",
  marginTop: "100px",
  width: "100%",
  backgroundColor: "white",
  paddingTop: "50px",
};

Amplify.configure(aws_exports);

class MentorDash extends Component {
  render() {
    return (
      <div>
        <Layout style={{ marginTop: "15px" }}>
          <Header style={{ backgroundColor: "white" }}>
            <Link to="/mentor/dashboard">
              <img
                className="logo"
                src={logo}
                style={{
                  float: "left",
                  height: "auto",
                  width: "auto",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
                alt="Logo"
              />
            </Link>
            <MentorNav />
          </Header>
          <Content style={{ margin: "30px" }}>{this.props.children}</Content>
          <Footer style={footerStyle}>
            <div className="columns">
              <div className="column is-one-fifth"></div>
              <div className="column is-4 is-offset-6">
                <p style={{ textAlign: "left" }}>
                  <ul style={{ listStyleType: "none" }}>
                    <li>
                      Made w/{" "}
                      <Icon
                        type="heart"
                        theme="twoTone"
                        twoToneColor="#eb2f96"
                      />{" "}
                      in California <br />
                    </li>
                    <li>
                      <a
                        href="mailto:hello@froshbite.com?Subject=Hello%20There!"
                        target="_top"
                      >
                        hello@froshbite.com
                      </a>
                    </li>
                    <br />
                  </ul>
                </p>
              </div>
            </div>
          </Footer>
        </Layout>
      </div>
    );
  }
}
export default withAuthenticator(MentorDash, {
  includeGreetings: false,
  signUpConfig: {
    hideAllDefaults: ["username"],
    signUpFields: [
      {
        label: "Email",
        key: "username",
        required: true,
        placeholder: "Email (will also be your username)",
        type: "email",
        displayOrder: 1,
      },
      {
        label: "Password",
        key: "password",
        required: true,
        placeholder: "Password",
        type: "password",
        displayOrder: 2,
      },
      {
        label: "Name",
        key: "name",
        required: true,
        type: "string",
        placeholder: "John Doe",
      },
    ],
  },
});
