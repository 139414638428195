import React, { useState, useEffect } from "react";
import moment from "moment";
import { Checkbox, Icon, Button, Input, Tag } from "antd";
import ReactQuill from "react-quill";
import {
  updateManager,
  deleteTasks,
  updateTasks,
  createTasks,
} from "./helpers";

function ApplicationTasks(props) {
  const [tasks, setTasks] = useState(props.data.tasks);
  const [note, setNote] = useState(props.data.note);
  const [addTask, setAddTask] = useState(false);
  const [oneTask, setTask] = useState("");
  const [school, setSchool] = useState(props.data); // set for badges

  useEffect(() => {
    setTasks(props.data.tasks);
    setNote(props.data.note);
    setSchool(props.data);
  }, [props.data]);

  const submitNotes = async () => {
    const updatedManager = {
      id: props.data.id,
      note: note,
    };
    await updateManager(updatedManager).then((data) => props.updateApplying());
  };

  const deleteTask = async (id) => {
    var taskIndex = tasks.items
      .map((x) => {
        return x.id;
      })
      .indexOf(id);
    tasks.items.splice(taskIndex, 1);
    const updatedManager = {
      id: props.data.id,
    };
    await deleteTasks(id).then((data) =>
      updateManager(updatedManager).then((taskData) => props.updateApplying())
    );
  };

  const updateTask = async (taskInput, updateCheck) => {
    const updatedManager = {
      id: props.data.id,
    };
    const task = {
      id: taskInput.id,
      complete: updateCheck ? !taskInput.complete : taskInput.complete,
    };
    await updateTasks(task).then((data) =>
      updateManager(updatedManager).then((managerData) =>
        props.updateApplying()
      )
    );
  };

  const createTask = async () => {
    const updatedManager = {
      id: props.data.id,
    };
    const task = {
      task: oneTask,
      complete: false,
      appTaskAppId: props.data.id,
    };
    await createTasks(task).then((data) =>
      updateManager(updatedManager).then((taskData) => props.updateApplying())
    );
    setTask("");
  };

  return (
    <div className="columns">
      <div className="column is-half">
        {addTask ? (
          <div className="columns">
            <div className="column">
              <Input
                value={oneTask}
                onChange={(e) => setTask(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
          </div>
        ) : null}
        <div className="columns" style={{ textAlign: "left" }}>
          <div className="column">
            {addTask ? (
              <div className="columns">
                <div className="column is-half">
                  <div
                    className="button is-small is-link is-fullwidth is-danger"
                    onClick={() => {
                      setAddTask(false);
                      setTask("");
                    }}
                  >
                    Cancel
                  </div>
                </div>
                <div className="column is-half">
                  <div
                    className="button is-small is-link is-fullwidth is-success"
                    onClick={() => {
                      createTask();
                      setAddTask(false);
                    }}
                  >
                    Submit
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="button is-small is-link is-fullwidth"
                onClick={() => {
                  setAddTask(true);
                }}
              >
                Add a task
              </div>
            )}
          </div>
        </div>
        {tasks ? (
          <ListTasks
            tasks={tasks}
            deleteTask={deleteTask}
            updateTask={updateTask}
          />
        ) : null}
      </div>
      <div className="column">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <div className="column">
                <ReactQuill
                  theme="snow"
                  type="note"
                  name="note"
                  value={note}
                  onChange={(value) => {
                    setNote(value);
                  }}
                  style={{ height: "50vh" }}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Button block onClick={submitNotes}>
                  Save Note
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="columns" style={{ textAlign: "left", marginTop: "5%" }}>
          <div className="column">
            <div className="columns">
              <div className="column">
                <label className="label">Intended Major</label>
              </div>
              <div className="column">
                <Tag color={school.intended ? "green" : "red"}>
                  {school.intended ? school.intended : "Undecided Major"}
                </Tag>
              </div>
            </div>
          </div>
        </div>
        <div className="columns" style={{ textAlign: "left" }}>
          <div className="column">
            <div className="columns">
              <div className="column">
                <label className="label">Application Term</label>
              </div>
              <div className="column">
                <Tag color={school.term ? "green" : "red"}>
                  {school.term ? school.term : "Unknown Application Term"}
                </Tag>
              </div>
            </div>
          </div>
        </div>
        <div className="columns" style={{ textAlign: "left" }}>
          <div className="column">
            <div className="columns">
              <div className="column">
                <label className="label">Application Fee</label>
              </div>
              <div className="column">
                <Tag color={school.fee ? "green" : "red"}>
                  {school.fee ? school.fee : "Unknown Fee"}
                </Tag>
              </div>
            </div>
          </div>
        </div>
        <div className="columns" style={{ textAlign: "left" }}>
          <div className="column">
            <div className="columns">
              <div className="column">
                <label className="label">Application Due Date</label>
              </div>
              <div className="column">
                <Tag color={school.due ? "green" : "red"}>
                  {school.due
                    ? moment(school.due).format("MM/DD/YYYY")
                    : "Please add a due date"}
                </Tag>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ApplicationTasks;

function ListTasks(props) {
  const tasks = props.tasks.items.map((task) => (
    <div className="columns" style={{ textAlign: "left" }}>
      <div className="column is-three-quarters">
        <Checkbox
          onClick={() => props.updateTask(task, true)}
          style={{ textDecoration: task.complete ? "line-through" : null }}
          checked={task.complete ? task.complete : null}
        >
          {task.task}
        </Checkbox>
      </div>
      <div className="column is-pulled-right">
        <Icon
          style={{ float: "right" }}
          type="delete"
          onClick={() => props.deleteTask(task.id)}
        />
      </div>
    </div>
  ));
  return <div>{tasks}</div>;
}
