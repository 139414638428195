var quizQuestions = [
  {
    question: "Only students with good grades get financial aid.",
    correct: "B",
    explanation:
      "Not true! Even though your GPA can help you get into a good school and receive certain scholarships, most of the federal student aid programs do not take your grades into consideration. As long as you keep the minimum GPA needed to graduate while you're in college, you can qualify for federal aid.",
    answer: "False",
    id: 1,
    answers: [
      {
        type: "A",
        content: "True",
      },
      {
        type: "B",
        content: "False",
      },
    ],
  },
  {
    question: "Who should fill out the FAFSA?",
    correct: "A",
    explanation:
      "Every student should fill out the FAFSA. You should fill it out even if you think your parents make too much or if you plan to support yourself. Filling out the FAFSA is free and you don't want to miss the opporunity to receive the financial help you need.",
    answer: "Anyone enrolling in college",
    id: 2,
    answers: [
      {
        type: "A",
        content: "Anyone enrolling in college",
      },
      {
        type: "B",
        content:
          "Only students who know they won't be able to afford the costs",
      },
      {
        type: "C",
        content:
          "The college will tell you if you're eligible for financial aid",
      },
    ],
  },
  {
    question:
      " If I borrow $20,000 in student loans, I’ll have to repay $20,000.",
    correct: "A",
    answer: "False",
    explanation:
      "Loans will cost you more than you borrowed. How much you'll end up owing will depend on the interest rate of the loan. Some loans will have interest rates that start piling on while you're in school. After you graduate, interest will continue to pile on until you fully pay off the debt.",
    id: 3,
    answers: [
      {
        type: "A",
        content: "False",
      },
      {
        type: "B",
        content: "True",
      },
    ],
  },
  {
    question:
      "If you're offered loans in your financial aid package, you must take it.",
    correct: "A",
    explanation:
      "Nope! You're not required to take all the loans that are offered to you. You can choose to decline a loan and replace it with money you've earned from a summer job. You can also work with the financial aid office at your college to find the right loan amount for you.",
    answer: "False",
    id: 4,
    answers: [
      {
        type: "A",
        content: "False",
      },
      {
        type: "B",
        content: "True",
      },
    ],
  },
  {
    question: "It’s all free money.",
    correct: "B",
    explanation:
      "Nope and we'll go over this in more depth in the final Playbook. There are a bunch of different types of aid (scholarships, grants, loans, and work-study) that will be included in your aid package. While some financial aid like Pell Grants only requires you to maintain certain attendance and academic standards, other programs carry additional requirements. Work-study awards must be earned through employment (usually 20 hours per week or less), and loans will need to repaid after students leave college ",
    answer: "False",
    id: 5,
    answers: [
      {
        type: "A",
        content: "True",
      },
      {
        type: "B",
        content: "False",
      },
    ],
  },
  {
    question: "An Award Letter is given by a college to...",
    correct: "D",
    explanation:
      "Again, we'll go over the financial award letter in the final playbook but this is a package that a school will send you after you've been admitted.",
    answer: "Break down a student's financial aid awards and their amounts",
    id: 6,
    answers: [
      {
        type: "A",
        content: "Explain the financial aid process",
      },
      {
        type: "B",
        content: "Inform the student of the Admissions decision",
      },
      {
        type: "C",
        content: "Tell a student they got a fancy award",
      },
      {
        type: "D",
        content:
          "Break down a student's financial aid awards and their amounts",
      },
    ],
  },
  {
    question:
      "This federal program allows students to work on a college's campus.",
    correct: "C",
    explanation:
      "Federal Work-Study provides part-time on-campus jobs for undergraduate and graduate students with financial need, allowing them to earn money to help pay education expenses.",
    answer: "Federal Work Study Program",
    id: 7,
    answers: [
      {
        type: "A",
        content: "Federal Student Work Program",
      },
      {
        type: "B",
        content: "Federal Work Program",
      },
      {
        type: "C",
        content: "Federal Work Study Program",
      },
      {
        type: "D",
        content: "Student Working Program",
      },
    ],
  },
  {
    question: "What is a loan?",
    correct: "D",
    explanation:
      "Just as the answer said. This is aid that must be repaid. There are a couple different types of loans and we'll be going over each of them later.",
    id: 8,
    answer: "Financial aid you have to pay back",
    answers: [
      {
        type: "A",
        content: "Same thing as a grant or scholarship",
      },
      {
        type: "B",
        content: "Free money for college",
      },
      {
        type: "C",
        content: "Money from your parents",
      },
      {
        type: "D",
        content: "Financial aid you have to pay back",
      },
    ],
  },
  {
    question:
      "The average annual income of a person with a college degree is over $55,000.",
    correct: "A",
    answer: "True",
    explanation:
      "Yup! A person with a bachelor’s degree will typically earn nearly twice as much per year as a person with a high school diploma. In fact, any education beyond high school (two-year, four-year, vocational or military training) will increase your earnings.",
    id: 9,
    answers: [
      {
        type: "A",
        content: "True",
      },
      {
        type: "B",
        content: "False",
      },
    ],
  },
  {
    question:
      "Private colleges are too expensive and you shouldn’t apply if you don’t have the money.",
    correct: "B",
    answer: "False",
    explanation:
      "Not true. Many private colleges give out generous aid to students who apply.",
    id: 10,
    answers: [
      {
        type: "A",
        content: "True",
      },
      {
        type: "B",
        content: "False",
      },
    ],
  },
];

export default quizQuestions;
