import React, { Component } from "react";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import "react-toastify/dist/ReactToastify.css";
import Amplify from "aws-amplify";
import aws_exports from "../../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Icon } from "antd";
const grid = 8;

Amplify.configure(aws_exports);

class PlaybookTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      current: 0,
      rankings: [],
      newItem: "",

      items: [
        "Distance from home",
        "Tuition / Fees",
        "Athletics",
        "Your Major Offered",
        "Student-Faculty Ratio",
        "Physical Size of Campus",
      ],
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  async componentDidMount() {
    this.setState({ rankings: this.props.rankings });
  }

  // fake data generator
  getItems = (count) =>
    Array.from({ length: count }, (v, k) => k).map((k) => ({
      id: `item-${k}`,
      content: `item ${k}`,
    }));

  // a little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 250,
  });

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  addRanking() {
    var ranks = this.props.rankings;
    ranks.unshift(this.state.newItem);
    this.props.handleRankChange(ranks);
  }

  getRanks = (title) => {
    // const {items} = this.state;

    return (
      <div style={{ textAlign: "left" }}>
        <div className="columns">
          <div className="column is-two-fifths">
            <h5 className="title is-5">{title}</h5>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <h6>
              From working through these playbooks, you might start to have some
              ideas about what you're looking for in a college.
            </h6>
            <p>
              Let's try to connect the dots and figure out your priorities. Like
              your goals, your priorities and what you're looking for in a
              college will probably change over time.
            </p>
            <p>
              So first, write down all your priorities for your ideal college.
              Include everything, from deal-breakers to nice-to-haves.
            </p>
            <div className="columns" style={{ margin: "2%" }}>
              <div className="columns is-centered">
                <div className="column is-two-thirds">
                  <div className="card">
                    <div className="card-content">
                      <div className="columns is-centered is-vcentered">
                        <div className="column">
                          <p>
                            <strong>Note: </strong>Make sure to take a note of
                            the factors that you find most important for you
                            when considering a college.
                          </p>
                        </div>
                        <div className="column">
                          <a href="/notes" target="_blank">
                            <div className="button is-link">
                              Open Notes in a new tab
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h6>Must haves</h6>
            <p>
              What are qualities in a school that will help with your long term
              goals? These are "must-haves". An example would be:{" "}
              <i>This school has a great STEM program.</i>
            </p>

            <hr />
            <h6>Would Like</h6>
            <p>
              What qualities in a college would benefit you and make you
              happiest? These are "would-likes". An example would be:&nbsp;
              <i>This school is close to home.</i>
            </p>
            <hr />
            <h6>Tie Breakers</h6>
            <p>
              What qualities about college would make life more interesting or
              fun? These are "tiebreakers". An example would be:{" "}
              <i>I can join a sorority or fraternity.</i>
            </p>
            <hr />
            <h5>Let's rank!</h5>
            <p>
              {" "}
              In this section, we want you to think about the qualities that are
              most important to you in your college search. We've listed an
              example to get you started. You can create a note using the button
              below to rank what's most important to you.
            </p>
            <div className="columns">
              <div className="column">
                <div className="content">
                  <div className="columns">
                    <div className="column">
                      <a href="/notes" target="_blank">
                        <div className="button is-link is-fullwidth">
                          Start a new note
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <p>Here's an example:</p>
                      <div className="card">
                        <div className="card-content">
                          <p>
                            These are factors that matter the most to me in my
                            college search
                          </p>
                          <ol type="1">
                            <li>Distance from home</li>
                            <li>Tuition / Fees</li>
                            <li>Athletics</li>
                            <li>The major I want to study</li>
                            <li>Student-faculty ratio</li>
                            <li>Size of campus</li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="columns">
                  <div className="column">
                    <h6>More factors to consider</h6>
                    <p className="help">
                      {" "}
                      Which of these factors are a must have for you to feel
                      comfortable at a college?
                    </p>
                    <p>
                      <strong>Location</strong> - big city, small college town,
                      suburbs, rural
                    </p>
                    <p>
                      <strong>Size</strong> - fewer than 2,000 students,
                      5,000-15,000 students, 20,000+ students
                    </p>
                    <p>
                      <strong>Campus Living</strong> - everyone lives on campus,
                      greek (frats/sorority) housing available, theme housing,
                      off campus housing, commuting
                    </p>
                    <p>
                      <strong>Distance From Home</strong> - less than two hours
                      a way, a couple hours away, a day roadtrip away, a plane
                      ride away
                    </p>
                    <p>
                      <strong>Social</strong> - social scene on campus, social
                      scene off campus, school spirit sports games, prominent
                      greek life, lots of extracurriculars and clubs, weekend
                      roadtrips
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.props.stepNum === 0 ? (
          <Starting title={this.props.title} />
        ) : this.props.stepNum === 1 ? (
          <Types title={this.props.title} />
        ) : this.props.stepNum === 2 ? (
          <Others title={this.props.title} />
        ) : this.props.stepNum === 3 ? (
          this.getRanks(this.props.title)
        ) : // <Rank
        //                                 title={this.props.title}
        //                                 items={this.state.items}
        //                                 onSortEnd={this.onSortEnd}
        //                                 // handleRankChange={this.props.handleRankChange}
        //                                 rankings={this.props.rankings}
        //                                 addRanking = {this.addRanking} /> :
        this.props.stepNum === 4 ? (
          <ShortList title={this.props.title} />
        ) : this.props.stepNum === 5 ? (
          <Visiting title={this.props.title} />
        ) : this.props.stepNum === 6 ? (
          <Resources title={this.props.title} />
        ) : null}

        {/* <Popover content="sfsdf" title="fasfa">
                                                    <p><strong>Hover me</strong></p>
                                                </Popover>
              playbook 1 */}
      </div>
    );
  }
}

export default PlaybookTwo;

function Starting(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            Deciding where to go to college is a super exciting process.
            However, there are 1000+ four-year colleges in the US so searching
            for the right school for you can easily seem overwhelming.
          </p>
          <p>
            Throughout this playbook, we'll help you identify factors in a
            college that are most important to you. Hopefully at the end of
            this, you'll have the tools you need to complete a final list of
            colleges to apply to.
          </p>

          <h6>Doing Your Research</h6>
          <div className="content">
            <ul>
              <li>
                Review your academic record and your goals/aspirations.
                Throughout this playbook, make{" "}
                <a href="/notes" target="_blank">
                  note
                </a>{" "}
                of the things that are most important to you in a school and
                start to research colleges via the{" "}
                <a href="/schools" target="_blank">
                  college search page
                </a>{" "}
                (or other research resources listed at the end) that might be a
                good fit.
              </li>
              <li>
                Talk to friends and family who have already gone to college
                about what they think of their school. You can also ask them
                about the other colleges they considered while applying.
              </li>
              <li>
                Have colleges in your area? Stop and take a look. Even if it's
                not a school that you plan on applying to, it could still give
                you ideas about what you want (and don't want) in a college.
              </li>
            </ul>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <img
              style={{ marginRight: "2%" }}
              alt=""
              src={require("../../../../../assets/gifs/lateshow.gif")}
            />
            <p className="help">Giphy.</p>
          </div>
        </div>
      </div>

      <div className="columns is-centered is-vcentered">
        <div className="column">
          <h6>A Quick Note on Ivies</h6>
          <p>
            Ivy League schools are just schools that are apart of an American
            collegiate athletic conference in the Northeast of the US. They also
            happen to be schools that are extremely hard to get into.
          </p>
          <p>
            Because of the large number of students who apply and the limited
            number of spaces at each school, any Ivy League on your list should
            be considered a reach unless you have an extraordinary reason
            otherwise.
          </p>
          <p>
            Oftentimes, getting into an Ivy (or just a school with very high
            admissions standards) can be a crapshoot. Students have applied with
            a 1600 SAT score and valedictorian of their graduating class but
            still won't get in. You could be qualified to get in but there's no
            guarantee. This is often the case for even your match and safety
            schools but it's especially true for Ivies.
          </p>
          <p>
            However, that doesn't mean to not apply. Just make sure you're also
            applying to other reach, match, and safety schools outside of the
            Ivies.
          </p>
        </div>
        <div className="column">
          <div className="box" style={{ textAlign: "center" }}>
            <div className="content">
              <div className="columns">
                <div className="column">
                  <ul style={{ listStyle: "none" }}>
                    <strong>Ivy League Schools</strong>
                    <br />
                    <br />

                    <li>Harvard University</li>
                    <li>Brown University</li>
                    <li>Columbia University</li>
                    <li>Cornell University</li>
                    <li>Dartmouth College</li>
                    <li>University of Pennsylvania</li>
                    <li>Princeton University</li>
                    <li>Yale University</li>
                  </ul>
                </div>
                <div className="column">
                  <strong>Selective schools that might as well be Ivies</strong>
                  <br />
                  <ul style={{ listStyle: "none" }}>
                    <li>Stanford University</li>
                    <li>MIT</li>
                    <li>California Institute of Technology</li>
                    <li>University of Chicago</li>
                    <li>Nortwestern University</li>
                    <li>Duke</li>
                    <li>Bowdoin College</li>
                    <p className="help">
                      <strong>& many more</strong>
                    </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Visiting(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            When you have the opportunity or time, visiting schools you're
            interested in can give you a better feel about the campus and the
            environment. Being able to experience the feel of a college can go a
            long way to helping you find the right fit.
          </p>
        </div>
        <div className="column">
          <img
            style={{ marginRight: "2%" }}
            alt=""
            src={require("../../../../../assets/gifs/college.gif")}
          />
          <p className="help">Giphy.</p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            Here are some tips to help you when you start visiting colleges. And
            don't forget to add upcoming visits to your calendar!
          </p>
          <a target="_blank" href="/calendar" className="button is-link">
            Calendar & Deadlines
          </a>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h3>Quick Tips</h3>
          <ol type="1">
            <li>
              <strong>Start planning early</strong> - As you get closer to your
              senior year, you start to get busy with balancing school and a
              social life. To make things easier, be sure to make time for
              visits sooner rather than later. Ideally, you should plan to visit
              schools before your senior year.
            </li>
            <br />
            <li>
              <strong>Budget!</strong> - Visiting schools can easily become
              expensive. Trying to plan for long road trips can be exhausting
              and tiring, especially if the schools you're interested in are far
              away from home. It's a good idea to set a budget for your college
              tours so that you don't end up spending so much money on tours
              that you end up pulling away valuable money that could go towards,
              applications, tuition, and other college necessities.
            </li>
            <br />
            <li>
              <strong>Take virtual tours</strong> - If some schools are too far,
              don't sweat it! Many schools have virtual tours available to
              students through online services. You could even find virtual
              tours of schools on Youtube.
            </li>
            <br />
            <li>
              <strong>Talk to current students</strong> - Talking to current
              students at a college you're interested in is a great way to gain
              perspective on a school. Students can give you their experience
              and opinions on the academics, professors, food, and a bunch of
              other things.
            </li>
            <br />
            <li>
              <strong>Looking into academic departments</strong> - Not only do
              you want to make sure you're comfortable on campus, but you also
              want to explore the departments you're interested in. You can sit
              in on classes and meet professors. It's a great way to get a
              well-rounded perspective of the academics of your school of
              interest.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}

function ShortList(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <p>
        Now that you have an idea of what you're looking for in a college, head
        over to the School Search page to start adding colleges to your
        Favorites and/or Applying list. Adding a school to your favorites list
        does not necessarily mean that you plan to apply but that you're
        interested in the school and the programs it offers.
      </p>

      <p>
        While you're searching for schools, you want to make sure that you're
        being smart about your choices and diversifying your list. What do we
        mean by that? When applying to colleges, you should make sure to apply
        to colleges that are either a <strong>safety, match, and reach</strong>{" "}
        in terms of your academic profile. Let's break this down:
        <div className="content">
          <ul>
            <li>
              <strong>Reach</strong> - This is a school where{" "}
              <strong>you are in the bottom 25%</strong> of the school's average
              range for admitted students from the previous year. Reach schools
              might be a long shot but they still have to be realistic. Also,
              don't let the sticker price of the school scare you! Financial
              need, academic strength, and a college's desire to have you on
              campus can all influence your financial aid award and make the
              cost of attendance more manageable.
            </li>
            <li>
              <strong>Match</strong> - This is a school where{" "}
              <strong>you are in the middle 50%</strong> of the school's average
              range for admitted students from the previous year.
            </li>
            <li>
              <strong>Safety</strong> - This is a school where{" "}
              <strong>you are in the top 25%</strong> of the school's average
              range for admitted students from the previous year. For these
              schools, you'll likely be admitted via an honors program and you
              might receive a generous financial aid package. Like your reach
              and match schools, these should also be colleges you'd be happy to
              attend.
            </li>
          </ul>
        </div>
        You should apply to a wide range of schools with at least 3 being a
        combination of safety and match schools. So, 2 safety + 1 match or 1
        match + 2 safeties, etc. Also, you should make sure to put as much
        though into your safety schools as you do into your match and reach
        schools. No matter how much research you do, you can't predict your
        chances of getting into a school so you should make sure all the schools
        on your list are places you'd be excited to attend.
      </p>
      <a
        target="_blank"
        href="/schools"
        className="button is-link"
        style={{ margin: "2%" }}
      >
        Search for Schools
      </a>

      <p>
        Here are some other sites that you can use in your college search.
        <ul>
          <li>
            <a
              target="_blank"
              href="https://www.collegesimply.com/"
              rel="noopener noreferrer"
            >
              College Simply
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://bigfuture.collegeboard.org/college-search"
            >
              Big Future
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.collegedata.com/en/explore-colleges/college-search/"
            >
              College Data
            </a>
          </li>
        </ul>
      </p>

      <p>
        {" "}
        When you find a college you like, search for the name of the school on
        the{" "}
        <a href="/schools" target="_blank">
          School Search page
        </a>{" "}
        and then click on the <Icon type="heart" /> to add the school to your
        favorites list.
      </p>
    </div>
  );
}

function Resources(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">Assignment</h5>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <ol type="1">
                  <li>
                    Make a{" "}
                    <a href="/notes" target="_blank">
                      note
                    </a>{" "}
                    of at least 5 factors in a school that are most important to
                    you.
                  </li>
                  <li>
                    Add at least 5{" "}
                    <a href="/schools" target="_blank">
                      colleges
                    </a>{" "}
                    to your Favorites list.
                  </li>
                  <li>
                    Add at least 3{" "}
                    <a href="/schools" target="_blank">
                      colleges
                    </a>{" "}
                    to your Applying list. Make sure you have{" "}
                    <strong>a Reach, a Match, and a Safety.</strong> Mark them
                    accordingly.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-two-fifths">
          <img src={require("../../../../../assets/book.svg")} alt="" />
        </div>
      </div>
      <hr />
      <div className="columns">
        <div className="column">
          <div className="content">
            <h5 className="title is-5">Resources</h5>
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://collegefund.org/students/tribal-colleges/"
                >
                  College Fund - Tribal Colleges
                </a>
                <p className="help">Information on Tribal Colleges in the US</p>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://www.collegesimply.com/"
                  rel="noopener noreferrer"
                >
                  College Simply
                </a>
                <p className="help">
                  A helpful website for more school search information
                </p>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://bigfuture.collegeboard.org/college-search"
                >
                  Big Future
                </a>
                <p className="help">
                  A helpful website for more school search information. Big
                  Future also has additional tools that can be helpful for
                  finding the right major and college for you.
                </p>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.collegedata.com/en/explore-colleges/college-search/"
                >
                  College Data
                </a>
                <p className="help">
                  A helpful website for more school search information
                </p>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://en.wikipedia.org/wiki/List_of_honors_programs_and_colleges_in_the_United_States"
                >
                  List of honors programs and colleges in the US
                </a>
                {/* <p className="help">A helpful website for more school search information</p> */}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function Others(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            {" "}
            Besides considering the type of school you'd like to go to, there
            are also other important factors to consider. You want to pick the
            right school for you, so make sure to look into as many things as
            you can!
            {/* Here are some other factors to think about: */}
          </p>
          <figure>
            <div className="columns">
              <div className="column">
                <img
                  style={{ marginRight: "2%" }}
                  alt=""
                  src={require("../../../../../assets/gifs/legallyblind.gif")}
                />
                <p className="help">Giphy.</p>
              </div>
            </div>
          </figure>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>Here are some other things to consider:</p>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <li>
            <strong>College Size</strong> - The size of a school will have a
            large impact on your opportunities and experiences. School sizes can
            determine the majors offered and personal attention you'll receive
            from professors.
          </li>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <li>
            <strong>Location</strong> - Being close or far from home might be an
            important factor for you when deciding on a school. Do you want to
            be able to go home often? Or maybe you want to take this time to go
            outside of the country? Do you prefer city life or a rural area with
            outdoor activities? These are important questions to ask yourself
            when making a decision.
          </li>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <li>
            <strong>Academic Programs</strong> - If you already know what you
            want to study then start by looking for well-respected academic
            departments in that field at the colleges you're interested in. You
            can also talk with professors and students from that department.
            However, try not to limit yourself by basing your self only on one
            academic programs. Many students end up changing their major one or
            more times while in school so look for unique opportunities like
            study abroad, open curriculums, and interesting extracurriculars.
          </li>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <li>
            <strong>Campus Life</strong> - Thinking about your experience on
            campus is also an important consideration. To grow and move outside
            your comfort zone, you'll want to have a balance between academic
            rigor and an active social life. You'll be able to find out what's
            available by looking into your school of choice's extracurriculars,
            club sports, and community groups that are on campus.
          </li>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <li>
            <strong>Cost</strong> - Like we talked about earlier, and as many
            people know, college can be super expensive. The cost of college is
            a huge reason why some students don't go to college at all. However,
            almost all colleges are working hard to make sure that every student
            who is academic qualified can get the financial aid that they need
            to attend college. When you're looking at the cost of a college,
            don't look at the sticker price. A lot of times, with a combination
            of scholarships, grants, and work study, the price for you will
            dramatically decrease. But you should also decide if the educational
            experience you'd receive would be worth the cost.
          </li>
        </div>
      </div>
    </div>
  );
}

function Types(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            There are a bunch of different types of colleges and not all
            colleges are the same. Each college has it's own goals and missions
            but most colleges and universities can be grouped into one or more
            of the following types:{" "}
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <ul>
            <li>
              <strong>Liberal Arts</strong>
              <p>
                These schools focus on giving students a well-rounded experience
                with a focus on the education of undergraduate students. Classes
                are usually smaller and students are typically able to get more
                personal attention from their professors. Instead of gaining
                experience in a specific career, like engineering, students who
                go to liberal arts schools are exposed to many different courses
                in sciences, humanities, and social sciences.
              </p>
              <h6 className="subtitle is-6">
                Examples: Hamilton College, Williams College, Pomona College
              </h6>
            </li>
            <br />

            <li>
              <strong>Universities</strong>
              <p>
                Generally larger and can include a liberal arts college as well
                as professional colleges and graduate programs. Universities
                have more academic choices for students compared to liberal arts
                colleges. Universities will also usually have better library
                resources, research opportunities, and athletic facilities. For
                students at large universities, they will most likely have large
                class sizes, especially in their intro courses. Some of these
                classes could also be taught by graduate students.
              </p>
              <h6 className="subtitle is-6">
                Examples: Umass Amherst, The University of Texas at Austin,
                University of Wisconsin-Madison
              </h6>
              <br />
            </li>

            <li>
              <strong>Community or junior colleges </strong>
              <p>
                These schools usually offer the first two years of a liberal
                arts education or specialized programs. At the end of a two-year
                program, students are awarded an associate degree. After
                finishing a 2 year program, you can choose to continue your
                education at a four-year school. Community colleges are a good
                option if you want to do more exploration into what you're
                interested in. They are also a good option if want to stay
                closer to home or would like to save money on tuition costs.
              </p>
              <h6 className="subtitle is-6">
                Examples: Santa Barbara City College, Northshore Community
                College, De Anza College
              </h6>
              <br />
            </li>
            <li>
              <strong>Technical and Professional Schools</strong>
              <p>
                These schools are great for students who know exactly what they
                want to study. Technical schools are laser-focused on preparing
                students for specific careers like music or engineering.
              </p>
              <h6 className="subtitle is-6">
                Examples: Berklee College of Music, Rhode Island School of
                Design, Olin College of Engineering
              </h6>
              <br />
            </li>

            <li>
              <strong>
                Historically Black Colleges and Universities (HBCU's)
              </strong>
              <p>
                HBCU's were formed in a time when African American students were
                systematically denied access to most colleges and universities.
                Students at these schools can experience an academic community
                where they are part of the majority.
              </p>
              <h6 className="subtitle is-6">
                Examples: Howard University, Florida A&M University, Spelman
                College
              </h6>
              <br />
            </li>
            <li>
              <strong>Tribal Colleges</strong>
              <p>
                Similar to HBCU's but focused on the education and needs of
                American Indian students.
              </p>
              <h6 className="subtitle is-6">
                Examples: Diné College, Bay Mills Community College, Institute
                of American Indian Arts
              </h6>
              <br />
            </li>

            <li>
              <strong>Women’s Colleges</strong>
              <p>
                Women Colleges only admit female-identifying students. They are
                often liberal arts colleges and offer larger numbers of women
                faculty and admin.
              </p>
              <h6 className="subtitle is-6">
                Examples: University of Holy Cross, Wellesley College, Barnard
                College{" "}
              </h6>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
