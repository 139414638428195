import React, { Component } from "react";
import { Layout, Icon } from "antd";

const { Footer } = Layout;

const footerStyle = {
  position: "relative",
  left: "0",
  right: "0",
  bottom: "0",
  marginTop: "100px",
  width: "100%",
  backgroundColor: "white",
  paddingTop: "50px",
};

class FooterPage extends Component {
  render() {
    return (
      <div>
        <Footer style={footerStyle}>
          <div className="columns">
            <div className="column is-one-fifth">
              <div className="columns is-gapless">
                <div className="column">
                  <a
                    href="https://twitter.com/froshbiteapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="twitter" style={{ scale: "2" }} />
                  </a>
                </div>
                <div className="column">
                  <a
                    href="https://www.instagram.com/froshbiteapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon type="instagram" style={{ scale: "2" }} />
                  </a>
                </div>
              </div>
              {/* <img src={logo} style={{float:'left'}} alt="Logo" /> */}
            </div>
            <div className="column is-4 is-offset-6">
              <p style={{ textAlign: "left" }}>
                <ul style={{ listStyleType: "none" }}>
                  <li>
                    © 2020&nbsp;<strong>froshbite</strong>&nbsp;All Rights
                    Reserved.
                  </li>
                  {/* <li> by <a href="https://froshlabs.com">FroshLabs</a><br/></li> */}
                  <li>
                    <a
                      href="mailto:hello@froshbite.com?Subject=Hello%20There!"
                      target="_top"
                    >
                      hello@froshbite.com
                    </a>
                  </li>
                  <br />
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/terms">Terms of Use</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy Policy</a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </Footer>
      </div>
    );
  }
}
export default FooterPage;
