import React, { useState, useEffect } from "react";
import "../../../App.css";
import "bulma/css/bulma.css";
import Emoji from "../components/CollegeList/Emoji";
import {
  Icon,
  Progress,
  Radio,
  Table,
  Select,
  Layout,
  Spin,
  Tooltip,
} from "antd";
import { taskPerct } from "../components/Schools/helpers";
import {
  getPath,
  listApplying,
  applyingSumCols,
  applyingFinCols,
  applyingAdmCols,
  favsSumCols,
} from "../components/CollegeList/helpers";
import {
  getCollegeData,
  numberWithCommas,
  getLocale,
} from "../components/Schools/collegeDataHelpers";
import { getFavsFromCache, getApplyingFromCache } from "../../cacheHelpers";
import CollegeManager from "../components/CollegeList/CollegeManager";
import { Link } from "react-router-dom";
import ListSuggestions from "../components/CollegeList/Suggestions/ListSuggestions";

const { Option } = Select;
const { Content } = Layout;

function Schools() {
  const [applyingList, setApplying] = useState([]);
  const [favsList, setFavs] = useState([]);
  const [currentSchool, setCurrentSchool] = useState(null);
  const [currentSchoolType, setCurrentSchoolType] = useState(0);
  const [currentView, setCurrentView] = useState(0);
  const [applyingData, setApplyingData] = useState(null);
  const [favsData, setFavsData] = useState([]);
  const [listBreakDown, setListBreakDown] = useState({
    reach: 0,
    match: 0,
    safety: 0,
    uncatergorized: 0,
    rating: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function applyingSetup(schools) {
      setApplying(schools);
      setupBreakdown(schools);

      const fields =
        "UNITID,TUITIONFEE_IN,TUITIONFEE_OUT,CITY,ZIP,STABBR,INSTNM,ADM_RATE,SATVR25,SATVR75,SATMT25,SATMT75,ACTCM25,ACTCM75,COSTT4_A";

      var data = [];

      for (let i = 0; i < schools.length; i++) {
        const results = await getCollegeData(schools[i].schoolId, fields);
        data.push(results[0]);
      }

      setApplyingData(data);
      setLoading(false);
    }

    getFavsFromCache().then((data) => favsSetup(data));
    getApplyingFromCache().then((data) => applyingSetup(data));
  }, []);

  function updateApplying() {
    getApplyingFromCache().then((data) => {
      setApplying(data);
      if (currentSchool !== "Overview") {
        const value = data.filter(function (x) {
          return x.id === currentSchool.id;
        })[0];
        setCurrentSchool(value);
      }
    });
  }

  const setupBreakdown = (schools) => {
    const reach = schools.filter((obj) => obj.category === "Reach").length;
    const match = schools.filter((obj) => obj.category === "Match").length;
    const safety = schools.filter((obj) => obj.category === "Safety").length;
    const uncatergorized = schools.filter((obj) => !obj.category).length;

    function rating() {
      if (match === 0 || safety === 0 || safety + match !== 3) {
        return <Emoji symbol="😟" />;
      } else {
        return <Emoji symbol="😄" />;
      }
    }
    const breakdown = {
      reach: reach,
      match: match,
      safety: safety,
      uncatergorized: uncatergorized,
      rating: rating(),
    };
    setListBreakDown(breakdown);
  };

  const favsSetup = async (schools) => {
    setFavs(schools);

    const fields =
      "UNITID,TUITIONFEE_IN,TUITIONFEE_OUT,LOCALE,UGDS,CITY,ZIP,STABBR,INSTNM,ADM_RATE,SATVR25,SATVR75,SATMT25,SATMT75,ACTCM25,ACTCM75,COSTT4_A";

    var data = [];

    for (let i = 0; i < schools.length; i++) {
      const results = await getCollegeData(schools[i].schoolid, fields);
      data.push(results[0]);
    }
    setFavsData(data);
  };

  const getApplyingOptions = applyingList
    .filter(function (obj) {
      return obj !== undefined;
    })
    .map(function (obj) {
      return <option value={obj.id}>{obj.name}</option>;
    });

  function getFavsTableData() {
    var data = [];
    for (let i = 0; i < favsList.length; i++) {
      const getData = favsData.find(
        (o) => o.UNITID === parseInt(favsList[i].schoolid)
      );

      if (getData || getData !== undefined) {
        const app = {
          key: i,
          action: [
            favsList[i].id,
            favsList[i].schoolid,
            favsList[i].name,
            setLoading,
          ],
          college: favsList[i].name,
          location: `${getData["CITY"]}, ${getData["STABBR"]}`,
          rate: `${Math.round(parseFloat(getData["ADM_RATE"]) * 100)}%`,
          sat: `${parseInt(getData["SATVR25"]) + parseInt(getData["SATMT25"])} -
							${parseInt(getData["SATVR75"]) + parseInt(getData["SATMT75"])} `,
          act: `${getData["ACTCM25"]} -
						  ${getData["ACTCM75"]}`,
          net_price: `$${numberWithCommas(parseInt(getData["COSTT4_A"]))}`,
          debt: `$${numberWithCommas(parseInt(getData["GRAD_DEBT_MDN"]))}`,
          students: `${numberWithCommas(parseInt(getData["UGDS"]))} students`,
          area: `${getLocale(parseInt(getData["LOCALE"]))}`,
          tuition: `$${numberWithCommas(
            parseInt(getData["TUITIONFEE_IN"])
          )} / $${numberWithCommas(parseInt(getData["TUITIONFEE_OUT"]))}`,
        };
        data.push(app);
      }
    }
    return data;
  }

  function getApplyingTableData() {
    var data = [];
    for (let i = 0; i < applyingList.length; i++) {
      const getData = applyingData.find(
        (o) => o.UNITID === parseInt(applyingList[i].schoolId)
      );

      if (getData || getData !== undefined) {
        const app = {
          key: i,
          college: applyingList[i].name,
          tasks: <Progress percent={taskPerct(applyingList[i].tasks)} />,
          tags: [applyingList[i].category, applyingList[i].status],
          location: `${getData["CITY"]}, ${getData["STABBR"]}`,
          rate: `${Math.round(parseFloat(getData["ADM_RATE"]) * 100)}%`,
          sat: `${parseInt(getData["SATVR25"]) + parseInt(getData["SATMT25"])} -
							${parseInt(getData["SATVR75"]) + parseInt(getData["SATMT75"])} `,
          act: `${getData["ACTCM25"]} -
						  ${getData["ACTCM75"]}`,
          net_price: `$${numberWithCommas(parseInt(getData["COSTT4_A"]))}`,
          tuition: `$${numberWithCommas(
            parseInt(getData["TUITIONFEE_IN"])
          )} / $${numberWithCommas(parseInt(getData["TUITIONFEE_OUT"]))}`,
        };
        data.push(app);
      }
    }
    return data;
  }

  function handleSchoolChange(e) {
    var value = e.target.value;
    if (value === "Overview") {
      value = null;
    } else {
      value = applyingList.filter(function (x) {
        return x.id === value;
      })[0];
    }
    setCurrentSchool(value);
  }

  function handleViewChange(value) {
    setCurrentView(value);
  }

  function handleSchoolTypeChange(value) {
    setCurrentSchoolType(value.target.value);
  }

  function getColumnData() {
    if (currentSchoolType === 0 && currentView === 0) {
      return applyingSumCols;
    } else if (currentSchoolType === 1 && currentView === 0) {
      return favsSumCols;
    } else if (
      (currentSchoolType === 0 || currentSchoolType === 1) &&
      currentView === 1
    ) {
      return applyingAdmCols;
    } else if (
      (currentSchoolType === 0 || currentSchoolType === 1) &&
      currentView === 2
    ) {
      return applyingFinCols;
    } else {
      return null;
    }
  }

  return (
    <div>
      <div className="columns is-centered is-offset-one-fifth">
        <div className="column is-one-fifth">
          <div className="control">
            <div className="select is-rounded is-fullwidth">
              <select
                onChange={(value) => handleSchoolChange(value)}
                value={currentSchool ? currentSchool.id : null}
                defaultValue={null}
              >
                <option value={null}>Overview</option>
                {getApplyingOptions}
              </select>
            </div>
          </div>
        </div>
        <div className="column is-one-fifth">
          <Link to="/schools">
            <button className="button is-fullwidth">
              <Icon type="plus" style={{ color: "black" }} />
              &nbsp;Add a College
            </button>
          </Link>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-three-quarters">
          <Layout style={{ padding: "2% 0", background: "#fff" }}>
            <Content style={{ marginLeft: "2%", marginRight: "2%" }}>
              {!currentSchool ? (
                <div>
                  <div className="columns">
                    <div className="column">
                      <Radio.Group
                        defaultValue={0}
                        buttonStyle="solid"
                        name="radiolist"
                        onChange={(value) => handleSchoolTypeChange(value)}
                      >
                        <Radio.Button value={0}>
                          <Icon type="unordered-list" />
                          &nbsp;&nbsp;Applying
                        </Radio.Button>
                        <Radio.Button value={1}>
                          <Icon
                            type="heart"
                            theme="twoTone"
                            twoToneColor="#eb2f96"
                          />
                          &nbsp;&nbsp;Favorites
                        </Radio.Button>
                        <Tooltip title="Coming Soon!">
                          <Radio.Button value={2} disabled={true}>
                            <Icon type="bulb" />
                            &nbsp;&nbsp;Suggestions
                          </Radio.Button>
                        </Tooltip>
                      </Radio.Group>
                    </div>
                    <div className="column">
                      <div className="columns">
                        <div className="column">
                          <label>View Colleges By:</label>
                        </div>
                        <div className="column">
                          <Select
                            defaultValue={currentView}
                            value={currentView}
                            style={{ width: "100%" }}
                            onChange={(value) => handleViewChange(value)}
                          >
                            <Option value={0}>Summary</Option>
                            <Option value={1}>Admissions</Option>
                            <Option value={2}>Costs</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {currentSchoolType === 0 ? ( // Breakdown only shown for Applying schools
                    <div className="columns">
                      <div className="column">
                        <nav className="level">
                          <div className="level-item has-text-centered">
                            <div>
                              <p className="heading">Safety</p>
                              <p className="title">{listBreakDown["safety"]}</p>
                            </div>
                          </div>
                          <div className="level-item has-text-centered">
                            <div>
                              <p className="heading">Match</p>
                              <p className="title">{listBreakDown["match"]}</p>
                            </div>
                          </div>
                          <div className="level-item has-text-centered">
                            <div>
                              <p className="heading">Reach</p>
                              <p className="title">{listBreakDown["reach"]}</p>
                            </div>
                          </div>
                          <div className="level-item has-text-centered">
                            <div>
                              <p className="heading">Uncatergorized</p>
                              <p className="title">
                                {listBreakDown["uncatergorized"]}
                              </p>
                            </div>
                          </div>
                          <div className="level-item has-text-centered">
                            <div>
                              <p className="heading">List Grade</p>
                              <p className="title">{listBreakDown["rating"]}</p>
                            </div>
                          </div>
                        </nav>
                      </div>
                    </div>
                  ) : null}
                  <div>
                    {currentSchoolType === 2 ? (
                      <ListSuggestions />
                    ) : applyingData && !loading ? (
                      <Table
                        columns={getColumnData()}
                        dataSource={
                          currentSchoolType === 0
                            ? getApplyingTableData()
                            : currentSchoolType === 1
                            ? getFavsTableData()
                            : null
                        }
                      />
                    ) : (
                      <Spin size="large" />
                    )}
                  </div>
                </div>
              ) : (
                <CollegeManager
                  getPath={getPath}
                  updateApplying={updateApplying}
                  listApplying={listApplying}
                  data={currentSchool}
                  getCollegeData={getCollegeData}
                />
              )}
            </Content>
          </Layout>
        </div>
      </div>
    </div>
  );
}
export default Schools;
