import React, { Component } from "react";
import "./App.css";
import "antd/dist/antd.css";
import ThemeContext from "./components/ThemeContext";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Auth } from "aws-amplify";
// import ReactGA from "react-ga";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "./aws-exports";
import "bulma/css/bulma.css";
import "font-awesome/css/font-awesome.min.css";
import * as queries from "./graphql/queries";
import { Role } from "./components/general/Constants/roles";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { createClient } from "@supabase/supabase-js";
// General pages for all users
import AuthenticatedComp from "./components/AuthenticatedComp";
import ProtectedRoute from "./components/layouts/ProtectedRoute";
import HomePage from "./components/homepage/homepage";
import AboutPage from "./components/homepage/aboutpage";
import NotFoundPage from "./components/homepage/notfound";
import FeaturesPage from "./components/homepage/featurespage";
// import PricingPage from "./components/homepage/pricingpage";
import PrivacyPage from "./components/homepage/privacypage";
import Blog from "./components/homepage/blog";
import Post from "./components/homepage/Blog/post";
import HowItWorks from "./components/homepage/howItWorks";
// import SatPrep from "./components/homepage/programs/satPrep";
import Guidance from "./components/homepage/programs/guidance";
import TermsPage from "./components/homepage/termspage";
import Settings from "./components/settings";
///////////////////////////////////////////////////////////////////

// Students pages
import DashboardComp from "./components/dashboard";
import Notes from "./components/notes";
import Majors from "./components/majors";
import Roadmap from "./components/Students/pages/roadmap";
import AcademicProfile from "./components/Students/pages/academicprofile";
import Calendar from "./components/Students/pages/calendar";
import Schools from "./components/Students/pages/schools";
import MatchMakingSurvey from "./components/Students/pages/matchMakingSurvey";
import MySchools from "./components/Students/pages/myschools";
import Playbooks from "./components/Students/pages/playbooks";
// import Mentoring from "./components/Students/pages/mentoring";
import Scholarships from "./components/Students/pages/scholarships";
import Groups from "./components/Students/pages/groups";
import College from "./components/Students/pages/college";
import Picnic from "./components/Students/components/Groups/picnic";
import Basket from "./components/Students/components/Groups/basket";
import PlaybookContent from "./components/Students/components/Playbooks/playbookContent";
///////////////////////////////////////////////////////////////////

// Mentor pages
import MentorAuth from "./components/layouts/MentorAuth";
import Mentor from "./components/Mentors/pages/mentor";
import MentorGroups from "./components/Mentors/pages/groups";
import MentorStudents from "./components/Mentors/pages/students";
import MentorGroup from "./components/Mentors/pages/group";
import MentorStudent from "./components/Mentors/pages/student";
import MentorCal from "./components/Mentors/pages/schedule";

///////////////////////////////////////////////////////////////////

// Org pages
import OrgAuth from "./components/layouts/OrgAuth";
import Org from "./components/Orgs/pages/org";
import OrgMentors from "./components/Orgs/pages/mentors";
import OrgReports from "./components/Orgs/pages/reports";

///////////////////////////////////////////////////////////////////

// Admin pages
import CreatePost from "./components/homepage/Blog/submitBlog";
import Admin from "./components/Admin/pages/admin";
import AdminAuth from "./components/layouts/AdminAuth";
import AdminBlog from "./components/Admin/pages/blog";
import AdminUsers from "./components/Admin/pages/users";

///////////////////////////////////////////////////////////////////
// Shared pages
import RolesSettings from "./components/layouts/Settings";
import RolesProfile from "./components/layouts/Profile";
// import Reports from "./components/Orgs/pages/reports";

const supabaseUrl = "https://jdcymsopfpnaklhdcigk.supabase.co";
const supabaseKey = process.env.REACT_APP_SUPABASE_FROSH_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

// window.LOG_LEVEL='DEBUG'
Amplify.configure(aws_exports);

// ReactGA.initialize("UA-175990007-1");
// ReactGA.pageview(window.location.pathname + window.location.search);

Sentry.init({
  dsn:
    "https://4135b4b5b9ba4c5cbf7480f23433c779@o456934.ingest.sentry.io/5450488",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.6,
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      isPro: false,
      role: Role.Student,
    };
  }

  async componentDidMount() {
    Amplify.configure({
      ...aws_exports,
      graphql_headers: async () => {
        try {
          const token = (await Auth.currentSession()).idToken.jwtToken;
          return { Authorization: token };
        } catch (e) {
          console.error(e);
          return {};
        }
      },
    });

    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) => this.setState({ currentUser: user }))
      .catch((err) => console.log(err));

    const getUser = await API.graphql(graphqlOperation(queries.listUsers));
    var checkPro = getUser.data.listUsers.items;
    if (checkPro.length !== 0) {
      checkPro = checkPro[0];
      if (checkPro.subscription && checkPro.subscription.startsWith("sub")) {
        this.setState({ isPro: true });
      }
    }

    // set the role of the current user in order to restrict the views
    try {
      const { body } = await supabase
        .from("users")
        .select(
          `
          mentor_id,
          mentors (
            active
          )
        `
        )
        .eq("id", this.state.currentUser.attributes.sub);
      if (body.length === 1 && body[0].mentor_id && body[0].mentors.active) {
        this.setState({ role: Role.Mentor });
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <ThemeContext.Provider value={this.state.isPro} role={this.state.role}>
        <Router>
          <div className="App" style={{ backgroundColor: "#F5F5F5" }}>
            <Switch>
              {/* Public routes */}
              <Route exact path="/" component={HomePage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/privacy" component={PrivacyPage} />
              <Route path="/terms" component={TermsPage} />
              <Route path="/blog" component={Blog} exact />
              <Route path="/how-it-works" component={HowItWorks} exact />
              {/* <Route path="/programs/sat-prep" component={SatPrep} exact /> */}
              <Route path="/blog/create" component={CreatePost} exact />
              <Route path="/features" component={FeaturesPage} exact />
              <Route
                path="/blog/:blogid"
                render={(props) => <Post {...props} />}
              />
              <Route
                path="/programs/college-planning"
                component={Guidance}
                exact
              />

              {/* Student routes */}
              <ProtectedRoute
                path="/dashboard"
                component={Dashboard}
                requiredRole="student"
                userRole={this.state.role}
                exact
              />
              <ProtectedRoute
                path="/notes"
                component={NotesPage}
                requiredRole="student"
                userRole={this.state.role}
                exact
              />
              <ProtectedRoute
                path="/roadmap"
                component={RoadMapPage}
                requiredRole="student"
                userRole={this.state.role}
                exact
              />
              <ProtectedRoute
                path="/match-making"
                component={MatchMakingSurveyPage}
                requiredRole="student"
                userRole={this.state.role}
                exact
              />
              <ProtectedRoute
                path="/scholarships"
                component={ScholarshipPage}
                requiredRole="student"
                userRole={this.state.role}
                exact
              />
              {/* mentoring page (/mentoring) disabled until ready */}
              {/* <ProtectedRoute path="/mentoring" component={MentoringPage} requiredRole="student" userRole={this.state.role} exact/> */}
              <ProtectedRoute
                path="/groups"
                component={GroupsPage}
                requiredRole="student"
                userRole={this.state.role}
                exact
              />

              {/* Mentor routes */}
              <ProtectedRoute
                path="/mentor/dashboard"
                component={MentorPage}
                requiredRole="mentor"
                userRole={this.state.role}
              />
              <ProtectedRoute
                exact
                path="/mentor/groups"
                component={MentorGroupsPage}
                requiredRole="mentor"
                userRole={this.state.role}
              />
              <ProtectedRoute
                exact
                path="/mentor/students"
                component={MentorStudentsPage}
                requiredRole="mentor"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/mentor/schedule"
                component={MentorCalPage}
                requiredRole="mentor"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/mentor/groups/:groupid"
                component={MentorGroupPage}
                requiredRole="mentor"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/mentor/students/:studentid"
                component={MentorStudentPage}
                requiredRole="mentor"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/mentor/settings"
                component={MentorSettingsPage}
                requiredRole="mentor"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/mentor/profile"
                component={MentorProfilePage}
                requiredRole="mentor"
                userRole={this.state.role}
              />

              {/* Org routes */}
              <ProtectedRoute
                path="/org/dashboard"
                component={OrgPage}
                requiredRole="org"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/org/groups/:groupid"
                component={OrgGroupPage}
                requiredRole="org"
                userRole={this.state.role}
              />
              <ProtectedRoute
                exact
                path="/org/students"
                component={OrgStudentsPage}
                requiredRole="org"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/org/students/:studentid"
                component={OrgStudentPage}
                requiredRole="org"
                userRole={this.state.role}
              />
              <ProtectedRoute
                exact
                path="/org/groups"
                component={OrgGroupsPage}
                requiredRole="org"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/org/settings"
                component={OrgSettingsPage}
                requiredRole="org"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/org/profile"
                component={OrgProfilePage}
                requiredRole="org"
                userRole={this.state.role}
              />
              <ProtectedRoute
                exact
                path="/org/mentors"
                component={OrgMentorsPage}
                requiredRole="org"
                userRole={this.state.role}
              />
              <ProtectedRoute
                exact
                path="/org/reports"
                component={OrgReportsPage}
                requiredRole="org"
                userRole={this.state.role}
              />

              {/* Admin routes */}
              <ProtectedRoute
                path="/admin/dashboard"
                component={AdminPage}
                requiredRole="admin"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/admin/blog"
                component={AdminBlogPage}
                requiredRole="admin"
                userRole={this.state.role}
              />
              <ProtectedRoute
                path="/admin/users"
                component={AdminUsersPage}
                requiredRole="admin"
                userRole={this.state.role}
              />

              <Route path="/settings" component={SettingsPage} />
              <Route
                path="/college/"
                render={(props) => <CollegePage {...props} />}
              />
              <Route
                path="/picnic/:groupid"
                render={(props) => (
                  <PicnicPage currentUser={this.state.currentUser} {...props} />
                )}
              />
              <Route
                path="/basket/:groupid"
                render={(props) => (
                  <BasketPage currentUser={this.state.currentUser} {...props} />
                )}
              />
              <Route
                path="/playbooks/:playbookid"
                render={(props) => (
                  <PlaybooksContent
                    currentUser={this.state.currentUser}
                    {...props}
                  />
                )}
              />
              <Route
                path="/majors"
                render={(props) => <MajorsPage {...props} />}
              />
              <Redirect from="/basket/" to="/groups" exact />
              <Redirect from="/picnic/" to="/groups" exact />

              <Route exact path="/profile">
                <ProfilePage />
              </Route>
              <Route exact path="/calendar">
                <CalendarPage />
              </Route>
              <Route exact path="/schools">
                <SchoolsPage />
              </Route>
              <Route exact path="/playbooks">
                <PlaybooksPage />
              </Route>
              <Route path="/list">
                <MySchoolsPage />
              </Route>
              <Route
                exact
                path="/match-making"
                component={MatchMakingSurveyPage}
              />

              <Route path="*" component={NotFoundPage} />
            </Switch>
          </div>
        </Router>
      </ThemeContext.Provider>
    );
  }
}
export default App;

// All pages (NOTE) this needs to change into one component that creates pages
function OrgProfilePage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <RolesProfile {...props} />
      </OrgAuth>
    </div>
  );
}

function OrgReportsPage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <OrgReports {...props} />
      </OrgAuth>
    </div>
  );
}

function OrgMentorsPage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <OrgMentors {...props} />
      </OrgAuth>
    </div>
  );
}

function OrgSettingsPage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <RolesSettings {...props} />
      </OrgAuth>
    </div>
  );
}

function MentorProfilePage(props) {
  return (
    <div>
      <MentorAuth {...props}>
        <RolesProfile {...props} />
      </MentorAuth>
    </div>
  );
}

function OrgPage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <Org {...props} />
      </OrgAuth>
    </div>
  );
}

function OrgGroupPage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <MentorGroup {...props} />
      </OrgAuth>
    </div>
  );
}

function OrgStudentPage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <MentorStudent {...props} />
      </OrgAuth>
    </div>
  );
}

function OrgStudentsPage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <MentorStudents {...props} />
      </OrgAuth>
    </div>
  );
}

function OrgGroupsPage(props) {
  return (
    <div>
      <OrgAuth {...props}>
        <MentorGroups {...props} />
      </OrgAuth>
    </div>
  );
}

function AdminBlogPage(props) {
  return (
    <div>
      <AdminAuth {...props}>
        <AdminBlog {...props} />
      </AdminAuth>
    </div>
  );
}

function AdminUsersPage(props) {
  return (
    <div>
      <AdminAuth {...props}>
        <AdminUsers {...props} />
      </AdminAuth>
    </div>
  );
}

function AdminPage(props) {
  return (
    <div>
      <AdminAuth {...props}>
        <Admin {...props} />
      </AdminAuth>
    </div>
  );
}

function MentorSettingsPage(props) {
  return (
    <div>
      <MentorAuth {...props}>
        <RolesSettings {...props} />
      </MentorAuth>
    </div>
  );
}

function MentorCalPage(props) {
  return (
    <div>
      <MentorAuth {...props}>
        <MentorCal {...props} />
      </MentorAuth>
    </div>
  );
}

function MentorStudentPage(props) {
  return (
    <div>
      <MentorAuth {...props}>
        <MentorStudent {...props} />
      </MentorAuth>
    </div>
  );
}

function MentorStudentsPage(props) {
  return (
    <div>
      <MentorAuth {...props}>
        <MentorStudents {...props} />
      </MentorAuth>
    </div>
  );
}

function MentorGroupPage(props) {
  return (
    <div>
      <MentorAuth {...props}>
        <MentorGroup {...props} />
      </MentorAuth>
    </div>
  );
}

function MentorGroupsPage(props) {
  return (
    <div>
      <MentorAuth {...props}>
        <MentorGroups {...props} />
      </MentorAuth>
    </div>
  );
}

function MentorPage(props) {
  return (
    <div>
      <MentorAuth {...props}>
        <Mentor {...props} />
      </MentorAuth>
    </div>
  );
}

function Dashboard(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <DashboardComp {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function NotesPage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <Notes {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function SchoolsPage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <Schools {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function CalendarPage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <Calendar {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function SettingsPage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <Settings {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function ScholarshipPage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <Scholarships {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function RoadMapPage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <Roadmap {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function ProfilePage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <AcademicProfile {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function PlaybooksPage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <Playbooks {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function MySchoolsPage(props) {
  return (
    <div>
      <AuthenticatedComp {...props}>
        <MySchools {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function GroupsPage(props) {
  return (
    <div>
      <AuthenticatedComp>
        <Groups {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function CollegePage(props) {
  return (
    <div>
      <AuthenticatedComp>
        <College {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function PicnicPage(props) {
  return (
    <div>
      <AuthenticatedComp>
        <Picnic {...props} />
      </AuthenticatedComp>
    </div>
  );
}

// function MentoringPage(props) {
//   return (
//     <div>
//       <AuthenticatedComp>
//         <Mentoring {...props} />
//       </AuthenticatedComp>
//     </div>
//   );
// }
function BasketPage(props) {
  return (
    <div>
      <AuthenticatedComp>
        <Basket {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function MajorsPage(props) {
  return (
    <div>
      <AuthenticatedComp>
        <Majors {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function PlaybooksContent(props) {
  return (
    <div>
      <AuthenticatedComp>
        <PlaybookContent {...props} />
      </AuthenticatedComp>
    </div>
  );
}

function MatchMakingSurveyPage(props) {
  return (
    <div>
      <AuthenticatedComp>
        <MatchMakingSurvey {...props} />
      </AuthenticatedComp>
    </div>
  );
}
