import React from "react";
import "bulma/css/bulma.css";
import { Table, Descriptions, Icon, Tooltip, Tag } from "antd";
import { addScholarship } from "./helpers";

function Search(props) {
  const data = [
    {
      key: "1",
      name: "National Scholarship",
      link: "https://ant.design/components/table/",
      amount: "$5000",
      deadline: "10/20/2020",
      description:
        "Current undergraduate women in Nevada are eligible to apply for this scholarship",
      requirements: "Must be 13+ or older",
      additional: "Here's some more random info",
      tags: ["Bachelors"],
      contact: "hello@nationalscholar.org",
      org: "National org",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <span>
          {tags.map((tag) => {
            let color = tag.length > 5 ? "geekblue" : "green";
            if (tag === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      key: "deadline",
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Tooltip title="Add to My Scholarships">
          <span>
            <Icon
              onClick={() => {
                addTableScholarship(record);
              }}
              style={{ scale: "1.5" }}
              type="plus-circle"
            />
          </span>
        </Tooltip>
      ),
    },
  ];

  const addTableScholarship = (record) => {
    const scholarship = {
      name: record.name,
      appSent: false,
      refSent: false,
      tranSent: false,
      recieved: false,
      deadline: record.deadline,
      org: record.org,
      essay: "",
      amount: record.amount,
      contact: record.contact,
      criteria: record.requirements,
      documents: record.additional,
      note: record.description,
      source: record.link,
    };
    props.setLoading(true);
    addScholarship(scholarship);
  };

  return (
    <div>
      <div className="columns">
        <div className="column">
          <Table
            columns={columns}
            expandedRowRender={(record) => (
              <div className="columns">
                <div className="column">
                  <Descriptions title={record.name}>
                    <Descriptions.Item label="Description" span={3}>
                      {record.description}
                    </Descriptions.Item>
                    <Descriptions.Item label="Organization">
                      {record.org}
                    </Descriptions.Item>
                    <Descriptions.Item label="Requirements">
                      {record.requirements}
                    </Descriptions.Item>
                    <Descriptions.Item label="Additional">
                      {record.additional}
                    </Descriptions.Item>
                    <Descriptions.Item label="Link">
                      <a
                        href={record.link}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {record.link}
                      </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Contact">
                      <a href={"mailto:" + record.contact}>{record.contact}</a>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
            )}
            dataSource={data}
          />
        </div>
      </div>
    </div>
  );
}
export default Search;
