import React, { Component } from "react";
import "../../App.css";
import "bulma/css/bulma.css";
import NavBar from "./navbar";
import { Typography, Icon, message, Alert } from "antd";
import plannerImage2 from "../../assets/landingpage/planner2.png";
import playbooksImage from "../../assets/landingpage/updatedPlays.png";
import joyImage from "../../assets/landingpage/joy.svg";
import groupsImage from "../../assets/landingpage/chat.png";
import { ID } from "../Students/components/Schools/helpers";
import FooterPage from "./footer";
import { createClient } from "@supabase/supabase-js";
import everydaeLogo from "../../assets/logos/partners/EverydaeBlue.png";
import junoLogo from "../../assets/logos/partners/juno.png";
import students2Students from "../../assets/logos/partners/s2s.png";

import Amplify from "aws-amplify";
import aws_exports from "../../aws-exports"; // specify the location of aws-exports.js file on your project

// import { Authenticator } from 'aws-amplify-react/dist/Auth';
const supabaseUrl = "https://rnpcsynkfxzorrreypqf.supabase.co";
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const { Title, Text } = Typography;
Amplify.configure(aws_exports);

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailSubmitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  validateEmail = () => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
      this.submitEmail();
    } else {
      message.error("Please enter a valid email.");
    }
  };

  async submitEmail() {
    const user = { email: this.state.email };
    // Submit email to DB
    await supabase.from("newsletter").insert([
      {
        id: ID(),
        email: user.email,
        inserted_at: new Date(),
        updated_at: new Date(),
      },
    ]);

    this.setState({ emailSubmitted: true, email: "" });
  }

  render() {
    return (
      <div>
        <NavBar />

        <div
          style={{ backgroundColor: "white" }}
          className="columns is-centered is-vcentered"
        >
          <div style={{ padding: "10%" }} className="column is-half">
            <div className="columns">
              <Title
                style={{ fontSize: 40, fontWeight: 900, textAlign: "left" }}
              >
                {/* Making planning for college suck less. */}
                College planning just got easier.
                {/* Your college planning command center */}
              </Title>
            </div>
            <div className="columns">
              <h5 style={{ textAlign: "left" }} className="subtitle is-5">
                The ultimate tool to help you understand, track, and organize
                the college search and application process. <br />
                <br />
                Plan with friends, take notes, search for colleges, manage
                application tasks, and more -{" "}
                <Text strong mark>
                  all in one place.
                </Text>
              </h5>
            </div>

            {/* <div className="button is-link"> Get Started For Free</div> */}
          </div>
          <div className="column">
            <div className="container">
              <figure className="image is-16by9">
                <img
                  className="has-ratio"
                  width="640"
                  height="360"
                  src={joyImage}
                  alt="Organizer"
                />
              </figure>
            </div>
          </div>
        </div>

        <div className="columns is-centered is-vcentered">
          <div className="column is-half">
            <div className="container">
              <figure className="image ">
                <img
                  style={{ width: "100%" }}
                  src={playbooksImage}
                  alt="Organizer"
                />
              </figure>
            </div>
          </div>
          <div className="column" style={{ margin: "10%" }}>
            <Title style={{ fontSize: 20, fontWeight: 900 }}>
              Understanding college planning shouldn't be hard. We're making it
              easy for you.
              {/* Your college planning command center */}
            </Title>
            <br />
            <p>
              The Playbooks are meant to guide you through each of the college
              planning steps, from your freshman to senior year. By the end,
              you'll have the foundations you need to make an informed decision.
            </p>
          </div>
        </div>

        <div className="columns is-centered is-vcentered">
          <div className="column" style={{ margin: "10%" }}>
            <Title style={{ fontSize: 20, fontWeight: 900 }}>
              Getting organized in the college planning process is your key to
              success.
            </Title>
            <br />
            <p>
              Froshbite is the one place where you can store your thoughts and
              tasks for each school you plan on applying to. From college
              organizers to scholarship organizers to notes and school year
              tasks, we have you covered and we'll keep you on track.
            </p>
          </div>
          <div className="column">
            <div className="container">
              <figure className="image ">
                <img
                  style={{ width: "100%" }}
                  src={plannerImage2}
                  alt="Groups"
                />
              </figure>
            </div>
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-half">
            <div className="container">
              <figure className="image ">
                <img
                  style={{ width: "100%" }}
                  src={groupsImage}
                  alt="Organizer"
                />
              </figure>
            </div>
          </div>
          <div className="column" style={{ margin: "10%" }}>
            <Title style={{ fontSize: 20, fontWeight: 900 }}>
              It's more fun with friends!
            </Title>
            <br />
            <p>
              Trust us, planning for college is way more fun when you're doing
              it with friends.{" "}
            </p>
            <p>
              On froshbite, you can create a Group and chat with your friends
              about the college planning process. Your Group will also have a
              shared basket where each member can add task, college, and
              scholarship recommendations.
            </p>
          </div>
        </div>

        <div className="content">
          <div
            className="columns is-centered is-vcentered"
            style={{
              padding: "5%",
              backgroundColor: "white",
              margin: "5%",
              overflow: "hidden",
            }}
          >
            <div className="column">
              <Title style={{ fontSize: 30 }} level={2}>
                Our Partners
              </Title>
              <p className="help">
                Want to work with us to find a better way to serve students?
                Send us an email at a{" "}
                <a href="mailto:hello@froshbite.com">hello@froshbite.com</a>.
              </p>
            </div>
            <div className="column">
              <figure className="image">
                <a
                  href="https://www.everydae.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    // style={{ width: "100%" }}
                    src={everydaeLogo}
                    alt="Everydae Logo"
                  />
                </a>
              </figure>
            </div>
            <div className="column">
              <figure className="image">
                <a
                  href="https://joinjuno.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "100%" }}
                    src={junoLogo}
                    alt="Juno Logo"
                  />
                </a>
              </figure>
            </div>
            <div className="column">
              <figure className="image">
                <a
                  href="https://www.students2students4u.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ width: "100%" }}
                    src={students2Students}
                    alt="Students 2 Students Logo"
                  />
                </a>
              </figure>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}
        </div>

        <div
          className="columns is-centered"
          style={{ margin: "5%", marginTop: "2%" }}
        >
          <div className="column is-half">
            <Title style={{ fontSize: 40, fontWeight: 900 }}>
              Why Froshbite?
            </Title>
            <div className="columns">
              <div className="column">
                <ul style={{ listStyle: "none", textAlign: "left" }}>
                  <li>
                    <div className="columns">
                      <div className="column is-1">
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                          style={{ scale: "2" }}
                        />
                      </div>
                      <div className="column">
                        <p>
                          <h4>
                            You'll{" "}
                            <strong>discover all the tools you need</strong> to
                            conquer the college planning process
                          </h4>
                          <br />
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="columns">
                      <div className="column is-1">
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                          style={{ scale: "2" }}
                        />
                      </div>
                      <div className="column">
                        <p>
                          <h4>
                            <strong>Save your time and energy</strong> by
                            tracking all your tasks, deadlines, files, notes and
                            thoughts in one place
                          </h4>
                          <br />
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="columns">
                      <div className="column is-1">
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                          style={{ scale: "2" }}
                        />
                      </div>
                      <div className="column">
                        <p>
                          <h4>
                            <strong>
                              No more forgetting what you have to get done
                            </strong>{" "}
                            for the school year or for your applications
                          </h4>
                          <br />
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="columns">
                      <div className="column is-1">
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                          style={{ scale: "2" }}
                        />
                      </div>
                      <div className="column">
                        <p>
                          <h4>
                            You’ll figure out how to{" "}
                            <strong>navigate your college goals</strong>,
                            understand each step to get there, and be able to
                            take organized and effective action
                          </h4>
                          <br />
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="columns">
                      <div className="column is-1">
                        <Icon
                          type="check-circle"
                          theme="twoTone"
                          twoToneColor="#52c41a"
                          style={{ scale: "2" }}
                        />
                      </div>
                      <div className="column">
                        <p>
                          <h4>
                            <strong>
                              Spend less time stressing about understanding and
                              organizing the college planning process
                            </strong>{" "}
                            and more time doing what you love
                          </h4>
                          <br />
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="columns is-centered">
                      <div className="column is-half">
                        <a href="/dashboard">
                          <div className="button is-link is-fullwidth">
                            {" "}
                            Get Started
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-half">
            <div className="columns">
              <div className="column">
                <h5>
                  Stay up to date with all things <strong>Froshbite.</strong>
                </h5>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                {!this.state.emailSubmitted ? (
                  <div className="field has-addons has-addons-right">
                    <p className="control is-expanded">
                      <input
                        className="input is-rounded"
                        type="email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        placeholder="Your email"
                      />
                    </p>
                    <p className="control">
                      <div
                        className="button is-link is-rounded is-focus"
                        onClick={this.validateEmail}
                      >
                        Subscribe &nbsp; <Icon type="double-right" />
                      </div>
                    </p>
                  </div>
                ) : (
                  <Alert
                    message="Success! Email submitted."
                    type="success"
                    showIcon
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <FooterPage />
      </div>
    );
  }
}
export default HomePage;
