import React from "react";
import "bulma/css/bulma.css";
import { Typography, Calendar, Card } from "antd";

const { Title } = Typography;

function onPanelChange(value, mode) {
  console.log(value, mode);
}

function MentorCal() {
  return (
    <div>
      <div className="columns">
        <div className="column">
          <Title style={{ textAlign: "center" }} level={1}>
            Schedule
          </Title>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <div className="box">
            <div className="columns">
              <div className="column is-half">
                <div
                  style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: 4,
                  }}
                >
                  <Calendar fullscreen={false} onPanelChange={onPanelChange} />
                </div>
              </div>
              <div className="column is-half">
                <Card>
                  <p>Sessions for April 5th</p>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MentorCal;
