import React, { Component } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import logo from "../../assets/logos/froshbite-logo/cover.png";
import { Auth } from "aws-amplify";

class NavBar extends Component {
  state = {
    isActive: false,
    setisActive: false,
  };

  handleClick = () => {
    let getStatus = this.state.isActive;
    this.setState({ isActive: !getStatus });
  };

  SignOut() {
    Auth.signOut()
      .then((data) => console.log(data))
      .catch((err) => console.log(err));

    // By doing this, you are revoking all the auth tokens(id token, access token and refresh token)
    // which means the user is signed out from all the devices
    // Note: although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
    // Auth.signOut({ global: true })
    //     .then(data => console.log(data))
    //     .catch(err => console.log(err));
  }

  render() {
    return (
      <div>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img
                className="navbar-logo"
                src={logo}
                style={{ scale: "1.5", padding: "20%" }}
                alt="Logo"
              />
            </a>

            <div
              role="button"
              className={`navbar-burger burger ${
                this.state.isActive ? "is-active" : null
              }`}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={() => {
                this.handleClick();
              }}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>

          <div
            id="navbarBasicExample"
            className={`navbar-menu ${
              this.state.isActive ? "is-active" : false
            }`}
          >
            <div className="navbar-start">
              <a href="/" className="navbar-item">
                Home
              </a>

              <div className="navbar-item has-dropdown is-hoverable">
                <div className="navbar-link">More</div>

                <div className="navbar-dropdown">
                  <a href="/features" className="navbar-item">
                    Features
                  </a>
                  {/* <a href="/services" className="navbar-item">
                    Pricing & Plans
                  </a> */}
                  <a href="/about" className="navbar-item">
                    About Us
                  </a>
                  <a href="/blog" className="navbar-item">
                    Blog
                  </a>
                  <hr className="navbar-divider" />
                  <a href="mailto: hello@froshbite.com" className="navbar-item">
                    Contact
                  </a>
                </div>
              </div>
            </div>

            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <a href="/dashboard" className="button is-link">
                    <strong>Log In</strong>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
export default NavBar;
