import React, { Component } from "react";
import "bulma/css/bulma.css";
import researchImage from "../assets/research.svg";
import helloImage from "../assets/hello.svg";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./components.css";
import { Typography, Icon, Statistic, Alert, Spin } from "antd";
import { Link } from "react-router-dom";

import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../aws-exports"; // specify the location of aws-exports.js file on your project
import Onboarding from "../components/general/Onboarding/onboarding";

import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import {
  handleFavDelete,
  addToApplying,
  getPath,
  getCollegeApp,
  taskPerct,
} from "./Students/components/Schools/helpers";

Amplify.configure(aws_exports);

const { Title } = Typography;

const cardinnerDashboardtop = {
  marginTop: "20px",
  borderRadius: "10px 10px 10px 10px",
};

const cardmaybeDashboardtop = {
  marginTop: "20px",
  // marginRight: '20px',
  borderRadius: "10px 10px 10px 10px",
  height: "100%",
};

const sectionRounded = {
  backgroundColor: "white",
  boxShadow: " 0 20px 20px -20px rgba(0,0,0,.2)",
};

class DashboardComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favList: [],
      favToken: "",
      mutatingFavs: false,
      fullyApplying: [],
      matches: 0,
      reaches: 0,
      safeties: 0,
      playbookProgress: 4,
      onboarding: [],
      playbooks: true,
      tasks: false,
      favs: false,
      applying: false,
      locker: false,
      loaded: false,
    };
  }

  async componentDidMount() {
    this.listFavs();
    this.listApplying();
    this.listOnboarding();

    const play1 = await API.graphql(graphqlOperation(queries.listPlaybook1s));
    const play2 = await API.graphql(graphqlOperation(queries.listPlaybook2s));
    const play3 = await API.graphql(graphqlOperation(queries.listPlaybook3s));
    const play4 = await API.graphql(graphqlOperation(queries.listPlaybook4s));
    var playProgress = this.state.playbookProgress;

    if (play1.data.listPlaybook1s.items.length === 0) {
      playProgress = playProgress - 1;
    } else {
      if (play1.data.listPlaybook1s.items[0].steps.includes(false)) {
        playProgress = playProgress - 1;
      }
    }

    if (play2.data.listPlaybook2s.items.length === 0) {
      playProgress = playProgress - 1;
    } else {
      if (play2.data.listPlaybook2s.items[0].steps.includes(false)) {
        playProgress = playProgress - 1;
      }
    }

    if (play3.data.listPlaybook3s.items.length === 0) {
      playProgress = playProgress - 1;
    } else {
      if (play3.data.listPlaybook3s.items[0].steps.includes(false)) {
        playProgress = playProgress - 1;
      }
    }

    if (play4.data.listPlaybook4s.items.length === 0) {
      playProgress = playProgress - 1;
    } else {
      if (play4.data.listPlaybook4s.items[0].steps.includes(false)) {
        playProgress = playProgress - 1;
      }
    }

    playProgress = Math.round((playProgress / 4) * 100);
    this.setState({ playbookProgress: playProgress, loaded: true });
  }

  deleteFav = (id) => {
    this.setState({ mutatingFavs: true }, () => {
      handleFavDelete(id).then((data) =>
        this.listFavs().then((data) => this.setState({ mutatingFavs: false }))
      );
    });
  };

  addFav = (id, name) => {
    this.setState({ mutatingFavs: true }, () => {
      addToApplying(id, name).then((data) =>
        this.setState({ mutatingFavs: false })
      );
    });
  };

  async listFavs() {
    const favsData = await API.graphql(
      graphqlOperation(queries.listFavs, { limit: 100 })
    );
    this.setState({
      favList: favsData.data.listFavs.items,
      favToken: favsData.data.listFavs.nextToken,
    });
  }

  async listOnboarding() {
    const onboarding = await API.graphql(
      graphqlOperation(queries.listOnboardings)
    );
    if (onboarding.data.listOnboardings.items.length === 0) {
      const input = {
        playbooks: false,
        tasks: false,
        favs: false,
        applying: false,
        locker: false,
      };
      try {
        const createOnboarding = await API.graphql(
          graphqlOperation(mutations.createOnboarding, { input: input })
        );
        this.setState({
          onboarding: createOnboarding.data.createOnboarding,
          playbooks: createOnboarding.data.createOnboarding.playbooks,
          tasks: createOnboarding.data.createOnboarding.tasks,
          favs: createOnboarding.data.createOnboarding.favs,
          applying: createOnboarding.data.createOnboarding.applying,
          locker: createOnboarding.data.createOnboarding.locker,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        onboarding: onboarding.data.listOnboardings.items[0],
        tasks: onboarding.data.listOnboardings.items[0].tasks,
        playbooks: onboarding.data.listOnboardings.items[0].playbooks,
        favs: onboarding.data.listOnboardings.items[0].favs,
        applying: onboarding.data.listOnboardings.items[0].applying,
        locker: onboarding.data.listOnboardings.items[0].locker,
      });
    }
  }

  getCategory(cat) {
    if (cat === "Reach") {
      var currReach = this.state.reaches;
      currReach = currReach + 1;
      this.setState({ reaches: currReach });
    } else if (cat === "Match") {
      var currMatch = this.state.matches;
      currMatch = currMatch + 1;
      this.setState({ matches: currMatch });
    } else if (cat === "Safety") {
      var currSafety = this.state.safeties;
      currSafety = currSafety + 1;
      this.setState({ safeties: currSafety });
    }
  }

  async listApplying() {
    const applyingData = await API.graphql(
      graphqlOperation(queries.listCollegeApps)
    );
    var fullyApplying = [];
    for (var i = 0; i < applyingData.data.listCollegeApps.items.length; i++) {
      var school = applyingData.data.listCollegeApps.items[i];
      this.getCategory(school.category);
      await this.getFullSchool(school.id)
        .then((data) => fullyApplying.push(data.data.getCollegeApp))
        .catch((err) => console.log(err));
    }
    this.setState({ fullyApplying: fullyApplying });
  }

  async getFullSchool(id) {
    const school = { id: id };
    const getSchool = await API.graphql(
      graphqlOperation(getCollegeApp, school)
    );
    return getSchool;
  }

  render() {
    const favs = this.state.favList.map((item) => (
      <tr>
        <th>
          <Link
            to={{
              pathname: getPath(item["name"], "college/"),
              state: {
                id: item["schoolid"],
                name: item["name"],
              },
            }}
          >
            {item.name}
          </Link>
        </th>
        <td>
          <div className="columns">
            <div className="column is-one-half">
              <button
                onClick={this.deleteFav.bind(this, item.id)}
                className="button is-small is-rounded"
              >
                Remove
              </button>
            </div>
            <div className="column is-one-half">
              <button
                onClick={this.addFav.bind(this, item.schoolid, item.name)}
                className="button is-small is-text is-rounded"
              >
                Add to Applying
              </button>
            </div>
          </div>
        </td>
      </tr>
    ));

    const applying = this.state.fullyApplying.slice(0, 2).map((item) => (
      <div className="column">
        <div className="card" style={cardinnerDashboardtop}>
          <div className="card-content">
            <div className="content">
              <div className="columns">
                <div className="column">
                  <Title level={4}>{item.name}</Title>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  {item.category === "Reach" ? (
                    <span className="tag is-danger is-medium">Reach</span>
                  ) : item.category === "Match" ? (
                    <span className="tag is-warning is-medium">Match</span>
                  ) : item.category === "Safety" ? (
                    <span className="tag is-success is-medium">Safety</span>
                  ) : (
                    <span className="tag is-info is-medium">
                      Unknown Academic Fit
                    </span>
                  )}
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <progress
                    style={{ marginTop: "5%" }}
                    className="progress is-success"
                    value={taskPerct(item.tasks)}
                    max="100"
                  />
                </div>
              </div>
            </div>
          </div>
          <footer className="card-footer">
            <div className="card-footer-item">
              <Link
                to={{
                  pathname: getPath(item["name"], "college/"),
                  state: {
                    id: item["schoolId"],
                    name: item["name"],
                  },
                }}
              >
                More Info
              </Link>
            </div>
            <div className="card-footer-item">
              <Link
                to={{
                  pathname: "/list",
                }}
              >
                Manage
              </Link>
            </div>
          </footer>
        </div>
      </div>
    ));

    return (
      <div>
        <div className="columns is-centered">
          <div className="column is-three-quarters">
            <Title style={{ textAlign: "center" }} level={1}>
              Dashboard
            </Title>

            {!this.state.loaded ? (
              <Spin size="large" />
            ) : !this.state.onboarding.onboarded ? (
              <Onboarding />
            ) : (
              <div>
                <Title
                  style={{
                    color: "#A9A9A9",
                    textAlign: "left",
                    fontWeight: 900,
                  }}
                  level={3}
                >
                  The Essentials
                </Title>

                <div className="columns is-centered is-vcentered">
                  <div className="column">
                    <section style={sectionRounded} className="hero is-primary">
                      <div className="hero-body">
                        <div className="container">
                          <div className="columns">
                            <div className="column">
                              <p style={{ color: "#696969", fontWeight: 900 }}>
                                Playbooks Progress
                              </p>
                              <progress
                                className="progress is-success"
                                value={this.state.playbookProgress}
                                max="100"
                              ></progress>
                            </div>
                          </div>
                          <div className="columns is-centered is-vcentered">
                            <div className="column">
                              <div className="columns">
                                {this.state.playbookProgress !== 100 ? (
                                  <div className="column is-10">
                                    <div className="columns">
                                      <div className="column">
                                        <p
                                          style={{
                                            color: "black",
                                            fontWeight: 600,
                                          }}
                                        >
                                          You still have some Playbooks to
                                          complete!
                                        </p>
                                      </div>
                                    </div>

                                    <div className="columns">
                                      <div className="column">
                                        <Alert
                                          message="The Playbooks are here to help guide you in the 
                                                            college planning process and provide you with the information you need to make a decision best for you. You can continue working through 
                                                            the Playbooks by clicking the button below."
                                          type="info"
                                          showIcon
                                        />
                                      </div>
                                    </div>

                                    <div className="columns">
                                      <div className="column">
                                        <Link to="/playbooks">
                                          <button className="button is-small is-link is-fullwidth">
                                            <Icon type="right-circle" /> &nbsp;
                                            Continue Learning
                                          </button>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="column is-10">
                                    <div className="columns">
                                      <div className="column">
                                        <p
                                          style={{
                                            color: "black",
                                            fontWeight: 600,
                                          }}
                                        >
                                          Awesome! All Playbooks have been
                                          completed.
                                        </p>
                                      </div>
                                    </div>

                                    <div className="columns">
                                      <div className="column">
                                        <Alert
                                          message="Nice job completing the Playbooks! Now you're ready to start your college planning. Start by adding some colleges to your list."
                                          type="success"
                                          showIcon
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="column is-narrow">
                              <img src={helloImage} alt="Logo" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <div className="columns" style={{ marginTop: "3%" }}>
                  <div className="column is-one-third">
                    <Title
                      style={{
                        color: "#B0B0B0",
                        textAlign: "left",
                        fontWeight: 900,
                      }}
                      level={3}
                    >
                      My Applying List{" "}
                    </Title>
                  </div>
                </div>

                <div className="columns is-centered">
                  {this.state.fullyApplying.length === 0 ? (
                    <div className="box" style={{ padding: "5%" }}>
                      <strong>
                        There are no colleges on your applying list.
                      </strong>{" "}
                    </div>
                  ) : (
                    applying
                  )}
                </div>

                <div className="columns" style={{ marginTop: "3%" }}>
                  <div className="column is-half">
                    <div style={{ marginBottom: "15px" }}>
                      <Title
                        style={{
                          color: "#B0B0B0",
                          textAlign: "left",
                          fontWeight: 900,
                        }}
                        level={3}
                      >
                        Progress
                      </Title>

                      <div className="card" style={cardmaybeDashboardtop}>
                        <div className="card-content">
                          <div className="content">
                            <p style={{ color: "#696969", fontWeight: 900 }}>
                              School List Progress
                            </p>
                            <progress
                              className="progress is-link"
                              value={Math.round(
                                ((this.state.reaches +
                                  this.state.matches +
                                  this.state.safeties) /
                                  5) *
                                  100
                              )}
                              max="100"
                            />
                            <div className="columns">
                              <div className="column">
                                <Statistic
                                  style={{ margin: "20px" }}
                                  title="REACH"
                                  value={this.state.reaches}
                                  suffix="/ 2"
                                />
                              </div>
                              <div className="column">
                                <Statistic
                                  style={{ margin: "20px" }}
                                  title="MATCH"
                                  value={this.state.matches}
                                  suffix="/ 1"
                                />
                              </div>
                              <div className="column">
                                <Statistic
                                  style={{ margin: "20px" }}
                                  title="SAFETY"
                                  value={this.state.safeties}
                                  suffix="/ 2"
                                />
                              </div>
                            </div>
                            <div className="columns is-centered">
                              <div className="column">
                                <img
                                  className="center"
                                  src={researchImage}
                                  alt="Logo"
                                />
                              </div>
                            </div>
                            <div className="columns">
                              <div className="column">
                                <div>
                                  <Link to="/schools">
                                    <button className="button is-link is-fullwidth">
                                      Search for Schools
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column is-half">
                    <div style={{ marginBottom: "15px" }}>
                      <Title
                        style={{
                          color: "#B0B0B0",
                          textAlign: "left",
                          fontWeight: 900,
                        }}
                        level={3}
                      >
                        Favorites
                      </Title>
                      <div className="card" style={cardmaybeDashboardtop}>
                        <div className="card-content">
                          <div className="content">
                            {this.state.mutatingFavs ? (
                              <Spin />
                            ) : (
                              <div className="table-container">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>School</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.favList.length === 0 ? (
                                      <div style={{ marginTop: "5%" }}>
                                        <strong>
                                          There are no favorites on your list.
                                        </strong>{" "}
                                      </div>
                                    ) : (
                                      favs
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default DashboardComp;
