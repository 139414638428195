import React, { Component } from "react";
import "../App.css";
import "bulma/css/bulma.css";
import { majorsData } from "../data/recentgrads";
import {
  Typography,
  Statistic,
  Card,
  Tag,
  Progress,
  Collapse,
  Icon,
} from "antd";
const { Panel } = Collapse;
const { Title } = Typography;
const { CheckableTag } = Tag;
const tagsFromServer = [
  "Engineering",
  "Business",
  "Education",
  "Humanities & Liberal Arts",
  "Biology & Life Science",
  "Computers & Mathematics",
  "Physical Sciences",
  "Health",
  "Industrial Arts & Consumer Services",
  "Social Science",
  "Arts",
  "Agriculture & Natural Resources",
  "Psychology & Social Work",
  "Communications & Journalism",
  "Law & Public Policy",
];
const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
};

class Majors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      majors: [],
      selectedTags: [],
    };
  }

  async componentDidMount() {
    this.setState({ majors: this.shuffleArray(majorsData) });
  }

  shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  handleChange(tag, checked) {
    const { selectedTags } = this.state;
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);

    var newMajors = majorsData.filter(function (major) {
      return nextSelectedTags.includes(major["Major_category"]);
    });

    if (nextSelectedTags.length === 0) {
      newMajors = majorsData;
    }

    this.setState({ selectedTags: nextSelectedTags, majors: newMajors });
  }

  getMajorCatergory(cat) {
    if (cat === "Engineering") {
      return <Tag color="magenta">Engineering</Tag>;
    } else if (cat === "Business") {
      return <Tag color="green">Business</Tag>;
    } else if (cat === "Education") {
      return <Tag color="purple">Education</Tag>;
    } else if (cat === "Humanities & Liberal Arts") {
      return <Tag color="geekblue">Humanities & Liberal Arts</Tag>;
    } else if (cat === "Biology & Life Science") {
      return <Tag color="orange">Biology & Life Science</Tag>;
    } else if (cat === "Computers & Mathematics") {
      return <Tag color="volcano">Computers & Mathematics</Tag>;
    } else if (cat === "Physical Sciences") {
      return <Tag color="cyan">Physical Sciences</Tag>;
    } else if (cat === "Health") {
      return <Tag color="red">Health</Tag>;
    } else if (cat === "Industrial Arts & Consumer Services") {
      return <Tag color="blue">Industrial Arts & Consumer Services</Tag>;
    } else if (cat === "Social Science") {
      return <Tag color="gold">Social Science</Tag>;
    } else if (cat === "Arts") {
      return <Tag color="lime">Arts</Tag>;
    } else if (cat === "Agriculture & Natural Resources") {
      return <Tag>Agriculture & Natural Resources</Tag>;
    } else if (cat === "Psychology & Social Work") {
      return <Tag color="orange">Psychology & Social Work</Tag>;
    } else if (cat === "Communications & Journalism") {
      return <Tag color="purple">Communications & Journalism</Tag>;
    } else if (cat === "Law & Public Policy") {
      return <Tag color="green">Law & Public Policy</Tag>;
    }
  }

  render() {
    const { selectedTags } = this.state;

    return (
      <div>
        <div className="columns">
          <div className="column">
            <Title style={{ textAlign: "center" }} level={1}>
              Majors{" "}
            </Title>
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-three-fifths">
            <Card>
              <div className="columns">
                <div className="column">
                  <span style={{ marginRight: 8, fontWeight: 900 }}>
                    Major Categories:
                  </span>
                  {tagsFromServer.map((tag) => (
                    <CheckableTag
                      key={tag}
                      checked={selectedTags.indexOf(tag) > -1}
                      onChange={(checked) => this.handleChange(tag, checked)}
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <Collapse
                    bordered={false}
                    // defaultActiveKey={['1']}
                    expandIcon={({ isActive }) => (
                      <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                    )}
                  >
                    <Panel
                      header="What's this?"
                      key="1"
                      style={customPanelStyle}
                    >
                      <p>
                        Knowing what you want to major in can be an important
                        factor when choosing a college. Here, we've listed some
                        of the most popular majors offered at many colleges
                        around the nation. Use this page to discover new majors
                        or look up the new grad salaries of the majors you're
                        interested in.
                      </p>
                      <p
                        style={{ textAlign: "left", marginTop: "5%" }}
                        className="help"
                      >
                        Click on one or more categories above to start
                        exploring.
                      </p>
                      <hr />
                      <p style={{ textAlign: "left" }}>
                        Understanding each card
                        <br />
                        <br />
                        <p>
                          <strong>Sample Size</strong>&nbsp; This is the number
                          of students who submitted their response after
                          graduating with a degree in a selected major.
                        </p>
                        <p>
                          <strong>New Grad Salary - 25th Percentile</strong>
                          &nbsp; This is the salary of the bottom range of
                          students who submitted their response after graduating
                          with a degree in a selected major.
                        </p>
                        <p>
                          <strong>New Grad Salary - Median</strong>&nbsp; This
                          is the salary in the middle found from students who
                          submitted their response after graduating with a
                          degree in a selected major.
                        </p>
                        <p>
                          <strong>New Grad Salary - 75th Percentile</strong>
                          &nbsp; This is the salary of the top range of students
                          who submitted their response after graduating with a
                          degree in a selected major.
                        </p>
                        <p>
                          <strong>Unemployment Rate</strong>&nbsp; This is the
                          percentage of unemployed students who submitted their
                          response after graduating with a degree in a selected
                          major.
                        </p>
                        <br />
                        <br />
                        <p className="help">
                          These numbers are for educational purposes only. Your
                          results may vary. Data found via{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://fivethirtyeight.com/"
                          >
                            FiveThirtyEight College Majors
                          </a>
                          .
                        </p>
                      </p>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="columns is-centered">
          <div className="column is-four-fifths">
            <div className="columns is-multiline is-centered">
              {this.state.majors.map((item) => {
                return (
                  <div className="column is-four-fifths">
                    <Card>
                      <div className="columns">
                        <div className="column is-two-thirds">
                          <h6 style={{ fontWeight: 900 }}>{item["Major"]}</h6>
                        </div>
                        <div className="column is-one-third is-pulled-right">
                          {this.getMajorCatergory(item["Major_category"])}
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column is-one-third">
                          <Statistic
                            title="New Grad Salary - 25th Percentile"
                            value={item["P25th"]}
                          />
                        </div>
                        <div className="column is-one-third">
                          <Statistic
                            title="New Grad Median Salary"
                            value={item["Median"]}
                          />
                        </div>
                        <div className="column is-one-third">
                          <Statistic
                            title="New Grad Salary - 75th Percentile"
                            value={item["P75th"]}
                          />
                        </div>
                      </div>
                      <div
                        className="columns is-centered"
                        style={{ margin: "2%" }}
                      >
                        <div className="column is-two-thirds">
                          <p className="help">
                            <strong>Unemployment Rate</strong>
                          </p>
                          <Progress
                            percent={Math.round(
                              item["Unemployment_rate"] * 100
                            )}
                          />
                        </div>
                      </div>
                      {/* <hr/> */}
                      {item["Descrip"] ? (
                        <p style={{ textAlign: "left", margin: "5%" }}>
                          {item["Descrip"]}
                        </p>
                      ) : null}
                      <div className="columns">
                        <div className="column">
                          <p style={{ marginTop: "5%" }} className="help">
                            Sample Size: <strong>{item["Sample_size"]}</strong>
                            &nbsp;New Grads
                          </p>
                        </div>
                      </div>
                    </Card>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <p style={{ marginTop: "5%" }} className="help">
              Data found via{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://fivethirtyeight.com/"
              >
                FiveThirtyEight College Majors
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Majors;
