import React from "react";
import "bulma/css/bulma.css";
import { Typography, Alert, Statistic } from "antd";

const { Title } = Typography;

function Org() {
  return (
    <div>
      <Title style={{ textAlign: "center" }} level={1}>
        Org Dashboard{" "}
      </Title>
      <div className="columns is-centered">
        <div className="column is-two-thirds">
          <div className="box">
            <div className="columns">
              <div className="column">
                <div className="column">
                  <div className="columns">
                    <div className="column">
                      <Statistic title="Active Students" value={20} />
                    </div>
                    <div className="column">
                      <Statistic title="Active Mentors" value={4} />
                    </div>
                    <div className="column">
                      <Statistic title="Active Groups" value={2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Title level={4}>Notifications</Title>
                <Alert
                  message="Nice! No new notifications."
                  type="success"
                  showIcon
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Org;
