import React from "react";
import "bulma/css/bulma.css";
import {
  Typography,
  PageHeader,
  Tabs,
  Descriptions,
  Button,
  Tag,
  Card,
  Icon,
  Pagination,
  Progress,
} from "antd";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Meta } = Card;

function callback(key) {
  console.log(key);
}
function MentorGroup(props) {
  return (
    <div>
      <div className="columns">
        <div className="column">
          <PageHeader
            onBack={() => props.history.push(`/mentor/groups`)}
            title="Back to All Groups"
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <Title style={{ textAlign: "center" }} level={2}>
            Group - {props.location.state.group}
          </Title>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column is-half">
          <div className="box">
            <div className="columns">
              <div className="column">
                <Tabs onChange={callback} type="card">
                  <TabPane tab="Overview" key="1">
                    <div className="columns">
                      <div className="column">
                        <Descriptions title="Group Info">
                          <Descriptions.Item label="Name">
                            College Planning
                          </Descriptions.Item>
                          <Descriptions.Item label="Meeting Times">
                            Mondays at 12pm
                          </Descriptions.Item>
                          <Descriptions.Item label="Starting Date">
                            Jan 2021
                          </Descriptions.Item>
                          <Descriptions.Item label="End Date">
                            May 2021
                          </Descriptions.Item>
                          <Descriptions.Item label="Zoom Link">
                            zoom.com/link
                          </Descriptions.Item>
                          <Descriptions.Item label="Status">
                            Active
                          </Descriptions.Item>
                        </Descriptions>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <Button type="link">Edit Details</Button>
                      </div>
                      <div className="column">
                        <Button type="link">Archive Group</Button>
                      </div>
                      <div className="column">
                        <Button type="link">Delete Group</Button>
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Questions" key="2">
                    <div className="columns">
                      <div className="column">
                        <Card
                          actions={[
                            <Icon type="delete" key="setting" />,
                            <Icon type="edit" key="edit" />,
                          ]}
                          extra={<Tag color="red">Unanswered</Tag>}
                        >
                          <p>What is the best way to create a college list?</p>
                          <Meta description="Asked by John Appleseed on March 5th 2021" />
                        </Card>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <Pagination defaultCurrent={1} total={1} />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Students" key="3">
                    <div className="columns">
                      <div className="column">
                        <Card
                          size="small"
                          extra={
                            <div className="button is-text">View Student</div>
                          }
                        >
                          <p>John Appleseed</p>
                          <div className="columns is-vcentered">
                            <div className="column">
                              <p className="help">Assigned Tasks Completed</p>
                            </div>
                            <div className="column">
                              <Progress percent={100} size="small" />
                            </div>
                          </div>
                        </Card>
                      </div>
                      <div className="column">
                        <Card
                          size="small"
                          extra={
                            <div className="button is-text">View Student</div>
                          }
                        >
                          <p>Henry Gray</p>
                          <div className="columns is-vcentered">
                            <div className="column">
                              <p className="help">Assigned Tasks Completed</p>
                            </div>
                            <div className="column">
                              <Progress percent={65} size="small" />
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <Pagination defaultCurrent={1} total={1} />
                      </div>
                    </div>
                  </TabPane>
                  <TabPane tab="Lesson Notes" key="4">
                    Content of Tab Pane 3
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MentorGroup;
