import React, { Component } from "react";
// import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Typography, Modal, message } from "antd";
import { Comment, Icon, Tooltip, Avatar } from "antd";
import moment from "moment";
import waitingImage from "../../../../assets/waiting.svg";

import * as mutations from "../../../../graphql/mutations";

import ReactCardFlip from "react-card-flip";
import { deleteSite, updateSite, checkChannelName } from "./helpers";
const { confirm } = Modal;

const { Text } = Typography;

Amplify.configure(aws_exports);

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      visible: false,
      channelName: "",
      channelDescrip: "",
      channels: [],
      message: "",
      isFlipped: false,
      member: null,
      messages: [],
      header: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    // await API.graphql(graphqlOperation(queries.getMember, {input: {id: this.props.userID}})).then(data=> console.log(data)).catch(err => console.log(err))

    // this.getGroupMember().then(data => this.createOrNot())
    this.scrollToBottom();
    this.setState({
      member: this.props.currentMember,
      channelName: this.props.channel.name,
      header: this.props.channel.header,
    });
  }

  handleChannelUpdate = (e) => {
    e.preventDefault();
    if (/^ *$/.test(this.state.channelName)) {
      message.error("Site name cannot be empty");
    } else if (
      this.state.channelName === this.props.channel.name &&
      this.state.header === this.props.channel.header
    ) {
      message.error("Site and header have not changed");
    } else {
      if (checkChannelName(this.state.channelName, this.props.numOfChannels)) {
        var channel = this.state.channelName
          .replace(/[^a-zA-Z ]/g, "")
          .replace(/ +/g, "-")
          .toLowerCase();
        updateSite(
          this.props.channel.id,
          channel,
          this.state.header
        ).then((data) => message.success("Channel updated"));
      }
    }
  };
  // componentDidUpdate() {
  //     this.scrollToBottom();
  //   }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleClick(e) {
    e.preventDefault();
    this.setState((prevState) => ({ isFlipped: !prevState.isFlipped }));
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  async sendMessage() {
    var name = this.state.member.email.split("@");
    name = name[0];
    if (this.state.member.name) {
      name = this.state.member.name;
    }
    const cmessage = {
      cMessageUserId: this.props.userID,
      user_id: this.props.userID,
      cMessageChannelId: this.props.channel.id,
      content: this.state.message,
      name: name,
    };
    await API.graphql(
      graphqlOperation(mutations.createCMessage, { input: cmessage })
    )
      .then((data) => console.log(data))
      .catch((err) => console.log(err))
      .then(this.setState({ message: "" }));
  }

  showDeleteConfirm = (id) => {
    confirm({
      title: "Are you sure you want to delete this site?",
      content:
        "Deleting a site is irreversible. The group will lose access to all messages and conversations.",
      okText: "Yes, please delete",
      okType: "danger",
      cancelText: "No, just kidding",
      onOk() {
        deleteSite(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  getAvatar = (name) => {
    var colorList = [
      "#FF6633",
      "#FFB399",
      "#FF33FF",
      "#FFFF99",
      "#00B3E6",
      "#E6B333",
      "#3366E6",
      "#999966",
      "#99FF99",
      "#B34D4D",
      "#80B300",
      "#809900",
      "#E6B3B3",
      "#6680B3",
      "#66991A",
      "#FF99E6",
      "#CCFF1A",
      "#FF1A66",
      "#E6331A",
      "#33FFCC",
      "#66994D",
      "#B366CC",
      "#4D8000",
      "#B33300",
      "#CC80CC",
      "#66664D",
      "#991AFF",
      "#E666FF",
      "#4DB3FF",
      "#1AB399",
      "#E666B3",
      "#33991A",
      "#CC9999",
      "#B3B31A",
      "#00E680",
      "#4D8066",
      "#809980",
      "#E6FF80",
      "#1AFF33",
      "#999933",
      "#FF3380",
      "#CCCC00",
      "#66E64D",
      "#4D80CC",
      "#9900B3",
      "#E64D66",
      "#4DB380",
      "#FF4D4D",
      "#99E6E6",
      "#6666FF",
      "#f56a00",
      "#7265e6",
      "#ffbf00",
      "#00a2ae",
    ];
    const avatarColor =
      colorList[name.substring(0, 1).toLowerCase().charCodeAt(0) - 97];
    return (
      <Avatar style={{ backgroundColor: avatarColor }}>
        {name.substring(0, 1).toUpperCase()}{" "}
      </Avatar>
    );
  };

  render() {
    const channelmessages = []
      .concat(this.props.messages)
      .map((item, i) => (
        <Comment
          author={<div>{item.name}</div>}
          avatar={this.getAvatar(item.name)}
          content={<p style={{ textAlign: "left" }}>{item.content}</p>}
          datetime={
            <Tooltip
              title={moment(item.createdAt).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}
            >
              <span>
                {moment(item.createdAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
              </span>
            </Tooltip>
          }
        />
      ))
      .reverse();

    return (
      <div>
        {/* FRONT SIDE OF CARD */}

        <ReactCardFlip
          isFlipped={this.state.isFlipped}
          flipDirection="vertical"
        >
          <div>
            <div className="box">
              <div className="columns" style={{ padding: "2%" }}>
                <div className="column is-four-fifths">
                  <strong>#{this.props.channel.name}</strong> ~{" "}
                  <Text>{this.props.channel.header}</Text>
                </div>
                <div className="column">
                  <Icon
                    type="info-circle"
                    onClick={this.handleClick.bind(this)}
                  />
                </div>
              </div>
            </div>

            <div
              className="box"
              style={{
                maxHeight: "75vh",
                overflow: "auto",
                marginTop: "2%",
                overflowY: "auto",
              }}
            >
              <div className="card-content">
                <div className="columns">
                  <div className="column">
                    {/* COMMNEST */}
                    {this.props.messages.length > 0 ? (
                      <div>
                        {" "}
                        {this.props.messageToken ? (
                          <div
                            onClick={this.props.getMoreMessages}
                            className="button is-rounded is-small"
                          >
                            Show Older Messages{" "}
                          </div>
                        ) : null}{" "}
                        {channelmessages}{" "}
                      </div>
                    ) : (
                      <div className="columns is-centered is-vcentered">
                        <div className="column">
                          <img src={waitingImage} alt="Logo" />
                        </div>
                        <div className="column">
                          <h4>No messages</h4>
                          <p>
                            Seems like this is a new Park Site. Feel free to
                            send a new message.
                          </p>
                        </div>
                      </div>
                    )}
                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        this.messagesEnd = el;
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="columns" style={{ marginTop: "2%" }}>
              <div className="column is-four-fifths">
                <input
                  className="input is-rounded"
                  type="message"
                  name="message"
                  value={this.state.message}
                  onChange={this.handleChange.bind(this)}
                  placeholder="Hello.."
                />
              </div>
              <div className="column">
                <div
                  className="button is-rounded is-link"
                  onClick={this.sendMessage.bind(this)}
                >
                  Send Message
                </div>
              </div>
            </div>
          </div>

          {/* BACK SIDE OF CARD */}

          <div className="card">
            <div className="card-content">
              <div className="columns">
                <div className="column is-four-fifths">
                  <strong>#{this.props.channel.name}</strong> ~{" "}
                  <Text>{this.props.channel.header}</Text>
                </div>
                <div className="column">
                  <Icon type="swap" onClick={this.handleClick.bind(this)} />
                </div>
                <hr />
              </div>

              <div className="columns">
                <div className="column is-half">
                  <label>Site Name</label>
                  <input
                    className="input is-rounded"
                    type="channelName"
                    name="channelName"
                    value={this.state.channelName}
                    onChange={this.handleChange.bind(this)}
                    placeholder={this.props.channel.name}
                  />
                </div>
                <div className="column is-half">
                  <label>Site Header</label>
                  <input
                    className="input is-rounded"
                    type="header"
                    name="header"
                    value={this.state.header}
                    onChange={this.handleChange.bind(this)}
                    placeholder={this.props.channel.header}
                  />
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div
                    className="button is-fullwidth is-warning"
                    onClick={this.handleChannelUpdate.bind(this)}
                  >
                    Update Site
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column">
                  <div
                    className="button is-fullwidth is-danger"
                    onClick={this.showDeleteConfirm.bind(
                      this,
                      this.props.channel.id
                    )}
                  >
                    Delete Site
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReactCardFlip>
      </div>
    );
  }
}
export default Chat;
