import React from "react";
import "bulma/css/bulma.css";
import { Typography, Card, Statistic, Icon, Button } from "antd";
// import { createClient } from "@supabase/supabase-js";
// import {SUPABASE_FROSH_URL} from "../../general/Constants/api"
import { Link } from "react-router-dom";
const roundedCard = {
  borderRadius: "10px 10px 10px 10px",
  height: "100%",
  backgroundColor: "#f5f5f5",
};
const { Title } = Typography;
// const supabaseUrl = SUPABASE_FROSH_URL
// const supabaseKey = process.env.REACT_APP_SUPABASE_FROSH_KEY
// const supabase = createClient(supabaseUrl, supabaseKey)

function ListGroups() {
  return (
    <div>
      <div className="columns is-vcentered">
        <div className="column">
          <div className="columns">
            <div className="column">
              <Card style={roundedCard}>
                <div className="columns is-vcentered">
                  <div className="column">
                    <Title level={3}>SAT Prep Group</Title>
                    <p>Started March 1st 2020</p>
                  </div>
                  <div className="column">
                    <Statistic
                      title="Students"
                      value={4}
                      prefix={<Icon type="smile" theme="twoTone" />}
                    />
                  </div>
                  <div className="column">
                    <Statistic
                      title="Unanswered Questions"
                      value={12}
                      prefix={
                        <Icon
                          type="alert"
                          theme="twoTone"
                          twoToneColor="#eb2f96"
                        />
                      }
                    />
                  </div>
                  <div className="column">
                    <Card>
                      <p>
                        <strong>Next Session</strong>
                        <br />
                        Monday March 21st
                      </p>
                      <p>
                        <Button type="link">Zoom Link</Button>
                      </p>
                    </Card>
                  </div>
                  <div className="column">
                    <Link
                      to={{
                        pathname: "groups/02",
                        state: {
                          group: "College Planning 101",
                        },
                      }}
                    >
                      {" "}
                      <Icon type="right-circle" style={{ scale: "3" }} />{" "}
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Card style={roundedCard}>
                <div className="columns is-vcentered">
                  <div className="column">
                    <Title level={3}>College Planning Group</Title>
                    <p>Starting April 14th 2021</p>
                  </div>
                  <div className="column">
                    <Statistic
                      title="Students"
                      value={7}
                      prefix={<Icon type="smile" theme="twoTone" />}
                    />
                  </div>
                  <div className="column">
                    <Statistic
                      title="Unanswered Questions"
                      value={0}
                      prefix={
                        <Icon
                          type="alert"
                          theme="twoTone"
                          twoToneColor="#eb2f96"
                        />
                      }
                    />
                  </div>
                  <div className="column">
                    <Card>
                      <p>
                        <strong>Next Session</strong>
                        <br /> No upcoming sessions
                      </p>
                    </Card>
                  </div>
                  <div className="column">
                    <Link
                      to={{
                        pathname: "groups/01",
                        state: {
                          group: "SAT Prep",
                        },
                      }}
                    >
                      {" "}
                      <Icon type="right-circle" style={{ scale: "3" }} />{" "}
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ListGroups;
