import React from "react";
import ScholarshipForm from "./ScholarshipForm";
import { addScholarship } from "./helpers";

function AddScholarship(props) {
  const data = {
    name: "",
    appSent: false,
    refSent: false,
    tranSent: false,
    recieved: false,
    deadline: new Date(),
    org: "",
    essay: "",
    amount: "",
    contact: "",
    criteria: "",
    documents: "",
    note: "",
    source: "",
  };

  return (
    <div>
      <h4>Add a New Scholarship</h4>
      <p className="help">
        This is a place where you can manage and store all the scholarships you
        plan on applying to. <br />
        Fill out the form below to add a new scholarship to your manager.
      </p>
      <ScholarshipForm
        setLoading={props.setLoading}
        action={addScholarship}
        setCurrentView={props.setCurrentView}
        setSelectedScholarship={props.setSelectedScholarship}
        scholarship={data}
      />
    </div>
  );
}
export default AddScholarship;
