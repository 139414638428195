import React, { Component } from "react";
import "../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Typography, Icon, message, Modal, notification, Alert } from "antd";
import ListGroups from "../components/Groups/ListGroups";
import socialImage from "../../../assets/social.svg";
import { Auth } from "aws-amplify";

import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

const { Title } = Typography;

Amplify.configure(aws_exports);

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      visible: false,
      groups: [],
      user: null,
      userID: null,
      groupName: "",
      groupsToken: "",
      isPro: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user) =>
        this.setState({
          user: user.attributes.email,
          userID: user.attributes.sub,
        })
      )
      .catch((err) => console.log(err));

    await API.graphql(graphqlOperation(queries.listGroups))
      .then((data) =>
        this.setState({
          groups: data.data.listGroups.items,
          groupsToken: data.data.listGroups.nextToken,
        })
      )
      .catch((err) => console.log(err));

    const getUser = await API.graphql(graphqlOperation(queries.listUsers));
    var user = getUser.data.listUsers.items;
    if (user.length !== 0) {
      user = user[0];
      if (user.subscription && user.subscription.startsWith("sub")) {
        this.setState({ isPro: true });
      }
    }
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    change[e.target.name] = e.target.value;
    this.setState(change);
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false,
      groupName: "",
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      groupName: "",
    });
  };

  async createGroup() {
    if (this.state.groups.length >= 1 && this.state.isPro !== true) {
      // if (this.state.groups.length >= 1 && this.state.groups.filter( x => x.owner === this.state.userID && this.state.isPro !== true)) {
      // message.error('Sorry, you can only be a part of or create up to 1 Group.');
      this.openNotificationWithIcon("error");
    } else if (/^ *$/.test(this.state.groupName)) {
      message.error("Please enter a valid Group name.");
    } else if (this.state.groups.length >= 10 && this.state.isPro) {
      message.error("Sorry, you are already a member of too many Groups.", 5);
    } else {
      const members = this.state.user;
      // const emails = this.state.user
      const newGroup = {
        name: this.state.groupName,
        owner: this.state.userID,
        members: members,
      };
      // Set the element to be loading in the array
      // this.handleLoading(id);
      await API.graphql(
        graphqlOperation(mutations.createGroup, { input: newGroup })
      )
        .then((data) => window.location.reload())
        .catch((err) => message.error(err));
      // this.Groups();
    }
  }

  openNotificationWithIcon = (type) => {
    notification[type]({
      message: "You need to upgrade your account.",
      description: this.getPage(),
      duration: 0,
    });
  };

  getPage() {
    var desc =
      "Sorry, to add more Groups, you'll need to upgrade your account.";
    return desc;
  }

  render() {
    // const isPro = React.useContext(ProContext);

    return (
      <div>
        <div className="columns">
          <div className="column is-offset-one-quarter">
            <Title style={{ textAlign: "center" }} level={1}>
              Groups{" "}
            </Title>
          </div>
          <div className="column is-one-fifth">
            <button
              onClick={this.showModal}
              className="button is-link is-fullwidth"
            >
              <Icon
                type="plus"
                style={{ scale: "1.5", color: "white", margin: "10px" }}
              />
              &nbsp;Create a Group
            </button>
            <Modal
              title="Create a Group"
              visible={this.state.visible}
              onOk={this.createGroup.bind(this)}
              onCancel={this.handleCancel}
              okText="Create"
            >
              <p>
                Groups are where you and your friends can get together to talk
                about the college planning process. <br />
                <br />
                Start chats, share colleges, and work on tasks together. All in
                one place.
              </p>
              <h5>Group Name</h5>
              <input
                className="input is-rounded"
                type="groupName"
                name="groupName"
                value={this.state.groupName}
                onChange={this.handleChange.bind(this)}
                placeholder="Eg. Frosh 123"
              />
            </Modal>
          </div>
        </div>
        {this.state.groups.length === 0 ? (
          <div className="columns is-centered is-vcentered">
            <div className="column is-half">
              <img src={socialImage} style={{ width: "100%" }} alt="Logo" />
            </div>
            <div className="column is-one-fifth">
              <h5>You're not part of any groups</h5>
              <p>
                Create a new group using the button above. If you are added to a
                group, it will show up here.
              </p>
            </div>
          </div>
        ) : (
          <div>
            {this.state.isPro === false && this.state.groups.length > 1 ? (
              <Alert
                message="You've been invited to other Groups!"
                description="Upgrade to join these Groups."
                type="info"
                showIcon
                closable
              />
            ) : null}
            <ListGroups
              user={this.state.user}
              userID={this.state.userID}
              groups={this.state.groups}
              isPro={this.state.isPro}
            />
          </div>
        )}
      </div>
    );
  }
}
export default Groups;
