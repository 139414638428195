import React from "react";
import "bulma/css/bulma.css";
import { Descriptions, Button, Badge, Tag, message } from "antd";
import Moment from "react-moment";

function ListScholarships(props) {
  const data = [].concat(props.scholarships).map((item, i) => (
    <div>
      <div className="columns is-vcentered">
        <div className="column is-four-fifths">
          <Descriptions title={item.name}>
            <Descriptions.Item label="Status" span={3}>
              <Badge
                status={item.appSent ? "success" : "error"}
                text="Application Sent"
              />{" "}
              &nbsp;&nbsp;
              <Badge
                status={item.refSent ? "success" : "error"}
                text="References Sent"
              />{" "}
              &nbsp;&nbsp;
              <Badge
                status={item.tranSent ? "success" : "error"}
                text="Transcripts Sent"
              />{" "}
              &nbsp;&nbsp;
              <Badge
                status={item.recieved ? "success" : "error"}
                text="Won Scholarship"
              />
            </Descriptions.Item>
            <Descriptions.Item label="Deadline">
              <Moment format="MMM D YYYY">{new Date(item.deadline)}</Moment>
              &nbsp;
              {new Date(item.deadline).getFullYear() ===
                new Date().getFullYear() &&
              new Date(item.deadline).getMonth() === new Date().getMonth() &&
              new Date(item.deadline).getDate() >= new Date().getDate() ? (
                <Tag color="volcano">Coming up!</Tag>
              ) : null}
            </Descriptions.Item>
            <Descriptions.Item label="Organization">
              {item.org}
            </Descriptions.Item>
            <Descriptions.Item label="Amount">${item.amount}</Descriptions.Item>
            <Descriptions.Item label="Criteria">
              {item.criteria}
            </Descriptions.Item>
            <Descriptions.Item label="Documents Needed">
              {item.documents}
            </Descriptions.Item>
            <Descriptions.Item label="Essay Length (if any)">
              {item.essay}
            </Descriptions.Item>
            <Descriptions.Item label="Contact Information">
              {item.contact}
            </Descriptions.Item>
            <Descriptions.Item label="Link">
              <a href={item.source} rel="noopener noreferrer" target="_blank">
                {item.source}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Notes">{item.note}</Descriptions.Item>
          </Descriptions>
        </div>
        <div className="column">
          <div className="columns">
            <div className="column">
              <Button
                block
                onClick={() => {
                  props.setCurrentView(4);
                  props.setSelectedScholarship(item);
                }}
              >
                Edit
              </Button>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <Button
                block
                onClick={() => {
                  props.setLoading(true);
                  try {
                    props.deleteScholarship(item.id);
                  } catch (err) {
                    message.error(
                      "There was an error deleting this Scholarship"
                    );
                    props.setLoading(false);
                  }
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
      {props.scholarships.length - 1 - i !== 0 ? <hr /> : null}
    </div>
  ));

  return <div>{data}</div>;
}
export default ListScholarships;
