import React from "react";
import "bulma/css/bulma.css";
import { Typography } from "antd";

const { Title } = Typography;

function Profile(props) {
  return (
    <div>
      <Title style={{ textAlign: "center" }} level={1}>
        Profile
      </Title>
    </div>
  );
}
export default Profile;
