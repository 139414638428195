import React, { Component } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import "react-toastify/dist/ReactToastify.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../aws-exports"; // specify the location of aws-exports.js file on your project
import { Typography, Icon, Spin, Alert, Modal, message } from "antd";
import {
  deleteNote,
  createNote,
  readNote,
  updateNote,
} from "./general/Notes/notesMutations";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactHtmlParser from "react-html-parser";

const { Title } = Typography;
Amplify.configure(aws_exports);

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      notes: [],
      note: "",
      value: "",
      displayAdd: true,
      token: "",
      displayUpdate: false,
      submitLoading: false,
      notesLoading: true,
      isLoading: [],
      tokenStorage: [],
      tokenCount: 0,
      nextToken: false,
      visible: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  async componentDidMount() {
    const notes = await API.graphql(graphqlOperation(readNote));
    const nextPair = { 1: notes.data.listNotes.nextToken };
    this.setState({
      notes: notes.data.listNotes.items,
      token: notes.data.listNotes.nextToken,
      tokenStorage: nextPair,
      notesLoading: false,
    });
  }

  handleEditorChange(value) {
    this.setState({
      value: value,
      // updateNote: true
    });
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleLoading(id) {
    let loading = this.state.isLoading.slice();
    loading[id] = true;
    this.setState({
      isLoading: loading,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    const note = { note: this.state.value };
    await API.graphql(graphqlOperation(createNote, note));
    message.success("Note successfully added.");
    this.listNotes();
    this.setState({ value: "", submitLoading: false, visible: false, id: "" });
  }

  async handleDelete(item) {
    const noteId = { id: item.id };
    // Set the element to be loading in the array
    this.handleLoading(item.id);
    await API.graphql(graphqlOperation(deleteNote, noteId));
    message.success("Note successfully deleted.");

    this.listNotes();
  }

  async handleUpdate(event) {
    event.preventDefault();
    event.stopPropagation();
    const note = { id: this.state.id, note: this.state.value };
    await API.graphql(graphqlOperation(updateNote, note));
    message.success("Note successfully updated.");
    this.listNotes();
    this.setState({
      displayAdd: true,
      displayUpdate: false,
      value: "",
      visible: false,
      submitLoading: false,
    });
  }

  selectNote(note) {
    this.setState({
      id: note.id,
      value: note.note,
      displayAdd: false,
      displayUpdate: true,
      visible: true,
    });
  }

  async listNotes() {
    const notes = await API.graphql(graphqlOperation(readNote));
    this.setState({
      notes: notes.data.listNotes.items,
      token: notes.data.listNotes.nextToken,
    });
  }

  getNextToken() {
    this.setState({
      token: true,
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      submitLoading: true,
    });

    if (this.state.displayUpdate) {
      this.handleUpdate(e);
    } else {
      this.handleSubmit(e);
    }
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      value: "",
      displayUpdate: false,
    });
  };

  async pagClickedRight() {
    if (this.state.token) {
      const newCount = this.state.tokenCount + 1;
      this.setState({
        tokenCount: newCount,
      });

      const nextToken = { nextToken: this.state.token };

      const newData = await API.graphql(graphqlOperation(readNote, nextToken));

      for (
        let itemIndex = 0;
        itemIndex < newData.data.listNotes.items.length;
        itemIndex++
      ) {
        let newNote = newData.data.listNotes.items[itemIndex];
        this.setState({ notes: [...this.state.notes, newNote] });
      }

      this.setState({ token: newData.data.listNotes.nextToken });
    }
  }

  render() {
    return (
      <div>
        <div className="columns">
          <div className="column is-offset-one-quarter">
            <Title style={{ textAlign: "center" }} level={1}>
              Notes{" "}
            </Title>
          </div>
          <div className="column is-one-fifth">
            <button
              onClick={this.showModal}
              className="button is-link is-fullwidth"
            >
              <Icon
                type="plus"
                style={{ scale: "1.5", color: "white", margin: "10px" }}
              />
              &nbsp;Add a Note
            </button>
            <Modal
              title="Add & Update Note"
              visible={this.state.visible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
              okText="Submit"
            >
              <Spin spinning={this.state.submitLoading}>
                {this.state.displayUpdate ? (
                  <div>
                    <div className="columns">
                      <div className="column">
                        <p>
                          You've selected a note to edit. Click submit once
                          you've finished making changes.
                        </p>
                        <ReactQuill
                          theme="snow"
                          type="value"
                          name="value"
                          value={this.state.value}
                          onChange={this.handleEditorChange}
                        />

                        {/* <textarea className="textarea" value={this.state.value} onChange={this.handleChange}/> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p>
                      Here's a place for you to take notes on whatever you want.
                      Make a note about your college planning process, what
                      you're learning so far while working through the
                      playbooks, or anything else that comes to mind.
                    </p>
                    <ReactQuill
                      theme="snow"
                      type="value"
                      name="value"
                      value={this.state.value}
                      onChange={this.handleEditorChange}
                      placeholder="E.g I really like how Hamilton College has an open curriculum"
                    />

                    {/* <textarea className="textarea" placeholder="E.g Add schools to my list" value={this.state.value} onChange={this.handleChange}/> */}
                  </div>
                )}
              </Spin>
            </Modal>
          </div>
        </div>

        <Spin spinning={this.state.notesLoading}>
          <hr />
          {this.state.notes.length === 0 ? (
            <div>
              <div className="columns is-centered">
                <div className="column is-half">
                  <Alert
                    description="Click on the button above to add a new note."
                    type="info"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="columns is-multiline is-centered">
              {this.state.notes.map((item) => {
                return (
                  <div className="column is-one-third">
                    <Spin spinning={this.state.isLoading[item.id] || false}>
                      <div className="card">
                        <div className="card-content">
                          <div
                            className="content"
                            style={{ textAlign: "left" }}
                          >
                            <div>{ReactHtmlParser(item.note)}</div>
                          </div>
                          <p>{item.createdAt}</p>
                        </div>
                        <footer className="card-footer">
                          <button
                            key={item.i}
                            onClick={this.selectNote.bind(this, item)}
                            className="button is-link is-inverted card-footer-item"
                          >
                            Edit
                          </button>{" "}
                          &nbsp;
                          <button
                            key={item.i}
                            onClick={this.handleDelete.bind(this, item)}
                            className="button is-link is-inverted card-footer-item"
                          >
                            Delete
                          </button>
                        </footer>
                      </div>
                    </Spin>
                  </div>
                );
              })}
            </div>
          )}
          <br />
          {this.state.token == null ? null : (
            <button
              onClick={this.pagClickedRight.bind(this)}
              className="button is-text is-fullwidth"
            >
              Show More
            </button>
          )}
        </Spin>
      </div>
    );
  }
}
export default Notes;
