import React, { Component } from "react";
import "../../../App.css";
import "bulma/css/bulma.css";
import NavBar from "../navbar";
import { Typography, Tag, PageHeader } from "antd";
import moment from "moment";
import FooterPage from "../footer";
import ReactHtmlParser from "react-html-parser";
import Amplify, { Storage } from "aws-amplify";
import aws_exports from "../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://rnpcsynkfxzorrreypqf.supabase.co";
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const { Title } = Typography;
Amplify.configure(aws_exports);

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      file: "",
      photo: "",
      post: null,
    };
  }
  async componentDidMount() {
    // this.getPhoto()
    if (this.props.location.state === undefined) {
      try {
        const getId = window.location.pathname.split("/")[2];

        let { body: posts } = await supabase
          .from("posts")
          .select("*")

          // Filters
          .eq("id", getId);

        if (posts.length > 0) {
          this.setState({ post: posts[0] });
        } else {
          window.location.href = "/blog";
        }
      } catch (e) {
        window.location.href = "/blog";
      }
    } else {
      this.setState({ post: this.props.location.state.post });
    }
  }

  getPhoto() {
    var formattedDate = moment(this.props.location.state.post.date).format(
      "YYYYMMDD"
    );
    const path = "blog/" + formattedDate + "/pic.png";
    Storage.get(path)
      .then((result) => this.setState({ photo: result }))
      .catch((err) => console.log(err));
  }

  render() {
    return (
      <div>
        {this.state.post ? (
          <div>
            <NavBar />
            <div className="columns">
              <div className="column">
                <PageHeader
                  style={
                    {
                      // border: '1px solid rgb(235, 237, 240)',
                    }
                  }
                  onBack={() => (window.location.href = "/blog")}
                  title="Blog Posts"
                  subTitle="Back to list"
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Title
                  style={{ fontSize: 40, fontWeight: 900, marginTop: "2%" }}
                >
                  {this.state.post.title}
                </Title>
                <br />
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-one-fifth">
                <strong>{this.state.post.author}</strong>
              </div>
              <div className="column is-one-fifth">
                <Tag color="geekblue">
                  {moment(this.state.post.inserted_at).format("MMM Do YYYY")}
                </Tag>
              </div>
            </div>
            {/* {this.state.photo === "" ? <Spin/> :

        <div className="columns is-centered">
            <div className="column is-half">
                <figure className="image is-16by9">
                    <img src={this.state.photo}></img>
                </figure>
            </div>
        </div>
        } */}

            <div className="columns is-centered">
              <div className="column is-half" style={{ textAlign: "left" }}>
                {ReactHtmlParser(this.state.post.content)}
              </div>
            </div>

            <FooterPage />
          </div>
        ) : null}
      </div>
    );
  }
}
export default Post;
