import React, { Component } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import NavBar from "./navbar";
import { Typography, Icon } from "antd";
import FooterPage from "./footer";
import profileImage from "../../assets/landingpage/closeprofile.jpg";

const { Title, Text } = Typography;

class AboutPage extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div
          style={{ backgroundColor: "white" }}
          className="columns is-centered"
        >
          <div className="column">
            <Title style={{ fontSize: 40, fontWeight: 900 }}>About Us</Title>
            <Title
              style={{
                fontSize: 20,
                fontWeight: 300,
                marginLeft: "30%",
                marginRight: "30%",
              }}
            >
              We are committed to helping students and their parents crush the
              daunting college planning process.
              <br />
              <br />
            </Title>
          </div>
        </div>

        <div
          className="columns is-centered"
          style={{ marginRight: "20%", marginLeft: "20%", marginTop: "2%" }}
        >
          <div className="column">
            <Title style={{ fontSize: 20, fontWeight: 900, textAlign: "left" }}>
              Understanding and organizing the college search and application
              process can easily become overwhelming <br />
              <br />
              <Title style={{ fontSize: 20, fontWeight: 300 }}>
                It has been well documented that the college application process
                is incredibly stressful with the national average of{" "}
                <strong>student to guidance counselor ratio </strong> being{" "}
                <strong>491:1</strong>. <br />
                <br />
                This leaves many students without proper guidance in the process
                and left to figure things out on their own. This can be
                especially hard for low income or first generation students who
                can’t rely on their parents or guardians for help. <br />
                <br />
                The challenge, as a high school student in this process, is to
                be well informed, prepared, focused and organized throughout the
                entire multi-step college search and admissions process.
                <br />
                <br /> Froshbite aims to be the{" "}
                <strong>College Planning Command Center</strong> for the
                millions of students starting the college search process or
                preparing for college admissions.
                <br />
                <br />
                Froshbite is the ultimate web app to{" "}
                <Text strong>
                  help students effectively understand and navigate a very
                  complicated and stressful process
                </Text>
                .
              </Title>
            </Title>
          </div>
        </div>
        <hr />

        <div
          className="columns is-centered"
          style={{ marginRight: "10%", marginLeft: "10%", marginTop: "5%" }}
        >
          <div className="column is-one-quarter">
            <div className="card">
              <div className="card-image">
                <figure className="image is-1by1">
                  <img
                    className="is-sqaure is-fullwidth"
                    src={profileImage}
                    alt="Profile"
                  />
                </figure>
              </div>
              <div className="card-content">
                <Title style={{ fontSize: 15 }}>Eseosa (Sosa) Asiruwa</Title>
                <Title style={{ fontSize: 13, fontWeight: 300 }}>
                  Founder and Developer
                </Title>
                <Title style={{ fontSize: 13, fontWeight: 300 }}>
                  <div className="columns is-centered">
                    <div className="column is-one-fifth">
                      <a
                        href="https://www.linkedin.com/in/easiruwa/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon type="linkedin" style={{ scale: "2" }} />
                      </a>
                    </div>
                    <div className="column is-one-fifth">
                      <a
                        href="mailto: sosa@froshbite.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon type="mail" style={{ scale: "2" }} />
                      </a>
                    </div>
                  </div>
                </Title>
              </div>
            </div>
          </div>
          <div className="column">
            <Title style={{ fontSize: 20, fontWeight: 900 }}>
              Notes from Founder
            </Title>
            <Title style={{ fontSize: 20, fontWeight: 300, textAlign: "left" }}>
              The idea for Froshbite came about while I was looking for problems
              to solve with code. For a summer during college, I was fortunate
              enough to work as a Site Director for a non-profit called Let's
              Get Ready. LGR is a program where the aim is to help students from
              inner-city communities with the SATs. While there, I noticed that
              a lot of my juniors and seniors had the same "organization"
              methods that I had years ago while I was going through the college
              search process. Scattered loose-leaf papers with potential schools
              and pictures of important documents on their phones. One of the
              biggest factors that enables students to be successful in the
              college planning process is proper organization and to this day,
              there are not many platforms to solve that issue.
              <br />
              <br />
              But that isn't the only issue.
              <br />
              <br />
              Years ago when I was in high school, as a first-gen student, I
              didn't have all the tools I needed to understand the college
              application process. There were oftentimes where my guidance
              counselor wasn't available to answer questions I had. And I don't
              blame her. She was also a counselor for 400 other students with
              duties other than college prep. I ended up feeling lost with
              trying to figure out what I was supposed to do for the process and
              how to prepare. I was lucky enough to join an after school program
              where I was assigned mentors to help me through parts of college
              applications when I needed it. <br />
              <br />
              When I look back, I realize that not every student has access to
              mentors and community programs to help walk them through each step
              of the college search and application process. And usually,
              students are applying to 6-10 schools at one time. Being well
              informed, keeping track of everything, and organizing the next
              steps for each college can easily become overwhelming.
              <br />
              <br />
              So, for me, the goal is to empower students in this really
              important process and to build a tool I wish I had while I was in
              high school.
              <br />
              <br />
              Anyway, I hope you try out Froshbite! I'm only an email away if
              you have any questions, comments or concerns or if you just want
              to say 'hi'. <br />
              <br />- Sosa
            </Title>
          </div>
        </div>

        <FooterPage />
      </div>
    );
  }
}
export default AboutPage;
