/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBlogPost = /* GraphQL */ `
  subscription OnCreateBlogPost {
    onCreateBlogPost {
      id
      title
      content
      date
      author
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBlogPost = /* GraphQL */ `
  subscription OnUpdateBlogPost {
    onUpdateBlogPost {
      id
      title
      content
      date
      author
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBlogPost = /* GraphQL */ `
  subscription OnDeleteBlogPost {
    onDeleteBlogPost {
      id
      title
      content
      date
      author
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      content
      post {
        id
        title
        content
        date
        author
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      content
      post {
        id
        title
        content
        date
        author
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      content
      post {
        id
        title
        content
        date
        author
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePerformance = /* GraphQL */ `
  subscription OnCreatePerformance($owner: String!) {
    onCreatePerformance(owner: $owner) {
      id
      weighted
      unweighted
      rank
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePerformance = /* GraphQL */ `
  subscription OnUpdatePerformance($owner: String!) {
    onUpdatePerformance(owner: $owner) {
      id
      weighted
      unweighted
      rank
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePerformance = /* GraphQL */ `
  subscription OnDeletePerformance($owner: String!) {
    onDeletePerformance(owner: $owner) {
      id
      weighted
      unweighted
      rank
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateNewsletter = /* GraphQL */ `
  subscription OnCreateNewsletter {
    onCreateNewsletter {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNewsletter = /* GraphQL */ `
  subscription OnUpdateNewsletter {
    onUpdateNewsletter {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNewsletter = /* GraphQL */ `
  subscription OnDeleteNewsletter {
    onDeleteNewsletter {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote($owner: String!) {
    onCreateNote(owner: $owner) {
      id
      note
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote($owner: String!) {
    onUpdateNote(owner: $owner) {
      id
      note
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote($owner: String!) {
    onDeleteNote(owner: $owner) {
      id
      note
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateNotifications = /* GraphQL */ `
  subscription OnCreateNotifications($owner: String!) {
    onCreateNotifications(owner: $owner) {
      id
      message
      category
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateNotifications = /* GraphQL */ `
  subscription OnUpdateNotifications($owner: String!) {
    onUpdateNotifications(owner: $owner) {
      id
      message
      category
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteNotifications = /* GraphQL */ `
  subscription OnDeleteNotifications($owner: String!) {
    onDeleteNotifications(owner: $owner) {
      id
      message
      category
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateTask = /* GraphQL */ `
  subscription OnCreateTask($owner: String!) {
    onCreateTask(owner: $owner) {
      id
      task
      due
      complete
      status
      year
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateTask = /* GraphQL */ `
  subscription OnUpdateTask($owner: String!) {
    onUpdateTask(owner: $owner) {
      id
      task
      due
      complete
      status
      year
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteTask = /* GraphQL */ `
  subscription OnDeleteTask($owner: String!) {
    onDeleteTask(owner: $owner) {
      id
      task
      due
      complete
      status
      year
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($owner: String!) {
    onCreateUser(owner: $owner) {
      id
      name
      email
      year
      awsId
      stripeCustomerId
      product
      subscription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($owner: String!) {
    onUpdateUser(owner: $owner) {
      id
      name
      email
      year
      awsId
      stripeCustomerId
      product
      subscription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($owner: String!) {
    onDeleteUser(owner: $owner) {
      id
      name
      email
      year
      awsId
      stripeCustomerId
      product
      subscription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateOnboarding = /* GraphQL */ `
  subscription OnCreateOnboarding($owner: String!) {
    onCreateOnboarding(owner: $owner) {
      id
      playbooks
      tasks
      favs
      applying
      locker
      onboarded
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateOnboarding = /* GraphQL */ `
  subscription OnUpdateOnboarding($owner: String!) {
    onUpdateOnboarding(owner: $owner) {
      id
      playbooks
      tasks
      favs
      applying
      locker
      onboarded
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteOnboarding = /* GraphQL */ `
  subscription OnDeleteOnboarding($owner: String!) {
    onDeleteOnboarding(owner: $owner) {
      id
      playbooks
      tasks
      favs
      applying
      locker
      onboarded
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateTestScore = /* GraphQL */ `
  subscription OnCreateTestScore($owner: String!) {
    onCreateTestScore(owner: $owner) {
      id
      test
      score
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateTestScore = /* GraphQL */ `
  subscription OnUpdateTestScore($owner: String!) {
    onUpdateTestScore(owner: $owner) {
      id
      test
      score
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteTestScore = /* GraphQL */ `
  subscription OnDeleteTestScore($owner: String!) {
    onDeleteTestScore(owner: $owner) {
      id
      test
      score
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateScholarship = /* GraphQL */ `
  subscription OnCreateScholarship($owner: String!) {
    onCreateScholarship(owner: $owner) {
      id
      name
      refSent
      tranSent
      appSent
      recieved
      org
      deadline
      source
      contact
      amount
      criteria
      documents
      essay
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateScholarship = /* GraphQL */ `
  subscription OnUpdateScholarship($owner: String!) {
    onUpdateScholarship(owner: $owner) {
      id
      name
      refSent
      tranSent
      appSent
      recieved
      org
      deadline
      source
      contact
      amount
      criteria
      documents
      essay
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteScholarship = /* GraphQL */ `
  subscription OnDeleteScholarship($owner: String!) {
    onDeleteScholarship(owner: $owner) {
      id
      name
      refSent
      tranSent
      appSent
      recieved
      org
      deadline
      source
      contact
      amount
      criteria
      documents
      essay
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCollegeApp = /* GraphQL */ `
  subscription OnCreateCollegeApp($owner: String!) {
    onCreateCollegeApp(owner: $owner) {
      id
      schoolId
      name
      intended
      term
      status
      fee
      due
      category
      link
      note
      tasks {
        items {
          id
          task
          complete
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      location
      academics
      size
      distance
      extras
      social
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCollegeApp = /* GraphQL */ `
  subscription OnUpdateCollegeApp($owner: String!) {
    onUpdateCollegeApp(owner: $owner) {
      id
      schoolId
      name
      intended
      term
      status
      fee
      due
      category
      link
      note
      tasks {
        items {
          id
          task
          complete
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      location
      academics
      size
      distance
      extras
      social
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCollegeApp = /* GraphQL */ `
  subscription OnDeleteCollegeApp($owner: String!) {
    onDeleteCollegeApp(owner: $owner) {
      id
      schoolId
      name
      intended
      term
      status
      fee
      due
      category
      link
      note
      tasks {
        items {
          id
          task
          complete
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      location
      academics
      size
      distance
      extras
      social
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateAppTask = /* GraphQL */ `
  subscription OnCreateAppTask($owner: String!) {
    onCreateAppTask(owner: $owner) {
      id
      app {
        id
        schoolId
        name
        intended
        term
        status
        fee
        due
        category
        link
        note
        tasks {
          nextToken
        }
        location
        academics
        size
        distance
        extras
        social
        createdAt
        updatedAt
        owner
      }
      task
      complete
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateAppTask = /* GraphQL */ `
  subscription OnUpdateAppTask($owner: String!) {
    onUpdateAppTask(owner: $owner) {
      id
      app {
        id
        schoolId
        name
        intended
        term
        status
        fee
        due
        category
        link
        note
        tasks {
          nextToken
        }
        location
        academics
        size
        distance
        extras
        social
        createdAt
        updatedAt
        owner
      }
      task
      complete
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteAppTask = /* GraphQL */ `
  subscription OnDeleteAppTask($owner: String!) {
    onDeleteAppTask(owner: $owner) {
      id
      app {
        id
        schoolId
        name
        intended
        term
        status
        fee
        due
        category
        link
        note
        tasks {
          nextToken
        }
        location
        academics
        size
        distance
        extras
        social
        createdAt
        updatedAt
        owner
      }
      task
      complete
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateFav = /* GraphQL */ `
  subscription OnCreateFav($owner: String!) {
    onCreateFav(owner: $owner) {
      id
      schoolid
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateFav = /* GraphQL */ `
  subscription OnUpdateFav($owner: String!) {
    onUpdateFav(owner: $owner) {
      id
      schoolid
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteFav = /* GraphQL */ `
  subscription OnDeleteFav($owner: String!) {
    onDeleteFav(owner: $owner) {
      id
      schoolid
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCalendar = /* GraphQL */ `
  subscription OnCreateCalendar($owner: String!) {
    onCreateCalendar(owner: $owner) {
      id
      event
      date
      type
      year
      month
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCalendar = /* GraphQL */ `
  subscription OnUpdateCalendar($owner: String!) {
    onUpdateCalendar(owner: $owner) {
      id
      event
      date
      type
      year
      month
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCalendar = /* GraphQL */ `
  subscription OnDeleteCalendar($owner: String!) {
    onDeleteCalendar(owner: $owner) {
      id
      event
      date
      type
      year
      month
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePlaybook1 = /* GraphQL */ `
  subscription OnCreatePlaybook1($owner: String!) {
    onCreatePlaybook1(owner: $owner) {
      id
      steps
      shortgoals
      longgoals
      how
      moregoals
      deeper
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePlaybook1 = /* GraphQL */ `
  subscription OnUpdatePlaybook1($owner: String!) {
    onUpdatePlaybook1(owner: $owner) {
      id
      steps
      shortgoals
      longgoals
      how
      moregoals
      deeper
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePlaybook1 = /* GraphQL */ `
  subscription OnDeletePlaybook1($owner: String!) {
    onDeletePlaybook1(owner: $owner) {
      id
      steps
      shortgoals
      longgoals
      how
      moregoals
      deeper
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePlaybook2 = /* GraphQL */ `
  subscription OnCreatePlaybook2($owner: String!) {
    onCreatePlaybook2(owner: $owner) {
      id
      steps
      rankings
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePlaybook2 = /* GraphQL */ `
  subscription OnUpdatePlaybook2($owner: String!) {
    onUpdatePlaybook2(owner: $owner) {
      id
      steps
      rankings
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePlaybook2 = /* GraphQL */ `
  subscription OnDeletePlaybook2($owner: String!) {
    onDeletePlaybook2(owner: $owner) {
      id
      steps
      rankings
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePlaybook3 = /* GraphQL */ `
  subscription OnCreatePlaybook3($owner: String!) {
    onCreatePlaybook3(owner: $owner) {
      id
      steps
      qualities
      best
      experience
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePlaybook3 = /* GraphQL */ `
  subscription OnUpdatePlaybook3($owner: String!) {
    onUpdatePlaybook3(owner: $owner) {
      id
      steps
      qualities
      best
      experience
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePlaybook3 = /* GraphQL */ `
  subscription OnDeletePlaybook3($owner: String!) {
    onDeletePlaybook3(owner: $owner) {
      id
      steps
      qualities
      best
      experience
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreatePlaybook4 = /* GraphQL */ `
  subscription OnCreatePlaybook4($owner: String!) {
    onCreatePlaybook4(owner: $owner) {
      id
      steps
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdatePlaybook4 = /* GraphQL */ `
  subscription OnUpdatePlaybook4($owner: String!) {
    onUpdatePlaybook4(owner: $owner) {
      id
      steps
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeletePlaybook4 = /* GraphQL */ `
  subscription OnDeletePlaybook4($owner: String!) {
    onDeletePlaybook4(owner: $owner) {
      id
      steps
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateClass = /* GraphQL */ `
  subscription OnCreateClass($owner: String!) {
    onCreateClass(owner: $owner) {
      id
      name
      teacher
      grade
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateClass = /* GraphQL */ `
  subscription OnUpdateClass($owner: String!) {
    onUpdateClass(owner: $owner) {
      id
      name
      teacher
      grade
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteClass = /* GraphQL */ `
  subscription OnDeleteClass($owner: String!) {
    onDeleteClass(owner: $owner) {
      id
      name
      teacher
      grade
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateRec = /* GraphQL */ `
  subscription OnCreateRec($owner: String!) {
    onCreateRec(owner: $owner) {
      id
      teacher
      email
      req
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateRec = /* GraphQL */ `
  subscription OnUpdateRec($owner: String!) {
    onUpdateRec(owner: $owner) {
      id
      teacher
      email
      req
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteRec = /* GraphQL */ `
  subscription OnDeleteRec($owner: String!) {
    onDeleteRec(owner: $owner) {
      id
      teacher
      email
      req
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateExtra = /* GraphQL */ `
  subscription OnCreateExtra($owner: String!) {
    onCreateExtra(owner: $owner) {
      id
      extra
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateExtra = /* GraphQL */ `
  subscription OnUpdateExtra($owner: String!) {
    onUpdateExtra(owner: $owner) {
      id
      extra
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteExtra = /* GraphQL */ `
  subscription OnDeleteExtra($owner: String!) {
    onDeleteExtra(owner: $owner) {
      id
      extra
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateExp = /* GraphQL */ `
  subscription OnCreateExp($owner: String!) {
    onCreateExp(owner: $owner) {
      id
      exp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateExp = /* GraphQL */ `
  subscription OnUpdateExp($owner: String!) {
    onUpdateExp(owner: $owner) {
      id
      exp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteExp = /* GraphQL */ `
  subscription OnDeleteExp($owner: String!) {
    onDeleteExp(owner: $owner) {
      id
      exp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateGTask = /* GraphQL */ `
  subscription OnCreateGTask {
    onCreateGTask {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      task
      suggestedby
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGTask = /* GraphQL */ `
  subscription OnUpdateGTask {
    onUpdateGTask {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      task
      suggestedby
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGTask = /* GraphQL */ `
  subscription OnDeleteGTask {
    onDeleteGTask {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      task
      suggestedby
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGCollege = /* GraphQL */ `
  subscription OnCreateGCollege {
    onCreateGCollege {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      college
      suggestedby
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGCollege = /* GraphQL */ `
  subscription OnUpdateGCollege {
    onUpdateGCollege {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      college
      suggestedby
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGCollege = /* GraphQL */ `
  subscription OnDeleteGCollege {
    onDeleteGCollege {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      college
      suggestedby
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGScholarship = /* GraphQL */ `
  subscription OnCreateGScholarship {
    onCreateGScholarship {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      suggestedby
      deadline
      link
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGScholarship = /* GraphQL */ `
  subscription OnUpdateGScholarship {
    onUpdateGScholarship {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      suggestedby
      deadline
      link
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGScholarship = /* GraphQL */ `
  subscription OnDeleteGScholarship {
    onDeleteGScholarship {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      suggestedby
      deadline
      link
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChannel = /* GraphQL */ `
  subscription OnCreateChannel {
    onCreateChannel {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      header
      messages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChannel = /* GraphQL */ `
  subscription OnUpdateChannel {
    onUpdateChannel {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      header
      messages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChannel = /* GraphQL */ `
  subscription OnDeleteChannel {
    onDeleteChannel {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      header
      messages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCMessage = /* GraphQL */ `
  subscription OnCreateCMessage {
    onCreateCMessage {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      channel {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        header
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCMessage = /* GraphQL */ `
  subscription OnUpdateCMessage {
    onUpdateCMessage {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      channel {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        header
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCMessage = /* GraphQL */ `
  subscription OnDeleteCMessage {
    onDeleteCMessage {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      channel {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        header
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup($members: String!) {
    onCreateGroup(members: $members) {
      id
      name
      owner
      members
      requests
      channels {
        items {
          id
          name
          header
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          task
          suggestedby
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      scholarships {
        items {
          id
          name
          suggestedby
          deadline
          link
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      colleges {
        items {
          id
          college
          suggestedby
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          name
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($members: String!) {
    onUpdateGroup(members: $members) {
      id
      name
      owner
      members
      requests
      channels {
        items {
          id
          name
          header
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          task
          suggestedby
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      scholarships {
        items {
          id
          name
          suggestedby
          deadline
          link
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      colleges {
        items {
          id
          college
          suggestedby
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          name
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup($members: String!) {
    onDeleteGroup(members: $members) {
      id
      name
      owner
      members
      requests
      channels {
        items {
          id
          name
          header
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          task
          suggestedby
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      scholarships {
        items {
          id
          name
          suggestedby
          deadline
          link
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      colleges {
        items {
          id
          college
          suggestedby
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          name
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMember = /* GraphQL */ `
  subscription OnCreateMember($email: String!) {
    onCreateMember(email: $email) {
      id
      channelmessages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      year
      more
      email
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMember = /* GraphQL */ `
  subscription OnUpdateMember($email: String!) {
    onUpdateMember(email: $email) {
      id
      channelmessages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      year
      more
      email
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMember = /* GraphQL */ `
  subscription OnDeleteMember($email: String!) {
    onDeleteMember(email: $email) {
      id
      channelmessages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      year
      more
      email
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePrivateMessage = /* GraphQL */ `
  subscription OnCreatePrivateMessage {
    onCreatePrivateMessage {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      content
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePrivateMessage = /* GraphQL */ `
  subscription OnUpdatePrivateMessage {
    onUpdatePrivateMessage {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      content
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePrivateMessage = /* GraphQL */ `
  subscription OnDeletePrivateMessage {
    onDeletePrivateMessage {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      content
      createdAt
      updatedAt
    }
  }
`;
export const onCreateConvoLink = /* GraphQL */ `
  subscription OnCreateConvoLink {
    onCreateConvoLink {
      id
      member {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      convoLinkMember
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateConvoLink = /* GraphQL */ `
  subscription OnUpdateConvoLink {
    onUpdateConvoLink {
      id
      member {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      convoLinkMember
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteConvoLink = /* GraphQL */ `
  subscription OnDeleteConvoLink {
    onDeleteConvoLink {
      id
      member {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      convoLinkMember
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
