import React, { Component } from "react";
import "../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import NavBar from "../navbar";
import { Typography, Button, Tag } from "antd";
import FooterPage from "../footer";
import edu from "../../../assets/landingpage/education.svg";
import Emoji from "../../Students/components/CollegeList/Emoji";

const { Title } = Typography;

const currLink =
  "https://docs.google.com/document/d/e/2PACX-1vRsuWBo3bE2sGQFlLAUsyShIv4lM9-QUAcli_ME1i6NH67p0IloSmKEW4-kdMLNG1kS6oK_N-DNGgCC/pub";

class Guidance extends Component {
  render() {
    return (
      <div>
        <NavBar />
        <div
          className="columns is-centered"
          style={{ backgroundColor: "white" }}
        >
          <div className="column">
            <Title
              level="4"
              style={{
                paddingTop: "5%",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              Recieve personalized guidance on your <br /> college planning
              journey <Emoji symbol="🎓" />
            </Title>
          </div>
        </div>
        <div
          style={{ backgroundColor: "white" }}
          className="columns is-centered is-vcentered"
        >
          <div
            style={{
              paddingLeft: "10%",
              paddingRight: "10%",
              paddingBottom: "5%",
              paddingTop: "5%",
            }}
            className="column is-half"
          >
            <div className="columns">
              <div className="column">
                <Title
                  style={{ fontSize: 40, fontWeight: 900, textAlign: "left" }}
                >
                  College Guidance
                </Title>
              </div>
            </div>
            <div className="columns">
              <div className="column" style={{ textAlign: "left" }}>
                <Emoji symbol="🤔" />
                &nbsp;<strong>What?:</strong>&nbsp; This bootcamp provides
                personalized support in a small group enviroment that helps you
                stay on track through your SAT prep where you may be falling
                behind, struggling, or just need a little extra support. <br />
                <br />
                Once you join the Froshbite SAT bootcamp, you are matched with a
                trained SAT prep instructor who hosts two small group tutoring
                sessions a week. Our SAT Boot Camp is a great stepping stone for
                juniors taking the test for the first time or for seniors who
                need better understanding in certain areas they are the weakest
                in.
              </div>
            </div>
            <div className="columns">
              <div className="column" style={{ textAlign: "left" }}>
                <Emoji symbol="⏰" />
                &nbsp;<strong>When?:</strong> Starting the week of March 22nd
                2020. Meeting days TBD. We will keep you updated!
              </div>
            </div>
            <div className="columns">
              <div className="column" style={{ textAlign: "left" }}>
                <Emoji symbol="🖥️" />
                &nbsp;<strong>Where?:</strong> Fully online Zoom sessions
              </div>
            </div>
            <div className="columns">
              <div className="column" style={{ textAlign: "left" }}>
                <Emoji symbol="📅" />
                &nbsp;<strong>How Long?:</strong> 8 weeks total. Classes will be
                two days a week for about 2 hours at a time.
              </div>
            </div>
            <div className="columns">
              <div className="column" style={{ textAlign: "left" }}>
                <Emoji symbol="💳" />
                &nbsp;<strong>How Much?:</strong> Always affordable. $20/week
                ($160 total){" "}
                <Tag color="red">
                  <Emoji symbol="⌛" />
                  &nbsp;Limited Time Offer
                </Tag>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <Button block type="primary">
                  Join the Waitlist
                </Button>
              </div>
              <div className="column">
                <Button href={currLink} target="_blank" block>
                  The Curriculum
                </Button>
              </div>
            </div>
          </div>
          <div className="column is-half">
            <div className="columns is-centered is-vcentered">
              <div className="column">
                <div className="container">
                  <div class="content" style={{ textAlign: "left" }}>
                    <h3>Guidance Plan Includes</h3>
                    <ul>
                      <li>24/7 Community Q&A</li>
                      <li>Weekly Homework Help Sessions</li>
                      <li>Practice Problems</li>
                      <li>Recorded Concept Reviews</li>
                      <li>Fully virtual sessions</li>
                      <li>Small class sizes with no more than 10 students</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column" style={{ textAlign: "right" }}>
                <div className="container">
                  <figure className="image ">
                    <img style={{ width: "50%" }} src={edu} alt="Organizer" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterPage />
      </div>
    );
  }
}
export default Guidance;
