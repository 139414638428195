import React, { Component } from "react";
import "../../../App.css";
import "bulma/css/bulma.css";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import aws_exports from "../../../aws-exports"; // specify the location of aws-exports.js file on your project
import { Typography, Spin } from "antd";
import joyRide from "../../../assets/joyride.svg";
import foundImage from "../../../assets/found.svg";
import buildingImage from "../../../assets/building.svg";
import decideImage from "../../../assets/savings.svg";
import { getPath } from "../components/Schools/helpers";
import { getPlaybookPath } from "../components/Playbooks/helpers";
import {
  playbook1steps,
  playbook2steps,
  playbook3steps,
  playbook4steps,
} from "../components/Playbooks/content";
import * as queries from "../../../graphql/queries";
import * as mutations from "../../../graphql/mutations";

import { Link } from "react-router-dom";

const cardinnerDashboardtop = {
  marginTop: "20px",
  // marginRight: '20px',
  borderRadius: "10px 10px 10px 10px",
  height: "100%",
};
const { Title, Text } = Typography;

Amplify.configure(aws_exports);

class Playbooks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      play1: null,
      play2: null,
      play3: null,
      play4: null,
    };
  }

  async componentDidMount() {
    const play1 = await API.graphql(graphqlOperation(queries.listPlaybook1s));
    const play2 = await API.graphql(graphqlOperation(queries.listPlaybook2s));
    const play3 = await API.graphql(graphqlOperation(queries.listPlaybook3s));
    const play4 = await API.graphql(graphqlOperation(queries.listPlaybook4s));

    // this.setState({play1:play1.data.listPlaybook1s.items[0]}, () => {
    if (play1.data.listPlaybook1s.items.length === 0) {
      this.createPlay1();
    } else {
      this.listPlaybook1();
    }

    if (play2.data.listPlaybook2s.items.length === 0) {
      this.createPlay2();
    } else {
      this.listPlaybook2();
    }

    if (play3.data.listPlaybook3s.items.length === 0) {
      this.createPlay3();
    } else {
      this.listPlaybook3();
    }

    if (play4.data.listPlaybook4s.items.length === 0) {
      this.createPlay4();
    } else {
      this.listPlaybook4();
    }
    //   })
  }

  getProgress(steps) {
    const numSteps = steps.length;
    var completeCount = steps.reduce(function (n, val) {
      return n + (val === true);
    }, 0);

    return (completeCount / numSteps) * 100;
  }

  // CREATING PLAYBOOK 1

  async createPlay1() {
    // change to be a general function to list all playbooks
    const play1 = { steps: [false, false, false, false, false, false] };
    try {
      await API.graphql(
        graphqlOperation(mutations.createPlaybook1, { input: play1 })
      );
    } catch (err) {
      console.log(err);
    }
    this.listPlaybook1();
  }

  async listPlaybook1() {
    const play1 = await API.graphql(graphqlOperation(queries.listPlaybook1s));
    const playbook = play1.data.listPlaybook1s.items[0];
    this.setState({ play1: playbook });
  }

  // CREATING PLAYBOOK 2

  async createPlay2() {
    // change to be a general function to list all playbooks
    const play2 = { steps: [false, false, false, false, false, false, false] };
    try {
      await API.graphql(
        graphqlOperation(mutations.createPlaybook2, { input: play2 })
      );
    } catch (err) {
      console.log(err);
    }
    this.listPlaybook2();
  }

  async listPlaybook2() {
    const play2 = await API.graphql(graphqlOperation(queries.listPlaybook2s));
    this.setState({ play2: play2.data.listPlaybook2s.items[0] });
  }

  // CREATING PLAYBOOK 3

  async createPlay3() {
    // change to be a general function to list all playbooks
    const play3 = { steps: [false, false, false, false, false, false] };
    try {
      await API.graphql(
        graphqlOperation(mutations.createPlaybook3, { input: play3 })
      );
    } catch (err) {
      console.log(err);
    }
    this.listPlaybook3();
  }

  async listPlaybook3() {
    const play3 = await API.graphql(graphqlOperation(queries.listPlaybook3s));
    this.setState({ play3: play3.data.listPlaybook3s.items[0] });
  }

  // CREATING PLAYBOOK 4

  async createPlay4() {
    // change to be a general function to list all playbooks
    const play4 = { steps: [false, false, false, false, false, false] };
    try {
      await API.graphql(
        graphqlOperation(mutations.createPlaybook4, { input: play4 })
      );
    } catch (err) {
      console.log(err);
    }
    this.listPlaybook4();
  }

  async listPlaybook4() {
    const play4 = await API.graphql(graphqlOperation(queries.listPlaybook4s));
    this.setState({ play4: play4.data.listPlaybook4s.items[0] });
  }

  render() {
    return (
      <div>
        <Title style={{ textAlign: "center" }} level={1}>
          Playbooks{" "}
        </Title>
        {/* <h2 className="subtitle" style={{padding:'20px'}}>All the steps you need to conquer college admissions.</h2> */}

        <div className="columns">
          <div className="column  is-one-quarter">
            {this.state.play1 ? (
              <Link
                to={{
                  pathname: getPath(
                    getPlaybookPath("Getting Ready for College Planning"),
                    "playbooks"
                  ),
                  state: {
                    name: "Getting Ready for College Planning",
                    playbook: this.state.play1,
                    number: 0,
                    content: playbook1steps,
                  },
                }}
              >
                <div className="card" style={cardinnerDashboardtop}>
                  <div className="card-image" style={{ margin: "15%" }}>
                    <figure className="image is-4by3">
                      <img src={joyRide} alt="Placeholder" />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      <div className="columns">
                        <div className="column">
                          <Text strong>Getting Ready for College Planning</Text>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">
                          {this.state.play1 ? (
                            <progress
                              style={{ marginTop: "5%" }}
                              className="progress is-success"
                              value={this.getProgress(this.state.play1.steps)}
                              max="100"
                            ></progress>
                          ) : (
                            <Spin size="small" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <Spin size="small" />
            )}
          </div>
          <div className="column  is-one-quarter">
            {this.state.play2 ? (
              <Link
                to={{
                  pathname: getPath(
                    getPlaybookPath("Finding Colleges You'll Love"),
                    "playbooks"
                  ),
                  state: {
                    name: "Finding Colleges You'll Love",
                    playbook: this.state.play2,
                    number: 1,
                    content: playbook2steps,
                  },
                }}
              >
                <div className="card" style={cardinnerDashboardtop}>
                  <div className="card-image" style={{ margin: "15%" }}>
                    <figure className="image is-4by3">
                      <img src={foundImage} alt="Placeholder" />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      <div className="columns">
                        <div className="column">
                          <Text strong>Finding Colleges You'll Love</Text>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">
                          {this.state.play2 ? (
                            <progress
                              style={{ marginTop: "5%" }}
                              className="progress is-success"
                              value={this.getProgress(this.state.play2.steps)}
                              max="100"
                            ></progress>
                          ) : (
                            <Spin size="small" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <Spin size="small" />
            )}
          </div>
          <div className="column is-one-quarter">
            {this.state.play3 ? (
              <Link
                to={{
                  pathname: getPath(
                    getPlaybookPath("Navigating the Application"),
                    "playbooks"
                  ),
                  state: {
                    name: "Navigating the Application",
                    playbook: this.state.play3,
                    number: 2,
                    content: playbook3steps,
                  },
                }}
              >
                <div className="card" style={cardinnerDashboardtop}>
                  <div className="card-image" style={{ padding: "15%" }}>
                    <figure className="image is-4by3">
                      <img src={buildingImage} alt="Placeholder" />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      <div className="columns">
                        <div className="column">
                          <Text strong>Navigating the College Application</Text>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">
                          {this.state.play3 ? (
                            <progress
                              style={{ marginTop: "5%" }}
                              className="progress is-success"
                              value={this.getProgress(this.state.play3.steps)}
                              max="100"
                            ></progress>
                          ) : (
                            <Spin size="small" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <Spin size="small" />
            )}
          </div>
          <div className="column  is-one-quarter">
            {this.state.play4 ? (
              <Link
                to={{
                  pathname: getPath(
                    getPlaybookPath("Applying for Financial Aid"),
                    "playbooks"
                  ),
                  state: {
                    name: "Applying for Financial Aid",
                    playbook: this.state.play4,
                    number: 3,
                    content: playbook4steps,
                  },
                }}
              >
                <div className="card" style={cardinnerDashboardtop}>
                  <div className="card-image" style={{ padding: "15%" }}>
                    <figure className="image is-4by3">
                      <img src={decideImage} alt="Placeholder" />
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      <div className="columns">
                        <div className="column">
                          <Text strong>Applying for Financial Aid</Text>
                        </div>
                      </div>
                      <div className="columns">
                        <div className="column">
                          {this.state.play4 ? (
                            <progress
                              style={{ marginTop: "5%" }}
                              className="progress is-success"
                              value={this.getProgress(this.state.play4.steps)}
                              max="100"
                            ></progress>
                          ) : (
                            <Spin size="small" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ) : (
              <Spin size="small" />
            )}
          </div>
        </div>
        {/* 
       <div className="columns" style={{marginTop:'5%'}}>
           <div className="column">
                <Title style={{textAlign:'left'}} level={3}>Playbook Library </Title>
                <h2 className="subtitle" style={{textAlign:'left'}}>Find additional playbooks created by counselors, teachers, colleges and other educators.</h2>
                <h2 className="subtitle" style={{textAlign:'left'}}><i>Coming soon.</i></h2>


           </div>
       </div> */}
      </div>
    );
  }
}
export default Playbooks;
