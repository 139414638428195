import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify from "aws-amplify";
import aws_exports from "../../../aws-exports"; // specify the location of aws-exports.js file on your project
import {
  Typography,
  Pagination,
  Icon,
  Divider,
  Alert,
  Spin,
  Statistic,
  Tooltip,
} from "antd";
import nothingImage from "../../../assets/undraw_empty_xct9.svg";
import searchImage from "../../../assets/search.svg";

import { Events, animateScroll as scroll, scrollSpy } from "react-scroll";

import {
  abbrState,
  numberWithCommas,
} from "../components/Schools/collegeDataHelpers";
import { addToApplying, addToFavs } from "../components/Schools/helpers";

const { Title, Text } = Typography;

const cardinnerDashboardtop = {
  marginTop: "20px",
  borderRadius: "10px 10px 10px 10px",
};

export const readFavs = `query ListFavs(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listFavs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schoolid
    }
    nextToken
  }
}`;

Amplify.configure(aws_exports);

class Schools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schools: [],
      value: "",
      searchString: "",
      // fieldsArr:["id", "school.name"],
      currentFetch: "",
      currentFetchString: "",
      fields:
        "id,school.name,latest.student.size,latest.admissions.sat_scores.average.overall,latest.admissions.admission_rate.overall,school.state,school.school_url",
      token: "",
      schoolsLoading: true,
      isLoading: [],
      tokenStorage: [],
      nothingFound: false,
      total: 0,
      pageNum: 0,
      tokenCount: 0,
      nextToken: false,
      visible: false,
      year4: true,
      year2: true,
      private: true,
      baseFetch:
        "fields=id,school.name,school.state,latest.student.size,school.school_url,latest.admissions.admission_rate.overall,latest.admissions.act_scores.75th_percentile.cumulative,latest.admissions.act_scores.25th_percentile.cumulative,latest.admissions.sat_scores.25th_percentile.critical_reading,latest.admissions.sat_scores.25th_percentile.math,latest.admissions.sat_scores.75th_percentile.critical_reading,latest.admissions.sat_scores.75th_percentile.math",
      public: true,
      checks: ["year4", "year2", "private", "public"],
      mainType: "",
      type: "",
      sat: "",
      region: "",
      size: "",
      sort: "latest.admissions.sat_scores.average.overall:desc",
      sortNum: 5,
      nameSearch: false,
      disableIcons: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    this.getColleges(this.state.baseFetch);
    Events.scrollEvent.register("begin", function (to, element) {});

    Events.scrollEvent.register("end", function (to, element) {});
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
    scrollSpy.update();
  }

  scrollToTop() {
    scroll.scrollToTop();
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    const value =
      this.state.checks.indexOf(e.target.name) > -1
        ? e.target.checked
        : e.target.value;
    change[e.target.name] = value;
    if (e.target.name === "sortNum") {
      const sortfields = [
        "latest.admissions.admission_rate.overall:asc",
        "latest.admissions.admission_rate.overall:desc",
        "school.name:asc",
        "school.name:desc",
        "latest.admissions.sat_scores.average.overall:asc",
        "latest.admissions.sat_scores.average.overall:desc",
        "latest.admissions.act_scores.midpoint.cumulative:asc",
        "latest.admissions.act_scores.midpoint.cumulative:desc",
      ];
      const chosenSort = sortfields[value];
      this.setState({ sortNum: value, sort: chosenSort, pageNum: 0 }, () => {
        this.getColleges(this.state.currentFetch);
      });
    } else {
      this.setState(change);
    }
  }

  searchClicked = () => {
    this.scrollToTop();
    this.setState(
      { schoolsLoading: true, pageNum: 0, nameSearch: true },
      () => {
        this.getColleges(this.state.baseFetch);
      }
    );
  };

  backtoFilter = () => {
    this.setState({ nameSearch: false, pageNum: 0, searchString: "" }, () => {
      this.resetFilters();
    });
  };

  changePage = (page) => {
    this.scrollToTop();
    this.setState({ pageNum: page - 1, schoolsLoading: true }, () => {
      this.getColleges(this.state.currentFetch);
    });
  };

  async getColleges(fetchString) {
    this.scrollToTop();
    var fetchURL = "https://api.data.gov/ed/collegescorecard/v1/schools.json?";

    if (this.state.nameSearch) {
      const searchString = this.state.searchString.replace(" ", "%20");

      fetchURL =
        fetchURL +
        "school.name=" +
        searchString +
        "&" +
        fetchString +
        "&api_key=" +
        process.env.REACT_APP_COLLEGE_SEARCH_API +
        "&page=" +
        this.state.pageNum +
        "&sort=" +
        this.state.sort;
    } else {
      fetchURL =
        fetchURL +
        fetchString +
        "&api_key=" +
        process.env.REACT_APP_COLLEGE_SEARCH_API +
        "&page=" +
        this.state.pageNum +
        "&sort=" +
        this.state.sort;
    }

    try {
      const response = await fetch(fetchURL);
      // const response = await fetch("https://api.data.gov/ed/collegescorecard/v1/schools.json?" + fetchString + "&api_key=ygpuCKKdi0cuKgzhlU2Zf6qOG22dCqlFVcjT8YaW&page=" + this.state.pageNum + "&sort=" + this.state.sort);
      const jsonData = await response.json();
      this.setState({
        schools: jsonData.results,
        schoolsLoading: false,
        total: jsonData.metadata.total,
        currentFetch: fetchString,
      });
      if (jsonData.results.length === 0) {
        this.setState({ nothingFound: true });
      } else {
        this.setState({ nothingFound: false });
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  handleLoading(id) {
    let loading = this.state.isLoading.slice();
    loading[id] = true;
    this.setState({
      isLoading: loading,
    });
  }

  getAdmissionsBar = (rate) => {
    if (rate < 33) {
      return (
        <progress
          className="progress is-danger is-small"
          value={rate}
          max="100"
        />
      );
    } else if (rate >= 33 && rate <= 50) {
      return (
        <progress
          className="progress is-warning is-small"
          value={rate}
          max="100"
        />
      );
    } else {
      return (
        <progress
          className="progress is-danger is-small"
          value={rate}
          max="100"
        />
      );
    }
  };

  resetFilters = () => {
    this.setState(
      {
        year2: true,
        year4: true,
        private: true,
        public: true,
        degrees: "",
        ownership: "",
        type: "",
        region: "",
        size: "",
        pageNum: 0,
      },
      () => {
        this.applyFilters();
      }
    );
  };

  applyFilters = () => {
    this.scrollToTop();
    var fetchString = this.state.baseFetch;
    var degrees = null;
    var ownership = null;
    var type = null;
    var region = null;
    var size = null;

    if (this.state.year2 && this.state.year4) {
      degrees = "school.degrees_awarded.predominant=2,3&";
    } else if (this.state.year2) {
      degrees = "school.degrees_awarded.predominant=2&";
    } else if (this.state.year4) {
      degrees = "school.degrees_awarded.predominant=3&";
    }

    if (degrees) {
      fetchString = degrees + fetchString;
    }

    if (this.state.private && this.state.public) {
      ownership = "school.ownership=1,2&";
    } else if (this.state.public) {
      ownership = "school.ownership=1&";
    } else if (this.state.private) {
      ownership = "school.ownership=2&";
    }

    if (ownership) {
      fetchString = ownership + fetchString;
    }

    if (this.state.type !== "") {
      var getType = parseInt(this.state.type);
      const types = [
        "school.women_only=1&",
        "school.men_only=1&",
        "school.minority_serving.historically_black=1&",
      ];
      type = types[getType];
      fetchString = type + fetchString;
    }

    if (this.state.region !== "") {
      var getRegion = parseInt(this.state.region) + 1;
      region = "school.region_id=" + getRegion + "&";
      fetchString = region + fetchString;
    }

    if (this.state.size !== "") {
      var getSize = parseInt(this.state.size);
      if (getSize === 0) {
        size = "latest.student.size__range=100..2000&";
      } else if (getSize === 1) {
        size = "latest.student.size__range=2000..15000&";
      } else {
        size = "latest.student.size__range=15000..&";
      }
      fetchString = size + fetchString;
    }

    this.setState({ pageNum: 0 }, () => {
      this.getColleges(fetchString);
    });
  };

  getPath = (name) => {
    name = name.replace("-", " ");
    var path = name.toLowerCase();
    path = "/college/" + path.replace(/ /g, "-");
    return path;
  };

  disableIcons = (type, id, name) => {
    this.setState({ disableIcons: true }, () => {
      this.applyOrFav(type, id, name);
    });
  };

  applyOrFav = (type, id, name) => {
    if (type === "fav") {
      addToFavs(id, name).then((data) => this.enableIcons());
    } else {
      addToApplying(id, name).then((data) => this.enableIcons());
    }
  };

  enableIcons = () => {
    this.setState({ disableIcons: false });
  };

  render() {
    // const data = [].concat(this.state.schools)
    const data = []
      .concat(this.state.schools)
      .filter(function (el) {
        return el["latest.student.size"] >= 250;
      })
      .map((item, i) => (
        <div className="card" style={cardinnerDashboardtop}>
          <div className="card-content">
            <div className="content">
              <Title level={4}>{item["school.name"]}</Title>
              <span className="tag is-info is-light">
                {abbrState(item["school.state"], "name")}
              </span>
              <Divider type="vertical" />
              <span className="tag is-light">
                {numberWithCommas(item["latest.student.size"])} students
              </span>
              <hr />
              {Math.ceil(
                item["latest.admissions.admission_rate.overall"] * 100
              ) === 0 ? null : (
                <div className="columns is-centered is-vcentered">
                  <div className="column">
                    <div className="columns">
                      <div className="column">
                        <Statistic
                          title="SAT 25TH"
                          value={
                            item[
                              "latest.admissions.sat_scores.25th_percentile.critical_reading"
                            ] == null ||
                            item[
                              "latest.admissions.sat_scores.25th_percentile.math"
                            ] == null
                              ? "N/A"
                              : item[
                                  "latest.admissions.sat_scores.25th_percentile.critical_reading"
                                ] +
                                item[
                                  "latest.admissions.sat_scores.25th_percentile.math"
                                ]
                          }
                          suffix="/ 1600"
                        />
                      </div>
                      <div className="column">
                        <Statistic
                          title="SAT 75TH"
                          value={
                            item[
                              "latest.admissions.sat_scores.75th_percentile.critical_reading"
                            ] == null ||
                            item[
                              "latest.admissions.sat_scores.75th_percentile.math"
                            ] == null
                              ? "N/A"
                              : item[
                                  "latest.admissions.sat_scores.75th_percentile.critical_reading"
                                ] +
                                item[
                                  "latest.admissions.sat_scores.75th_percentile.math"
                                ]
                          }
                          suffix="/ 1600"
                        />
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">
                        <Statistic
                          title="ACT 25TH"
                          value={
                            item[
                              "latest.admissions.act_scores.25th_percentile.cumulative"
                            ] == null
                              ? "N/A"
                              : item[
                                  "latest.admissions.act_scores.25th_percentile.cumulative"
                                ]
                          }
                          suffix="/ 36"
                        />
                      </div>
                      <div className="column">
                        <Statistic
                          title="ACT 75TH"
                          value={
                            item[
                              "latest.admissions.act_scores.75th_percentile.cumulative"
                            ] == null
                              ? "N/A"
                              : item[
                                  "latest.admissions.act_scores.75th_percentile.cumulative"
                                ]
                          }
                          suffix="/ 36"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="column">
                    <div className="columns">
                      <div className="column">
                        <p style={{ fontSize: 10, fontWeight: 900 }}>
                          ADMISSIONS RATE{" "}
                          {Math.ceil(
                            item["latest.admissions.admission_rate.overall"] *
                              100
                          )}
                          %{" "}
                        </p>
                        {this.getAdmissionsBar(
                          Math.ceil(
                            item["latest.admissions.admission_rate.overall"] *
                              100
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {this.state.disableIcons ? (
            <Spin />
          ) : (
            <footer className="card-footer">
              <a href="/" className="card-footer-item">
                <Link
                  to={{
                    pathname: this.getPath(item["school.name"]),
                    state: {
                      id: item["id"],
                      name: item["school.name"],
                    },
                  }}
                >
                  {" "}
                  <Tooltip title="More info about this college">
                    <Icon style={{ scale: "2" }} type="info-circle" />
                  </Tooltip>
                </Link>
              </a>
              <a
                href={"https://" + item["school.school_url"]}
                target="_blank"
                rel="noopener noreferrer"
                className="card-footer-item"
              >
                <Tooltip title="Go to college website">
                  <Icon style={{ scale: "2" }} type="link" />
                </Tooltip>
              </a>
              <div
                className="card-footer-item"
                onClick={() =>
                  this.disableIcons("add", item["id"], item["school.name"])
                }
              >
                <Tooltip title="Add this college to Applying list">
                  <Icon
                    style={{ scale: "2" }}
                    theme="twoTone"
                    twoToneColor="#52c41a"
                    type="plus-circle"
                  />
                </Tooltip>
              </div>
              <div
                className="card-footer-item"
                onClick={() =>
                  this.disableIcons("fav", item["id"], item["school.name"])
                }
              >
                <Tooltip title="Add this college to Favorites list">
                  <Icon
                    style={{ scale: "2" }}
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                    type="heart"
                  />
                </Tooltip>
              </div>
            </footer>
          )}
        </div>
      ));

    return (
      <div>
        <Title style={{ textAlign: "center" }} level={1}>
          College Search{" "}
        </Title>

        <div className="columns is-centered">
          <div className="column is-half">
            <div className="field  has-addons">
              <p className="control is-expanded">
                <input
                  className="input is-rounded"
                  value={this.state.searchString}
                  type="searchString"
                  name="searchString"
                  onChange={this.handleChange.bind(this)}
                  placeholder="Search by school name eg. Hamilton College"
                />
              </p>
              <p className="control">
                <div
                  onClick={this.searchClicked}
                  className="button is-rounded is-link"
                >
                  <Icon type="search" />
                </div>
              </p>
            </div>
          </div>
        </div>

        <div className="columns is-centered">
          {this.state.nameSearch && this.state.nothingFound !== true ? (
            <div className="column is-one-fifth">
              <img src={searchImage} alt="" />
            </div>
          ) : (
            <div className="column is-one-fifth">
              <Title style={{ textAlign: "left", fontWeight: 900 }} level={4}>
                Filter Your Results
              </Title>
              <br />

              <div className="columns">
                <div className="column">
                  <div className="columns">
                    <h6 className="title is-6">Years</h6>
                  </div>
                  <div className="columns">
                    <label className="checkbox">
                      <div className="columns is-centered is-vcentered">
                        <div className="column">
                          <input
                            checked={this.state.year4}
                            name="year4"
                            onClick={this.handleChange}
                            type="checkbox"
                          />
                        </div>
                        <div className="column is-two-thirds">
                          <Text>4 Year</Text>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="columns">
                    <label className="checkbox">
                      <div className="columns is-centered is-vcentered">
                        <div className="column">
                          <input
                            checked={this.state.year2}
                            name="year2"
                            onClick={this.handleChange}
                            type="checkbox"
                          />
                        </div>
                        <div className="column is-two-thirds">
                          <Text>2 Year</Text>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <br />

              <div className="columns">
                <div className="column">
                  <div className="columns">
                    <h6 className="title is-6">Type</h6>
                  </div>
                  <div className="columns">
                    <label className="checkbox">
                      <div className="columns is-centered is-vcentered">
                        <div className="column">
                          <input
                            checked={this.state.private}
                            name="private"
                            onClick={this.handleChange}
                            type="checkbox"
                          />
                        </div>
                        <div className="column is-two-thirds">
                          <Text>Private</Text>
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className="columns">
                    <label className="checkbox">
                      <div className="columns is-centered is-vcentered">
                        <div className="column">
                          <input
                            checked={this.state.public}
                            name="public"
                            onClick={this.handleChange}
                            type="checkbox"
                          />
                        </div>
                        <div className="column is-two-thirds">
                          <Text>Public</Text>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <br />

              {this.state.region === "" ? (
                <div className="columns">
                  <div className="column">
                    <div className="columns">
                      <h6 className="subtitle is-6">More Types</h6>
                    </div>
                    <div className="columns">
                      <div className="select">
                        <select
                          value={this.state.type}
                          name="type"
                          onChange={this.handleChange}
                        >
                          <option value="">All</option>
                          <option value="0">Women Only</option>
                          <option value="1">Men Only</option>
                          <option value="2">HBCU</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="columns">
                  <div className="column">
                    <div className="columns">
                      <h6 className="subtitle is-6">More Types</h6>
                    </div>
                    <div className="columns">
                      <div className="select">
                        <select disabled>
                          <option value="">All</option>
                          <option value="0">Women Only</option>
                          <option value="1">Men Only</option>
                          <option value="2">HBCU</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <hr />
              <br />

              {this.state.type === "" ? (
                <div className="columns">
                  <div className="column">
                    <div className="columns">
                      <h6 className="title is-6">State/Region </h6>
                    </div>
                    <div className="columns is-pulled-left">
                      <div className="column is-half">
                        <div className="select">
                          <select
                            value={this.state.region}
                            name="region"
                            onChange={this.handleChange}
                          >
                            <option value="">All</option>
                            <option value="0">
                              New England (CT, ME, MA, NH, RI, VT)
                            </option>
                            <option value="1">
                              Mid East (DE, DC, MD, NJ, NY, PA)
                            </option>
                            <option value="2">
                              Great Lakes (IL, IN, MI, OH, WI)
                            </option>
                            <option value="3">
                              Plains (IA, KS, MN, MO, NE, ND, SD)
                            </option>
                            <option value="4">
                              Southeast (AL, AR, FL, GA, KY, LA, MS, NC, SC, TN,
                              VA, WV)
                            </option>
                            <option value="5">
                              Southwest (AZ, NM, OK, TX)
                            </option>
                            <option value="6">
                              Rocky Mountains (CO, ID, MT, UT, WY)
                            </option>
                            <option value="7">
                              Far West (AK, CA, HI, NV, OR, WA)
                            </option>
                            <option value="8">
                              Outlying Areas (AS, FM, GU, MH, MP, PR, PW, VI)
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="columns">
                  <div className="column">
                    <div className="columns">
                      <h6 className="title is-6">State/Region </h6>
                    </div>
                    <div className="columns is-pulled-left">
                      <div className="column is-half">
                        <div className="select">
                          <select disabled>
                            <option value="">All</option>
                            <option value="0">
                              New England (CT, ME, MA, NH, RI, VT)
                            </option>
                            <option value="1">
                              Mid East (DE, DC, MD, NJ, NY, PA)
                            </option>
                            <option value="2">
                              Great Lakes (IL, IN, MI, OH, WI)
                            </option>
                            <option value="3">
                              Plains (IA, KS, MN, MO, NE, ND, SD)
                            </option>
                            <option value="4">
                              Southeast (AL, AR, FL, GA, KY, LA, MS, NC, SC, TN,
                              VA, WV)
                            </option>
                            <option value="5">
                              Southwest (AZ, NM, OK, TX)
                            </option>
                            <option value="6">
                              Rocky Mountains (CO, ID, MT, UT, WY)
                            </option>
                            <option value="7">
                              Far West (AK, CA, HI, NV, OR, WA)
                            </option>
                            <option value="8">
                              Outlying Areas (AS, FM, GU, MH, MP, PR, PW, VI)
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <hr />
              <br />
              {/* 

            <div className="columns">
              <div className="column">
                <div className="columns">
                  <h6 className="title is-6">Within Radius</h6>
                </div>
                <div className="columns">
                <div className="select">
                  <select>
                    <option>-</option>
                    <option>With options</option>
                  </select>
                </div>
                </div>           
              </div>
            </div>
            <br/>
            <div className="columns">
              <div className="column is-three-fifths">
                <div className="columns">
                  <h6 className="subtitle is-6">Of Zipcode</h6>
                </div>
                <div className="columns">
                  <input className="input" type="text" placeholder="E.g 13323"/>                
                </div>           
              </div>
            </div>

            <hr/>
            <br/> */}
              {/* 


            <div className="columns">
              <div className="column">
                <div className="columns">
                  <h6 className="title is-6">Your SAT Score</h6>
                </div>
                <div className="columns">
                <div className="select">
                  <select value={this.state.sat} name="sat" onChange={this.handleChange}>
                   </select>
                  <select>
                    <option>-</option>
                    <option>With options</option>
                  </select>
                </div>
                </div>           
              </div>
            </div>

            <hr/>
            <br/> */}

              {/* <div className="columns">
              <div className="column">
                <div className="columns">
                  <h6 className="title is-6">Your ACT Score</h6>
                </div>
                <div className="columns">
                <div className="select">
                  <select>
                    <option>-</option>
                    <option>With options</option>
                  </select>
                </div>
                </div>           
              </div>
            </div> */}

              {/* <hr/>
            <br/> */}

              <div className="columns">
                <div className="column">
                  <div className="columns">
                    <h6 className="title is-6">Size</h6>
                  </div>
                  <div className="columns is-pulled-left">
                    <div className="column is-two-thirds">
                      <div className="select">
                        <select
                          value={this.state.size}
                          name="size"
                          onChange={this.handleChange}
                        >
                          <option value="">All</option>
                          <option value="0">
                            Small (less than 2,000 students)
                          </option>
                          <option value="1">
                            Medium (2,000 - 15,000 students)
                          </option>
                          <option value="2">Large (15,000+ students)</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 
          <hr/>
          <br/> */}

              {/* <div className="columns">
            <div className="column">
              <div className="columns">
                <h6 className="title is-6">GPA</h6>
              </div>
              <div className="columns is-pulled-left">
                <div className="column">
                  <div className="select">
                    <select>
                      <option value="">All</option>
                      <option>1.0-1.5</option>
                      <option>1.6-2.0</option>
                      <option>2.1-2.5</option>
                      <option>2.6-3.0</option>
                      <option>3.1-3.5</option>
                      <option>3.6-4.0</option>
                  </select>
                  <br/>
                </div>
                </div>
              </div>           
            </div>
          </div> */}

              {/* <div className="columns">
              <div className="column">
                <div className="columns">
                  <h6 className="title is-6">Tuition + Fees</h6>
                </div>
                <div className="columns">
                <div className="select">
                  <select>
                    <option>-</option>
                    <option>With options</option>
                  </select>
                </div>
                </div>           
              </div>
            </div>

            <hr/>
            <br/> */}

              {/* <div className="columns">
              <div className="column">
                <div className="columns">
                  <h6 className="title is-6">Avg. Net Price</h6>
                </div>
                <div className="columns">
                <div className="select">
                  <select>
                    <option>-</option>
                    <option>With options</option>
                  </select>
                </div>
                </div>           
              </div>
            </div>
            <hr/>
            <br/> */}
              <hr />
              <br />
              <br />
              <div className="columns">
                <div className="column">
                  <div className="columns">
                    <div
                      className="button"
                      onClick={this.resetFilters}
                      style={{ width: "100%" }}
                    >
                      Reset Filters
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <div className="columns">
                <div className="column">
                  <div className="columns">
                    <div
                      className="button is-link"
                      onClick={this.applyFilters}
                      style={{ width: "100%" }}
                    >
                      Apply Filters
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="column is-two-fifths">
            {this.state.nothingFound ? (
              <div>
                <div className="columns is-multiline">
                  <div className="column">
                    <img src={nothingImage} alt="" />
                  </div>
                </div>
                <div className="columns is-centered is-vcentered">
                  <div className="column">
                    <Alert
                      message="No Schools found. Try another search string or remove some filters."
                      type="info"
                    />
                  </div>
                  <div className="column">
                    <div
                      className="button is-danger is-rounded is-small"
                      onClick={this.backtoFilter}
                    >
                      Back to Filter Search
                    </div>
                  </div>
                </div>
              </div>
            ) : this.state.schoolsLoading ? (
              <Spin />
            ) : (
              <div>
                <div className="columns">
                  <div className="column">
                    <div className="box">
                      <div className="columns is-centered is-vcentered">
                        <div className="column">
                          <div className="control">
                            <div className="select">
                              <select
                                value={this.state.sortNum}
                                name="sortNum"
                                onChange={this.handleChange}
                              >
                                <option value={0}>
                                  Sort: Admissions Rate (Low-High)
                                </option>
                                <option value={1}>
                                  Sort: Admissions Rate (High-Low)
                                </option>
                                <option value={2}>Sort: Name (A-Z)</option>
                                <option value={3}>Sort: Name (Z-A)</option>
                                <option value={4}>Sort: SAT (Low-High)</option>
                                <option value={5}>Sort: SAT (High-Low)</option>
                                <option value={6}>Sort: ACT (Low-High)</option>
                                <option value={7}>Sort: ACT (High-Low)</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        {this.state.nameSearch ? (
                          <div className="column">
                            <div
                              className="button is-danger is-rounded is-small"
                              onClick={this.backtoFilter}
                            >
                              Back to Filter Search
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">{data}</div>
                </div>
                <div className="columns" style={{ marginTop: "15%" }}>
                  <div className="column">
                    <Pagination
                      current={this.state.pageNum + 1}
                      onChange={this.changePage}
                      total={this.state.total}
                      defaultPageSize={20}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Schools;
