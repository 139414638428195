import React from "react";
import { Route, Redirect } from "react-router-dom";
// import {user} from "./AuthService";

export const ProtectedRoute = ({
  path,
  component: Component,
  render,
  requiredRole,
  userRole,
  ...rest
}) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (userRole === requiredRole) {
          return Component ? <Component {...props} /> : render(props);
        } else {
          if (userRole === "student") {
            return <Redirect to="/dashboard" />;
          } else if (userRole === "mentor") {
            return <Redirect to="/mentor/dashboard" />;
          } else if (userRole === "admin") {
            return <Redirect to="/admin/dashboard" />;
          } else if (userRole === "org") {
            return <Redirect to="/org/dashboard" />;
          } else {
            return <Redirect to="*" />;
          }
        }
      }}
    />
  );
};

export default ProtectedRoute;
