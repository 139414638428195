import { Modal } from "antd";
import React from "react";
import doneImage from "../../../../assets/team.svg";

export const getPlaybookPath = (name) => {
  return name.replace(/ /g, "-");
};

export function done() {
  Modal.success({
    title: "Awesome! You've completed this playbook.",
    content: (
      <div>
        <div className="columns">
          <div className="column">
            <p>
              Head over to the Playbooks page to get started on the next one.
            </p>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <img src={doneImage} alt="Logo" style={{ width: "100%" }} />
          </div>
        </div>
      </div>
    ),
    onOk() {},
  });
}
