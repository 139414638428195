import React, { Component } from "react";
import "../../../App.css";
import "bulma/css/bulma.css";
import NavBar from "../navbar";
import { Typography, message } from "antd";
import * as mutations from "../../../graphql/mutations";
import moment from "moment";
import FooterPage from "../footer";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Amplify, { API, graphqlOperation, Storage } from "aws-amplify";
import aws_exports from "../../../aws-exports"; // specify the location of aws-exports.js file on your project

// import { Authenticator } from 'aws-amplify-react/dist/Auth';

const { Title } = Typography;
Amplify.configure(aws_exports);

class CreatePost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      value: "",
      name: "",
      title: "",
      file: "",
      visible: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitPass = this.submitPass.bind(this);
  }

  handleChange(e) {
    // If you are using babel, you can use ES 6 dictionary syntax
    // let change = { [e.target.name] = e.target.value }
    let change = {};
    if (e.target) {
      change[e.target.name] = e.target.value;
      this.setState(change);
    } else {
      this.setState({ value: e });
    }
  }

  async submitPost(e) {
    e.preventDefault();

    var date = new Date();
    var formattedDate = moment(date).format("YYYYMMDD");
    const path = "blog/" + formattedDate + "/pic.png";
    Storage.put(path, this.state.file, {
      contentType: "image/png",
    })
      .then((result) => console.log(result))
      .catch((err) => console.log(err));

    const post = {
      title: this.state.title,
      date: date,
      content: this.state.value,
      author: this.state.name,
    };

    await API.graphql(
      graphqlOperation(mutations.createBlogPost, { input: post })
    ).then((data) => this.setState({ title: "", value: "", author: "" }));
  }

  submitPass(e) {
    e.preventDefault();

    console.log(process.env.REACT_APP_ADMIN_BLOG);
    if (this.state.password === process.env.REACT_APP_ADMIN_BLOG) {
      this.setState({ visible: true });
    }
  }

  onChange(e) {
    const file = e.target.files[0];
    this.setState({ file: file });
  }

  render() {
    return (
      <div>
        <NavBar />
        <div className="columns">
          <div className="column">
            <Title style={{ fontSize: 40, fontWeight: 900, marginTop: "2%" }}>
              Submit Blog
            </Title>
            <br />
          </div>
        </div>

        {this.state.visible ? (
          <div>
            <div className="columns is-centered">
              <div className="column is-one-third">
                <label>Title</label>
                <input
                  className="input"
                  type="title"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleChange.bind(this)}
                  placeholder="Title"
                />
              </div>
              <div className="column is-one-third">
                <label>Author</label>
                <input
                  className="input"
                  type="name"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange.bind(this)}
                  placeholder="Author"
                />
              </div>
            </div>
            <div className="columns is-centered">
              <div className="column is-four-fifths">
                <ReactQuill
                  theme="snow"
                  value={this.state.value}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <div
                  className="button is-link"
                  onClick={(evt) =>
                    this.submitPost(evt).then((data) =>
                      message.success("Uploaded!")
                    )
                  }
                >
                  Submit
                </div>
              </div>
              <div className="column">
                <input
                  type="file"
                  accept="image/png"
                  onChange={(evt) => this.onChange(evt)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="columns is-centered">
            <div className="column is-half">
              <input
                className="input"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange.bind(this)}
                placeholder="Password"
              />
              <div
                className="button is-link"
                onClick={(evt) => this.submitPass(evt)}
              >
                Submit
              </div>
            </div>
          </div>
        )}

        <FooterPage />
      </div>
    );
  }
}
export default CreatePost;
