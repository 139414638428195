export const majorsData = [
  {
    Rank: 1,
    Descrip:
      "Petroleum engineering is a field of engineering concerned with the activities related to the production of hydrocarbons, which can be either crude oil or natural gas. Exploration and production are deemed to fall within the upstream sector of the oil and gas industry.",
    Major_code: 2419,
    Major: "PETROLEUM ENGINEERING",
    Total: 2339,
    Men: 2057,
    Women: 282,
    Major_category: "Engineering",
    ShareWomen: 0.120564344,
    Sample_size: 36,
    Employed: 1976,
    Full_time: 1849,
    Part_time: 270,
    Full_time_year_round: 1207,
    Unemployed: 37,
    Unemployment_rate: 0.018380527,
    Median: 110000,
    P25th: 95000,
    P75th: 125000,
    College_jobs: 1534,
    Non_college_jobs: 364,
    Low_wage_jobs: 193,
  },
  {
    Rank: 2,
    Major_code: 2416,
    Descrip:
      "Mining and mineral engineering majors use math and science to study minerals (such as coal and diamonds). They also study the processes for removing them from the earth. They learn how to locate minerals and how to design and develop mines and systems for refining ore. Other topics include the environmental and safety concerns of mining.",
    Major: "MINING AND MINERAL ENGINEERING",
    Total: 756,
    Men: 679,
    Women: 77,
    Major_category: "Engineering",
    ShareWomen: 0.101851852,
    Sample_size: 7,
    Employed: 640,
    Full_time: 556,
    Part_time: 170,
    Full_time_year_round: 388,
    Unemployed: 85,
    Unemployment_rate: 0.117241379,
    Median: 75000,
    P25th: 55000,
    P75th: 90000,
    College_jobs: 350,
    Non_college_jobs: 257,
    Low_wage_jobs: 50,
  },
  {
    Rank: 3,
    Major_code: 2415,
    Descrip:
      "Metallurgical engineers study and produce materials that power our bodies and our world. They transform mineral resources into high-performance alloys, high-purity metals, and new materials used in surgical implants, computer chips, cars, space exploration, and more.",
    Major: "METALLURGICAL ENGINEERING",
    Total: 856,
    Men: 725,
    Women: 131,
    Major_category: "Engineering",
    ShareWomen: 0.153037383,
    Sample_size: 3,
    Employed: 648,
    Full_time: 558,
    Part_time: 133,
    Full_time_year_round: 340,
    Unemployed: 16,
    Unemployment_rate: 0.024096386,
    Median: 73000,
    P25th: 50000,
    P75th: 105000,
    College_jobs: 456,
    Non_college_jobs: 176,
    Low_wage_jobs: 0,
  },
  {
    Rank: 4,
    Major_code: 2417,
    Descrip:
      "Naval architecture and marine engineering can be described as the design of floating vessels and the integration of their components. ",
    Major: "NAVAL ARCHITECTURE AND MARINE ENGINEERING",
    Total: 1258,
    Men: 1123,
    Women: 135,
    Major_category: "Engineering",
    ShareWomen: 0.107313196,
    Sample_size: 16,
    Employed: 758,
    Full_time: 1069,
    Part_time: 150,
    Full_time_year_round: 692,
    Unemployed: 40,
    Unemployment_rate: 0.050125313,
    Median: 70000,
    P25th: 43000,
    P75th: 80000,
    College_jobs: 529,
    Non_college_jobs: 102,
    Low_wage_jobs: 0,
  },
  {
    Rank: 5,
    Major_code: 2405,
    Major: "CHEMICAL ENGINEERING",
    Descrip:
      "Chemical engineering is the branch of engineering that deals with chemical production and the manufacture of products through chemical processes. This includes designing equipment, systems and processes for refining raw materials and for mixing, compounding and processing chemicals to make valuable products.",
    Total: 32260,
    Men: 21239,
    Women: 11021,
    Major_category: "Engineering",
    ShareWomen: 0.341630502,
    Sample_size: 289,
    Employed: 25694,
    Full_time: 23170,
    Part_time: 5180,
    Full_time_year_round: 16697,
    Unemployed: 1672,
    Unemployment_rate: 0.061097712,
    Median: 65000,
    P25th: 50000,
    P75th: 75000,
    College_jobs: 18314,
    Non_college_jobs: 4440,
    Low_wage_jobs: 972,
  },
  {
    Rank: 6,
    Major_code: 2418,
    Major: "NUCLEAR ENGINEERING",
    Descrip:
      "Nuclear engineering is the branch of engineering concerned with the application of breaking down atomic nuclei (fission) or of combining atomic nuclei (fusion), or with the application of other sub-atomic processes based on the principles of nuclear physics.",
    Total: 2573,
    Men: 2200,
    Women: 373,
    Major_category: "Engineering",
    ShareWomen: 0.144966965,
    Sample_size: 17,
    Employed: 1857,
    Full_time: 2038,
    Part_time: 264,
    Full_time_year_round: 1449,
    Unemployed: 400,
    Unemployment_rate: 0.177226407,
    Median: 65000,
    P25th: 50000,
    P75th: 102000,
    College_jobs: 1142,
    Non_college_jobs: 657,
    Low_wage_jobs: 244,
  },
  {
    Rank: 7,
    Major_code: 6202,
    Major: "ACTUARIAL SCIENCE",
    Descrip:
      "Actuarial science is the discipline that applies mathematical and statistical methods to assess risk in insurance, finance, and other industries and professions. More generally, actuaries apply rigorous mathematics to model matters of uncertainty. Actuaries are professionals trained in this discipline.",
    Total: 3777,
    Men: 2110,
    Women: 1667,
    Major_category: "Business",
    ShareWomen: 0.441355573,
    Sample_size: 51,
    Employed: 2912,
    Full_time: 2924,
    Part_time: 296,
    Full_time_year_round: 2482,
    Unemployed: 308,
    Unemployment_rate: 0.095652174,
    Median: 62000,
    P25th: 53000,
    P75th: 72000,
    College_jobs: 1768,
    Non_college_jobs: 314,
    Low_wage_jobs: 259,
  },
  {
    Rank: 8,
    Major_code: 5001,
    Major: "ASTRONOMY AND ASTROPHYSICS",
    Total: 1792,
    Men: 832,
    Women: 960,
    Major_category: "Physical Sciences",
    ShareWomen: 0.535714286,
    Sample_size: 10,
    Employed: 1526,
    Full_time: 1085,
    Part_time: 553,
    Full_time_year_round: 827,
    Unemployed: 33,
    Unemployment_rate: 0.021167415,
    Median: 62000,
    P25th: 31500,
    P75th: 109000,
    College_jobs: 972,
    Non_college_jobs: 500,
    Low_wage_jobs: 220,
  },
  {
    Rank: 9,
    Major_code: 2414,
    Major: "MECHANICAL ENGINEERING",
    Total: 91227,
    Men: 80320,
    Women: 10907,
    Major_category: "Engineering",
    ShareWomen: 0.119558903,
    Sample_size: 1029,
    Employed: 76442,
    Full_time: 71298,
    Part_time: 13101,
    Full_time_year_round: 54639,
    Unemployed: 4650,
    Unemployment_rate: 0.057342278,
    Median: 60000,
    P25th: 48000,
    P75th: 70000,
    College_jobs: 52844,
    Non_college_jobs: 16384,
    Low_wage_jobs: 3253,
  },
  {
    Rank: 10,
    Major_code: 2408,
    Major: "ELECTRICAL ENGINEERING",
    Total: 81527,
    Men: 65511,
    Women: 16016,
    Major_category: "Engineering",
    ShareWomen: 0.196450256,
    Sample_size: 631,
    Employed: 61928,
    Full_time: 55450,
    Part_time: 12695,
    Full_time_year_round: 41413,
    Unemployed: 3895,
    Unemployment_rate: 0.059173845,
    Median: 60000,
    P25th: 45000,
    P75th: 72000,
    College_jobs: 45829,
    Non_college_jobs: 10874,
    Low_wage_jobs: 3170,
  },
  {
    Rank: 11,
    Major_code: 2407,
    Major: "COMPUTER ENGINEERING",
    Total: 41542,
    Men: 33258,
    Women: 8284,
    Major_category: "Engineering",
    ShareWomen: 0.199412643,
    Sample_size: 399,
    Employed: 32506,
    Full_time: 30315,
    Part_time: 5146,
    Full_time_year_round: 23621,
    Unemployed: 2275,
    Unemployment_rate: 0.065409275,
    Median: 60000,
    P25th: 45000,
    P75th: 75000,
    College_jobs: 23694,
    Non_college_jobs: 5721,
    Low_wage_jobs: 980,
  },
  {
    Rank: 12,
    Major_code: 2401,
    Major: "AEROSPACE ENGINEERING",
    Total: 15058,
    Men: 12953,
    Women: 2105,
    Major_category: "Engineering",
    ShareWomen: 0.139792801,
    Sample_size: 147,
    Employed: 11391,
    Full_time: 11106,
    Part_time: 2724,
    Full_time_year_round: 8790,
    Unemployed: 794,
    Unemployment_rate: 0.065162085,
    Median: 60000,
    P25th: 42000,
    P75th: 70000,
    College_jobs: 8184,
    Non_college_jobs: 2425,
    Low_wage_jobs: 372,
  },
  {
    Rank: 13,
    Major_code: 2404,
    Major: "BIOMEDICAL ENGINEERING",
    Total: 14955,
    Men: 8407,
    Women: 6548,
    Major_category: "Engineering",
    ShareWomen: 0.437846874,
    Sample_size: 79,
    Employed: 10047,
    Full_time: 9017,
    Part_time: 2694,
    Full_time_year_round: 5986,
    Unemployed: 1019,
    Unemployment_rate: 0.09208386,
    Median: 60000,
    P25th: 36000,
    P75th: 70000,
    College_jobs: 6439,
    Non_college_jobs: 2471,
    Low_wage_jobs: 789,
  },
  {
    Rank: 14,
    Major_code: 5008,
    Major: "MATERIALS SCIENCE",
    Total: 4279,
    Men: 2949,
    Women: 1330,
    Major_category: "Engineering",
    ShareWomen: 0.310820285,
    Sample_size: 22,
    Employed: 3307,
    Full_time: 2751,
    Part_time: 878,
    Full_time_year_round: 1967,
    Unemployed: 78,
    Unemployment_rate: 0.023042836,
    Median: 60000,
    P25th: 39000,
    P75th: 65000,
    College_jobs: 2626,
    Non_college_jobs: 391,
    Low_wage_jobs: 81,
  },
  {
    Rank: 15,
    Major_code: 2409,
    Major: "ENGINEERING MECHANICS PHYSICS AND SCIENCE",
    Total: 4321,
    Men: 3526,
    Women: 795,
    Major_category: "Engineering",
    ShareWomen: 0.183985189,
    Sample_size: 30,
    Employed: 3608,
    Full_time: 2999,
    Part_time: 811,
    Full_time_year_round: 2004,
    Unemployed: 23,
    Unemployment_rate: 0.006334343,
    Median: 58000,
    P25th: 25000,
    P75th: 74000,
    College_jobs: 2439,
    Non_college_jobs: 947,
    Low_wage_jobs: 263,
  },
  {
    Rank: 16,
    Major_code: 2402,
    Major: "BIOLOGICAL ENGINEERING",
    Total: 8925,
    Men: 6062,
    Women: 2863,
    Major_category: "Engineering",
    ShareWomen: 0.320784314,
    Sample_size: 55,
    Employed: 6170,
    Full_time: 5455,
    Part_time: 1983,
    Full_time_year_round: 3413,
    Unemployed: 589,
    Unemployment_rate: 0.087143069,
    Median: 57100,
    P25th: 40000,
    P75th: 76000,
    College_jobs: 3603,
    Non_college_jobs: 1595,
    Low_wage_jobs: 524,
  },
  {
    Rank: 17,
    Major_code: 2412,
    Major: "INDUSTRIAL AND MANUFACTURING ENGINEERING",
    Total: 18968,
    Men: 12453,
    Women: 6515,
    Major_category: "Engineering",
    ShareWomen: 0.343473218,
    Sample_size: 183,
    Employed: 15604,
    Full_time: 14879,
    Part_time: 2243,
    Full_time_year_round: 11326,
    Unemployed: 699,
    Unemployment_rate: 0.042875544,
    Median: 57000,
    P25th: 37900,
    P75th: 67000,
    College_jobs: 8306,
    Non_college_jobs: 3235,
    Low_wage_jobs: 640,
  },
  {
    Rank: 18,
    Major_code: 2400,
    Major: "GENERAL ENGINEERING",
    Total: 61152,
    Men: 45683,
    Women: 15469,
    Major_category: "Engineering",
    ShareWomen: 0.252959838,
    Sample_size: 425,
    Employed: 44931,
    Full_time: 41235,
    Part_time: 7199,
    Full_time_year_round: 33540,
    Unemployed: 2859,
    Unemployment_rate: 0.059824231,
    Median: 56000,
    P25th: 36000,
    P75th: 69000,
    College_jobs: 26898,
    Non_college_jobs: 11734,
    Low_wage_jobs: 3192,
  },
  {
    Rank: 19,
    Major_code: 2403,
    Major: "ARCHITECTURAL ENGINEERING",
    Total: 2825,
    Men: 1835,
    Women: 990,
    Major_category: "Engineering",
    ShareWomen: 0.350442478,
    Sample_size: 26,
    Employed: 2575,
    Full_time: 2277,
    Part_time: 343,
    Full_time_year_round: 1848,
    Unemployed: 170,
    Unemployment_rate: 0.061930783,
    Median: 54000,
    P25th: 38000,
    P75th: 65000,
    College_jobs: 1665,
    Non_college_jobs: 649,
    Low_wage_jobs: 137,
  },
  {
    Rank: 20,
    Major_code: 3201,
    Major: "COURT REPORTING",
    Total: 1148,
    Men: 877,
    Women: 271,
    Major_category: "Law & Public Policy",
    ShareWomen: 0.236062718,
    Sample_size: 14,
    Employed: 930,
    Full_time: 808,
    Part_time: 223,
    Full_time_year_round: 808,
    Unemployed: 11,
    Unemployment_rate: 0.011689692,
    Median: 54000,
    P25th: 50000,
    P75th: 54000,
    College_jobs: 402,
    Non_college_jobs: 528,
    Low_wage_jobs: 144,
  },
  {
    Rank: 21,
    Major_code: 2102,
    Major: "COMPUTER SCIENCE",
    Total: 128319,
    Men: 99743,
    Women: 28576,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.222695002,
    Sample_size: 1196,
    Employed: 102087,
    Full_time: 91485,
    Part_time: 18726,
    Full_time_year_round: 70932,
    Unemployed: 6884,
    Unemployment_rate: 0.063172771,
    Median: 53000,
    P25th: 39000,
    P75th: 70000,
    College_jobs: 68622,
    Non_college_jobs: 25667,
    Low_wage_jobs: 5144,
  },
  {
    Rank: 22,
    Major_code: 1104,
    Major: "FOOD SCIENCE",
    Total: "",
    Men: "",
    Women: "",
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: "",
    Sample_size: 36,
    Employed: 3149,
    Full_time: 2558,
    Part_time: 1121,
    Full_time_year_round: 1735,
    Unemployed: 338,
    Unemployment_rate: 0.09693146,
    Median: 53000,
    P25th: 32000,
    P75th: 70000,
    College_jobs: 1183,
    Non_college_jobs: 1274,
    Low_wage_jobs: 485,
  },
  {
    Rank: 23,
    Major_code: 2502,
    Major: "ELECTRICAL ENGINEERING TECHNOLOGY",
    Total: 11565,
    Men: 8181,
    Women: 3384,
    Major_category: "Engineering",
    ShareWomen: 0.292607004,
    Sample_size: 97,
    Employed: 8587,
    Full_time: 7530,
    Part_time: 1873,
    Full_time_year_round: 5681,
    Unemployed: 824,
    Unemployment_rate: 0.087557114,
    Median: 52000,
    P25th: 35000,
    P75th: 60000,
    College_jobs: 5126,
    Non_college_jobs: 2686,
    Low_wage_jobs: 696,
  },
  {
    Rank: 24,
    Major_code: 2413,
    Major: "MATERIALS ENGINEERING AND MATERIALS SCIENCE",
    Total: 2993,
    Men: 2020,
    Women: 973,
    Major_category: "Engineering",
    ShareWomen: 0.325091881,
    Sample_size: 22,
    Employed: 2449,
    Full_time: 1658,
    Part_time: 1040,
    Full_time_year_round: 1151,
    Unemployed: 70,
    Unemployment_rate: 0.027788805,
    Median: 52000,
    P25th: 35000,
    P75th: 62000,
    College_jobs: 1911,
    Non_college_jobs: 305,
    Low_wage_jobs: 70,
  },
  {
    Rank: 25,
    Major_code: 6212,
    Major: "MANAGEMENT INFORMATION SYSTEMS AND STATISTICS",
    Total: 18713,
    Men: 13496,
    Women: 5217,
    Major_category: "Business",
    ShareWomen: 0.278790146,
    Sample_size: 278,
    Employed: 16413,
    Full_time: 15141,
    Part_time: 2420,
    Full_time_year_round: 13017,
    Unemployed: 1015,
    Unemployment_rate: 0.058239614,
    Median: 51000,
    P25th: 38000,
    P75th: 60000,
    College_jobs: 6342,
    Non_college_jobs: 5741,
    Low_wage_jobs: 708,
  },
  {
    Rank: 26,
    Major_code: 2406,
    Major: "CIVIL ENGINEERING",
    Total: 53153,
    Men: 41081,
    Women: 12072,
    Major_category: "Engineering",
    ShareWomen: 0.227117943,
    Sample_size: 565,
    Employed: 43041,
    Full_time: 38302,
    Part_time: 10080,
    Full_time_year_round: 29196,
    Unemployed: 3270,
    Unemployment_rate: 0.070609574,
    Median: 50000,
    P25th: 40000,
    P75th: 60000,
    College_jobs: 28526,
    Non_college_jobs: 9356,
    Low_wage_jobs: 2899,
  },
  {
    Rank: 27,
    Major_code: 5601,
    Major: "CONSTRUCTION SERVICES",
    Total: 18498,
    Men: 16820,
    Women: 1678,
    Major_category: "Industrial Arts & Consumer Services",
    ShareWomen: 0.090712509,
    Sample_size: 295,
    Employed: 16318,
    Full_time: 15690,
    Part_time: 1751,
    Full_time_year_round: 12313,
    Unemployed: 1042,
    Unemployment_rate: 0.060023041,
    Median: 50000,
    P25th: 36000,
    P75th: 60000,
    College_jobs: 3275,
    Non_college_jobs: 5351,
    Low_wage_jobs: 703,
  },
  {
    Rank: 28,
    Major_code: 6204,
    Major: "OPERATIONS LOGISTICS AND E-COMMERCE",
    Total: 11732,
    Men: 7921,
    Women: 3811,
    Major_category: "Business",
    ShareWomen: 0.32483805,
    Sample_size: 156,
    Employed: 10027,
    Full_time: 9639,
    Part_time: 1183,
    Full_time_year_round: 7724,
    Unemployed: 504,
    Unemployment_rate: 0.047858703,
    Median: 50000,
    P25th: 40000,
    P75th: 60000,
    College_jobs: 1466,
    Non_college_jobs: 3629,
    Low_wage_jobs: 285,
  },
  {
    Rank: 29,
    Major_code: 2499,
    Major: "MISCELLANEOUS ENGINEERING",
    Total: 9133,
    Men: 7398,
    Women: 1735,
    Major_category: "Engineering",
    ShareWomen: 0.189970437,
    Sample_size: 118,
    Employed: 7428,
    Full_time: 6811,
    Part_time: 1662,
    Full_time_year_round: 5476,
    Unemployed: 597,
    Unemployment_rate: 0.074392523,
    Median: 50000,
    P25th: 39000,
    P75th: 65000,
    College_jobs: 3445,
    Non_college_jobs: 2426,
    Low_wage_jobs: 365,
  },
  {
    Rank: 30,
    Major_code: 5402,
    Major: "PUBLIC POLICY",
    Total: 5978,
    Men: 2639,
    Women: 3339,
    Major_category: "Law & Public Policy",
    ShareWomen: 0.558548009,
    Sample_size: 55,
    Employed: 4547,
    Full_time: 4163,
    Part_time: 1306,
    Full_time_year_round: 2776,
    Unemployed: 670,
    Unemployment_rate: 0.128426299,
    Median: 50000,
    P25th: 35000,
    P75th: 70000,
    College_jobs: 1550,
    Non_college_jobs: 1871,
    Low_wage_jobs: 340,
  },
  {
    Rank: 31,
    Major_code: 2410,
    Major: "ENVIRONMENTAL ENGINEERING",
    Total: 4047,
    Men: 2662,
    Women: 1385,
    Major_category: "Engineering",
    ShareWomen: 0.342228811,
    Sample_size: 26,
    Employed: 2983,
    Full_time: 2384,
    Part_time: 930,
    Full_time_year_round: 1951,
    Unemployed: 308,
    Unemployment_rate: 0.093588575,
    Median: 50000,
    P25th: 42000,
    P75th: 56000,
    College_jobs: 2028,
    Non_college_jobs: 830,
    Low_wage_jobs: 260,
  },
  {
    Rank: 32,
    Major_code: 2500,
    Major: "ENGINEERING TECHNOLOGIES",
    Total: 3600,
    Men: 2695,
    Women: 905,
    Major_category: "Engineering",
    ShareWomen: 0.251388889,
    Sample_size: 39,
    Employed: 2799,
    Full_time: 2257,
    Part_time: 689,
    Full_time_year_round: 1723,
    Unemployed: 163,
    Unemployment_rate: 0.055030385,
    Median: 50000,
    P25th: 43000,
    P75th: 60000,
    College_jobs: 1017,
    Non_college_jobs: 1269,
    Low_wage_jobs: 142,
  },
  {
    Rank: 33,
    Major_code: 6099,
    Major: "MISCELLANEOUS FINE ARTS",
    Total: 3340,
    Men: 1970,
    Women: 1370,
    Major_category: "Arts",
    ShareWomen: 0.410179641,
    Sample_size: 30,
    Employed: 2914,
    Full_time: 2049,
    Part_time: 1067,
    Full_time_year_round: 1200,
    Unemployed: 286,
    Unemployment_rate: 0.089375,
    Median: 50000,
    P25th: 25000,
    P75th: 66000,
    College_jobs: 693,
    Non_college_jobs: 1714,
    Low_wage_jobs: 755,
  },
  {
    Rank: 34,
    Major_code: 2411,
    Major: "GEOLOGICAL AND GEOPHYSICAL ENGINEERING",
    Total: 720,
    Men: 488,
    Women: 232,
    Major_category: "Engineering",
    ShareWomen: 0.322222222,
    Sample_size: 5,
    Employed: 604,
    Full_time: 524,
    Part_time: 126,
    Full_time_year_round: 396,
    Unemployed: 49,
    Unemployment_rate: 0.075038285,
    Median: 50000,
    P25th: 42800,
    P75th: 57000,
    College_jobs: 501,
    Non_college_jobs: 50,
    Low_wage_jobs: 49,
  },
  {
    Rank: 35,
    Major_code: 6107,
    Major: "NURSING",
    Total: 209394,
    Men: 21773,
    Women: 187621,
    Major_category: "Health",
    ShareWomen: 0.896018988,
    Sample_size: 2554,
    Employed: 180903,
    Full_time: 151191,
    Part_time: 40818,
    Full_time_year_round: 122817,
    Unemployed: 8497,
    Unemployment_rate: 0.044862724,
    Median: 48000,
    P25th: 39000,
    P75th: 58000,
    College_jobs: 151643,
    Non_college_jobs: 26146,
    Low_wage_jobs: 6193,
  },
  {
    Rank: 36,
    Major_code: 6207,
    Major: "FINANCE",
    Total: 174506,
    Men: 115030,
    Women: 59476,
    Major_category: "Business",
    ShareWomen: 0.340824957,
    Sample_size: 2189,
    Employed: 145696,
    Full_time: 137921,
    Part_time: 21463,
    Full_time_year_round: 108595,
    Unemployed: 9413,
    Unemployment_rate: 0.060686356,
    Median: 47000,
    P25th: 35000,
    P75th: 64000,
    College_jobs: 24243,
    Non_college_jobs: 48447,
    Low_wage_jobs: 9910,
  },
  {
    Rank: 37,
    Major_code: 5501,
    Major: "ECONOMICS",
    Total: 139247,
    Men: 89749,
    Women: 49498,
    Major_category: "Social Science",
    ShareWomen: 0.355469059,
    Sample_size: 1322,
    Employed: 104117,
    Full_time: 96567,
    Part_time: 25325,
    Full_time_year_round: 70740,
    Unemployed: 11452,
    Unemployment_rate: 0.099092317,
    Median: 47000,
    P25th: 35000,
    P75th: 65000,
    College_jobs: 25582,
    Non_college_jobs: 37057,
    Low_wage_jobs: 10653,
  },
  {
    Rank: 38,
    Major_code: 6205,
    Major: "BUSINESS ECONOMICS",
    Total: 13302,
    Men: 7575,
    Women: 5727,
    Major_category: "Business",
    ShareWomen: 0.430536761,
    Sample_size: 199,
    Employed: 10914,
    Full_time: 10048,
    Part_time: 1937,
    Full_time_year_round: 8000,
    Unemployed: 1165,
    Unemployment_rate: 0.096448381,
    Median: 46000,
    P25th: 33000,
    P75th: 58000,
    College_jobs: 1578,
    Non_college_jobs: 4612,
    Low_wage_jobs: 1284,
  },
  {
    Rank: 39,
    Major_code: 2503,
    Major: "INDUSTRIAL PRODUCTION TECHNOLOGIES",
    Total: 4631,
    Men: 3477,
    Women: 1154,
    Major_category: "Engineering",
    ShareWomen: 0.24919024,
    Sample_size: 73,
    Employed: 4428,
    Full_time: 3988,
    Part_time: 597,
    Full_time_year_round: 3242,
    Unemployed: 129,
    Unemployment_rate: 0.028308097,
    Median: 46000,
    P25th: 35000,
    P75th: 65000,
    College_jobs: 1394,
    Non_college_jobs: 2454,
    Low_wage_jobs: 480,
  },
  {
    Rank: 40,
    Major_code: 5102,
    Major: "NUCLEAR, INDUSTRIAL RADIOLOGY, AND BIOLOGICAL TECHNOLOGIES",
    Total: 2116,
    Men: 528,
    Women: 1588,
    Major_category: "Physical Sciences",
    ShareWomen: 0.75047259,
    Sample_size: 31,
    Employed: 1778,
    Full_time: 1392,
    Part_time: 579,
    Full_time_year_round: 1115,
    Unemployed: 137,
    Unemployment_rate: 0.07154047,
    Median: 46000,
    P25th: 38000,
    P75th: 53000,
    College_jobs: 162,
    Non_college_jobs: 1475,
    Low_wage_jobs: 124,
  },
  {
    Rank: 41,
    Major_code: 6201,
    Major: "ACCOUNTING",
    Total: 198633,
    Men: 94519,
    Women: 104114,
    Major_category: "Business",
    ShareWomen: 0.524152583,
    Sample_size: 2042,
    Employed: 165527,
    Full_time: 151967,
    Part_time: 27693,
    Full_time_year_round: 123169,
    Unemployed: 12411,
    Unemployment_rate: 0.069749014,
    Median: 45000,
    P25th: 34000,
    P75th: 56000,
    College_jobs: 11417,
    Non_college_jobs: 39323,
    Low_wage_jobs: 10886,
  },
  {
    Rank: 42,
    Major_code: 3700,
    Major: "MATHEMATICS",
    Total: 72397,
    Men: 39956,
    Women: 32441,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.448098678,
    Sample_size: 541,
    Employed: 58118,
    Full_time: 46399,
    Part_time: 18079,
    Full_time_year_round: 33738,
    Unemployed: 2884,
    Unemployment_rate: 0.047277138,
    Median: 45000,
    P25th: 33000,
    P75th: 60000,
    College_jobs: 34800,
    Non_college_jobs: 14829,
    Low_wage_jobs: 4569,
  },
  {
    Rank: 43,
    Major_code: 2100,
    Major: "COMPUTER AND INFORMATION SYSTEMS",
    Total: 36698,
    Men: 27392,
    Women: 9306,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.253583302,
    Sample_size: 425,
    Employed: 28459,
    Full_time: 26348,
    Part_time: 4332,
    Full_time_year_round: 21130,
    Unemployed: 2934,
    Unemployment_rate: 0.093460326,
    Median: 45000,
    P25th: 30000,
    P75th: 60000,
    College_jobs: 13344,
    Non_college_jobs: 11783,
    Low_wage_jobs: 1672,
  },
  {
    Rank: 44,
    Major_code: 5007,
    Major: "PHYSICS",
    Total: 32142,
    Men: 23080,
    Women: 9062,
    Major_category: "Physical Sciences",
    ShareWomen: 0.281936407,
    Sample_size: 142,
    Employed: 25302,
    Full_time: 19428,
    Part_time: 8721,
    Full_time_year_round: 14389,
    Unemployed: 1282,
    Unemployment_rate: 0.048224496,
    Median: 45000,
    P25th: 30000,
    P75th: 68000,
    College_jobs: 18674,
    Non_college_jobs: 4576,
    Low_wage_jobs: 1823,
  },
  {
    Rank: 45,
    Major_code: 6105,
    Major: "MEDICAL TECHNOLOGIES TECHNICIANS",
    Total: 15914,
    Men: 3916,
    Women: 11998,
    Major_category: "Health",
    ShareWomen: 0.75392736,
    Sample_size: 190,
    Employed: 13150,
    Full_time: 11510,
    Part_time: 2665,
    Full_time_year_round: 9005,
    Unemployed: 505,
    Unemployment_rate: 0.03698279,
    Median: 45000,
    P25th: 36000,
    P75th: 50000,
    College_jobs: 5546,
    Non_college_jobs: 7176,
    Low_wage_jobs: 1002,
  },
  {
    Rank: 46,
    Major_code: 2105,
    Major: "INFORMATION SCIENCES",
    Total: 11913,
    Men: 9005,
    Women: 2908,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.244103081,
    Sample_size: 158,
    Employed: 9881,
    Full_time: 9105,
    Part_time: 1468,
    Full_time_year_round: 7378,
    Unemployed: 639,
    Unemployment_rate: 0.060741445,
    Median: 45000,
    P25th: 32500,
    P75th: 58000,
    College_jobs: 4390,
    Non_college_jobs: 4102,
    Low_wage_jobs: 608,
  },
  {
    Rank: 47,
    Major_code: 3702,
    Major: "STATISTICS AND DECISION SCIENCE",
    Total: 6251,
    Men: 2960,
    Women: 3291,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.526475764,
    Sample_size: 37,
    Employed: 4247,
    Full_time: 3190,
    Part_time: 1840,
    Full_time_year_round: 2151,
    Unemployed: 401,
    Unemployment_rate: 0.086273666,
    Median: 45000,
    P25th: 26700,
    P75th: 60000,
    College_jobs: 2298,
    Non_college_jobs: 1200,
    Low_wage_jobs: 343,
  },
  {
    Rank: 48,
    Major_code: 3701,
    Major: "APPLIED MATHEMATICS",
    Total: 4939,
    Men: 2794,
    Women: 2145,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.434298441,
    Sample_size: 45,
    Employed: 3854,
    Full_time: 3465,
    Part_time: 1176,
    Full_time_year_round: 2593,
    Unemployed: 385,
    Unemployment_rate: 0.090823307,
    Median: 45000,
    P25th: 34000,
    P75th: 63000,
    College_jobs: 2437,
    Non_college_jobs: 803,
    Low_wage_jobs: 357,
  },
  {
    Rank: 49,
    Major_code: 3607,
    Major: "PHARMACOLOGY",
    Total: 1762,
    Men: 515,
    Women: 1247,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.707718502,
    Sample_size: 3,
    Employed: 1144,
    Full_time: 657,
    Part_time: 532,
    Full_time_year_round: 565,
    Unemployed: 107,
    Unemployment_rate: 0.085531575,
    Median: 45000,
    P25th: 40000,
    P75th: 45000,
    College_jobs: 603,
    Non_college_jobs: 478,
    Low_wage_jobs: 93,
  },
  {
    Rank: 50,
    Major_code: 5006,
    Major: "OCEANOGRAPHY",
    Total: 2418,
    Men: 752,
    Women: 1666,
    Major_category: "Physical Sciences",
    ShareWomen: 0.688999173,
    Sample_size: 36,
    Employed: 1638,
    Full_time: 1931,
    Part_time: 379,
    Full_time_year_round: 1595,
    Unemployed: 99,
    Unemployment_rate: 0.056994819,
    Median: 44700,
    P25th: 23000,
    P75th: 50000,
    College_jobs: 459,
    Non_college_jobs: 996,
    Low_wage_jobs: 186,
  },
  {
    Rank: 51,
    Major_code: 2501,
    Major: "ENGINEERING AND INDUSTRIAL MANAGEMENT",
    Total: 2906,
    Men: 2400,
    Women: 506,
    Major_category: "Engineering",
    ShareWomen: 0.174122505,
    Sample_size: 29,
    Employed: 2125,
    Full_time: 1992,
    Part_time: 462,
    Full_time_year_round: 1358,
    Unemployed: 74,
    Unemployment_rate: 0.03365166,
    Median: 44000,
    P25th: 30000,
    P75th: 50000,
    College_jobs: 482,
    Non_college_jobs: 844,
    Low_wage_jobs: 245,
  },
  {
    Rank: 52,
    Major_code: 6104,
    Major: "MEDICAL ASSISTING SERVICES",
    Total: 11123,
    Men: 803,
    Women: 10320,
    Major_category: "Health",
    ShareWomen: 0.927807246,
    Sample_size: 67,
    Employed: 9168,
    Full_time: 5643,
    Part_time: 4107,
    Full_time_year_round: 4290,
    Unemployed: 407,
    Unemployment_rate: 0.042506527,
    Median: 42000,
    P25th: 30000,
    P75th: 65000,
    College_jobs: 2091,
    Non_college_jobs: 6948,
    Low_wage_jobs: 1270,
  },
  {
    Rank: 53,
    Major_code: 4005,
    Major: "MATHEMATICS AND COMPUTER SCIENCE",
    Total: 609,
    Men: 500,
    Women: 109,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.178981938,
    Sample_size: 7,
    Employed: 559,
    Full_time: 584,
    Part_time: 0,
    Full_time_year_round: 391,
    Unemployed: 0,
    Unemployment_rate: 0,
    Median: 42000,
    P25th: 30000,
    P75th: 78000,
    College_jobs: 452,
    Non_college_jobs: 67,
    Low_wage_jobs: 25,
  },
  {
    Rank: 54,
    Major_code: 2101,
    Major: "COMPUTER PROGRAMMING AND DATA PROCESSING",
    Total: 4168,
    Men: 3046,
    Women: 1122,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.269193858,
    Sample_size: 43,
    Employed: 3257,
    Full_time: 3204,
    Part_time: 482,
    Full_time_year_round: 2453,
    Unemployed: 419,
    Unemployment_rate: 0.11398259,
    Median: 41300,
    P25th: 20000,
    P75th: 46000,
    College_jobs: 2024,
    Non_college_jobs: 1033,
    Low_wage_jobs: 263,
  },
  {
    Rank: 55,
    Major_code: 4006,
    Major: "COGNITIVE SCIENCE AND BIOPSYCHOLOGY",
    Total: 3831,
    Men: 1667,
    Women: 2164,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.56486557,
    Sample_size: 25,
    Employed: 2741,
    Full_time: 2470,
    Part_time: 711,
    Full_time_year_round: 1584,
    Unemployed: 223,
    Unemployment_rate: 0.075236167,
    Median: 41000,
    P25th: 20000,
    P75th: 60000,
    College_jobs: 1369,
    Non_college_jobs: 921,
    Low_wage_jobs: 135,
  },
  {
    Rank: 56,
    Major_code: 2303,
    Major: "SCHOOL STUDENT COUNSELING",
    Total: 818,
    Men: 119,
    Women: 699,
    Major_category: "Education",
    ShareWomen: 0.854523227,
    Sample_size: 4,
    Employed: 730,
    Full_time: 595,
    Part_time: 135,
    Full_time_year_round: 545,
    Unemployed: 88,
    Unemployment_rate: 0.107579462,
    Median: 41000,
    P25th: 41000,
    P75th: 43000,
    College_jobs: 509,
    Non_college_jobs: 221,
    Low_wage_jobs: 0,
  },
  {
    Rank: 57,
    Major_code: 5505,
    Major: "INTERNATIONAL RELATIONS",
    Total: 28187,
    Men: 10345,
    Women: 17842,
    Major_category: "Social Science",
    ShareWomen: 0.632986838,
    Sample_size: 219,
    Employed: 21190,
    Full_time: 18681,
    Part_time: 5563,
    Full_time_year_round: 13583,
    Unemployed: 2271,
    Unemployment_rate: 0.096798943,
    Median: 40100,
    P25th: 31200,
    P75th: 53000,
    College_jobs: 6774,
    Non_college_jobs: 9570,
    Low_wage_jobs: 2499,
  },
  {
    Rank: 58,
    Major_code: 6200,
    Major: "GENERAL BUSINESS",
    Total: 234590,
    Men: 132238,
    Women: 102352,
    Major_category: "Business",
    ShareWomen: 0.436301633,
    Sample_size: 2380,
    Employed: 190183,
    Full_time: 171385,
    Part_time: 36241,
    Full_time_year_round: 138299,
    Unemployed: 14946,
    Unemployment_rate: 0.072861468,
    Median: 40000,
    P25th: 30000,
    P75th: 55000,
    College_jobs: 29334,
    Non_college_jobs: 100831,
    Low_wage_jobs: 27320,
  },
  {
    Rank: 59,
    Major_code: 1401,
    Major: "ARCHITECTURE",
    Total: 46420,
    Men: 25463,
    Women: 20957,
    Major_category: "Engineering",
    ShareWomen: 0.451464886,
    Sample_size: 362,
    Employed: 34158,
    Full_time: 29223,
    Part_time: 10206,
    Full_time_year_round: 20026,
    Unemployed: 4366,
    Unemployment_rate: 0.113331949,
    Median: 40000,
    P25th: 31000,
    P75th: 50000,
    College_jobs: 16178,
    Non_college_jobs: 13724,
    Low_wage_jobs: 4221,
  },
  {
    Rank: 60,
    Major_code: 6210,
    Major: "INTERNATIONAL BUSINESS",
    Total: 25894,
    Men: 10624,
    Women: 15270,
    Major_category: "Business",
    ShareWomen: 0.589711902,
    Sample_size: 260,
    Employed: 19660,
    Full_time: 17563,
    Part_time: 4890,
    Full_time_year_round: 12823,
    Unemployed: 2092,
    Unemployment_rate: 0.096175064,
    Median: 40000,
    P25th: 30000,
    P75th: 50000,
    College_jobs: 3383,
    Non_college_jobs: 9482,
    Low_wage_jobs: 3046,
  },
  {
    Rank: 61,
    Major_code: 6108,
    Major: "PHARMACY PHARMACEUTICAL SCIENCES AND ADMINISTRATION",
    Total: 23551,
    Men: 8697,
    Women: 14854,
    Major_category: "Health",
    ShareWomen: 0.630716318,
    Sample_size: 38,
    Employed: 16620,
    Full_time: 12537,
    Part_time: 5346,
    Full_time_year_round: 9131,
    Unemployed: 977,
    Unemployment_rate: 0.055520827,
    Median: 40000,
    P25th: 20000,
    P75th: 90000,
    College_jobs: 11573,
    Non_college_jobs: 4493,
    Low_wage_jobs: 1121,
  },
  {
    Rank: 62,
    Major_code: 3603,
    Major: "MOLECULAR BIOLOGY",
    Total: 18300,
    Men: 7426,
    Women: 10874,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.59420765,
    Sample_size: 90,
    Employed: 11581,
    Full_time: 9441,
    Part_time: 4590,
    Full_time_year_round: 6183,
    Unemployed: 1067,
    Unemployment_rate: 0.084361164,
    Median: 40000,
    P25th: 29000,
    P75th: 47000,
    College_jobs: 7225,
    Non_college_jobs: 3145,
    Low_wage_jobs: 1168,
  },
  {
    Rank: 63,
    Major_code: 6299,
    Major: "MISCELLANEOUS BUSINESS & MEDICAL ADMINISTRATION",
    Total: 17947,
    Men: 10285,
    Women: 7662,
    Major_category: "Business",
    ShareWomen: 0.42692372,
    Sample_size: 244,
    Employed: 14826,
    Full_time: 13364,
    Part_time: 3366,
    Full_time_year_round: 10637,
    Unemployed: 1150,
    Unemployment_rate: 0.071982974,
    Median: 40000,
    P25th: 30000,
    P75th: 51000,
    College_jobs: 2236,
    Non_college_jobs: 8937,
    Low_wage_jobs: 1758,
  },
  {
    Rank: 64,
    Major_code: 1101,
    Major: "AGRICULTURE PRODUCTION AND MANAGEMENT",
    Total: 14240,
    Men: 9658,
    Women: 4582,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.321769663,
    Sample_size: 273,
    Employed: 12323,
    Full_time: 11119,
    Part_time: 2196,
    Full_time_year_round: 9093,
    Unemployed: 649,
    Unemployment_rate: 0.050030836,
    Median: 40000,
    P25th: 25000,
    P75th: 50000,
    College_jobs: 1925,
    Non_college_jobs: 6221,
    Low_wage_jobs: 1362,
  },
  {
    Rank: 65,
    Major_code: 1100,
    Major: "GENERAL AGRICULTURE",
    Total: 10399,
    Men: 6053,
    Women: 4346,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.4179248,
    Sample_size: 158,
    Employed: 8884,
    Full_time: 7589,
    Part_time: 2031,
    Full_time_year_round: 5888,
    Unemployed: 178,
    Unemployment_rate: 0.019642463,
    Median: 40000,
    P25th: 30000,
    P75th: 50000,
    College_jobs: 2418,
    Non_college_jobs: 4717,
    Low_wage_jobs: 839,
  },
  {
    Rank: 66,
    Major_code: 2599,
    Major: "MISCELLANEOUS ENGINEERING TECHNOLOGIES",
    Total: 8804,
    Men: 7043,
    Women: 1761,
    Major_category: "Engineering",
    ShareWomen: 0.200022717,
    Sample_size: 125,
    Employed: 7502,
    Full_time: 7001,
    Part_time: 1240,
    Full_time_year_round: 5825,
    Unemployed: 416,
    Unemployment_rate: 0.05253852,
    Median: 40000,
    P25th: 30400,
    P75th: 56000,
    College_jobs: 2446,
    Non_college_jobs: 3896,
    Low_wage_jobs: 386,
  },
  {
    Rank: 67,
    Major_code: 2504,
    Major: "MECHANICAL ENGINEERING RELATED TECHNOLOGIES",
    Total: 4790,
    Men: 4419,
    Women: 371,
    Major_category: "Engineering",
    ShareWomen: 0.077453027,
    Sample_size: 71,
    Employed: 4186,
    Full_time: 4175,
    Part_time: 247,
    Full_time_year_round: 3607,
    Unemployed: 250,
    Unemployment_rate: 0.056357078,
    Median: 40000,
    P25th: 27000,
    P75th: 52000,
    College_jobs: 1861,
    Non_college_jobs: 2121,
    Low_wage_jobs: 406,
  },
  {
    Rank: 68,
    Major_code: 3605,
    Major: "GENETICS",
    Total: 3635,
    Men: 1761,
    Women: 1874,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.515543329,
    Sample_size: 11,
    Employed: 2463,
    Full_time: 1787,
    Part_time: 847,
    Full_time_year_round: 1487,
    Unemployed: 87,
    Unemployment_rate: 0.034117647,
    Median: 40000,
    P25th: 34000,
    P75th: 45000,
    College_jobs: 1675,
    Non_college_jobs: 678,
    Low_wage_jobs: 201,
  },
  {
    Rank: 69,
    Major_code: 5599,
    Major: "MISCELLANEOUS SOCIAL SCIENCES",
    Total: 3283,
    Men: 1499,
    Women: 1784,
    Major_category: "Social Science",
    ShareWomen: 0.543405422,
    Sample_size: 28,
    Employed: 2727,
    Full_time: 2183,
    Part_time: 907,
    Full_time_year_round: 1530,
    Unemployed: 215,
    Unemployment_rate: 0.073079538,
    Median: 40000,
    P25th: 30000,
    P75th: 54000,
    College_jobs: 744,
    Non_college_jobs: 1654,
    Low_wage_jobs: 573,
  },
  {
    Rank: 70,
    Major_code: 6403,
    Major: "UNITED STATES HISTORY",
    Total: 3079,
    Men: 1756,
    Women: 1323,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.429684963,
    Sample_size: 22,
    Employed: 2787,
    Full_time: 2103,
    Part_time: 839,
    Full_time_year_round: 1274,
    Unemployed: 138,
    Unemployment_rate: 0.047179487,
    Median: 40000,
    P25th: 30000,
    P75th: 42000,
    College_jobs: 801,
    Non_college_jobs: 1591,
    Low_wage_jobs: 302,
  },
  {
    Rank: 71,
    Major_code: 5205,
    Major: "INDUSTRIAL AND ORGANIZATIONAL PSYCHOLOGY",
    Total: 3014,
    Men: 1075,
    Women: 1939,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.643331121,
    Sample_size: 24,
    Employed: 2343,
    Full_time: 1644,
    Part_time: 1095,
    Full_time_year_round: 1409,
    Unemployed: 286,
    Unemployment_rate: 0.108786611,
    Median: 40000,
    P25th: 32000,
    P75th: 53000,
    College_jobs: 559,
    Non_college_jobs: 1224,
    Low_wage_jobs: 272,
  },
  {
    Rank: 72,
    Major_code: 1102,
    Major: "AGRICULTURAL ECONOMICS",
    Total: 2439,
    Men: 1749,
    Women: 690,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.282902829,
    Sample_size: 44,
    Employed: 2174,
    Full_time: 1819,
    Part_time: 620,
    Full_time_year_round: 1528,
    Unemployed: 182,
    Unemployment_rate: 0.077249576,
    Median: 40000,
    P25th: 27000,
    P75th: 54000,
    College_jobs: 535,
    Non_college_jobs: 893,
    Low_wage_jobs: 94,
  },
  {
    Rank: 73,
    Major_code: 5000,
    Major: "PHYSICAL SCIENCES",
    Total: 1436,
    Men: 894,
    Women: 542,
    Major_category: "Physical Sciences",
    ShareWomen: 0.377437326,
    Sample_size: 10,
    Employed: 1146,
    Full_time: 768,
    Part_time: 437,
    Full_time_year_round: 653,
    Unemployed: 42,
    Unemployment_rate: 0.035353535,
    Median: 40000,
    P25th: 30000,
    P75th: 55000,
    College_jobs: 530,
    Non_college_jobs: 465,
    Low_wage_jobs: 269,
  },
  {
    Rank: 74,
    Major_code: 3801,
    Major: "MILITARY TECHNOLOGIES",
    Total: 124,
    Men: 124,
    Women: 0,
    Major_category: "Industrial Arts & Consumer Services",
    ShareWomen: 0,
    Sample_size: 4,
    Employed: 0,
    Full_time: 111,
    Part_time: 0,
    Full_time_year_round: 111,
    Unemployed: 0,
    Unemployment_rate: 0,
    Median: 40000,
    P25th: 40000,
    P75th: 40000,
    College_jobs: 0,
    Non_college_jobs: 0,
    Low_wage_jobs: 0,
  },
  {
    Rank: 75,
    Major_code: 5003,
    Major: "CHEMISTRY",
    Total: 66530,
    Men: 32923,
    Women: 33607,
    Major_category: "Physical Sciences",
    ShareWomen: 0.505140538,
    Sample_size: 353,
    Employed: 48535,
    Full_time: 39509,
    Part_time: 15066,
    Full_time_year_round: 29910,
    Unemployed: 2769,
    Unemployment_rate: 0.0539724,
    Median: 39000,
    P25th: 30000,
    P75th: 49900,
    College_jobs: 30382,
    Non_college_jobs: 14718,
    Low_wage_jobs: 4288,
  },
  {
    Rank: 76,
    Major_code: 5701,
    Major: "ELECTRICAL, MECHANICAL, AND PRECISION TECHNOLOGIES AND PRODUCTION",
    Total: 2435,
    Men: 1869,
    Women: 566,
    Major_category: "Industrial Arts & Consumer Services",
    ShareWomen: 0.232443532,
    Sample_size: 37,
    Employed: 2107,
    Full_time: 2057,
    Part_time: 287,
    Full_time_year_round: 1752,
    Unemployed: 64,
    Unemployment_rate: 0.029479503,
    Median: 38400,
    P25th: 22500,
    P75th: 45000,
    College_jobs: 221,
    Non_college_jobs: 1659,
    Low_wage_jobs: 81,
  },
  {
    Rank: 77,
    Major_code: 6203,
    Major: "BUSINESS MANAGEMENT AND ADMINISTRATION",
    Total: 329927,
    Men: 173809,
    Women: 156118,
    Major_category: "Business",
    ShareWomen: 0.473189524,
    Sample_size: 4212,
    Employed: 276234,
    Full_time: 251540,
    Part_time: 50357,
    Full_time_year_round: 199897,
    Unemployed: 21502,
    Unemployment_rate: 0.072218341,
    Median: 38000,
    P25th: 29000,
    P75th: 50000,
    College_jobs: 36720,
    Non_college_jobs: 148395,
    Low_wage_jobs: 32395,
  },
  {
    Rank: 78,
    Major_code: 6206,
    Major: "MARKETING AND MARKETING RESEARCH",
    Total: 205211,
    Men: 78857,
    Women: 126354,
    Major_category: "Business",
    ShareWomen: 0.615727227,
    Sample_size: 2684,
    Employed: 178862,
    Full_time: 156668,
    Part_time: 35829,
    Full_time_year_round: 127230,
    Unemployed: 11663,
    Unemployment_rate: 0.061215064,
    Median: 38000,
    P25th: 30000,
    P75th: 50000,
    College_jobs: 25320,
    Non_college_jobs: 93889,
    Low_wage_jobs: 27968,
  },
  {
    Rank: 79,
    Major_code: 5506,
    Major: "POLITICAL SCIENCE AND GOVERNMENT",
    Total: 182621,
    Men: 93880,
    Women: 88741,
    Major_category: "Social Science",
    ShareWomen: 0.485929877,
    Sample_size: 1387,
    Employed: 133454,
    Full_time: 117709,
    Part_time: 43711,
    Full_time_year_round: 83236,
    Unemployed: 15022,
    Unemployment_rate: 0.101174601,
    Median: 38000,
    P25th: 28000,
    P75th: 50000,
    College_jobs: 36854,
    Non_college_jobs: 66947,
    Low_wage_jobs: 19803,
  },
  {
    Rank: 80,
    Major_code: 5504,
    Major: "GEOGRAPHY",
    Total: 18480,
    Men: 11404,
    Women: 7076,
    Major_category: "Social Science",
    ShareWomen: 0.382900433,
    Sample_size: 179,
    Employed: 14057,
    Full_time: 11367,
    Part_time: 5651,
    Full_time_year_round: 8628,
    Unemployed: 1799,
    Unemployment_rate: 0.113458628,
    Median: 38000,
    P25th: 30000,
    P75th: 50000,
    College_jobs: 5350,
    Non_college_jobs: 6830,
    Low_wage_jobs: 1905,
  },
  {
    Rank: 81,
    Major_code: 3606,
    Major: "MICROBIOLOGY",
    Total: 15232,
    Men: 6383,
    Women: 8849,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.580948004,
    Sample_size: 62,
    Employed: 9685,
    Full_time: 7453,
    Part_time: 3379,
    Full_time_year_round: 5080,
    Unemployed: 693,
    Unemployment_rate: 0.066775872,
    Median: 38000,
    P25th: 29600,
    P75th: 50000,
    College_jobs: 5577,
    Non_college_jobs: 3174,
    Low_wage_jobs: 1246,
  },
  {
    Rank: 82,
    Major_code: 2106,
    Major: "COMPUTER ADMINISTRATION MANAGEMENT AND SECURITY",
    Total: 8066,
    Men: 6607,
    Women: 1459,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.180882718,
    Sample_size: 103,
    Employed: 6509,
    Full_time: 6289,
    Part_time: 1030,
    Full_time_year_round: 4936,
    Unemployed: 721,
    Unemployment_rate: 0.099723375,
    Median: 37500,
    P25th: 25000,
    P75th: 50000,
    College_jobs: 2354,
    Non_college_jobs: 3244,
    Low_wage_jobs: 308,
  },
  {
    Rank: 83,
    Major_code: 3601,
    Major: "BIOCHEMICAL SCIENCES",
    Total: 39107,
    Men: 18951,
    Women: 20156,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.515406449,
    Sample_size: 174,
    Employed: 25678,
    Full_time: 20643,
    Part_time: 9948,
    Full_time_year_round: 13785,
    Unemployed: 2249,
    Unemployment_rate: 0.080531385,
    Median: 37400,
    P25th: 29000,
    P75th: 50000,
    College_jobs: 15654,
    Non_college_jobs: 8394,
    Low_wage_jobs: 3012,
  },
  {
    Rank: 84,
    Major_code: 3602,
    Major: "BOTANY",
    Total: 1329,
    Men: 626,
    Women: 703,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.52896915,
    Sample_size: 9,
    Employed: 1010,
    Full_time: 946,
    Part_time: 169,
    Full_time_year_round: 740,
    Unemployed: 0,
    Unemployment_rate: 0,
    Median: 37000,
    P25th: 26000,
    P75th: 40000,
    College_jobs: 677,
    Non_college_jobs: 184,
    Low_wage_jobs: 56,
  },
  {
    Rank: 85,
    Major_code: 2107,
    Major: "COMPUTER NETWORKING AND TELECOMMUNICATIONS",
    Total: 7613,
    Men: 5291,
    Women: 2322,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.305004597,
    Sample_size: 97,
    Employed: 6144,
    Full_time: 5495,
    Part_time: 1447,
    Full_time_year_round: 4369,
    Unemployed: 1100,
    Unemployment_rate: 0.151849807,
    Median: 36400,
    P25th: 27000,
    P75th: 49000,
    College_jobs: 2593,
    Non_college_jobs: 2941,
    Low_wage_jobs: 352,
  },
  {
    Rank: 86,
    Major_code: 5004,
    Major: "GEOLOGY AND EARTH SCIENCE",
    Total: 10972,
    Men: 5813,
    Women: 5159,
    Major_category: "Physical Sciences",
    ShareWomen: 0.470196865,
    Sample_size: 78,
    Employed: 8296,
    Full_time: 6966,
    Part_time: 2913,
    Full_time_year_round: 5008,
    Unemployed: 677,
    Unemployment_rate: 0.075448568,
    Median: 36200,
    P25th: 28000,
    P75th: 47000,
    College_jobs: 4858,
    Non_college_jobs: 2792,
    Low_wage_jobs: 959,
  },
  {
    Rank: 87,
    Major_code: 6209,
    Major: "HUMAN RESOURCES AND PERSONNEL MANAGEMENT",
    Total: 24497,
    Men: 6184,
    Women: 18313,
    Major_category: "Business",
    ShareWomen: 0.747560926,
    Sample_size: 264,
    Employed: 20760,
    Full_time: 18550,
    Part_time: 3767,
    Full_time_year_round: 15446,
    Unemployed: 1315,
    Unemployment_rate: 0.059569649,
    Median: 36000,
    P25th: 28000,
    P75th: 45000,
    College_jobs: 2406,
    Non_college_jobs: 9629,
    Low_wage_jobs: 1906,
  },
  {
    Rank: 88,
    Major_code: 3202,
    Major: "PRE-LAW AND LEGAL STUDIES",
    Total: 13528,
    Men: 4435,
    Women: 9093,
    Major_category: "Law & Public Policy",
    ShareWomen: 0.672161443,
    Sample_size: 92,
    Employed: 9762,
    Full_time: 7851,
    Part_time: 3595,
    Full_time_year_round: 5370,
    Unemployed: 757,
    Unemployment_rate: 0.071965016,
    Median: 36000,
    P25th: 29200,
    P75th: 46000,
    College_jobs: 2002,
    Non_college_jobs: 6454,
    Low_wage_jobs: 1336,
  },
  {
    Rank: 89,
    Major_code: 6199,
    Major: "MISCELLANEOUS HEALTH MEDICAL PROFESSIONS",
    Total: 13386,
    Men: 1589,
    Women: 11797,
    Major_category: "Health",
    ShareWomen: 0.881293889,
    Sample_size: 81,
    Employed: 10076,
    Full_time: 7514,
    Part_time: 4145,
    Full_time_year_round: 5868,
    Unemployed: 893,
    Unemployment_rate: 0.08141125,
    Median: 36000,
    P25th: 23000,
    P75th: 42000,
    College_jobs: 5652,
    Non_college_jobs: 3835,
    Low_wage_jobs: 1422,
  },
  {
    Rank: 90,
    Major_code: 5401,
    Major: "PUBLIC ADMINISTRATION",
    Total: 5629,
    Men: 2947,
    Women: 2682,
    Major_category: "Law & Public Policy",
    ShareWomen: 0.476461183,
    Sample_size: 46,
    Employed: 4158,
    Full_time: 4148,
    Part_time: 847,
    Full_time_year_round: 2952,
    Unemployed: 789,
    Unemployment_rate: 0.1594906,
    Median: 36000,
    P25th: 23000,
    P75th: 60000,
    College_jobs: 919,
    Non_college_jobs: 2313,
    Low_wage_jobs: 496,
  },
  {
    Rank: 91,
    Major_code: 5005,
    Major: "GEOSCIENCES",
    Total: 1978,
    Men: 809,
    Women: 1169,
    Major_category: "Physical Sciences",
    ShareWomen: 0.591001011,
    Sample_size: 18,
    Employed: 1441,
    Full_time: 1264,
    Part_time: 354,
    Full_time_year_round: 1011,
    Unemployed: 36,
    Unemployment_rate: 0.024373731,
    Median: 36000,
    P25th: 21000,
    P75th: 41000,
    College_jobs: 784,
    Non_college_jobs: 591,
    Low_wage_jobs: 221,
  },
  {
    Rank: 92,
    Major_code: 5206,
    Major: "SOCIAL PSYCHOLOGY",
    Total: 1386,
    Men: 413,
    Women: 973,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.702020202,
    Sample_size: 8,
    Employed: 1080,
    Full_time: 828,
    Part_time: 433,
    Full_time_year_round: 529,
    Unemployed: 33,
    Unemployment_rate: 0.029649596,
    Median: 36000,
    P25th: 34000,
    P75th: 45000,
    College_jobs: 434,
    Non_college_jobs: 593,
    Low_wage_jobs: 37,
  },
  {
    Rank: 93,
    Major_code: 1301,
    Major: "ENVIRONMENTAL SCIENCE",
    Total: 25965,
    Men: 10787,
    Women: 15178,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.584556133,
    Sample_size: 225,
    Employed: 20859,
    Full_time: 16987,
    Part_time: 7071,
    Full_time_year_round: 10916,
    Unemployed: 1779,
    Unemployment_rate: 0.078584681,
    Median: 35600,
    P25th: 25000,
    P75th: 40200,
    College_jobs: 8149,
    Non_college_jobs: 10076,
    Low_wage_jobs: 3175,
  },
  {
    Rank: 94,
    Major_code: 1901,
    Major: "COMMUNICATIONS",
    Total: 213996,
    Men: 70619,
    Women: 143377,
    Major_category: "Communications & Journalism",
    ShareWomen: 0.669998505,
    Sample_size: 2394,
    Employed: 179633,
    Full_time: 147335,
    Part_time: 49889,
    Full_time_year_round: 116251,
    Unemployed: 14602,
    Unemployment_rate: 0.075176976,
    Median: 35000,
    P25th: 27000,
    P75th: 45000,
    College_jobs: 40763,
    Non_college_jobs: 97964,
    Low_wage_jobs: 27440,
  },
  {
    Rank: 95,
    Major_code: 5301,
    Major: "CRIMINAL JUSTICE AND FIRE PROTECTION",
    Total: 152824,
    Men: 80231,
    Women: 72593,
    Major_category: "Law & Public Policy",
    ShareWomen: 0.47501047,
    Sample_size: 1728,
    Employed: 125393,
    Full_time: 109970,
    Part_time: 32242,
    Full_time_year_round: 88548,
    Unemployed: 11268,
    Unemployment_rate: 0.082452199,
    Median: 35000,
    P25th: 26000,
    P75th: 45000,
    College_jobs: 24348,
    Non_college_jobs: 88858,
    Low_wage_jobs: 18404,
  },
  {
    Rank: 96,
    Major_code: 6004,
    Major: "COMMERCIAL ART AND GRAPHIC DESIGN",
    Total: 103480,
    Men: 32041,
    Women: 71439,
    Major_category: "Arts",
    ShareWomen: 0.690365288,
    Sample_size: 1186,
    Employed: 83483,
    Full_time: 67448,
    Part_time: 24387,
    Full_time_year_round: 52243,
    Unemployed: 8947,
    Unemployment_rate: 0.096797577,
    Median: 35000,
    P25th: 25000,
    P75th: 45000,
    College_jobs: 37389,
    Non_college_jobs: 38119,
    Low_wage_jobs: 14839,
  },
  {
    Rank: 97,
    Major_code: 1902,
    Major: "JOURNALISM",
    Total: 72619,
    Men: 23736,
    Women: 48883,
    Major_category: "Communications & Journalism",
    ShareWomen: 0.673143392,
    Sample_size: 843,
    Employed: 61022,
    Full_time: 51411,
    Part_time: 15902,
    Full_time_year_round: 39524,
    Unemployed: 4535,
    Unemployment_rate: 0.069176442,
    Median: 35000,
    P25th: 26000,
    P75th: 42900,
    College_jobs: 23279,
    Non_college_jobs: 26672,
    Low_wage_jobs: 8512,
  },
  {
    Rank: 98,
    Major_code: 5098,
    Major: "MULTI-DISCIPLINARY OR GENERAL SCIENCE",
    Total: 62052,
    Men: 27015,
    Women: 35037,
    Major_category: "Physical Sciences",
    ShareWomen: 0.564639335,
    Sample_size: 427,
    Employed: 46138,
    Full_time: 37850,
    Part_time: 13133,
    Full_time_year_round: 28966,
    Unemployed: 2727,
    Unemployment_rate: 0.055806815,
    Median: 35000,
    P25th: 24000,
    P75th: 50000,
    College_jobs: 17923,
    Non_college_jobs: 22039,
    Low_wage_jobs: 5751,
  },
  {
    Rank: 99,
    Major_code: 1904,
    Major: "ADVERTISING AND PUBLIC RELATIONS",
    Total: 53162,
    Men: 12862,
    Women: 40300,
    Major_category: "Communications & Journalism",
    ShareWomen: 0.758060269,
    Sample_size: 681,
    Employed: 45326,
    Full_time: 38815,
    Part_time: 10948,
    Full_time_year_round: 30932,
    Unemployed: 3305,
    Unemployment_rate: 0.067960766,
    Median: 35000,
    P25th: 27000,
    P75th: 47000,
    College_jobs: 9659,
    Non_college_jobs: 23059,
    Low_wage_jobs: 7214,
  },
  {
    Rank: 100,
    Major_code: 1501,
    Major: "AREA ETHNIC AND CIVILIZATION STUDIES",
    Total: 31195,
    Men: 8739,
    Women: 22456,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.719858952,
    Sample_size: 249,
    Employed: 24629,
    Full_time: 18755,
    Part_time: 9541,
    Full_time_year_round: 13109,
    Unemployed: 1668,
    Unemployment_rate: 0.063429289,
    Median: 35000,
    P25th: 24500,
    P75th: 44000,
    College_jobs: 8465,
    Non_college_jobs: 11818,
    Low_wage_jobs: 3677,
  },
  {
    Rank: 101,
    Major_code: 2310,
    Major: "SPECIAL NEEDS EDUCATION",
    Total: 28739,
    Men: 2682,
    Women: 26057,
    Major_category: "Education",
    ShareWomen: 0.906677337,
    Sample_size: 246,
    Employed: 24639,
    Full_time: 21584,
    Part_time: 5153,
    Full_time_year_round: 16642,
    Unemployed: 1067,
    Unemployment_rate: 0.041507819,
    Median: 35000,
    P25th: 32000,
    P75th: 42000,
    College_jobs: 20185,
    Non_college_jobs: 3797,
    Low_wage_jobs: 1179,
  },
  {
    Rank: 102,
    Major_code: 3608,
    Major: "PHYSIOLOGY",
    Total: 22060,
    Men: 8422,
    Women: 13638,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.618223028,
    Sample_size: 99,
    Employed: 14643,
    Full_time: 10732,
    Part_time: 6541,
    Full_time_year_round: 7588,
    Unemployed: 1088,
    Unemployment_rate: 0.0691628,
    Median: 35000,
    P25th: 20000,
    P75th: 50000,
    College_jobs: 6587,
    Non_college_jobs: 6894,
    Low_wage_jobs: 2237,
  },
  {
    Rank: 103,
    Major_code: 5503,
    Major: "CRIMINOLOGY",
    Total: 19879,
    Men: 10031,
    Women: 9848,
    Major_category: "Social Science",
    ShareWomen: 0.495397153,
    Sample_size: 214,
    Employed: 16181,
    Full_time: 13616,
    Part_time: 4543,
    Full_time_year_round: 10548,
    Unemployed: 1743,
    Unemployment_rate: 0.097243919,
    Median: 35000,
    P25th: 25000,
    P75th: 45000,
    College_jobs: 3373,
    Non_college_jobs: 10605,
    Low_wage_jobs: 1895,
  },
  {
    Rank: 104,
    Major_code: 4002,
    Major: "NUTRITION SCIENCES",
    Total: 18909,
    Men: 2563,
    Women: 16346,
    Major_category: "Health",
    ShareWomen: 0.864456079,
    Sample_size: 118,
    Employed: 13217,
    Full_time: 9601,
    Part_time: 6648,
    Full_time_year_round: 6625,
    Unemployed: 975,
    Unemployment_rate: 0.068700676,
    Median: 35000,
    P25th: 26000,
    P75th: 45000,
    College_jobs: 6535,
    Non_college_jobs: 5473,
    Low_wage_jobs: 2449,
  },
  {
    Rank: 105,
    Major_code: 6103,
    Major: "HEALTH AND MEDICAL ADMINISTRATIVE SERVICES",
    Total: 18109,
    Men: 4266,
    Women: 13843,
    Major_category: "Health",
    ShareWomen: 0.764426528,
    Sample_size: 184,
    Employed: 15419,
    Full_time: 13534,
    Part_time: 3299,
    Full_time_year_round: 10982,
    Unemployed: 1518,
    Unemployment_rate: 0.089626262,
    Median: 35000,
    P25th: 27000,
    P75th: 42000,
    College_jobs: 2589,
    Non_college_jobs: 8592,
    Low_wage_jobs: 1391,
  },
  {
    Rank: 106,
    Major_code: 2001,
    Major: "COMMUNICATION TECHNOLOGIES",
    Total: 18035,
    Men: 11431,
    Women: 6604,
    Major_category: "Computers & Mathematics",
    ShareWomen: 0.366176878,
    Sample_size: 208,
    Employed: 14779,
    Full_time: 11981,
    Part_time: 4690,
    Full_time_year_round: 9085,
    Unemployed: 2006,
    Unemployment_rate: 0.119511469,
    Median: 35000,
    P25th: 25000,
    P75th: 45000,
    College_jobs: 4545,
    Non_college_jobs: 8794,
    Low_wage_jobs: 2495,
  },
  {
    Rank: 107,
    Major_code: 5901,
    Major: "TRANSPORTATION SCIENCES AND TECHNOLOGIES",
    Total: 15150,
    Men: 13257,
    Women: 1893,
    Major_category: "Industrial Arts & Consumer Services",
    ShareWomen: 0.124950495,
    Sample_size: 180,
    Employed: 12266,
    Full_time: 11688,
    Part_time: 2633,
    Full_time_year_round: 9170,
    Unemployed: 962,
    Unemployment_rate: 0.072724524,
    Median: 35000,
    P25th: 22000,
    P75th: 52000,
    College_jobs: 4575,
    Non_college_jobs: 6147,
    Low_wage_jobs: 557,
  },
  {
    Rank: 108,
    Major_code: 1303,
    Major: "NATURAL RESOURCES MANAGEMENT",
    Total: 13773,
    Men: 8617,
    Women: 5156,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.374355623,
    Sample_size: 152,
    Employed: 11797,
    Full_time: 10722,
    Part_time: 2613,
    Full_time_year_round: 6954,
    Unemployed: 842,
    Unemployment_rate: 0.066619195,
    Median: 35000,
    P25th: 25000,
    P75th: 42000,
    College_jobs: 4333,
    Non_college_jobs: 5808,
    Low_wage_jobs: 1405,
  },
  {
    Rank: 109,
    Major_code: 3611,
    Major: "NEUROSCIENCE",
    Total: 13663,
    Men: 4944,
    Women: 8719,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.63814682,
    Sample_size: 53,
    Employed: 9087,
    Full_time: 8027,
    Part_time: 3078,
    Full_time_year_round: 5482,
    Unemployed: 463,
    Unemployment_rate: 0.048481675,
    Median: 35000,
    P25th: 30000,
    P75th: 44000,
    College_jobs: 5605,
    Non_college_jobs: 2301,
    Low_wage_jobs: 902,
  },
  {
    Rank: 110,
    Major_code: 4000,
    Major: "MULTI/INTERDISCIPLINARY STUDIES",
    Total: 12296,
    Men: 2817,
    Women: 9479,
    Major_category: "Interdisciplinary",
    ShareWomen: 0.770901106,
    Sample_size: 128,
    Employed: 9821,
    Full_time: 8032,
    Part_time: 3173,
    Full_time_year_round: 6234,
    Unemployed: 749,
    Unemployment_rate: 0.070860927,
    Median: 35000,
    P25th: 25000,
    P75th: 44000,
    College_jobs: 5176,
    Non_college_jobs: 3903,
    Low_wage_jobs: 1061,
  },
  {
    Rank: 111,
    Major_code: 5002,
    Major: "ATMOSPHERIC SCIENCES AND METEOROLOGY",
    Total: 4043,
    Men: 2744,
    Women: 1299,
    Major_category: "Physical Sciences",
    ShareWomen: 0.321296067,
    Sample_size: 32,
    Employed: 3431,
    Full_time: 2659,
    Part_time: 1309,
    Full_time_year_round: 2161,
    Unemployed: 78,
    Unemployment_rate: 0.022228555,
    Median: 35000,
    P25th: 28000,
    P75th: 50000,
    College_jobs: 1808,
    Non_college_jobs: 1317,
    Low_wage_jobs: 237,
  },
  {
    Rank: 112,
    Major_code: 1302,
    Major: "FORESTRY",
    Total: 3607,
    Men: 3156,
    Women: 451,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.125034655,
    Sample_size: 48,
    Employed: 3007,
    Full_time: 2473,
    Part_time: 891,
    Full_time_year_round: 1763,
    Unemployed: 322,
    Unemployment_rate: 0.096725743,
    Median: 35000,
    P25th: 28600,
    P75th: 48000,
    College_jobs: 1096,
    Non_college_jobs: 1692,
    Low_wage_jobs: 327,
  },
  {
    Rank: 113,
    Major_code: 1106,
    Major: "SOIL SCIENCE",
    Total: 685,
    Men: 476,
    Women: 209,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.305109489,
    Sample_size: 4,
    Employed: 613,
    Full_time: 488,
    Part_time: 185,
    Full_time_year_round: 383,
    Unemployed: 0,
    Unemployment_rate: 0,
    Median: 35000,
    P25th: 18500,
    P75th: 44000,
    College_jobs: 355,
    Non_college_jobs: 144,
    Low_wage_jobs: 0,
  },
  {
    Rank: 114,
    Major_code: 2300,
    Major: "GENERAL EDUCATION",
    Total: 143718,
    Men: 26893,
    Women: 116825,
    Major_category: "Education",
    ShareWomen: 0.812876606,
    Sample_size: 919,
    Employed: 118241,
    Full_time: 98408,
    Part_time: 29558,
    Full_time_year_round: 73531,
    Unemployed: 7195,
    Unemployment_rate: 0.057359929,
    Median: 34000,
    P25th: 26000,
    P75th: 41000,
    College_jobs: 82007,
    Non_college_jobs: 31112,
    Low_wage_jobs: 11443,
  },
  {
    Rank: 115,
    Major_code: 6402,
    Major: "HISTORY",
    Total: 141951,
    Men: 78253,
    Women: 63698,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.448732309,
    Sample_size: 1058,
    Employed: 105646,
    Full_time: 84681,
    Part_time: 40657,
    Full_time_year_round: 59218,
    Unemployed: 11176,
    Unemployment_rate: 0.095666912,
    Median: 34000,
    P25th: 25000,
    P75th: 47000,
    College_jobs: 35336,
    Non_college_jobs: 54569,
    Low_wage_jobs: 16839,
  },
  {
    Rank: 116,
    Major_code: 2602,
    Major: "FRENCH GERMAN LATIN AND OTHER COMMON FOREIGN LANGUAGE STUDIES",
    Total: 48246,
    Men: 12835,
    Women: 35411,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.733967583,
    Sample_size: 342,
    Employed: 38315,
    Full_time: 29340,
    Part_time: 14569,
    Full_time_year_round: 20056,
    Unemployed: 3132,
    Unemployment_rate: 0.075566386,
    Median: 34000,
    P25th: 25000,
    P75th: 45000,
    College_jobs: 15051,
    Non_college_jobs: 18193,
    Low_wage_jobs: 5267,
  },
  {
    Rank: 117,
    Major_code: 4001,
    Major: "INTERCULTURAL AND INTERNATIONAL STUDIES",
    Total: 24650,
    Men: 8575,
    Women: 16075,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.652129817,
    Sample_size: 184,
    Employed: 18824,
    Full_time: 14354,
    Part_time: 7978,
    Full_time_year_round: 8801,
    Unemployed: 1718,
    Unemployment_rate: 0.083633531,
    Median: 34000,
    P25th: 24000,
    P75th: 45000,
    College_jobs: 4956,
    Non_college_jobs: 10343,
    Low_wage_jobs: 3168,
  },
  {
    Rank: 118,
    Major_code: 2311,
    Major: "SOCIAL SCIENCE OR HISTORY TEACHER EDUCATION",
    Total: 20198,
    Men: 9950,
    Women: 10248,
    Major_category: "Education",
    ShareWomen: 0.507376968,
    Sample_size: 157,
    Employed: 17700,
    Full_time: 14002,
    Part_time: 5168,
    Full_time_year_round: 8871,
    Unemployed: 1012,
    Unemployment_rate: 0.054082941,
    Median: 34000,
    P25th: 23050,
    P75th: 42000,
    College_jobs: 10928,
    Non_college_jobs: 5561,
    Low_wage_jobs: 1806,
  },
  {
    Rank: 119,
    Major_code: 6110,
    Major: "COMMUNITY AND PUBLIC HEALTH",
    Total: 19735,
    Men: 4103,
    Women: 15632,
    Major_category: "Health",
    ShareWomen: 0.792095262,
    Sample_size: 130,
    Employed: 14512,
    Full_time: 10099,
    Part_time: 6377,
    Full_time_year_round: 7460,
    Unemployed: 1833,
    Unemployment_rate: 0.112144387,
    Median: 34000,
    P25th: 21000,
    P75th: 45000,
    College_jobs: 5225,
    Non_college_jobs: 7385,
    Low_wage_jobs: 1854,
  },
  {
    Rank: 120,
    Major_code: 2305,
    Major: "MATHEMATICS TEACHER EDUCATION",
    Total: 14237,
    Men: 3872,
    Women: 10365,
    Major_category: "Education",
    ShareWomen: 0.728032591,
    Sample_size: 123,
    Employed: 13115,
    Full_time: 11259,
    Part_time: 2273,
    Full_time_year_round: 8073,
    Unemployed: 216,
    Unemployment_rate: 0.016202835,
    Median: 34000,
    P25th: 30000,
    P75th: 40000,
    College_jobs: 10699,
    Non_college_jobs: 1977,
    Low_wage_jobs: 786,
  },
  {
    Rank: 121,
    Major_code: 2301,
    Major: "EDUCATIONAL ADMINISTRATION AND SUPERVISION",
    Total: 804,
    Men: 280,
    Women: 524,
    Major_category: "Education",
    ShareWomen: 0.651741294,
    Sample_size: 5,
    Employed: 703,
    Full_time: 733,
    Part_time: 0,
    Full_time_year_round: 504,
    Unemployed: 0,
    Unemployment_rate: 0,
    Median: 34000,
    P25th: 29000,
    P75th: 35000,
    College_jobs: 346,
    Non_college_jobs: 206,
    Low_wage_jobs: 111,
  },
  {
    Rank: 122,
    Major_code: 6106,
    Major: "HEALTH AND MEDICAL PREPARATORY PROGRAMS",
    Total: 12740,
    Men: 5521,
    Women: 7219,
    Major_category: "Health",
    ShareWomen: 0.566640502,
    Sample_size: 31,
    Employed: 7052,
    Full_time: 5029,
    Part_time: 3891,
    Full_time_year_round: 3236,
    Unemployed: 529,
    Unemployment_rate: 0.069779712,
    Median: 33500,
    P25th: 23000,
    P75th: 40000,
    College_jobs: 3051,
    Non_college_jobs: 3539,
    Low_wage_jobs: 1159,
  },
  {
    Rank: 123,
    Major_code: 3699,
    Major: "MISCELLANEOUS BIOLOGY",
    Total: 10706,
    Men: 4747,
    Women: 5959,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.556603774,
    Sample_size: 63,
    Employed: 7767,
    Full_time: 6076,
    Part_time: 2568,
    Full_time_year_round: 4542,
    Unemployed: 483,
    Unemployment_rate: 0.058545455,
    Median: 33500,
    P25th: 23000,
    P75th: 48000,
    College_jobs: 4253,
    Non_college_jobs: 2722,
    Low_wage_jobs: 459,
  },
  {
    Rank: 124,
    Major_code: 3600,
    Major: "BIOLOGY",
    Total: 280709,
    Men: 111762,
    Women: 168947,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.601858152,
    Sample_size: 1370,
    Employed: 182295,
    Full_time: 144512,
    Part_time: 72371,
    Full_time_year_round: 100336,
    Unemployed: 13874,
    Unemployment_rate: 0.070724732,
    Median: 33400,
    P25th: 24000,
    P75th: 45000,
    College_jobs: 88232,
    Non_college_jobs: 81109,
    Low_wage_jobs: 28339,
  },
  {
    Rank: 125,
    Major_code: 5507,
    Major: "SOCIOLOGY",
    Total: 115433,
    Men: 32510,
    Women: 82923,
    Major_category: "Social Science",
    ShareWomen: 0.718364766,
    Sample_size: 1024,
    Employed: 92721,
    Full_time: 73475,
    Part_time: 29639,
    Full_time_year_round: 56561,
    Unemployed: 8608,
    Unemployment_rate: 0.084951001,
    Median: 33000,
    P25th: 25000,
    P75th: 44000,
    College_jobs: 29051,
    Non_college_jobs: 48899,
    Low_wage_jobs: 13748,
  },
  {
    Rank: 126,
    Major_code: 1903,
    Major: "MASS MEDIA",
    Total: 52824,
    Men: 24704,
    Women: 28120,
    Major_category: "Communications & Journalism",
    ShareWomen: 0.532333788,
    Sample_size: 590,
    Employed: 44679,
    Full_time: 35769,
    Part_time: 13078,
    Full_time_year_round: 27521,
    Unemployed: 4410,
    Unemployment_rate: 0.089836827,
    Median: 33000,
    P25th: 25000,
    P75th: 45000,
    College_jobs: 12855,
    Non_college_jobs: 25297,
    Low_wage_jobs: 6429,
  },
  {
    Rank: 127,
    Major_code: 6109,
    Major: "TREATMENT THERAPY PROFESSIONS",
    Total: 48491,
    Men: 13487,
    Women: 35004,
    Major_category: "Health",
    ShareWomen: 0.721865913,
    Sample_size: 224,
    Employed: 37861,
    Full_time: 30020,
    Part_time: 12346,
    Full_time_year_round: 21735,
    Unemployed: 2409,
    Unemployment_rate: 0.059821207,
    Median: 33000,
    P25th: 24000,
    P75th: 41000,
    College_jobs: 22215,
    Non_college_jobs: 14616,
    Low_wage_jobs: 4468,
  },
  {
    Rank: 128,
    Major_code: 6211,
    Major: "HOSPITALITY MANAGEMENT",
    Total: 43647,
    Men: 15204,
    Women: 28443,
    Major_category: "Business",
    ShareWomen: 0.651659908,
    Sample_size: 546,
    Employed: 36728,
    Full_time: 32160,
    Part_time: 7494,
    Full_time_year_round: 23106,
    Unemployed: 2393,
    Unemployment_rate: 0.061169193,
    Median: 33000,
    P25th: 25000,
    P75th: 42000,
    College_jobs: 2325,
    Non_college_jobs: 23341,
    Low_wage_jobs: 9063,
  },
  {
    Rank: 129,
    Major_code: 2313,
    Major: "LANGUAGE AND DRAMA EDUCATION",
    Total: 30471,
    Men: 3741,
    Women: 26730,
    Major_category: "Education",
    ShareWomen: 0.877227528,
    Sample_size: 235,
    Employed: 26033,
    Full_time: 21419,
    Part_time: 7239,
    Full_time_year_round: 15266,
    Unemployed: 1379,
    Unemployment_rate: 0.050306435,
    Median: 33000,
    P25th: 24000,
    P75th: 40000,
    College_jobs: 17985,
    Non_college_jobs: 6824,
    Low_wage_jobs: 2819,
  },
  {
    Rank: 130,
    Major_code: 2601,
    Major: "LINGUISTICS AND COMPARATIVE LANGUAGE AND LITERATURE",
    Total: 16601,
    Men: 4416,
    Women: 12185,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.733991928,
    Sample_size: 88,
    Employed: 11165,
    Full_time: 8462,
    Part_time: 4831,
    Full_time_year_round: 5821,
    Unemployed: 1302,
    Unemployment_rate: 0.10443571,
    Median: 33000,
    P25th: 25000,
    P75th: 40000,
    College_jobs: 4122,
    Non_college_jobs: 5695,
    Low_wage_jobs: 2085,
  },
  {
    Rank: 131,
    Major_code: 2399,
    Major: "MISCELLANEOUS EDUCATION",
    Total: 10150,
    Men: 3654,
    Women: 6496,
    Major_category: "Education",
    ShareWomen: 0.64,
    Sample_size: 126,
    Employed: 8691,
    Full_time: 7264,
    Part_time: 2202,
    Full_time_year_round: 5816,
    Unemployed: 547,
    Unemployment_rate: 0.059211951,
    Median: 33000,
    P25th: 30000,
    P75th: 45000,
    College_jobs: 5284,
    Non_college_jobs: 2438,
    Low_wage_jobs: 657,
  },
  {
    Rank: 132,
    Major_code: 4007,
    Major: "INTERDISCIPLINARY SOCIAL SCIENCES",
    Total: 9916,
    Men: 2337,
    Women: 7579,
    Major_category: "Social Science",
    ShareWomen: 0.76432029,
    Sample_size: 95,
    Employed: 7444,
    Full_time: 5843,
    Part_time: 2834,
    Full_time_year_round: 4714,
    Unemployed: 757,
    Unemployment_rate: 0.092305816,
    Median: 33000,
    P25th: 24000,
    P75th: 40000,
    College_jobs: 2630,
    Non_college_jobs: 3906,
    Low_wage_jobs: 1470,
  },
  {
    Rank: 133,
    Major_code: 3604,
    Major: "ECOLOGY",
    Total: 9154,
    Men: 3878,
    Women: 5276,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.576360061,
    Sample_size: 86,
    Employed: 7585,
    Full_time: 5603,
    Part_time: 2741,
    Full_time_year_round: 3912,
    Unemployed: 437,
    Unemployment_rate: 0.054475193,
    Median: 33000,
    P25th: 23000,
    P75th: 42000,
    College_jobs: 2856,
    Non_college_jobs: 4159,
    Low_wage_jobs: 976,
  },
  {
    Rank: 134,
    Major_code: 2309,
    Major: "SECONDARY TEACHER EDUCATION",
    Total: 17125,
    Men: 6820,
    Women: 10305,
    Major_category: "Education",
    ShareWomen: 0.601751825,
    Sample_size: 156,
    Employed: 15116,
    Full_time: 12520,
    Part_time: 3782,
    Full_time_year_round: 9193,
    Unemployed: 833,
    Unemployment_rate: 0.05222898,
    Median: 32500,
    P25th: 25000,
    P75th: 38000,
    College_jobs: 10304,
    Non_college_jobs: 3967,
    Low_wage_jobs: 1385,
  },
  {
    Rank: 135,
    Major_code: 6100,
    Major: "GENERAL MEDICAL AND HEALTH SERVICES",
    Total: 33599,
    Men: 7574,
    Women: 26025,
    Major_category: "Health",
    ShareWomen: 0.774576624,
    Sample_size: 202,
    Employed: 24406,
    Full_time: 18166,
    Part_time: 11088,
    Full_time_year_round: 12809,
    Unemployed: 2183,
    Unemployment_rate: 0.082101621,
    Median: 32400,
    P25th: 25000,
    P75th: 45000,
    College_jobs: 9364,
    Non_college_jobs: 12889,
    Low_wage_jobs: 3816,
  },
  {
    Rank: 136,
    Major_code: 4801,
    Major: "PHILOSOPHY AND RELIGIOUS STUDIES",
    Total: 54814,
    Men: 31967,
    Women: 22847,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.416809574,
    Sample_size: 375,
    Employed: 40157,
    Full_time: 31086,
    Part_time: 16659,
    Full_time_year_round: 21816,
    Unemployed: 4267,
    Unemployment_rate: 0.096051684,
    Median: 32200,
    P25th: 23000,
    P75th: 47100,
    College_jobs: 14444,
    Non_college_jobs: 20313,
    Low_wage_jobs: 8051,
  },
  {
    Rank: 137,
    Major_code: 2314,
    Major: "ART AND MUSIC EDUCATION",
    Total: 34181,
    Men: 10732,
    Women: 23449,
    Major_category: "Education",
    ShareWomen: 0.6860244,
    Sample_size: 338,
    Employed: 30007,
    Full_time: 23018,
    Part_time: 9209,
    Full_time_year_round: 16537,
    Unemployed: 1206,
    Unemployment_rate: 0.038637747,
    Median: 32100,
    P25th: 25000,
    P75th: 40000,
    College_jobs: 20821,
    Non_college_jobs: 8260,
    Low_wage_jobs: 2767,
  },
  {
    Rank: 138,
    Major_code: 3301,
    Major: "ENGLISH LANGUAGE AND LITERATURE",
    Total: 194673,
    Men: 58227,
    Women: 136446,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.70089843,
    Sample_size: 1436,
    Employed: 149180,
    Full_time: 114386,
    Part_time: 57825,
    Full_time_year_round: 81180,
    Unemployed: 14345,
    Unemployment_rate: 0.08772359,
    Median: 32000,
    P25th: 23000,
    P75th: 41000,
    College_jobs: 57690,
    Non_college_jobs: 71827,
    Low_wage_jobs: 26503,
  },
  {
    Rank: 139,
    Major_code: 2304,
    Major: "ELEMENTARY EDUCATION",
    Total: 170862,
    Men: 13029,
    Women: 157833,
    Major_category: "Education",
    ShareWomen: 0.923745479,
    Sample_size: 1629,
    Employed: 149339,
    Full_time: 123177,
    Part_time: 37965,
    Full_time_year_round: 86540,
    Unemployed: 7297,
    Unemployment_rate: 0.046585715,
    Median: 32000,
    P25th: 23400,
    P75th: 38000,
    College_jobs: 108085,
    Non_college_jobs: 36972,
    Low_wage_jobs: 11502,
  },
  {
    Rank: 140,
    Major_code: 4101,
    Major: "PHYSICAL FITNESS PARKS RECREATION AND LEISURE",
    Total: 125074,
    Men: 62181,
    Women: 62893,
    Major_category: "Industrial Arts & Consumer Services",
    ShareWomen: 0.502846315,
    Sample_size: 1014,
    Employed: 103078,
    Full_time: 77428,
    Part_time: 38515,
    Full_time_year_round: 57978,
    Unemployed: 5593,
    Unemployment_rate: 0.051467273,
    Median: 32000,
    P25th: 24000,
    P75th: 43000,
    College_jobs: 27581,
    Non_college_jobs: 63946,
    Low_wage_jobs: 16838,
  },
  {
    Rank: 141,
    Major_code: 3401,
    Major: "LIBERAL ARTS",
    Total: 71369,
    Men: 22339,
    Women: 49030,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.686992952,
    Sample_size: 569,
    Employed: 54844,
    Full_time: 43401,
    Part_time: 19187,
    Full_time_year_round: 33438,
    Unemployed: 4657,
    Unemployment_rate: 0.078267592,
    Median: 32000,
    P25th: 25000,
    P75th: 42000,
    College_jobs: 18565,
    Non_college_jobs: 28558,
    Low_wage_jobs: 9030,
  },
  {
    Rank: 142,
    Major_code: 6005,
    Major: "FILM VIDEO AND PHOTOGRAPHIC ARTS",
    Total: 38761,
    Men: 22357,
    Women: 16404,
    Major_category: "Arts",
    ShareWomen: 0.423208896,
    Sample_size: 331,
    Employed: 31433,
    Full_time: 22457,
    Part_time: 12818,
    Full_time_year_round: 15740,
    Unemployed: 3718,
    Unemployment_rate: 0.10577224,
    Median: 32000,
    P25th: 22000,
    P75th: 42000,
    College_jobs: 7368,
    Non_college_jobs: 20721,
    Low_wage_jobs: 5862,
  },
  {
    Rank: 143,
    Major_code: 5500,
    Major: "GENERAL SOCIAL SCIENCES",
    Total: 12920,
    Men: 5079,
    Women: 7841,
    Major_category: "Social Science",
    ShareWomen: 0.606888545,
    Sample_size: 113,
    Employed: 9602,
    Full_time: 7700,
    Part_time: 3396,
    Full_time_year_round: 5679,
    Unemployed: 1108,
    Unemployment_rate: 0.103454715,
    Median: 32000,
    P25th: 27000,
    P75th: 50000,
    College_jobs: 3602,
    Non_college_jobs: 4778,
    Low_wage_jobs: 1634,
  },
  {
    Rank: 144,
    Major_code: 1105,
    Major: "PLANT SCIENCE AND AGRONOMY",
    Total: 7416,
    Men: 4897,
    Women: 2519,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.339670982,
    Sample_size: 110,
    Employed: 6594,
    Full_time: 5798,
    Part_time: 1246,
    Full_time_year_round: 4522,
    Unemployed: 314,
    Unemployment_rate: 0.045454545,
    Median: 32000,
    P25th: 22900,
    P75th: 40000,
    College_jobs: 2089,
    Non_college_jobs: 3545,
    Low_wage_jobs: 1231,
  },
  {
    Rank: 145,
    Major_code: 2308,
    Major: "SCIENCE AND COMPUTER TEACHER EDUCATION",
    Total: 6483,
    Men: 2049,
    Women: 4434,
    Major_category: "Education",
    ShareWomen: 0.683942619,
    Sample_size: 59,
    Employed: 5362,
    Full_time: 4764,
    Part_time: 1227,
    Full_time_year_round: 3247,
    Unemployed: 266,
    Unemployment_rate: 0.047263682,
    Median: 32000,
    P25th: 28000,
    P75th: 39000,
    College_jobs: 4214,
    Non_college_jobs: 1106,
    Low_wage_jobs: 591,
  },
  {
    Rank: 146,
    Major_code: 5200,
    Major: "PSYCHOLOGY",
    Total: 393735,
    Men: 86648,
    Women: 307087,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.779933204,
    Sample_size: 2584,
    Employed: 307933,
    Full_time: 233205,
    Part_time: 115172,
    Full_time_year_round: 174438,
    Unemployed: 28169,
    Unemployment_rate: 0.083810867,
    Median: 31500,
    P25th: 24000,
    P75th: 41000,
    College_jobs: 125148,
    Non_college_jobs: 141860,
    Low_wage_jobs: 48207,
  },
  {
    Rank: 147,
    Major_code: 6002,
    Major: "MUSIC",
    Total: 60633,
    Men: 29909,
    Women: 30724,
    Major_category: "Arts",
    ShareWomen: 0.506720763,
    Sample_size: 419,
    Employed: 47662,
    Full_time: 29010,
    Part_time: 24943,
    Full_time_year_round: 21425,
    Unemployed: 3918,
    Unemployment_rate: 0.075959674,
    Median: 31000,
    P25th: 22300,
    P75th: 42000,
    College_jobs: 13752,
    Non_college_jobs: 28786,
    Low_wage_jobs: 9286,
  },
  {
    Rank: 148,
    Major_code: 2306,
    Major: "PHYSICAL AND HEALTH EDUCATION TEACHING",
    Total: 28213,
    Men: 15670,
    Women: 12543,
    Major_category: "Education",
    ShareWomen: 0.444582285,
    Sample_size: 259,
    Employed: 23794,
    Full_time: 19420,
    Part_time: 7230,
    Full_time_year_round: 13651,
    Unemployed: 1920,
    Unemployment_rate: 0.074667496,
    Median: 31000,
    P25th: 24000,
    P75th: 40000,
    College_jobs: 12777,
    Non_college_jobs: 9328,
    Low_wage_jobs: 2042,
  },
  {
    Rank: 149,
    Major_code: 6006,
    Major: "ART HISTORY AND CRITICISM",
    Total: 21030,
    Men: 3240,
    Women: 17790,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.845934379,
    Sample_size: 204,
    Employed: 17579,
    Full_time: 13262,
    Part_time: 6140,
    Full_time_year_round: 9965,
    Unemployed: 1128,
    Unemployment_rate: 0.060298284,
    Median: 31000,
    P25th: 23000,
    P75th: 40000,
    College_jobs: 5139,
    Non_college_jobs: 9738,
    Low_wage_jobs: 3426,
  },
  {
    Rank: 150,
    Major_code: 6000,
    Major: "FINE ARTS",
    Total: 74440,
    Men: 24786,
    Women: 49654,
    Major_category: "Arts",
    ShareWomen: 0.667033853,
    Sample_size: 623,
    Employed: 59679,
    Full_time: 42764,
    Part_time: 23656,
    Full_time_year_round: 31877,
    Unemployed: 5486,
    Unemployment_rate: 0.084186296,
    Median: 30500,
    P25th: 21000,
    P75th: 41000,
    College_jobs: 20792,
    Non_college_jobs: 32725,
    Low_wage_jobs: 11880,
  },
  {
    Rank: 151,
    Major_code: 2901,
    Major: "FAMILY AND CONSUMER SCIENCES",
    Total: 58001,
    Men: 5166,
    Women: 52835,
    Major_category: "Industrial Arts & Consumer Services",
    ShareWomen: 0.91093257,
    Sample_size: 518,
    Employed: 46624,
    Full_time: 36747,
    Part_time: 15872,
    Full_time_year_round: 26906,
    Unemployed: 3355,
    Unemployment_rate: 0.067128194,
    Median: 30000,
    P25th: 22900,
    P75th: 40000,
    College_jobs: 20985,
    Non_college_jobs: 20133,
    Low_wage_jobs: 5248,
  },
  {
    Rank: 152,
    Major_code: 5404,
    Major: "SOCIAL WORK",
    Total: 53552,
    Men: 5137,
    Women: 48415,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.904074544,
    Sample_size: 374,
    Employed: 45038,
    Full_time: 34941,
    Part_time: 13481,
    Full_time_year_round: 27588,
    Unemployed: 3329,
    Unemployment_rate: 0.06882792,
    Median: 30000,
    P25th: 25000,
    P75th: 35000,
    College_jobs: 27449,
    Non_college_jobs: 14416,
    Low_wage_jobs: 4344,
  },
  {
    Rank: 153,
    Major_code: 1103,
    Major: "ANIMAL SCIENCES",
    Total: 21573,
    Men: 5347,
    Women: 16226,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.752143884,
    Sample_size: 255,
    Employed: 17112,
    Full_time: 14479,
    Part_time: 5353,
    Full_time_year_round: 10824,
    Unemployed: 917,
    Unemployment_rate: 0.050862499,
    Median: 30000,
    P25th: 22000,
    P75th: 40000,
    College_jobs: 5443,
    Non_college_jobs: 9571,
    Low_wage_jobs: 2125,
  },
  {
    Rank: 154,
    Major_code: 6003,
    Major: "VISUAL AND PERFORMING ARTS",
    Total: 16250,
    Men: 4133,
    Women: 12117,
    Major_category: "Arts",
    ShareWomen: 0.745661538,
    Sample_size: 132,
    Employed: 12870,
    Full_time: 8447,
    Part_time: 6253,
    Full_time_year_round: 6322,
    Unemployed: 1465,
    Unemployment_rate: 0.102197419,
    Median: 30000,
    P25th: 22000,
    P75th: 40000,
    College_jobs: 3849,
    Non_college_jobs: 7635,
    Low_wage_jobs: 2840,
  },
  {
    Rank: 155,
    Major_code: 2312,
    Major: "TEACHER EDUCATION: MULTIPLE LEVELS",
    Total: 14443,
    Men: 2734,
    Women: 11709,
    Major_category: "Education",
    ShareWomen: 0.810704147,
    Sample_size: 142,
    Employed: 13076,
    Full_time: 11734,
    Part_time: 2214,
    Full_time_year_round: 8457,
    Unemployed: 496,
    Unemployment_rate: 0.03654583,
    Median: 30000,
    P25th: 24000,
    P75th: 37000,
    College_jobs: 10766,
    Non_college_jobs: 1949,
    Low_wage_jobs: 722,
  },
  {
    Rank: 156,
    Major_code: 5299,
    Major: "MISCELLANEOUS PSYCHOLOGY",
    Total: 9628,
    Men: 1936,
    Women: 7692,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.798919817,
    Sample_size: 60,
    Employed: 7653,
    Full_time: 5201,
    Part_time: 3221,
    Full_time_year_round: 3838,
    Unemployed: 419,
    Unemployment_rate: 0.05190783,
    Median: 30000,
    P25th: 20800,
    P75th: 40000,
    College_jobs: 2960,
    Non_college_jobs: 3948,
    Low_wage_jobs: 1650,
  },
  {
    Rank: 157,
    Major_code: 5403,
    Major: "HUMAN SERVICES AND COMMUNITY ORGANIZATION",
    Total: 9374,
    Men: 885,
    Women: 8489,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.90558993,
    Sample_size: 89,
    Employed: 8294,
    Full_time: 6455,
    Part_time: 2405,
    Full_time_year_round: 5061,
    Unemployed: 326,
    Unemployment_rate: 0.037819026,
    Median: 30000,
    P25th: 24000,
    P75th: 35000,
    College_jobs: 2878,
    Non_college_jobs: 4595,
    Low_wage_jobs: 724,
  },
  {
    Rank: 158,
    Major_code: 3402,
    Major: "HUMANITIES",
    Total: 6652,
    Men: 2013,
    Women: 4639,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.697384245,
    Sample_size: 49,
    Employed: 5052,
    Full_time: 3565,
    Part_time: 2225,
    Full_time_year_round: 2661,
    Unemployed: 372,
    Unemployment_rate: 0.068584071,
    Median: 30000,
    P25th: 20000,
    P75th: 49000,
    College_jobs: 1168,
    Non_college_jobs: 3354,
    Low_wage_jobs: 1141,
  },
  {
    Rank: 159,
    Major_code: 4901,
    Major: "THEOLOGY AND RELIGIOUS VOCATIONS",
    Total: 30207,
    Men: 18616,
    Women: 11591,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.383719006,
    Sample_size: 310,
    Employed: 24202,
    Full_time: 18079,
    Part_time: 8767,
    Full_time_year_round: 13944,
    Unemployed: 1617,
    Unemployment_rate: 0.062628297,
    Median: 29000,
    P25th: 22000,
    P75th: 38000,
    College_jobs: 9927,
    Non_college_jobs: 12037,
    Low_wage_jobs: 3304,
  },
  {
    Rank: 160,
    Major_code: 6007,
    Major: "STUDIO ARTS",
    Total: 16977,
    Men: 4754,
    Women: 12223,
    Major_category: "Arts",
    ShareWomen: 0.719974083,
    Sample_size: 182,
    Employed: 13908,
    Full_time: 10451,
    Part_time: 5673,
    Full_time_year_round: 7413,
    Unemployed: 1368,
    Unemployment_rate: 0.089552239,
    Median: 29000,
    P25th: 19200,
    P75th: 38300,
    College_jobs: 3948,
    Non_college_jobs: 8707,
    Low_wage_jobs: 3586,
  },
  {
    Rank: 161,
    Major_code: 2201,
    Major: "COSMETOLOGY SERVICES AND CULINARY ARTS",
    Total: 10510,
    Men: 4364,
    Women: 6146,
    Major_category: "Industrial Arts & Consumer Services",
    ShareWomen: 0.584776403,
    Sample_size: 117,
    Employed: 8650,
    Full_time: 7662,
    Part_time: 2064,
    Full_time_year_round: 5949,
    Unemployed: 510,
    Unemployment_rate: 0.055676856,
    Median: 29000,
    P25th: 20000,
    P75th: 36000,
    College_jobs: 563,
    Non_college_jobs: 7384,
    Low_wage_jobs: 3163,
  },
  {
    Rank: 162,
    Major_code: 1199,
    Major: "MISCELLANEOUS AGRICULTURE",
    Total: 1488,
    Men: 404,
    Women: 1084,
    Major_category: "Agriculture & Natural Resources",
    ShareWomen: 0.728494624,
    Sample_size: 24,
    Employed: 1290,
    Full_time: 1098,
    Part_time: 335,
    Full_time_year_round: 936,
    Unemployed: 82,
    Unemployment_rate: 0.059766764,
    Median: 29000,
    P25th: 23000,
    P75th: 42100,
    College_jobs: 483,
    Non_college_jobs: 626,
    Low_wage_jobs: 31,
  },
  {
    Rank: 163,
    Major_code: 5502,
    Major: "ANTHROPOLOGY AND ARCHEOLOGY",
    Total: 38844,
    Men: 11376,
    Women: 27468,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.707136237,
    Sample_size: 247,
    Employed: 29633,
    Full_time: 20147,
    Part_time: 14515,
    Full_time_year_round: 13232,
    Unemployed: 3395,
    Unemployment_rate: 0.102791571,
    Median: 28000,
    P25th: 20000,
    P75th: 38000,
    College_jobs: 9805,
    Non_college_jobs: 16693,
    Low_wage_jobs: 6866,
  },
  {
    Rank: 164,
    Descrip:
      "A program that focuses on the application of biomedical, psychological, and physical principles to the study of the scientific bases, development, and treatment of speech, language, hearing, and cognitive communication problems caused by disease, injury, or disability.",
    Major_code: 6102,
    Major: "COMMUNICATION DISORDERS SCIENCES AND SERVICES",
    Total: 38279,
    Men: 1225,
    Women: 37054,
    Major_category: "Health",
    ShareWomen: 0.967998119,
    Sample_size: 95,
    Employed: 29763,
    Full_time: 19975,
    Part_time: 13862,
    Full_time_year_round: 14460,
    Unemployed: 1487,
    Unemployment_rate: 0.047584,
    Median: 28000,
    P25th: 20000,
    P75th: 40000,
    College_jobs: 19957,
    Non_college_jobs: 9404,
    Low_wage_jobs: 5125,
  },
  {
    Rank: 165,
    Major_code: 2307,
    Major: "EARLY CHILDHOOD EDUCATION",
    Total: 37589,
    Men: 1167,
    Women: 36422,
    Major_category: "Education",
    ShareWomen: 0.968953683,
    Sample_size: 342,
    Employed: 32551,
    Full_time: 27569,
    Part_time: 7001,
    Full_time_year_round: 20748,
    Unemployed: 1360,
    Unemployment_rate: 0.040104981,
    Median: 28000,
    P25th: 21000,
    P75th: 35000,
    College_jobs: 23515,
    Non_college_jobs: 7705,
    Low_wage_jobs: 2868,
  },
  {
    Rank: 166,
    Major_code: 2603,
    Major: "OTHER FOREIGN LANGUAGES",
    Total: 11204,
    Men: 3472,
    Women: 7732,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.690110675,
    Sample_size: 56,
    Employed: 7052,
    Full_time: 5197,
    Part_time: 3685,
    Full_time_year_round: 3214,
    Unemployed: 846,
    Unemployment_rate: 0.107115726,
    Median: 27500,
    P25th: 22900,
    P75th: 38000,
    College_jobs: 2326,
    Non_college_jobs: 3703,
    Low_wage_jobs: 1115,
  },
  {
    Rank: 167,
    Major_code: 6001,
    Major: "DRAMA AND THEATER ARTS",
    Total: 43249,
    Men: 14440,
    Women: 28809,
    Major_category: "Arts",
    ShareWomen: 0.666119448,
    Sample_size: 357,
    Employed: 36165,
    Full_time: 25147,
    Part_time: 15994,
    Full_time_year_round: 16891,
    Unemployed: 3040,
    Unemployment_rate: 0.07754113,
    Median: 27000,
    P25th: 19200,
    P75th: 35000,
    College_jobs: 6994,
    Non_college_jobs: 25313,
    Low_wage_jobs: 11068,
  },
  {
    Rank: 168,
    Major_code: 3302,
    Major: "COMPOSITION AND RHETORIC",
    Total: 18953,
    Men: 7022,
    Women: 11931,
    Major_category: "Humanities & Liberal Arts",
    ShareWomen: 0.629504564,
    Sample_size: 151,
    Employed: 15053,
    Full_time: 10121,
    Part_time: 6612,
    Full_time_year_round: 7832,
    Unemployed: 1340,
    Unemployment_rate: 0.081742207,
    Median: 27000,
    P25th: 20000,
    P75th: 35000,
    College_jobs: 4855,
    Non_college_jobs: 8100,
    Low_wage_jobs: 3466,
  },
  {
    Rank: 169,
    Major_code: 3609,
    Major: "ZOOLOGY",
    Total: 8409,
    Men: 3050,
    Women: 5359,
    Major_category: "Biology & Life Science",
    ShareWomen: 0.637293376,
    Sample_size: 47,
    Employed: 6259,
    Full_time: 5043,
    Part_time: 2190,
    Full_time_year_round: 3602,
    Unemployed: 304,
    Unemployment_rate: 0.04632028,
    Median: 26000,
    P25th: 20000,
    P75th: 39000,
    College_jobs: 2771,
    Non_college_jobs: 2947,
    Low_wage_jobs: 743,
  },
  {
    Rank: 170,
    Major_code: 5201,
    Major: "EDUCATIONAL PSYCHOLOGY",
    Total: 2854,
    Men: 522,
    Women: 2332,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.817098809,
    Sample_size: 7,
    Employed: 2125,
    Full_time: 1848,
    Part_time: 572,
    Full_time_year_round: 1211,
    Unemployed: 148,
    Unemployment_rate: 0.065112187,
    Median: 25000,
    P25th: 24000,
    P75th: 34000,
    College_jobs: 1488,
    Non_college_jobs: 615,
    Low_wage_jobs: 82,
  },
  {
    Rank: 171,
    Major_code: 5202,
    Major: "CLINICAL PSYCHOLOGY",
    Total: 2838,
    Men: 568,
    Women: 2270,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.799859056,
    Sample_size: 13,
    Employed: 2101,
    Full_time: 1724,
    Part_time: 648,
    Full_time_year_round: 1293,
    Unemployed: 368,
    Unemployment_rate: 0.149048198,
    Median: 25000,
    P25th: 25000,
    P75th: 40000,
    College_jobs: 986,
    Non_college_jobs: 870,
    Low_wage_jobs: 622,
  },
  {
    Rank: 172,
    Major_code: 5203,
    Major: "COUNSELING PSYCHOLOGY",
    Total: 4626,
    Men: 931,
    Women: 3695,
    Major_category: "Psychology & Social Work",
    ShareWomen: 0.798746217,
    Sample_size: 21,
    Employed: 3777,
    Full_time: 3154,
    Part_time: 965,
    Full_time_year_round: 2738,
    Unemployed: 214,
    Unemployment_rate: 0.053620646,
    Median: 23400,
    P25th: 19200,
    P75th: 26000,
    College_jobs: 2403,
    Non_college_jobs: 1245,
    Low_wage_jobs: 308,
  },
  {
    Rank: 173,
    Major_code: 3501,
    Major: "LIBRARY SCIENCE",
    Total: 1098,
    Men: 134,
    Women: 964,
    Major_category: "Education",
    ShareWomen: 0.877959927,
    Sample_size: 2,
    Employed: 742,
    Full_time: 593,
    Part_time: 237,
    Full_time_year_round: 410,
    Unemployed: 87,
    Unemployment_rate: 0.104945718,
    Median: 22000,
    P25th: 20000,
    P75th: 22000,
    College_jobs: 288,
    Non_college_jobs: 338,
    Low_wage_jobs: 192,
  },
];
