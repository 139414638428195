import React, { Component } from "react";
import "../../../../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bulma/css/bulma.css";
import Amplify from "aws-amplify";
import aws_exports from "../../../../../aws-exports"; // specify the location of aws-exports.js file on your project
import aidImage from "../../../../../assets/aid.png";
Amplify.configure(aws_exports);

class PlaybookFour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      current: 0,
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <div>
        {this.props.stepNum === 0 ? (
          <Aid title={this.props.title} />
        ) : this.props.stepNum === 1 ? (
          <Apply title={this.props.title} />
        ) : this.props.stepNum === 2 ? (
          <Understanding title={this.props.title} />
        ) : this.props.stepNum === 3 ? (
          <Resources title={this.props.title} />
        ) : null}

        {/* <Popover content="sfsdf" title="fasfa">
                                                    <p><strong>Hover me</strong></p>
                                                </Popover>
              playbook 1 */}
      </div>
    );
  }
}

export default PlaybookFour;

function Aid(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            You can't talk about applying to college without considering
            finances. For this section, we want to give you enough foundational
            information about the financial aid process. Understanding financial
            aid can seem difficult but it can be less stressful with the right
            information. And, understanding how financial aid works is well
            worth it if you can save thousands in the process.
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h4>
            <strong>Sources of Financial Aid</strong>
          </h4>
          <p>
            Contrary to popular belief, there's actually a lot of money
            available to students who need it. Here are the main sources of
            financial aid:
          </p>
          <div className="columns">
            <div className="column">
              <img
                alt=""
                style={{ marginRight: "2%" }}
                src={require("../../../../../assets/gifs/moneystack.gif")}
              />
              <p className="help">Giphy.</p>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h5>
                <strong>
                  Apply to as many outside scholarships (besides the
                  scholarships and grants a school might give you) as you can!
                  If you qualify, then you should apply.
                </strong>
              </h5>
              <p>
                Having enough money for your college education is an essential
                step in your future planning. If you can get them, scholarships
                are a great way to pay for college without the stress and
                pressure of having to pay someone back when you graduate.
              </p>
            </div>
          </div>
        </div>
        <div className="column">
          <p>
            <strong>Grants and scholarships - </strong> Grants are based on
            financial need and you don't need to pay it back. Scholarships are
            usually based on merit like strong academics, impressive leadership
            roles or interest in a specific field. You also don't need to pay
            back scholarships.
          </p>
          <p>
            <strong>Work-study - </strong> This gives you the option to work
            part-time on campus.
          </p>
          <p>
            <strong>Loans - </strong> Awarded based on financial need and must
            be repaid. Loans are low interest and are usually sponsored by the
            government. There are various types of loans. Here, we'll list a few
            but please make sure to do your research to determine which would be
            the best fit for you.
            <div className="content">
              <ul>
                <li>
                  <strong>Need Based</strong>
                  <p>
                    <strong>Federal Perkins </strong> - These loans are awarded
                    by colleges to students with the highest need.
                  </p>
                  <p>
                    <strong>Federal Direct Subsidized Loans </strong>- Are
                    interest-free loans while you're in college and have a
                    borrowing limit that increases for each year of school you
                    complete.
                  </p>
                </li>
                <li>
                  <strong>Non-Need Based</strong>
                  <p>
                    <strong>Federal Direct Unsubsidized Loans </strong> - This
                    loan charges interest, but allow you to add the interest
                    fees to the amount you borrow until after graduation.
                    However, doing this means you’ll actually end up owing more.
                  </p>
                  <p>
                    <strong>Federal Direct PLUS Loans </strong> - This loan
                    allows parents (or graduate students) to borrow the total
                    cost of college, minus any financial aid received.
                  </p>
                </li>
                <li>
                  <strong>Private</strong>
                  <p>
                    Usually, private loans are not need-based or subsidized.
                    They also require a cosigner (someone who promises to repay
                    the money if you fail to do so). You can find private loans
                    through colleges which will have much lower interest rates.
                    Banks and other financial institutions also provide private
                    loans but their interest rates are usually very high.
                  </p>
                </li>
              </ul>
            </div>
          </p>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          There are a lot of people and resources you can rely on to help you
          through the financial aid process. Make sure to reach out to your high
          school guidance counselor, your teachers, and other adults that can
          advise you. Also, don't be afraid to reach out to the financial aid
          officers at the colleges you apply to. They are very willing to help
          students like you understand and complete the financial aid process.
          Overall, the financial aid process is a big deal,{" "}
          <strong>don't be afraid to ask for help!</strong>
        </div>
      </div>
    </div>
  );
}

function Apply(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            Applying for aid is fairly easy since there's one universal form,
            the FAFSA (Free Application for Federal Aid), that all colleges use
            to determine how much money to give you. However, this might not be
            the only form you end up filling out. Some colleges may also ask you
            to fill out the CSS (College Scholarship Service Financial Aid)
            Profile. Some schools might also use state forms. It's really
            important to check with the admissions office at each school you
            apply to to figure out which financial aid forms you need to fill
            out.
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <hr />
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <h4>
            <strong>What's the FAFSA?</strong>
          </h4>
          <p>
            The FAFSA is a free form from the federal government use to
            determine how much aid you qualify for to attend school. The
            government then shares this info with the schools you're applying
            to. Schools then use this information to determine how much aid they
            can give you.
          </p>
        </div>
        <div className="column">
          <h5>
            <strong>When?</strong>
          </h5>
          <p>
            The earliest you can submit the FAFSA is on Jan 1st. You should try
            to submit this form as soon as possible.
          </p>
          <p>
            <h5>
              <strong>How?</strong>
            </h5>
            You can fill out the form online or by mail. To fill out the form
            online, you can go here to{" "}
            <a
              href="https://studentaid.gov/h/apply-for-aid/fafsa"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://studentaid.gov
            </a>
            . After filling out the form, you'll receive your expected family
            contribution (EFC). You can subtract your EFC from the cost of
            attendance at the colleges you plan on applying to. This will
            determine your financial need.
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <h4>
            <strong>What's the CSS Profile?</strong>
          </h4>
          <p>
            A lot of schools use this form in combination with the FAFSA. The
            CSS Profile is much more specific to the school and will ask you
            questions that are not listed on the FAFSA. You do have to pay to
            fill out the CSS Profile, however, make sure to ask your guidance
            counselor and/or admissions office for fee waivers.
          </p>
        </div>
        <div className="column">
          <p>
            <h5>
              <strong>When?</strong>
            </h5>
            The earliest you can submit this form is Oct 1st but the deadlines
            range depending on the school. Make sure to check with the
            admissions office to figure out the deadline.
          </p>
          <p>
            <h5>
              <strong>How?</strong>
            </h5>
            You can fill out the form online here via the College Board{" "}
            <a
              href="https://cssprofile.collegeboard.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://cssprofile.collegeboard.org/
            </a>
            .
          </p>
        </div>
      </div>

      <div className="columns is-centered">
        <div className="column is-two-thirds">
          <div className="card">
            <div className="card-content">
              <div className="columns is-centered is-vcentered">
                <div className="column">
                  <p>
                    <strong>Note: </strong>Stay on top of all the scholarships
                    you plan on applying to with your scholarship organizer!
                  </p>
                </div>
                <div className="column">
                  <a href="/scholarships" target="_blank">
                    <div className="button is-link">
                      Go to Scholarship Organizer
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <h4>
            <strong>Steps for Applying</strong>
          </h4>
          <p>
            Applying for financial aid can be a lot of work but it's really not
            that hard. Here's how it works:
          </p>
          <div className="content">
            <ol type="1">
              <li>
                All students have to complete the Free Application for Federal
                Student Aid (FAFSA). This is an application required by most
                colleges and needs to completed as soon as possible during your
                senior year. It's <strong>FREE</strong> and you can complete the
                form online.
              </li>
              <li>
                A few schools, including many liberal arts colleges, require a
                financial aid form that is specific to their school. This is
                called the College Scholarship Service Profile (CSS Profile).
                You have to pay for this form <strong>however</strong>, you can
                get fee waivers through your high school guidance counselor and
                college financial aid offices.
              </li>
              <li>
                Using information you provide via the FASFA and/or the CSS
                Profile, financial aid offices can use these forms to determine
                you and your family's ability to pay for college. This is called
                the <strong>"estimated family contribution"</strong> (EFC). Your
                EFC is always the same no matter how much the college costs. The
                financial aid office will cover the difference between your EFC
                and the cost of attendance to your school of choice. This means
                financial aid can help you afford even the most expensive
                colleges.
              </li>
              <li>
                In the spring, after you've received your acceptance, you'll get
                your financial aid package where colleges will tell you how much
                and what types of aid they plan on offering you.
              </li>
              <li>
                You can take the time you need (until May 1st) to compare
                financial aid offers and make a decision
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <hr />
          <h5>
            <strong>Final Thoughts</strong>
          </h5>
          <p>
            Last thing, if you are a decent student and you participant in
            school and your community, you could also qualify for scholarships
            offered by foundations, corporations, and community groups. Make
            sure to do your research and ask your school about possible
            scholarship opportunities.
          </p>
        </div>
      </div>
    </div>
  );
}

function Understanding(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">{props.title}</h5>
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <p>
            The financial aid package is the combination of scholarships,
            grants, and loans that a college will offer you after you've
            received your acceptance. Reading through the aid package can be a
            little confusing but hopefully, the example below will help you
            understand your package better.
          </p>
        </div>
      </div>

      <div className="columns">
        {/* <div className="column">
                  <p>
                      The financial aid package is the combination of scholarships, grants, and loans that a college will offer 
                      you after you've received you acceptance. 
                      
                      Hopefully, you'll receive more than enough aid to cover the cost of
                      attendance.
                  </p>
                
              </div> */}
        <div className="column">
          <div className="card">
            <div className="card-image">
              <figure className="image">
                <img src={aidImage} style={{ scale: "1" }} alt="Placeholder" />
              </figure>
            </div>
          </div>
          <p className="help">
            From{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.fastweb.com/"
            >
              fastweb.com
            </a>
          </p>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <h4>Asking Colleges for More Aid</h4>

          <p>
            Hopefully, you'll receive more than enough aid to cover the cost of
            attendance. But if this wasn't the case, then you shouldn't be
            afraid to ask for more. Most schools say that their original
            financial aid offer is non-negotiable but that's not always the
            case.
          </p>
          <p>
            When asking for more aid, you have to do so in a way that doesn't
            upset the school. You want to be as polite as possible instead of
            being demanding and aggressive.
          </p>
          <p>
            If you want a college to reconsider your financial aid offer, then
            the best way to go about this is to write a letter explaining your
            circumstances and why the original offer does not meet your
            financial need. This letter should make a valid case for a financial
            aid office to use to reconsider your original aid package. You can
            include more information on things like:
            <div className="content">
              <ul>
                <li>
                  Extenuating circumstances (other siblings in college, parent
                  recently laid off)
                </li>
                <li>
                  You received a better financial aid package from another
                  school
                </li>
                <li>
                  The aid you received was significantly less than the average
                  most other students get (for this, you'll need to do your
                  research on the school's average financial aid package)
                </li>
              </ul>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}

function Resources(props) {
  return (
    <div style={{ textAlign: "left" }}>
      <div className="columns">
        <div className="column is-two-fifths">
          <h5 className="title is-5">Assignment</h5>
        </div>
      </div>
      <div className="columns is-centered">
        <div className="column">
          <div className="card">
            <div className="card-content">
              <div className="content">
                <ol type="1">
                  <li>
                    Make a{" "}
                    <a href="/notes" target="_blank">
                      note
                    </a>{" "}
                    on at least 2 resources that you plan on using to pay for
                    college.
                  </li>
                  <li>
                    Look up at least 3 scholarships that you qualify for and add
                    them to your{" "}
                    <a href="/scholarships" target="_blank">
                      scholarship manager
                    </a>
                    .
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-two-fifths">
          <img src={require("../../../../../assets/book.svg")} alt="" />
        </div>
      </div>
      <hr />
      <div className="columns">
        <div className="column">
          <div className="content">
            <h5 className="title is-5">Resources</h5>
            <ul>
              <li>
                <a href="https://www.fastweb.com/">Fastweb</a>
                <p className="help">Scholarship search</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
