/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBlogPost = /* GraphQL */ `
  query GetBlogPost($id: ID!) {
    getBlogPost(id: $id) {
      id
      title
      content
      date
      author
      comments {
        items {
          id
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBlogPosts = /* GraphQL */ `
  query ListBlogPosts(
    $filter: ModelBlogPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        date
        author
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      post {
        id
        title
        content
        date
        author
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        post {
          id
          title
          content
          date
          author
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPerformance = /* GraphQL */ `
  query GetPerformance($id: ID!) {
    getPerformance(id: $id) {
      id
      weighted
      unweighted
      rank
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPerformances = /* GraphQL */ `
  query ListPerformances(
    $filter: ModelPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPerformances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        weighted
        unweighted
        rank
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNewsletter = /* GraphQL */ `
  query GetNewsletter($id: ID!) {
    getNewsletter(id: $id) {
      id
      email
      createdAt
      updatedAt
    }
  }
`;
export const listNewsletters = /* GraphQL */ `
  query ListNewsletters(
    $filter: ModelNewsletterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      note
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        note
        appId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($id: ID!) {
    getNotifications(id: $id) {
      id
      message
      category
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listNotificationss = /* GraphQL */ `
  query ListNotificationss(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        category
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      task
      due
      complete
      status
      year
      appId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        task
        due
        complete
        status
        year
        appId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      year
      awsId
      stripeCustomerId
      product
      subscription
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        year
        awsId
        stripeCustomerId
        product
        subscription
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      playbooks
      tasks
      favs
      applying
      locker
      onboarded
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        playbooks
        tasks
        favs
        applying
        locker
        onboarded
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTestScore = /* GraphQL */ `
  query GetTestScore($id: ID!) {
    getTestScore(id: $id) {
      id
      test
      score
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTestScores = /* GraphQL */ `
  query ListTestScores(
    $filter: ModelTestScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        test
        score
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getScholarship = /* GraphQL */ `
  query GetScholarship($id: ID!) {
    getScholarship(id: $id) {
      id
      name
      refSent
      tranSent
      appSent
      recieved
      org
      deadline
      source
      contact
      amount
      criteria
      documents
      essay
      note
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listScholarships = /* GraphQL */ `
  query ListScholarships(
    $filter: ModelScholarshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScholarships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        refSent
        tranSent
        appSent
        recieved
        org
        deadline
        source
        contact
        amount
        criteria
        documents
        essay
        note
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCollegeApp = /* GraphQL */ `
  query GetCollegeApp($id: ID!) {
    getCollegeApp(id: $id) {
      id
      schoolId
      name
      intended
      term
      status
      fee
      due
      category
      link
      note
      tasks {
        items {
          id
          task
          complete
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      location
      academics
      size
      distance
      extras
      social
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCollegeApps = /* GraphQL */ `
  query ListCollegeApps(
    $filter: ModelCollegeAppFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollegeApps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolId
        name
        intended
        term
        status
        fee
        due
        category
        link
        note
        tasks {
          nextToken
        }
        location
        academics
        size
        distance
        extras
        social
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAppTask = /* GraphQL */ `
  query GetAppTask($id: ID!) {
    getAppTask(id: $id) {
      id
      app {
        id
        schoolId
        name
        intended
        term
        status
        fee
        due
        category
        link
        note
        tasks {
          nextToken
        }
        location
        academics
        size
        distance
        extras
        social
        createdAt
        updatedAt
        owner
      }
      task
      complete
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAppTasks = /* GraphQL */ `
  query ListAppTasks(
    $filter: ModelAppTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        app {
          id
          schoolId
          name
          intended
          term
          status
          fee
          due
          category
          link
          note
          location
          academics
          size
          distance
          extras
          social
          createdAt
          updatedAt
          owner
        }
        task
        complete
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFav = /* GraphQL */ `
  query GetFav($id: ID!) {
    getFav(id: $id) {
      id
      schoolid
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFavs = /* GraphQL */ `
  query ListFavs(
    $filter: ModelFavFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        schoolid
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCalendar = /* GraphQL */ `
  query GetCalendar($id: ID!) {
    getCalendar(id: $id) {
      id
      event
      date
      type
      year
      month
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCalendars = /* GraphQL */ `
  query ListCalendars(
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalendars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event
        date
        type
        year
        month
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPlaybook1 = /* GraphQL */ `
  query GetPlaybook1($id: ID!) {
    getPlaybook1(id: $id) {
      id
      steps
      shortgoals
      longgoals
      how
      moregoals
      deeper
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPlaybook1s = /* GraphQL */ `
  query ListPlaybook1s(
    $filter: ModelPlaybook1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaybook1s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        steps
        shortgoals
        longgoals
        how
        moregoals
        deeper
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPlaybook2 = /* GraphQL */ `
  query GetPlaybook2($id: ID!) {
    getPlaybook2(id: $id) {
      id
      steps
      rankings
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPlaybook2s = /* GraphQL */ `
  query ListPlaybook2s(
    $filter: ModelPlaybook2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaybook2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        steps
        rankings
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPlaybook3 = /* GraphQL */ `
  query GetPlaybook3($id: ID!) {
    getPlaybook3(id: $id) {
      id
      steps
      qualities
      best
      experience
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPlaybook3s = /* GraphQL */ `
  query ListPlaybook3s(
    $filter: ModelPlaybook3FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaybook3s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        steps
        qualities
        best
        experience
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPlaybook4 = /* GraphQL */ `
  query GetPlaybook4($id: ID!) {
    getPlaybook4(id: $id) {
      id
      steps
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listPlaybook4s = /* GraphQL */ `
  query ListPlaybook4s(
    $filter: ModelPlaybook4FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlaybook4s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        steps
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getClass = /* GraphQL */ `
  query GetClass($id: ID!) {
    getClass(id: $id) {
      id
      name
      teacher
      grade
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listClasss = /* GraphQL */ `
  query ListClasss(
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClasss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        teacher
        grade
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRec = /* GraphQL */ `
  query GetRec($id: ID!) {
    getRec(id: $id) {
      id
      teacher
      email
      req
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRecs = /* GraphQL */ `
  query ListRecs(
    $filter: ModelRecFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teacher
        email
        req
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExtra = /* GraphQL */ `
  query GetExtra($id: ID!) {
    getExtra(id: $id) {
      id
      extra
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listExtras = /* GraphQL */ `
  query ListExtras(
    $filter: ModelExtraFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExtras(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        extra
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getExp = /* GraphQL */ `
  query GetExp($id: ID!) {
    getExp(id: $id) {
      id
      exp
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listExps = /* GraphQL */ `
  query ListExps(
    $filter: ModelExpFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        exp
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGTask = /* GraphQL */ `
  query GetGTask($id: ID!) {
    getGTask(id: $id) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      task
      suggestedby
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listGTasks = /* GraphQL */ `
  query ListGTasks(
    $filter: ModelGTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        task
        suggestedby
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGCollege = /* GraphQL */ `
  query GetGCollege($id: ID!) {
    getGCollege(id: $id) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      college
      suggestedby
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listGColleges = /* GraphQL */ `
  query ListGColleges(
    $filter: ModelGCollegeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGColleges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        college
        suggestedby
        note
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGScholarship = /* GraphQL */ `
  query GetGScholarship($id: ID!) {
    getGScholarship(id: $id) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      suggestedby
      deadline
      link
      note
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listGScholarships = /* GraphQL */ `
  query ListGScholarships(
    $filter: ModelGScholarshipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGScholarships(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        suggestedby
        deadline
        link
        note
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      name
      header
      messages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        header
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCMessage = /* GraphQL */ `
  query GetCMessage($id: ID!) {
    getCMessage(id: $id) {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      channel {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        name
        header
        messages {
          nextToken
        }
        createdAt
        updatedAt
      }
      content
      createdAt
      updatedAt
    }
  }
`;
export const listCMessages = /* GraphQL */ `
  query ListCMessages(
    $filter: ModelCMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        name
        user {
          id
          name
          year
          more
          email
          createdAt
          updatedAt
        }
        channel {
          id
          name
          header
          createdAt
          updatedAt
        }
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      owner
      members
      requests
      channels {
        items {
          id
          name
          header
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          task
          suggestedby
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      scholarships {
        items {
          id
          name
          suggestedby
          deadline
          link
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      colleges {
        items {
          id
          college
          suggestedby
          note
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          name
          members
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      channelmessages {
        items {
          id
          user_id
          name
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      conversations {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      year
      more
      email
      createdAt
      updatedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConvo = /* GraphQL */ `
  query GetConvo($id: ID!) {
    getConvo(id: $id) {
      id
      group {
        id
        name
        owner
        members
        requests
        channels {
          nextToken
        }
        tasks {
          nextToken
        }
        scholarships {
          nextToken
        }
        colleges {
          nextToken
        }
        conversations {
          nextToken
        }
        createdAt
        updatedAt
      }
      messages {
        items {
          id
          user_id
          name
          messageConversationId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      associated {
        items {
          id
          convoLinkMember
          convoLinkConversationId
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      members
      createdAt
      updatedAt
    }
  }
`;
export const getPrivateMessage = /* GraphQL */ `
  query GetPrivateMessage($id: ID!) {
    getPrivateMessage(id: $id) {
      id
      user_id
      name
      user {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      messageConversationId
      content
      createdAt
      updatedAt
    }
  }
`;
export const listPrivateMessages = /* GraphQL */ `
  query ListPrivateMessages(
    $filter: ModelPrivateMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrivateMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        name
        user {
          id
          name
          year
          more
          email
          createdAt
          updatedAt
        }
        conversation {
          id
          name
          members
          createdAt
          updatedAt
        }
        messageConversationId
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConvoLink = /* GraphQL */ `
  query GetConvoLink($id: ID!) {
    getConvoLink(id: $id) {
      id
      member {
        id
        channelmessages {
          nextToken
        }
        conversations {
          nextToken
        }
        messages {
          nextToken
        }
        name
        year
        more
        email
        createdAt
        updatedAt
      }
      convoLinkMember
      conversation {
        id
        group {
          id
          name
          owner
          members
          requests
          createdAt
          updatedAt
        }
        messages {
          nextToken
        }
        associated {
          nextToken
        }
        name
        members
        createdAt
        updatedAt
      }
      convoLinkConversationId
      createdAt
      updatedAt
    }
  }
`;
export const listConvoLinks = /* GraphQL */ `
  query ListConvoLinks(
    $filter: ModelConvoLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConvoLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        member {
          id
          name
          year
          more
          email
          createdAt
          updatedAt
        }
        convoLinkMember
        conversation {
          id
          name
          members
          createdAt
          updatedAt
        }
        convoLinkConversationId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const datesSorted = /* GraphQL */ `
  query DatesSorted(
    $type: String
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCalendarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    datesSorted(
      type: $type
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event
        date
        type
        year
        month
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
